import { Column } from '@ant-design/plots';
import React from 'react';

const IdeaSentimentDonut = ({positive, negative , neutral}) => {

  const positiveValue = parseFloat(positive);
  const negativeValue = parseFloat(negative);
  const neutralValue = parseFloat(neutral);


 const data = [
    { value: positiveValue, type: 'Positive' },
    { value: negativeValue, type: 'Negative' },
    { value: neutralValue, type: 'Neutral' },
  ];


  const config = {
    title: {
      open: true,
      text: 'Sentiment Analysis',
    },
    data,
    xField: 'type',
    yField: 'value',
    seriesField: 'type',
    legend: true,
    color: ({ type }) => {
      // Customize colors based on the series type
      if (type === 'Positive') {
        return '#52c41a'; // Green for 'Positive' series
      } else if (type === 'Neutral') {
        return '#d9d9d9'; // Custom color for 'Neutral' series
      } else if (type === 'Negative') {
        return '#f5222d'; // Custom color for 'Negative' series
      }
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    yAxis: {
      min: 0.0, // Ensure the y-axis starts at 0
      label: {
        formatter: (v) => `${Math.abs(v)}`, // Display the absolute value on the yAxis labels
      },
    },
  
  };
  
  return <Column  {...config} />;
};

 

export default IdeaSentimentDonut;