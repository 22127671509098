import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';

import IdeaDetail from './components/Idea/IdeaDetail';
import Home from './components/HomePage';
import Collect from './components/collect';
import Communicate from './components/communicate';
import Engage from './components/engage';
import Prioritize from './components/prioritize';
import Employee from './components/employee';
import Feature from './components/product';
import Intake from './components/intake';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import NewSignup from './components/NewSignup';
import MainFramework from './components/Layout/MainFramework';
import MainLayout from './components/Layout/MainLayout';
import CreateIdea from './components/Idea/CreateIdea';
import Insights from './components/Insights/Insights';
import ProductUserAnalytics from './components/Insights/User/ProductUser';
import ProductList from './components/Product/ProductList';
import ProductDetail from './components/Product/ProductDetail';
import CreateProduct2 from './components/Product/CreateProduct2';
 import IdeaLounge from './components/IdeaLounge/IdeaLounge';
 import Idea from './components/IdeaLounge/Idea';
import KeyInsight from './components/insight';
import SubmitIdea from './components/invitation/SubmitIdea';
import RewardDashboard from './components/Offer/Dashboard';
import Profile from './components/IdeaLounge/Profile'
import RewardLogin from './components/User/RewardLogin';
import ProductHome from './components/Product/ProductHome';
import MyActivity from './components/IdeaLounge/MyActivity';
import Favorite from './components/IdeaLounge/Favorite';


// import Profile from './components/IdeaLounge/Profile';
 
 
const APIAccess = lazy(() => import('./components/Settings/APIAccess'));



const Learn = lazy(() => import('./components/Learn'));
const Hypothesis = lazy(() => import('./components/hypothesis'));
const Prioritization = lazy(() => import('./components/prioritization'));
const KanoModel = lazy(() => import('./components/kanoModel'));
const Rice = lazy(() => import('./components/rice'));
const ValueModel = lazy(() => import('./components/valuemodel'));
const ABTest = lazy(() => import('./components/abtest'));
const Feedback = lazy(() => import('./components/Feedback'));
const Contact = lazy(() => import('./components/Contact'));
const About = lazy(() => import('./components/About'));
const Privacy = lazy(() => import('./components/Privacy'));
const Terms = lazy(() => import('./components/Terms'));
 
const Settings = lazy(() => import('./components/Settings/Settings'));
const Configs = lazy(() => import('./components/Settings/Configs'));

function App() {
  useEffect(() => {
    let user = localStorage.getItem('user');
    if (!user) {
      console.log('user not found');
      // this.props.router.push('/');
    }
  }, []);

  return (
    <Router>
   
      <Routes>
       
        <Route exact path="/" element={<Home />} />
        <Route path="/Features/Collect" element={<Collect />} />
        <Route path="/Features/Engage" element={<Engage />} />
        <Route path="/Features/Prioritize" element={<Prioritize />} />
        <Route path="/Features/Communicate" element={<Communicate />} />
        <Route path="/Features/Key-Insights" element={<KeyInsight />} />
        <Route path="/Usecases/Customer-Feature-Intake" element={<Intake />} />
        <Route path="/Usecases/Product-Development" element={<Feature />} />
        <Route path="/Usecases/Internal-Employee-Engagement" element={<Employee />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/newsignup" element={<NewSignup />} />
        <Route path="/login" element={<LoginPage />} />

        <Route path="/Research" element={<ProductHome />} />
        <Route path="/Research/Product/Add" element={<CreateProduct2 />} />
        <Route path="/Research/Product" element={<ProductList />} />
        <Route path="/Research/Product/:id" element={<ProductDetail />} /> 
        <Route path="/Research/Product/:product_id/Idea/:idea_id" element={<IdeaDetail />} /> 
        <Route path="/Research/Product/:product_id/Idea/:idea_id/Insights" element={<Insights />} />
        <Route path="/Research/Product/:product_id/Users" element={<ProductUserAnalytics />} />
        <Route path="/Research/Product/:product_id/Idea/:idea_id/RewardDashboard" element={<RewardDashboard />} />
        <Route path="/Reward/Lounge" element={<IdeaLounge />} />
        <Route path="/Reward/Idea/:product_id/:idea_id" element={<Idea />} />
        
        <Route path="/IdeaSubmission"  element={<SubmitIdea />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/Reward/Login" element={<RewardLogin />} />
        <Route path="/Reward/Activity" element={<MyActivity />} />
        <Route path="/Reward/Favorite" element={<Favorite />} />
        

        {/* <Route path="/profile" element={<Profile />} /> */}
      
 
        <Route path="/APIAccess" element={<Suspense fallback={<div>Loading...</div>}><APIAccess /></Suspense>} />
        <Route path="/feedback" element={<Suspense fallback={<div>Loading...</div>}><Feedback /></Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<div>Loading...</div>}><Contact /></Suspense>} />
        <Route path="/learn" element={<Suspense fallback={<div>Loading...</div>}><Learn /></Suspense>} />
        <Route path="/about" element={<Suspense fallback={<div>Loading...</div>}><About /></Suspense>} />
        <Route path="/privacy" element={<Suspense fallback={<div>Loading...</div>}><Privacy /></Suspense>} />
        <Route path="/terms" element={<Suspense fallback={<div>Loading...</div>}><Terms /></Suspense>} />
        <Route path="/Hypothesis" element={<Suspense fallback={<div>Loading...</div>}><Hypothesis /></Suspense>} />
        <Route path="/Prioritization" element={<Suspense fallback={<div>Loading...</div>}><Prioritization /></Suspense>} />
        <Route path="/KanoModel" element={<Suspense fallback={<div>Loading...</div>}><KanoModel /></Suspense>} />
        <Route path="/Rice" element={<Suspense fallback={<div>Loading...</div>}><Rice /></Suspense>} />
        <Route path="/ValueModel" element={<Suspense fallback={<div>Loading...</div>}><ValueModel /></Suspense>} />
        <Route path="/ABTest" element={<Suspense fallback={<div>Loading...</div>}><ABTest /></Suspense>} />
       
        <Route path="/settings" element={<Suspense fallback={<div>Loading...</div>}><Settings /></Suspense>} />
        <Route path="/configs" element={<Suspense fallback={<div>Loading...</div>}><Configs /></Suspense>} />
       
        <Route path="*" element={<Navigate to="/" replace />} />
        
      </Routes>
 
    </Router>
  );
}

export default App;
