import React, { useState, useEffect } from 'react';
import { Card, Button, Space, Modal, Form, Input, Select, Radio, Slider, Checkbox } from 'antd';

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const SurveyList = ({ survey, onUpdate, onDelete }) => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  // Sample surveys for testing
  const sampleSurveys = [
    {
      surveyId: 1,
      title: 'Customer Satisfaction',
      status: 'active',
      questions: JSON.stringify([
        { question: 'How satisfied are you?', type: 'Opinion Scale' },
        { question: 'Any suggestions?', type: 'Open Question' },
      ]),
    },
    {
      surveyId: 2,
      title: 'Product Feedback',
      status: 'inactive',
      questions: JSON.stringify([
        { question: 'What features do you like?', type: 'Open Question' },
        { question: 'Any improvements needed?', type: 'Multiple Choice', options: ['Option 1', 'Option 2', 'Option 3'] },
      ]),
    },
  ];

  useEffect(() => {
    // Fetch surveys from the backend and update the state
    // Example: fetchSurveys().then((data) => setSurveys(data));
    // For now, using sample surveys for demonstration
    setSurveys(sampleSurveys);
  }, []);

  const handleEditSurvey = (survey) => {
    setSelectedSurvey(survey);
    setModalVisible(true);
    form.setFieldsValue({
      title: survey.title,
      status: survey.status,
      questions: JSON.parse(survey.questions),
    });
  };

  const handleRemoveSurvey = (surveyId) => {
    // Remove the survey with ID surveyId from the backend and update the state
    console.log('Remove Survey with ID:', surveyId);
    onDelete(surveyId);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedSurvey(null);
    form.resetFields();
  };

  const handleSaveChanges = () => {
    // Extract form values and update the selected survey
    const updatedSurvey = {
      ...selectedSurvey,
      title: form.getFieldValue('title'),
      status: form.getFieldValue('status'),
      questions: JSON.stringify(form.getFieldValue('questions')),
    };
    console.log(updatedSurvey);
    // Update the survey in the state or send it to the backend for update
    // ...

    onUpdate(updatedSurvey);

    // Close the modal
    closeModal();
  };

  const renderQuestionInput = (question) => {
    switch (question.type) {
      case 'Open Question':
        return <Input placeholder="Type your answer" />;
      case 'Yes/No':
        return <Radio.Group>
          <Radio value="Yes">Yes</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>;
      case 'Opinion Scale':
        return <Slider marks={{ 1: '1', 5: '5', 10: '10' }} min={1} max={10} />;
      case 'Multiple Choice':
        return <Checkbox.Group options={question.options} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <h2>Survey List</h2>
      {surveys.map((survey) => (
        <Card key={survey.surveyId} title={survey.title} style={{ marginBottom: 16 }}>
          <p>Status: {survey.status}</p>
          <p>
            Questions:{' '}
            {JSON.parse(survey.questions).map((q) => `${q.question} (${q.type})`).join(', ')}
          </p>
          <Space>
            <Button type="primary" onClick={() => handleEditSurvey(survey)}>
              View/Edit
            </Button>
            <Button danger onClick={() => handleRemoveSurvey(survey.surveyId)}>
              Remove
            </Button>
          </Space>
        </Card>
      ))}

      {/* Modal for viewing/editing survey */}
      <Modal
        title={selectedSurvey ? 'Edit Survey' : 'Survey Details'}
        visible={modalVisible}
        onCancel={closeModal}
        onOk={handleSaveChanges}
        destroyOnClose
      >
        {/* Content of the modal */}
        <Form form={form} layout="vertical">
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter a title' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Please select a status' }]}>
            <Select>
              <Option value={1}>Active</Option>
              <Option value={0}>Inactive</Option>
            </Select>
          </Form.Item>
          <Form.List name="questions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'question']}
                      fieldKey={[fieldKey, 'question']}
                      rules={[{ required: true, message: 'Please enter a question' }]}
                    >
                      <Input placeholder="Enter Question" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      fieldKey={[fieldKey, 'type']}
                      rules={[{ required: true, message: 'Please select a type' }]}
                    >
                      <Select placeholder="Select Type">
                        <Option value="Open">Open Question</Option>
                        <Option value="Multiple">Multiple Choice</Option>
                        <Option value="Yes/No">Yes/No</Option>
                        <Option value="Scale">Opinion Scale</Option>
                        {/* Add other types as needed */}
                      </Select>
                    </Form.Item>
                    {renderQuestionInput(form.getFieldValue(['questions', name]))}
                    <Button type="link" onClick={() => remove(name)}>
                      Remove
                    </Button>
                  </Space>
                ))}
                {/* <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={fields.length >= 5}
                  >
                    Add Question
                  </Button>
                </Form.Item> */}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default SurveyList;
