import React, { useState, useEffect } from 'react';
import { Card,   Modal,Input, Space } from 'antd';

const PersonaAdmin = ({ persona, attitudes, onUpdate, onDelete }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatedName, setUpdatedName] = useState(persona.name);
  const [updatedDescription, setUpdatedDescription] = useState(persona.description);
  const [updatedAttitudes, setUpdatedAttitudes] = useState(JSON.stringify(attitudes));

 
  const handleUpdate = () => {
    // Extract only necessary fields for update
    const updatedPersona = {
      personaId: persona.personaId,
      name: updatedName,
      description: updatedDescription,
    };

    onUpdate(updatedPersona);
    setIsModalVisible(false); // Close the update modal
  };
 
 
  const handleDelete = () => {
    // Implement the logic to delete the persona
    onDelete(persona.personaId);
  };

  return (
    <Card key={persona.personaId} title={`Audience: ${persona.name}`} >
      <p><strong>Description:</strong> {persona.description}</p>
      <p><strong>Attitudes:</strong></p>
      {Array.isArray(attitudes) && attitudes.length > 0 ? (
        attitudes.map((attitude, index) => (
          <p key={index}>{attitude && attitude.attitude}</p>
        ))
      ) : (
        <p>No attitudes available.</p>
      )}

    <Space>  <button type="primary" onClick={() => setIsModalVisible(true)}>
        Update
      </button>
      <button type="danger" onClick={handleDelete}>
        Delete
      </button>
      </Space>
      {/* Modal for Update */}
  {/* Modal for Update */}
  <Modal
        title={`Update Persona: ${persona.name}`}
        visible={isModalVisible}
        onOk={handleUpdate}
        onCancel={() => setIsModalVisible(false)}
      >
        <label>
          Name:
          <Input
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Description:
          <textarea
            value={updatedDescription}
            onChange={(e) => setUpdatedDescription(e.target.value)}
          />
        </label>
       
      </Modal>
    </Card>
  );
};

export default PersonaAdmin;
