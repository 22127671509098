import { Breadcrumb, Layout, Menu, theme } from 'antd';
import React from 'react';
import UserHeader2 from './UserHeader2';
import { useNavigate } from 'react-router-dom';
import  Footer  from './Footer';

 

const { Header, Content, Sider } = Layout;
 


const items2 =  [
  {
    label: 'Hypothesis',
    key: 'Hypothesis',
  
    },
    {
      label: 'Prioritization',
      key: 'Prioritization',
    
      },
  {
    label: 'IDEALY Value Model',
    key: 'ValueModel',
  
    },
  {
  label: 'R.I.C.E Score Framework',
  key: 'rice',

  },
  {
    label: 'KanoModel',
    key: 'KanoModel',
  
    },

  {
    label: 'A/B Testing',
    key: 'ABTest',
  
    },


]

 
 
 

const MainLayout = ({children}) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  
  const navigate = useNavigate();
const onClick = (e)=>{navigate(`/${e.key}`)}
   

  return (
    <Layout>
   
     < UserHeader2/>
   
      <Content
        style={{
          padding: '0 50px',
        }}
      >
        <Breadcrumb
            style={{
              margin: '10px 20px',
            }}
          >
         
        <Breadcrumb.Item href={"../product-list/"}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Learn</Breadcrumb.Item>
      </Breadcrumb>
        <Layout
          style={{
            padding: '24px 0',
            background: colorBgContainer,
          }}
        >
          <Sider
            style={{
              background: colorBgContainer,
            }}
            width={200}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{
                height: '100%',
              }}
              items={items2}
              onClick = {onClick}
            />
          </Sider>


          <Content
            style={{
              padding: '0 24px',
              minHeight: 1080,
            }}
          >
    {children}


          </Content>
        </Layout>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
 
      </Footer>
    </Layout>
  ); 
      
};
export default MainLayout;
