import {
	CloseOutlined,
	CommentOutlined,
	DislikeFilled,
	DislikeOutlined,
	EditOutlined,
	LikeFilled,
	LikeOutlined,
	SaveOutlined,
} from "@ant-design/icons";
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Divider,
	Image,
	Input,
	Row,
	Space,
	Spin,
	Tag,
	Tooltip,
	Typography,
	Dropdown,
	Form, Modal, message, Layout, Tabs
} from "antd";
import axios from "axios";
import { formatRelative, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminView from "../Engagement/AdminView";
import PollPanel from "../Engagement/PollPane";
import PriorityPicksPanel from "../Engagement/PriorityPicksPanel";

import AddComment from "../Idea/AddComment";
import AddVariationModal from "./AddVariations";
import CommentsCard from "../Idea/CommentsCard";
import VariationCard from "../Idea/Variation";
import UserHeader2 from "../Layout/UserHeader2";
import PersonaModal from "../User/PersonaModal";
import getCommentsArray from "./getCommentsArray";
import ShareLinkButton from "../Engagement/ShareLinkButton";
import AdminVariation from "../Engagement/AdminVariation";
import AddFeature from "../CardSort/AddFeature";
import SurveyDashboard from "../Survey/SurveyDashboard";
import '../../index.css';
import Persona from "../User/Persona";
import KeyFeatures from "./KeyFeatures";
import TagInput from "./TagInput";
import getCategoryAttributes from "./Categories"


const { Content, Header } = Layout;
const {  Text } = Typography;
const { TextArea } = Input;

const { TabPane } = Tabs;

const IdeaDetail = () => {
	const { product_id, idea_id } = useParams();
	const [loadingRefineMe, setLoadingRefineMe] = useState(false);
	const [loadingVintage, setLoadingVintage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [ideaStatus, setStatus] = useState("");
	const [totalVotes, setVote] = useState(0);
	const [userID, setUserID] = useState(""); // Set the user ID from your authentication
	const [idea, setIdea] = useState({});
	const [voteSubmitted, setVoteSubmit] = useState(false);
	const [description, setDescription] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [isStoryEditing, setStoryEditing] = useState(false);
	const [customerVintage, setCustomerVintage] = useState("");
	const [isPersonaModalVisible, setPersonaModalVisible] = useState(false);
	const [downVoteSubmitted, setDownVoteSubmit] = useState(false);
	const navigate = useNavigate(); // Access the history object
	const [fileList, setFileList] = useState([]);
	const [image_urls, setImage_urls] = useState([]);
	const [visible, setVisible] = useState(false);
	const [error, setError] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [variations, setVariation] = useState([]);
	const [companyId, setCompanyId] = useState(0);
	const [form] = Form.useForm();
	const [keyFeatures, setKeyFeatures]= useState([]);
	const [tags, setIdeaTags] = useState([]);
	const [isEditingTitle, setIsEditingTitle] = useState(false);
	const [editedTitle, setEditedTitle] = useState("");


 

	const checkUserPersona = async () => {
		try {
			const response = { data: null }; //await axios.get(`${process.env.REACT_APP_API_URL}/user/${userID}/persona`);
			if (!response.data) {
				// If no persona is assigned, show the modal
				setPersonaModalVisible(true);
			}
		} catch (error) {
			console.error("Error checking user persona:", error);
		}
	};

	const handlePersonaModalClose = () => {
		// Refresh the page or perform any necessary action after persona selection
		// window.location.reload();
		setPersonaModalVisible(false);
	};

	const handleBackButtonClick = () => {
		navigate(`/Research/Product/${product_id}`); // Navigate to the product detail page
	};

	const handleFileChange = (event) => {
		setFileList([...event.target.files]);
	};

	const handleUpload = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			fileList.forEach((file) => {
				formData.append("images", file);
			});

			const apiUrl = `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/images`;

			const user_access_token = localStorage.getItem("user_access_token");
			const response = await axios.post(apiUrl, formData, {
				headers: {
					Authorization: `Bearer ${user_access_token}`,
					"Content-Type": "multipart/form-data", // Make sure to set content type
				},
			});

			// Assuming the response.data contains an array of image URLs
			setImage_urls(response.data.urls);

			setFileList([]); // Clear the upload queue
			// Clear the file input element
			const fileInput = document.getElementById("file-input");
			if (fileInput) {
				fileInput.value = null;
			}
		} catch (error) {
			console.error("Error uploading images:", error);
		} finally {
			setLoading(false); // Set loading back to false regardless of success or error
		}
	};

	// Event Handlers
	const handleRefineIdea = async () => {
		try {
			setLoadingRefineMe(true);
			const user_access_token = localStorage.getItem("user_access_token");

			const commentData = await getCommentsArray(product_id, idea_id);
			const combinedMessagesArray = ["Comments:"].concat(
				commentData
					.filter((message) => !message.text.includes("Status Changed"))
					.map((message) => message.text)
			);

			const combinedText = combinedMessagesArray.join("\n");

			const payload = {
				idea: description,
				comments: combinedText,
			};

			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}product/${product_id}/idea/refine`,
				payload,
				{
					headers: {
						Authorization: `Bearer ${user_access_token}`,
					},
				}
			);

			if (response.status === 200) {
				setDescription(response.data);
			}
		} catch (error) {
			console.error("Error adding comment:", error);
			// Handle error as needed
		} finally {
			setLoadingRefineMe(false); // Set loading back to false after the request is completed
		}

		setIsEditing(true);
	};

	const handleEditButtonClick = () => {
		setIsEditing(true);
	};

	const handleStoryEditButtonClick = () => {
		setStoryEditing(true);
	};

	const handleCancelEdit = () => {
		setIsEditing(false);
		setDescription(idea.description);
	};

	const handleStoryCancelEdit = () => {
		setStoryEditing(false);
		setCustomerVintage(idea.userstory);
	};

	const handleSaveButtonClick = () => {
		setIsEditing(false);

		setDescription(description);
		updateIdea("description", description);
		// Add logic to save the updated description, e.g., update the backend or state
	};

	const handleStorySaveButtonClick = () => {
		updateIdea("userstory", customerVintage);
		setStoryEditing(false);

		// setCustomerVintage(value);
		// Add logic to save the updated description, e.g., update the backend or state
	};

	const handleTitleEdit = () => {
		setIsEditingTitle(true);
	  };
	
	  const handleTitleSave = () => {
		setIsEditingTitle(false);
		updateIdea("title", editedTitle);
	  };
	
	  const handleTitleChange = (e) => {
		setEditedTitle(e.target.value);
	  };

	  const handleTitleCancel = () => {
		setIsEditingTitle(false);
	  };


	  

	const updateIdea = async (paramToUpdate, valueToUpdate) => {
		try {
			const payload = {};

			// Depending on the parameter to update, assign the value to the payload
			if (paramToUpdate === "description") {
				payload.description = valueToUpdate;
			} else if (paramToUpdate === "url") {
				payload.url = valueToUpdate;
			} else if (paramToUpdate === "userstory") {
				payload.userstory = valueToUpdate;
			} else if (paramToUpdate === "tags") {
				payload.tags = valueToUpdate.tags;
			} 
			
			else if (paramToUpdate === "features") {
				payload.features = valueToUpdate.features;
			} 
			else if (paramToUpdate === "title") {
				payload.title = valueToUpdate;
			} 
			else {
				console.error("Invalid parameter to update");
				return; // Early return if the parameter is not valid
			}

			var accessToken = localStorage.getItem("user_access_token");

			const response = await axios.put(
				`${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}`,
				payload,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);

			if (response.status === 200) {
console.log("Idea updated");

			} else {
			}
		} catch (error) {
			console.error(error);
			if (error.response) {
				if (error.response.status === 403) {
					setError(
						"Oops! Looks like you do not have permissions to update the ideas."
					);
				} else if (error.response.status === 401) {
					setError("Oops! Please log in to update ideas.");
				}
			} else {
				setError("Something went wrong. Please try again!");
			}
		}
	};

	const generateCustomerVintage = async () => {
		try {
			setLoadingVintage(true);
			const user_access_token = localStorage.getItem("user_access_token");

			const payload = {
				idea: description,
			};

			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}product/${product_id}/idea/vintage`,
				payload,
				{
					headers: {
						Authorization: `Bearer ${user_access_token}`,
					},
				}
			);

			if (response.status === 200) {
				setCustomerVintage(response.data);
				setStoryEditing(true);
			}
		} catch (error) {
			console.error("Error adding comment:", error);
			// Handle error as needed
		} finally {
			setLoadingVintage(false); // Set loading back to false after the request is completed
		}
	};

	const formatDate = (date) => {
		try {
			const parsedDate = parseISO(date);
			return formatRelative(parsedDate, new Date(), { addSuffix: true });
		} catch (error) {
			// Handle invalid date format here
			return "Invalid date";
		}
	};

	const onDownVote = async () => {
		try {
			const payload = { idIdea: idea_id };
			let accessToken = localStorage.getItem("user_access_token");

		
			const res = await axios.post(
				process.env.REACT_APP_API_URL +
					"product/" +
					product_id +
					"/idea/" +
					idea_id +
					"/downVote",
				payload,
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			);

			if (res.status === 200) {
				toast.success("Voted successfully", { autoClose: 5 });
				setVote(res.data.totalVotes);
				setDownVoteSubmit(true);
				setVoteSubmit(false);
				checkUserPersona();
			}
		} catch (error) {
			// Handle errors here
			console.error(error);
		}
	};

	const onClickVote = async () => {
		try {
			const payload = { idIdea: idea_id };
			const user_access_token = localStorage.getItem("user_access_token");
			const res = await axios.post(
				process.env.REACT_APP_API_URL +
					"product/" +
					product_id +
					"/idea/" +
					idea_id +
					"/vote",
				payload,
				{
					headers: {
						Authorization: "Bearer " + user_access_token,
					},
				}
			);

			if (res.status === 200) {
				toast.success("You have been Voted successfully", { autoClose: 5 });
				setVote(res.data.totalVotes);
				setVoteSubmit(true);
				setDownVoteSubmit(false);
				checkUserPersona();
			}
		} catch (error) {
			// Handle errors here
			console.error(error);
		}
	};

	// useEffect(() => {
	//   getData();
	// }, [idea_id]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (idea_id) {
					const user_access_token = localStorage.getItem("user_access_token");

					// For Idea detail
					const response = await axios.get(
						process.env.REACT_APP_API_URL +
							"product/" +
							product_id +
							"/idea/" +
							idea_id +
							"/short",
						{
							headers: {
								Authorization: "Bearer " + user_access_token,
							},
						}
					);

					setIdea(response.data);
					setStatus(response.data.status);
					setVote(response.data.votes);
					setVoteSubmit(false);
					setDescription(response.data.description);
					setCustomerVintage(response.data.userstory);
					setEditedTitle( response.data.title)

					const imageUrlString = response.data.url;
					

					if (response.data.tags) {
						setIdeaTags(response.data.tags.split(','));
					  }
					  if (response.data.features) {
						setKeyFeatures(response.data.features.split(','));
					  }
					// Check if the string is not empty before splitting
					if (imageUrlString) {
						setImage_urls(imageUrlString.split(","));
					}


					console.log(response.data);	
				
				}
			} catch (error) {
				console.error("Error fetching idea data:", error);
			}
		};

	

		// checkUserPersona();
		fetchData();
		fetchVariations();
	}, [product_id, idea_id]);

	const fetchVariations = async () => {
		try {
			if (idea_id) {
				const user_access_token = localStorage.getItem("user_access_token");

				// For Idea detail
				const response = await axios.get(
					process.env.REACT_APP_API_URL +
						"product/" +
						product_id +
						"/idea/" +
						idea_id +
						"/variation",
					{
						headers: {
							Authorization: "Bearer " + user_access_token,
						},
					}
				);

				setVariation(response.data);
				console.log(response.data);
			}
		} catch (error) {
			console.error("Error fetching idea data:", error);
		}
	};

	const onVote = async ({ ideaid, vote }) => {
		try {
			const payload = { poll: vote };
			console.log("poll", vote, "ideaid", ideaid);

			const user_access_token = localStorage.getItem("user_access_token");
			const res = await axios.post(
				process.env.REACT_APP_API_URL +
					"product/" +
					product_id +
					"/idea/" +
					idea_id +
					"/variation/" +
					ideaid +
					"/poll",
				payload,
				{
					headers: {
						Authorization: "Bearer " + user_access_token,
					},
				}
			);

			if (res.status === 200) {
				toast.success("You have been Voted successfully", { autoClose: 5 });
			}
		} catch (error) {
			// Handle errors here
			console.error(error);
		}
	};

	const onDelete = async ({ variationId }) => {
		try {
			const payload = {   };
			 

			const user_access_token = localStorage.getItem("user_access_token");
			const res = await axios.delete(
				process.env.REACT_APP_API_URL +
					"product/" +
					product_id +
					"/idea/" +
					idea_id +
					"/variation/" +
					variationId  ,
			 
				{
					headers: {
						Authorization: "Bearer " + user_access_token,
					},
				}
			);

			if (res.status === 200) {
				toast.success("Deletion successfully", { autoClose: 5 });
				fetchVariations();
			}
		} catch (error) {
			// Handle errors here
			console.error(error);
		}
	};


	const handleItemClick = (ideaId, productId) => {
		// Handle the click, e.g., navigate to the idea details page
		console.log(`Clicked on idea with ID: ${ideaId}`);
		window.open(`/idea/${productId}/${ideaId}`, '_blank');
	  };

	  const onClickStatus = async (value) => {
		try {
		  const payload = {
			ideaStatus: value,
		  };
		  const user_access_token = localStorage.getItem("user_access_token");
		  const response = await axios.post(
			`${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/status`,
			payload,
			{
			  headers: {
				Authorization: `Bearer ${user_access_token}`,
			  },
			}
		  );
	
		  if (response.status === 200) {
			 
	
			form.setFieldsValue({
			  Comment: "",
			});
	
			//  open the modal
	
			Modal.confirm({
			  title: "Change Status",
			  content: (
				<div>
				  <p>New Status: {value}</p>
				  <Form form={form} name="control-hooks">
					<Form.Item name="Comment" label="">
					  <TextArea
						placeholder="Enter your comment (optional)"
						autoSize={{ minRows: 3 }}
						style={{ width: "100%" }}
					  />
					</Form.Item>
				  </Form>
				</div>
			  ),
			  onOk: async () => {
				// Handle OK button click
				await AddStatusComment(value, form.getFieldValue("Comment"));
			  },
			  onCancel: () => {
				// Handle modal cancellation if needed
	
			  },
			});
		  }
		} catch (error) {
		  console.error("Error updating status:", error);
	
		  // Check for permission-related error (403) and provide feedback to the user
		  if (error.response && error.response.status === 403) {
			message.error(
			  "Permission Denied. You do not have the necessary permissions."
			);
		  } else {
			// Handle other errors as needed
			message.error(
			  "An error occurred while updating the status. Please try again."
			);
		  }
		}
	  };

	  const AddStatusComment = async (ideaStatus, comment) => {
		try {
		  const user_access_token = localStorage.getItem("user_access_token");
		  const commentString =
			"Status Changed to: " +
			ideaStatus +
			"\n\nProduct Owner Comments: " +
			comment;
		  const payload = {
			text: commentString,
		  };
	
		  const response = await axios.post(
			`${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/comment`,
			payload,
			{
			  headers: {
				Authorization: `Bearer ${user_access_token}`,
			  },
			}
		  );
	
		  if (response.status === 200) {
		  }
		} catch (error) {
		  console.error("Error adding comment:", error);
		  // Handle error as needed
		}
	  };

	  
	return (
		<Layout>
		<Layout>
		  <Header
					style={{
					  position: 'sticky',
					  top: 0,
					  zIndex: 1,
					  width: '100%',
					  display: 'flex',
					  
					}}
	
				  > 
				 
					<UserHeader2 />
		 
		   </Header>
	   <Content
		  style={{
			flex: '1',
			padding: '25px 15px',
			background: 'white',
		  }}
		>

<Tabs defaultActiveKey="1" >
      <TabPane tab="Manage Feedback" key="1"  >
	  <Row gutter={[16, 16]}>
					<Col xs={24} sm={24} md={24} lg={24}>
						<div style={{ height: "100%" }}>
							<Card
								
								actions={[
									<>
										{" "}
										<span
										style={{
											fontSize: "18px",
											fontWeight: "bold",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
	<Tag color="#FF6F61">
											{" "}
											<Tooltip title="Status">
												 IDEA Stage:
											</Tooltip>
										</Tag>{" "}
	 <select
      value={idea.status}
      onChange={(e) => onClickStatus(e.target.value)}
      style={{ border: "1px gray solid", width: '200px' }}
    >
      <option value="New">New</option>
      <option value="Research">Research</option>
      <option value="In Production">In Production</option>
      <option value="Launched">Launched</option>
      <option value="On Hold">On Hold</option>
      <option value="In Planning">In Planning</option>
      <option value="Cancelled">Cancelled</option>
    </select>

									</span>,
									</>,


<>
<span>

<button type='primary' onClick={() => window.location.href=`/Research/Product/${product_id}/Idea/${idea_id}/RewardDashboard?title=${encodeURIComponent(idea.title)}`}>
Manage Reward Offer
</button>
 

</span>

</>

,
								
									<span
										style={{
											fontSize: "18px",
											fontWeight: "bold",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Tag color="#FF6F61">
											{" "}
											<Tooltip title="Date IDEA Submitted on">
												{formatDate(idea.createdOn)}
											</Tooltip>
										</Tag>{" "}
									</span>,
								]}
							>
								 
									{/* First Column */}
<Col>
										{/* First Row */}
										<Space style={{ marginBottom: "16px" }}>
											<button onClick={handleBackButtonClick}>Back</button>
										</Space>
										<br />
										<Space align="center">
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
											>
												
															 
															<div>
																{isEditingTitle ? (
																	<>
																	<input
																		type="text"
																		value={editedTitle}
																		onChange={handleTitleChange}
																	/>
																	<button onClick={handleTitleSave}>Save</button>
																	<button onClick={handleTitleCancel}>cancel</button>
																	</>
																) : (
																	<>
																<h3 style={{ margin: "0", marginRight: "5px" }}> {editedTitle} 	<EditOutlined
																  onClick={handleTitleEdit}/>
															</h3>
													 
																 
																	</>
																)}
																</div>
												 
												   <ShareLinkButton productId={product_id} ideaId={idea_id} />


												
       
         
												<span
													role="link"
													tabIndex={0}
													onClick={handleRefineIdea}
													style={{
														color: "#1c3048ff",
														textDecoration: "underline",
														cursor: "pointer",
														outline: "none",
														fontSize: "16px",
													}}
												>
													<Tag style={{ marginLeft: "5px" }}>Ask Max!</Tag>{" "}
													{loadingRefineMe ? <Spin size="large" /> : null}
												</span>
											</div>
										</Space>

										<h4
											style={{
												marginTop: 8,
												marginBottom: 8,
												marginRight: "5px",
											}}
										>
											Product Concept
										</h4>

										{isEditing ? (
											<>
												<TextArea
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													autoSize={{ minRows: 3 }}
													style={{
														marginTop: 16,
														marginBottom: 16,
														padding: "16px",
														backgroundColor: "#1c3048ff",
														borderRadius: "5px",
														color: "#fff",
													}}
												/>
												<a href="#" onClick={handleSaveButtonClick}>
													<SaveOutlined />{" "}
												</a>
												<a href="#" onClick={handleCancelEdit}>
													<CloseOutlined />
												</a>
											</>
										) : (
											<div
												style={{
													marginTop: 16,
													marginBottom: 16,
													padding: "16px",
													backgroundColor: "#1c3048ff",
													borderRadius: "5px",
												}}
											>
												<Space>
													<Text style={{ marginLeft: 8, color: "#fff" }}>
														{description}
														<a
															href="#"
															onClick={handleEditButtonClick}
															style={{ marginLeft: 8 }}
														>
															<EditOutlined
																style={{ marginLeft: 8, color: "#fff" }}
															/>
														</a>
													</Text>
												</Space>
											</div>
										)}
</Col>
<Col  >

 <TagInput masterTagList={getCategoryAttributes(idea.productType)} existingTags={idea.tags} 
 updateTag={(payload) => updateIdea("tags", payload)}
 />
				 

<KeyFeatures keyFeatures={keyFeatures} 
 saveFeatures={(payload) => updateIdea("features", payload)}
/>
 
</Col>

<Col >

										{/* Second Row */}
 
												<Space>
											 
													<h4
														style={{
															marginTop: 8,
															marginBottom: 8,
															marginRight: "5px",
														}}
													>
														{" "}
														<Tooltip title="Get ready for an amazing story—all about you! Discover how a cool idea makes a big difference in your experience. See how it perfectly meets your needs and creates a future you'll love!">
															{" "}
															Story Board
														</Tooltip>
													</h4>
													<a onClick={generateCustomerVintage}>
														<Tag>Ask Max!</Tag>
													</a>
												</Space>

												{isStoryEditing ? (
													<>
														<TextArea
															className="idea-detail"
															value={customerVintage}
															onChange={(e) =>
																setCustomerVintage(e.target.value)
															}
															autoSize={{ minRows: 3 }}
															style={{
																marginTop: 16,
																marginBottom: 16,
																padding: "16px",
																backgroundColor: "#1c3048ff",
																borderRadius: "5px",
																color: "#fff",
															}}
														/>
														<a
															href="#"
															onClick={handleStorySaveButtonClick}
															style={{ marginLeft: 8 }}
														>
															<SaveOutlined />{" "}
														</a>
														<a
															href="#"
															onClick={handleStoryCancelEdit}
															style={{ marginLeft: 8 }}
														>
															<CloseOutlined />
														</a>
													</>
												) : (
													<div
														style={{
															marginTop: 16,
															marginBottom: 16,
															padding: "16px",
															backgroundColor: "#1c3048ff",
															borderRadius: "5px",
														}}
													>
														{loadingVintage ? (
															<Spin size="large" />
														) : (
															<div>
																{customerVintage && (
																	<Text
																		strong // Make the text bold
																		style={{ marginLeft: 8, color: "#fff" }}
																	>
																		{customerVintage}
																	</Text>
																)}
															</div>
														)}

														<a
															href="#"
															onClick={handleStoryEditButtonClick}
															style={{ marginLeft: 8, color: "#1c3048ff" }}
														>
															<EditOutlined />
														</a>
													</div>
												)}
 
</Col>

									{/* Second Column */}
<Col xs={24} sm={24} md={7} lg={7} xl={7}>
                      {/* Image Widget */}
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


                        <Image
                          preview={{ visible: false }}
                          width='50%' // Adjust the width to 100%
                          src={image_urls[0]}
                          onClick={() => setVisible(true)}
                        />
                        <div style={{ display: 'none' }}>
                          <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                            {image_urls.map((url, index) => (
                              <Image key={index} src={url} />
                            ))}
                          </Image.PreviewGroup>
                        </div>
                      </div>
					  <Space style={{ width: "100%" }}>
													<input
														type="file"
														accept="image/*"
														multiple
														onChange={handleFileChange}
													/>
													<Divider />
													<button onClick={handleUpload}>Upload</button>
												</Space>
 </Col>
							 
							</Card>
							
							
							


							<br />

							{/*  add ideaVariations */}
							<Card >
								<h3> Variations.</h3>
								{/* Button to trigger the modal */}
								<button onClick={() => setIsModalVisible(true)}>
									Add Variation
								</button>

								{/* Modal for adding variations */}
								<AddVariationModal
									visible={isModalVisible}
									onCancel={() => setIsModalVisible(false)}
									ideaId={idea_id}
									productId={product_id}
									onUpdate={()=>fetchVariations()}
								/>

								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										gap: "16px",
										marginTop: "5px",
									}}
								>
									{variations.map((variation) => ( <>
								 
										<AdminVariation
											
										key={variation.idvariation}
										title={variation.title}
										description={variation.description}
										variationId={variation.idvariation}
										onVote={onVote}
										image={variation.imageurl}
										onDelete={onDelete}
											/>
</> 
									))}
								</div>
							</Card>

							<br />
							{/*  add ideaVariations */}
							<Card >
								<h3> Poll</h3>
								{/* Button to trigger the modal */}
								<div
										style={{
										display: "flex",
										flexWrap: "wrap",
										gap: "16px",
										marginTop: "5px",
										}}
  >
									
									<AdminView idea_id={idea_id} product_id={product_id} />
					 

								</div>
							</Card>
							<br/>
							<Card >
								<h3> Add Sorting Feature Card</h3>
								{/* Button to trigger the modal */}
								<div
										style={{
										display: "flex",
										flexWrap: "wrap",
										gap: "16px",
										marginTop: "5px",
										}}
  >
									
									<AddFeature idea_id={idea_id} product_id={product_id} />
							 

								</div>
							</Card>

						<br/>
						
						{/* <Card >
								<h3> Add a Short Survey</h3>
							 
								<div
										style={{
										display: "flex",
										flexWrap: "wrap",
										gap: "16px",
										marginTop: "5px",
										}}
  >
	
	<SurveyDashboard idea_id={idea_id} product_id={product_id}/>

								</div>
							</Card> 
							
							*/}
							
							<br />

							<Card >
								<Col xs={24} sm={24} md={24} lg={24}>
									<h3>
										<CommentOutlined /> Conversations.
									</h3>
									<AddComment product_id={product_id} idea_id={idea_id} />
								</Col>
								<Col xs={24} sm={24} md={24} lg={24}>
									<CommentsCard
										product_id={product_id}
										idea_id={idea_id}
										owner={idea.owner}
									/>
								</Col>
							</Card>
						</div>
					</Col>
				</Row>
      </TabPane>
      <TabPane tab="Audience Management" key="persona"  >
        <Persona product_id={product_id}  />
      </TabPane>
      
      
    </Tabs>

			
			 

			{/* PersonaModal */}
			<PersonaModal
				userID={userID}
				isVisible={isPersonaModalVisible}
				onClose={handlePersonaModalClose}
			/>

</Content>
  </Layout>
    </Layout>
	 
	);
};

export default IdeaDetail;
