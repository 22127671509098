import { Button, Col, Form, Input, Layout, Row, Select, Steps, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import handleSubmitProduct from './handleSubmitProduct';
import { Link } from 'react-router-dom';
 
const { Content } = Layout;
const { Step } = Steps;



const OnboardingForm = ({ onFinish }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  const [currentStep, setCurrentStep] = useState(0);
  const [productName, setProductName] = useState('');
  const [productID, setProductID] = useState('');
  const [description, setDescription] = useState('');
  const [vision, setVision] = useState('');
  const [why, setWhy] = useState('');
  const [tenets, setTenets] = useState('');
  const [customers, setCustomers] = useState('');
  const [flywheel, setFlywheel] = useState('');
  const [differentiator, setDifferentiator] = useState('');
  const [type, setType] = useState('');
  const spaceOptions = [

    'Health and Wellness Products',
    'Pet Care Items',
    'Baby Care Products',
    'Snack Foods',
    'Frozen Foods',
    'Dairy Products',
    'Bakery Goods',
    'Confectionery',
    'Breakfast Cereals',
    'Beverages',
    'Personal Care Products',
    'Household Cleaning Products',
    'Beauty and Cosmetics',
    'Apparel and Accessories',
    'Home and Kitchen Essentials',
    'Office and School Supplies',
    'Electronics and Gadgets',
    'Other'
      ];

  useEffect(() => {
    setCurrentStep(0);

  }, []);

  const handleNext = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else if (currentStep === 1) {
      // Step 2 validation before moving to Step 3
      if (productName.trim() !== '' && type.trim() !== '') {
        setCurrentStep(2);
      } else {
        
      }
    } else if (currentStep === 2) {
   
      // Step 3 validation before moving to Step 4
      // Add Step 3 validation logic here if needed
      setCurrentStep(3);
    } else if (currentStep === 3) {
      // Step 4 validation before submitting the form
      // Add Step 4 validation logic here if needed

      handleSubmitProduct(productName, productID, description, vision, why, customers, flywheel, differentiator, tenets, type)
        .then((data) => {
         
          window.location.href = '/product-list';
        })
        .catch((error) => {
          // Handle error response
          console.error('Error adding product:', error);
          window.location.href = '/product-list';
        });

      onFinish();
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };



  const renderStep3Details = () => {
    return (
      <div style={{ marginTop: '20px' }}>
     
        <p><strong>Product Vision:</strong></p>
        <div dangerouslySetInnerHTML={{ __html: vision }} />
        <p><strong>Why does your Product exist?</strong></p>
        <div dangerouslySetInnerHTML={{ __html: why }} />
        <p><strong>What are your top Product Tenets?</strong></p>
        <div dangerouslySetInnerHTML={{ __html: tenets }} />
        <p><strong>Who are your Primary Customers?</strong></p>
        <div dangerouslySetInnerHTML={{ __html: customers }} />
        <p><strong>What are your Fly Wheel Drivers?</strong></p>
        <div dangerouslySetInnerHTML={{ __html: flywheel }} />
        <p><strong>What is your Key Differentiator?</strong></p>
        <div dangerouslySetInnerHTML={{ __html: differentiator }} />
      </div>
    );
  };


  const handleSelectChange = (value) => {
    setType(value);
  };

  const WelcomeScreen = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Welcome to IDEALY!</h1>
        <p>Your IDEA Feedback & Evaluation Platform. Let's get started.</p>
        {/* <button type="primary" size="large" onClick={handleNext}>Start</button> */}
        <Link to="/Research/Product/Add">
      <button type="primary" size="large">
        Start
      </button>
    </Link>
      </div>
    );
  };
  
  const [form] = Form.useForm();

  return (
    <div style={{ padding: '20px' }}>

<WelcomeScreen />

      {/* <Steps current={currentStep}>
        <Step title="Step 1" description="Welcome!" />
        <Step title="Step 2" description="Add your first product." />
        <Step title="Step 3" description="Submit your first Idea" />
      </Steps>
      {currentStep === 0 && <WelcomeScreen />}
      {currentStep === 1 && (
 <Form form={form} style={{ marginTop: '20px' }}>
 <Row gutter={[16, 16]}>
   <Col span={24}>
     <Form.Item label='Give your product space a name' name='productName' rules={[{ required: true, message: 'Please input the product name!' }]}>
       <Input style={{ width: '100%' }} value={productName} onChange={(e) => setProductName(e.target.value)} />
     </Form.Item>
   </Col>
      {/* New Dropdown for selecting product type */}
      {/* <Col span={24}>
   <Form.Item name="product_type" label="What kind of a space is it?" rules={[{ required: true,  message: 'Please tell us about the Space' }]}>
        <Select onChange={handleSelectChange} dropdownRender={(menu) => <div>{menu}</div>}>
          {spaceOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
   </Col>
  
   <Col span={24}>
     <Form.Item label='Tell us more about this space' name='description' rules={[{   message: 'Please input the description!' }]}>
       <Input.TextArea style={{ width: '100%' }} value={description} onChange={(e) => setDescription(e.target.value)} />
     </Form.Item>
   </Col>
   <Col span={24}>
     <Form.Item label='An optional Identifier' name='productID' rules={[{   message: 'Please input the product ID!' }]}>
       <Input style={{ width: '100%' }} value={productID} onChange={(e) => setProductID(e.target.value)} />
     </Form.Item>
   </Col>
   {/* End of new Dropdown */}
 {/* </Row>
</Form>
      )}
      {currentStep === 2 && (
 
 <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
 <Content style={{ padding: '20px', margin: '0 auto', maxWidth: '800px' }}>
 <Row gutter={[16, 16]}>
 <Col span={24}>
   <Form.Item label="Product Vision">
     <ReactQuill
       theme="snow"
       value={vision}
       onChange={setVision}
       className="react-quill-container"
       placeholder="Paint a vivid picture of the future you envision. Describe the core problem your product solves and the transformative impact it will have. Outline the ideal state you want to achieve, capturing the benefits and value it brings to users and stakeholders."
     />
   </Form.Item>
 </Col>
 <Col span={24}>
   <Form.Item label="Why does your Product exist?">
     <ReactQuill
       theme="snow"
       value={why}
       onChange={setWhy}
       className="react-quill-container"
       placeholder="Clearly state the purpose and motivation behind your product. Explain why it matters and the ultimate value it brings to users, aligning with their needs and aspirations."
     />
   </Form.Item>
 </Col>
 <Col span={24}>
   <Form.Item label="What are your top Product Tenets?">
     <ReactQuill
       theme="snow"
       value={tenets}
       onChange={setTenets}
       className="react-quill-container"
       placeholder="Define the guiding principles and core values that shape your product's development and decision-making. These tenets reflect your commitment to user-centricity, innovation, simplicity, or any other critical aspect of your product."
     />
   </Form.Item>
 </Col>
 <Col span={24}>
   <Form.Item label="Who are your Primary Customers?">
     <ReactQuill
       theme="snow"
       value={customers}
       onChange={setCustomers}
       className="react-quill-container"
       placeholder="Define your ideal customer profile and the specific audience segments you aim to serve. Describe their characteristics, needs, pain points, and how your product provides a tailored solution for them."
     />
   </Form.Item>
 </Col>
 <Col span={24}>
   <Form.Item label="What are your Fly Wheel Drivers?">
     <ReactQuill
       theme="snow"
       value={flywheel}
       onChange={setFlywheel}
       className="react-quill-container"
       placeholder="Outline the key factors or actions that drive the momentum and growth of your product's user base. This could include referrals, network effects, user-generated content, or any other mechanisms that encourage adoption and retention."
     />
   </Form.Item>
 </Col>
 <Col span={24}>
   <Form.Item label="What is your Key Differentiator?">
     <ReactQuill
       theme="snow"
       value={differentiator}
       onChange={setDifferentiator}
       className="react-quill-container"
       placeholder="Clearly articulate the distinct qualities or features that differentiate your product from others in the market. Highlight what makes it stand out and why customers would choose it over alternatives."
     />
   </Form.Item>
 </Col>
</Row> 

      </Content></Layout>
      )}
      {currentStep === 3 && (
        // Show all details entered in Step 1, Step 2, and Step 3
        <>
          <h2 style={{ marginBottom: '16px' }}>Summary</h2>
          <p><strong>Name:</strong> {productName}</p>
          <p><strong>ID:</strong> {productID}</p>
          <p><strong>Description:</strong> {description}</p>
          <p><strong>Type:</strong> {type}</p>
          {renderStep3Details()}
        </>
      )}
   <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        {currentStep > 0 && (
          <button onClick={handlePrevious}>Previous</button>
        )}
        <button type="primary" onClick={handleNext}>
          {currentStep === 3 ? 'Submit' : 'Next'}
        </button>
      </div>  */}
    </div>
  );
};

export default OnboardingForm;
