import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import { Card, Image, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const VariationCard = ({ title, description, ideaid, onVote, image }) => {
  const [voted, setVoted] = useState(false);
  const [voteSubmitted, setVoteSubmit] = useState(false);
  const [downVoteSubmitted, setDownVoteSubmit] = useState(false);

  const onClickVote = (vote) => {
    setVoted(true);
    // Call the onVote function with the selected vote option and ideaid
    onVote({ ideaid, vote });
    if (vote==="yes")
    {setVoteSubmit(true); setDownVoteSubmit(false);}
     else if (vote==="no") {setDownVoteSubmit(true); setVoteSubmit(false);}
  };


  const renderedDescription = description || ""; // If description is undefined, use an empty string
  const renderedImage = image || ""; // If image is undefined, use an empty string


  return (
    <Card style={{ marginBottom: 16, textAlign: 'center' }}>
        
    <h5>{title}</h5>

      {renderedDescription && <p>{renderedDescription}</p>}
      {renderedImage && <Image src={renderedImage} alt="Variation Image" style={{ width: '100%', maxHeight: 200, objectFit: 'cover' }} />}
      <br />
         <br />
         <Space size="middle">
  <Tooltip title="Like">
    <Link onClick={() => onClickVote("yes")}>
      <div style={{ justifyContent: 'center', alignItems: 'center', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {voteSubmitted === false ? (
          <>
            <LikeOutlined style={{ fontSize: '24px',   }} />
          </>
        ) : (
          <>
            <LikeFilled style={{ fontSize: '24px', marginBottom: '2px',  }} />
          </>
        )}
       
      </div>
    </Link>
  </Tooltip>
  <Tooltip title="Dislike">
    <Link onClick={() => onClickVote("no")}>
      <div style={{ justifyContent: 'center', alignItems: 'center', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {downVoteSubmitted === false ? (
          <>
            <DislikeOutlined style={{ fontSize: '24px' }} />
          </>
        ) : (
          <>
            <DislikeFilled style={{ fontSize: '24px', marginBottom: '2px', }} />
          </>
        )}
       
      </div>
    </Link>
  </Tooltip>
</Space>


     
    </Card>
  );
};

export default VariationCard;
