import React from 'react';
import { Avatar, Tooltip, Divider, Typography, Space, Empty } from 'antd';
const { Text } = Typography;

const UserAvatarGroup = ({ userList }) => {
  const totalUsers = userList.length;

  return (
    <>
      <Space>
        {totalUsers > 0 ? (
          <> <Space align='center'><p></p>  <Avatar.Group
            maxCount={2}
            maxStyle={{
              color: '#f56a00',
              backgroundColor: '#fde3cf',
            }}
          >
            {userList.map((user) => (
              <Tooltip key={user.userId} title={`${user.nickname}`}> 
              <Avatar
                  size="small"
                  style={{
                    backgroundColor: '#f56a00',
                  }}
                >
                  {user.nickname.charAt(0).toUpperCase()}
                </Avatar>
              </Tooltip>
            ))}
          </Avatar.Group> </Space>  </> 
        )  : (
          <p> </p>  
        )}
      </Space>
    </>
  );
};

export default UserAvatarGroup;
