import { Layout, Modal,   Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import UserHeader2 from '../Layout/UserHeader2';
import OfferForm from './OfferForm';
import OfferList from './OfferList';
import SurveyForm from '../Survey/SurveyForm';
import SurveyList from '../Survey/SurveyList';

const { Header, Content, Footer } = Layout;

const RewardDashboard = () => {
  const [offers, setOffers] = useState(null);
  const [isCreateOfferModalVisible, setCreateOfferModalVisible] = useState(false);
 

  const { product_id, idea_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get('title');

  const getOfferData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/RewardOffer`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );
      setOffers(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching offers:', error.message);
    }
  };

  useEffect(() => {


    getOfferData();
  }, [product_id, idea_id]);


  const handleCreateOfferClick = () => {
    setCreateOfferModalVisible(true);
  };

  const handleCreateOfferModalCancel = () => {
    setCreateOfferModalVisible(false);
  };

  const handleCreateOffer = async (newOffer) => {
    const { dateRange, rewards, tasks, status, ...restOffer } = newOffer;
  
    // Map status to 1 for "Active" and 9 for "Inactive"
    const mappedStatus = status === "Active" ? "1" : status === "Inactive" ? "9" : null;
  
    // if (dateRange && Array.isArray(dateRange) && dateRange.length === 2) {
    //   const startDate = dateRange[0].format('YYYY-MM-DD');
    //   const endDate = dateRange[1].format('YYYY-MM-DD');
  
    //   const formattedRewards = rewards ? JSON.stringify(rewards) : 'null';
    //   const formattedTasks = tasks ? JSON.stringify(tasks) : 'null';
      
  
      const updatedOffer = {
        ...restOffer,
        // startDate,
        // endDate,
        status: mappedStatus
        // reward: formattedRewards,
        // tasks: formattedTasks
      };
  
      try {
        const userAccessToken = localStorage.getItem('user_access_token');
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/RewardOffer`,
          updatedOffer,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        setCreateOfferModalVisible(false);
      getOfferData();
        
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
   
  };
  
  
  
  const onUpdate = (offerStatus) => {
console.log(offerStatus);


    if (offerStatus) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/RewardOffer/${offerStatus.offerId}`,
          { status: offerStatus.status },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('Offer status updated successfully:', response.data);
    
        })
        .catch((error) => {
          console.error('Error updating offer status:', error);
        });
    }
  };

  const handleBackbuttonClick = () => {
    navigate(`/product-detail/${product_id}`);
  };


  const handleDeleteOffer = async (offerId) => {
    try {
      const userAccessToken = localStorage.getItem('user_access_token');
      await axios.delete(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/RewardOffer/${offerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );
  
      // Assuming setOffers(null) sets the offers state to null
      setOffers(null);
    } catch (error) {
      console.error('Error deleting offer:', error.message);
    }
  };
  
  return (
    <Layout>
		<Layout>
		  <Header
					style={{
					  position: 'sticky',
					  top: 0,
					  zIndex: 1,
					  width: '100%',
					  display: 'flex',
					  
					}}
	
				  > 
			 
					<UserHeader2 />
 
		   </Header>
	   <Content
		  style={{
			flex: '1',
			padding: '25px 15px',
			background: 'white',
		  }}
		>
			
 

      <Row  >
            <Col>
<h3>Reward Management</h3>

<p>

 
IDEALY allows you to design enticing reward offers for customers who generously share their valuable feedback. Elevate your customer experience and foster loyalty through our incentivizing approach. IDEALY's Offer Creation isn't just about rewards; it's a meaningful way to connect with your customers. Turn feedback into a two-way street of appreciation, 
building a community that values your brand. Start crafting offers today and watch as your customers feel truly seen and rewarded for their insights.  
</p>

              <button onClick={handleCreateOfferClick} type="primary">
                Create Reward
              </button>
            </Col>
          </Row>
          <Modal
            title="Create Reward"
            visible={isCreateOfferModalVisible}
            onCancel={handleCreateOfferModalCancel}
            footer={null}
            destroyOnClose
          >
            <OfferForm onCreateOffer={handleCreateOffer} />
          </Modal>
      {offers && (
        <>

          <div className="dashboard-container">
            
            <OfferList offers={offers} onUpdate={onUpdate} onDelete={handleDeleteOffer} />
          </div>

          {/* Modal for Create Offer */}
  
        </>
      )}
</Content>
  </Layout>
    </Layout>
	 
  );
};

export default RewardDashboard;
