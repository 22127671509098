import { Breadcrumb,  Col, Row ,Tour,Space, Layout} from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import React, {useRef, useState} from 'react';
import UserHeader2 from '../Layout/UserHeader2';
import AddMetrics from '../Settings/AddMetrics';
import AddOpportunity from '../Settings/AddOpportunity';
import AddInitiative from '../Settings/addInitiative';
import ProductTable from './ProductTable';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
const { Sider, Content,Header } = Layout;

const ProductSpacesPage = () => {
 

  return (
 

    <Layout>
      <Layout>
        <Header
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    width: '100%',
                    display: 'flex',
                    
                  }}

                > 
             
                  <UserHeader2 />
      
         </Header>
     <Content
        style={{
          flex: '1',
          padding: '25px 15px',
          background: 'white',
        }}
      >

 
      <div  >
      <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <div style={{ height: '100%' }}>
             <Space> <h2>My Products</h2>  
                <Link to={`/Research/Product/Add`}>
					
					<button htmlType="button" >
          Add a New Product
					</button>
					</Link></Space>
              <div className="col-md-12" >
                <br />




                <ProductTable />
                
              </div>
            </div>
          </Col>


         
  {/* <h2>Business Configurations</h2>

  <p>
    Tailor the platform to your unique needs by adding Business Initiatives,Key Metrics, and Product Gap Areas. This strategic setup ensures that every idea aligns seamlessly with your product vision and business goals, driving both innovation and efficiency.
  </p>
 
 
 <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <AddInitiative />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <AddMetrics />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <AddOpportunity />
        </Col>
      </Row> */}
      
        </Row>
      </div>
     
      </Content>
  </Layout>
    </Layout>
   
    
  );
};

export default ProductSpacesPage;

