import {
    HomeOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Layout } from 'antd';

import { useNavigate } from 'react-router-dom';

const LogoutFooter = ({ username }) => {

    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/');
      };

     
      const handleLoginPage = () => {
        navigate('/Reward/Login');
      };


      return (
        <Layout.Footer style={{
            position: 'fixed', bottom: '0', width: '100%',
            padding: '15px', // Adjust padding as needed
            height: '50px', // Set the desired height
         
      }}
      
      className="custom-footer"
      >
          <div style={{
             display: 'flex',
             justifyContent: 'space-around',
                  }}>
              <div style={{ textAlign: 'center' }}>
                  <HomeOutlined onClick={handleHomeClick} style={{ fontSize: '24px' }} />
                 
              </div>

              <div style={{ textAlign: 'center' }}>
                  <UserOutlined onClick={handleLoginPage} style={{ fontSize: '24px' }} />
                  
              </div>
          </div>
      </Layout.Footer>
    );
};

export default LogoutFooter;
