import React, { useState, useEffect } from 'react';
import { SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';

const TagInput = ({ masterTagList = [], existingTags = '', updateTag }) => {
  const [selectedTag, setSelectedTag] = useState('');
  const [addedTags, setAddedTags] = useState([]);

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleAddTag = () => {
    if (selectedTag.trim() !== '') {
      setAddedTags([...addedTags, selectedTag.trim()]);
      setSelectedTag('');
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...addedTags];
    updatedTags.splice(index, 1);
    setAddedTags(updatedTags);
  };

  const handleSaveTags = () => {
    const finalTags = addedTags.join(',');
    updateTag({ tags: finalTags });
  };

  useEffect(() => {
    // Convert comma-separated strings to arrays
    if (existingTags && existingTags.length > 0) {
      setAddedTags(existingTags.split(',').map((tag) => tag.trim()));}
  }, [existingTags]);

  return (
    <div>
      <h5>Certifications  <SaveOutlined style={{ color: 'green' }} onClick={handleSaveTags}/></h5>
      <div>
        <select value={selectedTag} onChange={handleTagChange}>
          <option value="" disabled>Select a Certification</option>
          {masterTagList.map((tag, index) => (
            <option key={index} value={tag}>
              {tag}
            </option>
          ))}
        </select>
        <button onClick={handleAddTag}>Add Certification</button>
        
      </div>
      <ul>
        {Array.isArray(addedTags) &&
          addedTags.map((tag, index) => (
            <li key={index}>
              {tag}       <DeleteOutlined style={{ color: 'red' }}  onClick={() => handleRemoveTag(index)}/>  
            </li>
          ))}
      </ul>
     
       
    </div>
  );
};

// Example usage:

export default TagInput;
