import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Modal, Form, Input, Select, Radio, Space } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
 

const { Option } = Select;

const PollPanel = ({product_id,idea_id}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [polls, setPolls] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleAddPoll = async (values) => {
    const payload = {
      question: values.question,
      options: JSON.stringify(values.options), // Convert options to JSON string
      status: values.status,
    };
  
    const userAccessToken = localStorage.getItem('user_access_token');
  
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/poll',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );
  
      // Assuming the response.data contains the saved poll
      setPolls([...polls, payload]);
  
      console.log(response.data);
  
      handleCloseModal();
    } catch (error) {
      console.error('Error sending poll data:', error.message);
    }
  };
  

  const handleRemovePoll = (index) => {
    const updatedPolls = [...polls];
    updatedPolls.splice(index, 1);
    setPolls(updatedPolls);
  };

  const handleSelectOption = (value) => {
    setSelectedOption(value);
  };

  return (
    <Card title="Poll Panel" style={{ width: 400, margin: 'auto', marginTop: 20 }}>
      <p>
        <Button type="primary" style={{ marginTop: 10 }} onClick={handleOpenModal}>
          Create New Poll <PlusOutlined />
        </Button>
      </p>


      {polls.map((poll, index) => (
        <div key={index} style={{ marginBottom: 16 }}>
          <p>{poll.question}</p>
          <Radio.Group onChange={() => handleSelectOption(null)} value={selectedOption}>
            <Space direction="vertical">
              {/* Parse the options JSON string back into an array */}
              {JSON.parse(poll.options).map((option, optionIndex) => (
                <Radio key={optionIndex} value={option}>
                  {option}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => handleRemovePoll(index)}
            style={{ marginLeft: 8 }}
          />
        </div>
      ))}

      
  
  


      {/* Poll Input Modal */}
      <Modal
        title="Create New Poll"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form onFinish={handleAddPoll}>
          <Form.Item label="Question" name="question" rules={[{ required: true, message: 'Please enter a question' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Options" name="options" rules={[{ required: true, message: 'Please enter options' }]}>
            <Select mode="tags" style={{ width: '100%' }} placeholder="Enter options" />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Please select a status' }]}>
            <Select>
              <Option value="1">Active</Option>
              <Option value="0">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Poll
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default PollPanel;
