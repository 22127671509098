import React, { useState } from 'react';
import { Card, Result,   Badge } from 'antd';
import VariationCard from '../Idea/Variation';
import { CheckCircleTwoTone } from '@ant-design/icons';

const VariationCarousel = ({ variations, onVote }) => {
  const [currentVariationIndex, setCurrentVariationIndex] = useState(0);

  const handleVote = (vote) => {
    // Increment the current variation index
    setCurrentVariationIndex((prevIndex) => prevIndex + 1);
  
    // Call the onVote callback with the correct argument
    onVote({ vote });
  };

  const isAllDone = currentVariationIndex >= variations.length;

  const handleStartOver = () => {
    // Reset the current variation index to start over
    setCurrentVariationIndex(0);
  };

  const variationsCompleted = isAllDone ? variations.length : currentVariationIndex;
  const variationsLeft = isAllDone ? 0 : variations.length - currentVariationIndex;

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h4 className="h5-md" style={{   marginBottom: 3,  }}>  🔄 Product Variations.</h4>
        <div>
          <Badge count={variationsCompleted} style={{ backgroundColor: '#52c41a', marginRight: '8px' }} />
          <span>{`Variations: ${variationsCompleted} / ${variations.length}`}</span>
        </div>
      </div>

      {isAllDone ? (
        <>
          <Result
            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            title="Boom! All Done!"
            subTitle="Your creative spark has been unleashed. Thanks for sharing your opinions!"
          />
          <button type="primary" onClick={handleStartOver}>
            Start Over
          </button>
        </>
      ) : (
        <>
          <p>Time to play favorites! Pick your vibe for each variation by selecting Thumbs up or Thumbs down. </p>
          <div style={{  gap: '16px', marginTop: '5px' }}>
            {variations && variations.length > 0 && currentVariationIndex < variations.length && (
              <VariationCard
                key={variations[currentVariationIndex].idvariation}
                title={variations[currentVariationIndex].title}
                description={variations[currentVariationIndex].description}
                ideaid={variations[currentVariationIndex].idvariation}
                onVote={handleVote}
                image={variations[currentVariationIndex].imageurl}
              />
            )}
          </div>
        </>
      )}
    </Card>
  );
};

export default VariationCarousel;
