import React, { useState } from 'react';
import { Card, Badge, Result  } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import PollUserView from './PollUserView';

const PollCarousel = ({ pollsData, product_id, idea_id,onPoll }) => {
  const [currentPollIndex, setCurrentPollIndex] = useState(0);

  const handlePoll = () => {
    // Increment the current poll index
    setCurrentPollIndex((prevIndex) => prevIndex + 1);
    onPoll();
  };

  const isAllDone = currentPollIndex >= pollsData.length;

  const handleStartOver = () => {
    // Reset the current poll index to start over
    setCurrentPollIndex(0);
  };

  const pollsCompleted = isAllDone ? pollsData.length : currentPollIndex;
  const pollsLeft = isAllDone ? 0 : pollsData.length - currentPollIndex;

  return (
    <Card>
       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
       <h4 className="h5-md" style={{   marginBottom: 3,  }}>  📊 Polls.</h4>
        <div>
          <Badge count={pollsCompleted} style={{ backgroundColor: '#52c41a', marginRight: '8px' }} />
          <span>{`Polls: ${pollsCompleted} / ${pollsData.length}`}</span>
        </div>
      </div>

      {isAllDone ? (
        <>
          <Result
            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            title="High-five! All Polls are in!"
            subTitle="Your choice matters, and we've got it recorded!"
          />
          <button type="primary" onClick={handleStartOver}>
            Start Over
          </button>
        </>
      ) : (
        <>
          <p>Tap into your inner visionary! Cast your vote and shape the destiny of the next big thing—your opinion matters!</p>
          <div style={{  gap: '16px', marginTop: '5px' }}>
            {pollsData && pollsData.length > 0 && currentPollIndex < pollsData.length && (
              <PollUserView
                key={pollsData[currentPollIndex].pollId}
                poll={pollsData[currentPollIndex]}
                product_id={product_id}
                idea_id={idea_id}
                onPoll={handlePoll}
              />
            )}
            {currentPollIndex >= pollsData.length && <p>All Done!</p>}
          </div>
        </>
      )}
    </Card>
  );
};

export default PollCarousel;
