
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Layout, Space, Spin, Tabs, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { decodeToken } from "react-jwt";
import { useLocation } from 'react-router-dom';
import GoogleUserLogin from './GoogleUserLogin';
import Login from './Login';
import Signup from './Signup';
 

const { Header, Content } = Layout;
const { success, error, warning } = message;
const {TabPane}=Tabs;


const LoginAgain = (props) => {
  const {
    setLoggedInUser,
    user,
    refreshIdea
  } = props;

  const [clientId, setClientId]=useState('');
  const [clientSecret, setClientSecret]=useState('');
 const [accessToken, setAccessToken]=useState("");
  const [role, setRole] = useState("guest");
  const [points, setPoints] = useState("0");
  const [level, setLevel] = useState("Rookie");
  const [company, setCompany] = useState("guest");
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState('');
  const [name ,setName] = useState('');
  const [lastName ,setLastName] = useState('');
  
  const [password, setPassword] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
   
  const [currentStep, setCurrentStep] = useState(0);
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    // Check token expiration on component mount
    const  User = JSON.parse(localStorage.getItem('user'));
    console.log("user",User);
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    // Set company to 0 if it's not present in the URL
    const companyValue = company || 0;
    setCompany(companyValue);

    if (User) {
    setCurrentUser(User);
    checkTokenExpiration();
    decodeAccess();
    // Set up a timer to periodically check token expiration
    const tokenCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute
    
    // Cleanup the interval on component unmount
    return () => clearInterval(tokenCheckInterval);}
  }, []);
  
 


  const checkTokenExpiration = () => {
    // Retrieve the stored token from localStorage
    const storedToken = JSON.parse(localStorage.getItem('user'));
  
    // Check if the token is present and not expired
    if (storedToken && !isTokenExpired(storedToken)) {
      // Token is valid, user is authenticated
      console.log("Token is valid. User is authenticated.");
      return;
    }
  
    // Token is either not present or expired, log the user out and redirect to login page
    handleLogout();
  };
  
  // Function to logout the user
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();
    
    window.location.reload(); // Redirect to login page
  };
  
  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      console.log('User IP Address:', data.ip);
      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
  
  
  const getApiToken = async (clientId,clientSecret,username) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL +`user/web_token`, {
        clientId,
        clientSecret,
        username,
      });

      console.log("token",response.data.accessToken )
     
    return  response.data;



     
    } catch (error) {
      console.error(error);
      message.error('Error occurred while getting the API token.');
    }
  };
  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    if (token && token.exp) {
      return token.exp * 1000 <= Date.now();
    }
    return true; // Token is considered expired if no expiration information is available
  };
  
  const decodeAccess=() => {
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    setCompany(company);

  }

 
  const handleGoogleLogin = async (credentialResponse) => {
    try {
 
   
      const result=  await GoogleUserLogin(credentialResponse,company);
        
        if (result && result.code === 200) {
  
        
  
            const accessToken = localStorage.getItem("user_access_token");
            // Decode the token
            setAccessToken(accessToken);
            refreshIdea();
            const decodeTokens = decodeToken(accessToken);
            
            // Access token properties
            const { sub, company, scope, ROLE, exp } = decodeTokens;
             
  
        }
     
      
      } 
      
  
      catch (error) {
        console.error(error);
   
   
      }
  
  };
 

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true); // Set loading to true during login
      const result = await Login(username, password);
  
      if (result && result.code === 200) {
        const accessToken = localStorage.getItem('user_access_token');
        
  
        setAccessToken(accessToken);
        refreshIdea();
      }   
    } catch (error) {
      
      message.error('Login failed. Please try again.');
     
    } finally {
      setLoading(false); // Set loading back to false after login completes (regardless of success or failure)
      
    }
  };
  

   
 
const showModal=() => {

  setModalVisible(true);

}


  const handleSignupFormSubmit = async (e) => {
    e.preventDefault();
 
    setUsernameError('');
  const formData = new FormData(e.target);
  const email = formData.get('email');
  const password = formData.get('password');
  const name = formData.get('name');
  const lastName = formData.get('lastname');
  const nick = formData.get('nick');
  const searchParams = new URLSearchParams(location.search);
  let company = searchParams.get('company');

  const fullNameWithoutSpaces = `${name.toLowerCase()}${lastName.toLowerCase()}`;
  const nameRegex = new RegExp(`\\b${fullNameWithoutSpaces}\\b`);
  
  if (name.length < 3 || !/^[a-zA-Z0-9]+$/.test(name)) {
    setFirstNameError('First name must be at least 3 characters.');
    return;
  } else {
    setFirstNameError('');
  }

  // Validate last name
  if (lastName.length < 3 || !/^[a-zA-Z0-9]+$/.test(lastName)) {
    setLastNameError('Last name must be at least 3 characters.');
    return;
  } else {
    setLastNameError('');
  }
  
  if (username.toLowerCase().includes(name.toLowerCase()) || username.toLowerCase().includes(lastName.toLowerCase())) {
    setUsernameError('Username cannot be part of the first or last name.');
    return;
  }

  if (username.length < 5 || !/^[a-zA-Z0-9]+$/.test(username)) {
    setUsernameError('Username must be at least 5 characters and contain only alphanumeric characters.');
    return;
  } else {
    setUsernameError('');
  }


  console.log(nick, name);

  // Check if company parameter is not present or empty
  if (!company) {
    company = 0;
  }
 

  const names = name.split(' ');
 try{
  setLoading(true); // Set loading to true during signup
  const result = await Signup(name,lastName, nick ,company, "", email, password,"Guest");
  
      if (result && result.code === 200) {
       
        const result=  await Login(email, password);
      
        if (result && result.code === 200)  
    {
       
      const accessToken = localStorage.getItem("user_access_token");
    
 
 
    
      setAccessToken(accessToken);
      refreshIdea();
      setLoading(false); // Set loading back to false after login completes
       
    }

      }
    }
    catch (error) {
      console.error(error);
      message.error('Signup failed. Please try again.');
      setLoading(false); // Set loading back to false after login completes
    }  
 
  };


 

  return (
   

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '5px 10px', background: 'white', minHeight: '50vh' }}>
  <h4>Welcome Back!</h4>
  
    
        {loading && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <p>Hang in there...</p>
        </div>
      )}

 <Tabs defaultActiveKey="signin" type="line" size="small">

                {/* Signup Tab */}
                <TabPane tab="Sign Up" key="signup">

                    <form onSubmit={handleSignupFormSubmit} className="auth-form">
                        <Space direction='vertical'>
                            <p>Sign up to become a product influencer! It's simple, just tell us your Name, Email and a select a unique passowrd and hit Sign-Up!</p>


                            <Input type="text" name="name" required placeholder="Your First Name"  onChange={(e) => setName(e.target.value)} />
                            {firstNameError && <p className="error-message">{firstNameError}</p>}
                             <Input type="text" name="lastname" required placeholder="Your Last Name"  onChange={(e) => setLastName(e.target.value)} />
                             {lastNameError && <p className="error-message">{lastNameError}</p>}
                            <Input type="text" name="nick" required placeholder="A distinct Username" onChange={(e) => setUsername(e.target.value)} />
                          {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}



                            <Input type="email" name="email" placeholder="Your Email Address" required prefix={<UserOutlined className="site-form-item-icon" />} />


                            <Input.Password type="password" name="password" required placeholder="Your Password" />

                            <button type="primary" htmlType="submit">
                                Sign Up
                            </button>

                            <p>
                                By clicking "Sign Up," you agree to our{' '}
                                <a href="/terms" target="_blank" rel="noopener noreferrer">
                                    Terms and Conditions
                                </a>{' '}
                                and{' '}
                                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>
                                .
                            </p>
                           
                        </Space>
                    </form>

                </TabPane>
                {/* Sign In Tab */}
                <TabPane tab="Sign In" key="signin">
                <p>Not part of the product influencer community yet? Sign up to join IDEALY!</p>
                    <form onSubmit={handleLogin} className="auth-form">
                        <Space direction='vertical'>

                            <Input
                                placeholder="Email"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)} />


                            <Input.Password
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />

                            <button type="primary" htmlType="submit">
                                Login
                            </button>

                            {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                <GoogleLogin
                                    onSuccess={(credentialResponse) => {
                                        handleGoogleLogin(credentialResponse);
                                    } }
                                    onError={() => {
                                        message.error('Login Failed');
                                    } } />
                            </GoogleOAuthProvider> */}
                        </Space>
                    </form>


                </TabPane>
            </Tabs>
      
  </div>
    );
};

export default LoginAgain;
