import { Avatar, List, Space, Tag, Tooltip } from 'antd';
import axios from 'axios';
import { formatRelative, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import '../../index.css';

const CommentCard = ({ product_id, idea_id, owner }) => {
  const [data, setData] = useState([]);
  const [comment_list, setCommentList] = useState([]);

  const getData = () => {
    var user_access_token = localStorage.getItem('user_access_token');
    axios
      .get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/comment', {
        headers: {
          Authorization: 'Bearer ' + user_access_token,
        },
      })
      .then((response) => {
        setCommentList(response.data);
      });
  };

  useEffect(() => {
    getData();
  }, [idea_id]);

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Tag color="blue">({comment_list.length})</Tag>
      </div>

      <List
        itemLayout="vertical"
        dataSource={comment_list.sort((a, b) => new Date(b.commentDate) - new Date(a.commentDate))}
        renderItem={(item) => (
          <List.Item
            style={{
              border: '2px',
              borderRadius: '5px',
              padding: '10px',
              background: item.userId === owner ? '#c3ddff' : null,
              marginBottom: '10px',
            }}
            actions={[
              item.userId === owner ? 'Product Manager' : null,
             // Display time since the comment was made
            ]}
          >
            <List.Item.Meta
              avatar={<Tooltip title={item.userId}><Avatar  src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.userId}`} /></Tooltip>}
              title={item.userId === owner ? <strong>{item.userId}</strong> : item.userId} // Highlight name if it's the owner
              description={
                <p style={{ fontSize: '0.75rem', margin: 5 }}>
                  {item.text} <br/>  {formatRelative(parseISO(item.commentdate), new Date(), { addSuffix: true })}
                 </p>
              }
              
            />
          </List.Item>
        )}
        style={{ padding: '0 5px', marginBottom: '5px' }}
      />
    </>
  );
};

export default CommentCard;
