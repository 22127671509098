import {
  BulbOutlined,MessageOutlined ,TrophyOutlined ,GiftOutlined,LikeOutlined,FieldTimeOutlined,FundProjectionScreenOutlined,BarChartOutlined,RocketOutlined
} from '@ant-design/icons';
import { Card, Avatar, Row, Col, Modal, Button, Layout,theme , Tooltip,Badge,Divider, Tag } from 'antd';

import axios from 'axios'; // Import axios or use your preferred HTTP library
import React, { useEffect, useState } from 'react';
import LogoutFooter from '../Layout/LogoutFooter';
import UserFooter from '../Layout/UserFooter';
import WelcomeHeader from '../Layout/WelcomeHeader';
import LoginAgain from '../User/LoginAgain';
import UserAvatarGroup from './UserAvatarGroup';

const { Content,Header, Footer,Sider } = Layout;

 

const { Meta } = Card;

const IdeaLounge = ({ }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();


  const [invitationsData, setInvitationsData] = useState([]);
  const [myIdeasData, setMyIdeasData] = useState([]);
  const [ideasCommentedData, setIdeasCommentedData] = useState([]);
  const [ideasVotedData, setIdeasVotedData] = useState([]);
  const [user, setUser] = useState(null); // State to track logged-in user
  const [accessToken, setAccessToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [points, setPoints] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
 

  const handleOfferClick = (clickedOffer) => {
    setSelectedOffer(clickedOffer);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedOffer(null); // Reset selectedOffer when the modal is closed
  };

  const userStats = {
    rank: 5,
    totalRank: 1000,
    points: 5678,
    badge: 'Gold',
    pointsToNextLevel: 200,
  };
  
  const leaderboardStats = {
    rank: 25,
    totalRank: 1000,
    badge: 'Silver',
    points: 4567,
  };
  
  const myVotedIdeas = [
    { title: 'Idea 1', id: 1 },
    { title: 'Idea 2', id: 2 },
    // Add more sample data as needed
  ];
  
  const myCommentedIdeas = [
    { title: 'Idea 3', id: 3 },
    { title: 'Idea 4', id: 4 },
    // Add more sample data as needed
  ];
  
  const trendingOffers = [
    { offerCard: 'Offer 1', progress: 50, rewardStatus: 'locked' },
    { offerCard: 'Offer 2', progress: 80, rewardStatus: 'unlocked' },
    // Add more sample data as needed
  ];
  
  const progressOnTasks = [
    { title: 'Task 1', description: 'Description 1', avatar: 'Avatar 1', progress: 70, href: '#' },
    { title: 'Task 2', description: 'Description 2', avatar: 'Avatar 2', progress: 30, href: '#' },
    // Add more sample data as needed
  ];
  
  const rewardStatusData = [
    { title: 'Reward 1', description: 'Description 1', avatar: 'Avatar 1', rewardStatus: 'locked', href: '#' },
    { title: 'Reward 2', description: 'Description 2', avatar: 'Avatar 2', rewardStatus: 'unlocked', href: '#' },
    // Add more sample data as needed
  ];
  const checkTokenExpiration = () => {
    // Retrieve the stored token from localStorage
    const storedToken = JSON.parse(localStorage.getItem('user'));

    // Check if the token is present and not expired
    if (storedToken && !isTokenExpired(storedToken)) {
      // Token is valid, user is authenticated
      console.log("Token is valid. User is authenticated.");

      return;
    }

    // Token is either not present or expired, log the user out and redirect to login page
    handleLogout();
  };

  // Function to logout the user
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();

  };

  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    if (token && token.exp) {
      return token.exp * 1000 <= Date.now();
    }
    return true; // Token is considered expired if no expiration information is available
  };

  useEffect(() => {
     
    const fetchMyIdeasData = async () => {
      try {

        const user_access_token = localStorage.getItem("user_access_token");
        const response = await axios.get(process.env.REACT_APP_API_URL + 'product/offerCards', {
          headers: {
            Authorization: "Bearer " + user_access_token,
          }
        });
        
        setMyIdeasData(response.data);
 
        
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching my ideas data:', error);
      }
    };


    setAccessToken(localStorage.getItem("user_access_token"));
    console.log(localStorage.getItem("user_access_token"));
    setPoints(points+1);   
    console.log("user token", accessToken, );

    // getApiToken();  
    // Check if there is a stored user
    if (localStorage.getItem("user_access_token")) {
      const  User = JSON.parse(localStorage.getItem('user'));
      setUser(User);
     console.log("user is there", user)
     fetchMyIdeasData ();

      setIsAuthenticated(true);
      checkTokenExpiration();
      setPoints(points+10)
    }



    const fetchIdeasCommentedData = async () => {
      // try {
      //   const response = await axios.get(process.env.REACT_APP_API_URL + 'product/:product_id/user/:user_id/commented', {
      //     headers: {
      //       Authorization: "Bearer " + accessToken,
      //     }
      //   });
      //   setIdeasCommentedData(response.data);
      // } catch (error) {
      //   console.error('Error fetching ideas commented data:', error);
      // }
    };

    const fetchIdeasVotedData = async () => {
      // try {
      //   const response = await axios.get(process.env.REACT_APP_API_URL + 'product/:product_id/user/:user_id/voted', {
      //     headers: {
      //       Authorization: "Bearer " + accessToken,
      //     }
      //   });
      //   setIdeasVotedData(response.data);
      // } catch (error) {
      //   console.error('Error fetching ideas voted data:', error);
      // }
    };
 
    fetchIdeasCommentedData();
    fetchIdeasVotedData();
  }, []);


  const refreshIdea = () => {
    // Implement the logic to refresh Idea.js
    window.location.reload(); // Redirect to login page

  };

  const triggerUpdate = () => {
    setForceUpdate((prev) => prev + 1);
    
  };


  useEffect(() => {

    setAccessToken(localStorage.getItem("user_access_token"));
    setPoints(points+1);   

    // getApiToken();  
    // Check if there is a stored user
    if (accessToken) {
      const  User = JSON.parse(localStorage.getItem('user'));
      setUser(User);
     
      setIsAuthenticated(true);
      checkTokenExpiration();
      setPoints(points+10)
      // Set up a timer to periodically check token expiration
      const tokenCheckInterval = setInterval(() => {
        checkTokenExpiration();
      }, 60000); // Check every minute

      // Cleanup the interval on component unmount
      return () => clearInterval(tokenCheckInterval);
    } else {
      // If there is no user, show the modal
    }
   
  }, [forceUpdate]);

  const handleItemClick = (ideaId, productId) => {
    // Handle the click, e.g., navigate to the idea details page
    console.log(`Clicked on idea with ID: ${ideaId}`);

    window.location.href = "./Idea/" + productId + "/" + ideaId;
  };

 
  

  return (
 


      
<Layout>
<Layout>
        
   
<Header
           style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            
          }}

        > 
      {localStorage.getItem("user_access_token") && (
        <WelcomeHeader activity={1} />
      )}
     </Header>


 
     <Content
        style={{
          flex: '1',
          padding: '25px 15px',
          background: 'white',
        }}
      >


 

{isAuthenticated ? (<><div style={{ marginBottom: '2px',  fontSize: '14px' }}>
 Hello, {user.name} 👋
</div>
<div style={{ marginBottom: '2px',   fontSize: '18px', fontWeight: 'bold' }}>
  Welcome Back! 
  </div>
  <div style={{ marginBottom: '10px',   fontSize: '14px',  }}>
  Check out the latest offers and share your valuable feedback.
</div> 
{myIdeasData.map((offer, index) => (
    <Card
    key={offer.id}
    style={{
      width: '100%',
      marginBottom: '10px',
      backgroundColor: '#f0f3f6', // Dusty pastel blue background color
      borderRadius: '8px', // Add some border-radius for a softer look
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    }}
  >
    <Meta
      avatar={<GiftOutlined style={{ color: '#3d5a9f' }} />} // Dusty pastel blue icon color
      title={<span onClick={() => handleOfferClick(offer)} style={{ color: '#333', cursor: 'pointer' }}>{offer.offerName}   <p> by   {offer.brandName}</p></span> }
      description={
        <>
          <span onClick={() => handleOfferClick(offer)} style={{ color: '#555' }}>
         <p>   {offer.offerDescription}</p>
       <p>  <Button
          onClick={() => handleItemClick(offer.ideaId, offer.productId)}
          style={{
            backgroundColor: '#3d5a9f', // Dusty pastel blue button color
            color: '#fff', // Set text color to white
            border: 'none', // Remove border
            borderRadius: '4px', // Add some border-radius
          }}
        >
          Let's get started!
        </Button></p>
          </span>
        </>
      }
    />

   
    <Row style={{ marginTop: '16px', position: 'relative' }}>
      <Col xs={24} sm={12} md={12} lg={12} style={{  bottom: 0, left: 0 }}>
        <UserAvatarGroup userList={offer.users} />
        <Tag   style={{
            backgroundColor: '#3d5a9f', // Dusty pastel blue button color
            color: '#fff', // Set text color to white
            border: 'none', // Remove border
            borderRadius: '4px', // Add some border-radius
          }}>Category:  {offer.productType}</Tag>
      </Col>
     
     

 
      <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: 'right', position: 'absolute', bottom: 0, right: 0 }}>
        <span>
          <MessageOutlined /> {offer.commentCount}
        </span>
      </Col>
    </Row>

    <Modal
      title="Offer Details"
      visible={selectedOffer !== null}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          Close
        </Button>,
      ]}
    >
      {selectedOffer && (
        <>
          <p style={{ color: '#333' }}>{selectedOffer.offerDescription}</p>

          <Divider />

          <div style={{ textAlign: 'left', marginBottom: '16px' }}>
            <TrophyOutlined style={{ fontSize: '24px', color: '#8a9aa5', marginRight: '8px' }} /> {/* Dusty pastel blue trophy color */}
            <span style={{ fontWeight: 'bold', color: '#555' }}>Activities required to unlock the reward:</span>
          </div>

          <div>
            <div style={{ marginBottom: '12px' }}>
              <Badge count={1} style={{ backgroundColor: '#52c41a' }}>
                <LikeOutlined style={{ fontSize: '24px', color: '#52c41a' }} /> Share your feedback.
              </Badge>
            </div>

            <div style={{ marginBottom: '12px' }}>
              <Badge count={selectedOffer.pollCount} style={{ backgroundColor: '#1890ff' }}>
                <BarChartOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> Cast your vote on the Polls
              </Badge>
            </div>

            <div style={{ marginBottom: '12px', fontSize: '24px' }}>
              <Badge count={selectedOffer.variationCount} style={{ backgroundColor: '#fa8c16' }}>
                🔄 Pick your vibe for each variation.
              </Badge>
            </div>

            <div style={{ marginBottom: '12px' }}>
              <Badge count={selectedOffer.featureCount} style={{ backgroundColor: '#722ed1' }}>
                <RocketOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> Rank the most important!
              </Badge>
            </div>

            <div>
              <Badge count={1} style={{ backgroundColor: '#1890ff' }}>
                <MessageOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> Tell us more with your comments!
              </Badge>
            </div>
          </div>
        </>
      )}
    </Modal>
  </Card>
))
      }</>) : (
   <LoginAgain refreshIdea={refreshIdea} />
)}

        </Content>
 
  
 

{/* {reward && (<RewardCard reward={reward} userTaskProgress={userTaskProgress}  variationCount={variationCount} pollCount={pollCount} featureCount={featureData && featureData.idfeature !== null ? 1 : 0} />)} */}
   
{localStorage.getItem("user_access_token") && user ? (
        // If user is logged in, show UserFooter
        <UserFooter username={user.name} />
      ) : (
        // If user is not logged in, show LogoutFooter
        <LogoutFooter />
      )}




 </Layout>
   </Layout>
  

 
 

  );

};
export default IdeaLounge;
