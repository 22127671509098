import React, { useState } from 'react';
import { Button, Input, Form, Select, Typography,  Divider, message } from 'antd';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import '../index.css';
import { isExpired, decodeToken } from "react-jwt";
import Signup from './User/Signup';
import Login from './User/Login';
import { useParams, Link, useNavigate,useLocation } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;
const { success, error, warning } = message;

const SignupPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState(0);
  const [industry, setIndustry] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const location = useLocation();
 

  const handleRegisterClick = async () => {
    try {

     
      const searchParams = new URLSearchParams(location.search);
      const type = searchParams.get('userType')|| "Guest";
      const companyName = searchParams.get('company')|| 0;

      const result = await Signup(firstName, lastName, companyName, industry, regEmail, regPassword, type);
  
      if (result && result.code === 200) {
        // Redirect to /idea-lounge after successful registration
        const sign = await Login(regEmail, regPassword);
        if (sign && sign.code === 200) {
          window.location.href = "/product-list";
        }
      } else {
        // Other cases, stay on the same page or handle accordingly
        // If you want to display an error message, you can use toast.error(result.message);
      }
    } catch (error) {
      console.error(error);
      // Handle unexpected errors
      // If you want to display an error message, you can use toast.error("Something went wrong. Please try again.");
    }
  };
  
  
  

  const formContainerStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white', // Replace with theme background color
  };

  const formTitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333', // Replace with theme text color
  };

  const inputFieldStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    marginBottom: '20px',
    border: '1px solid #ccc', // Replace with theme border color
    borderRadius: '4px',
    backgroundColor: '#fff', // Replace with theme background color
    color: '#333', // Replace with theme text color
  };

  const buttonStyle = {
    width: '100%',
 
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff', // Replace with theme text color
    background: '#007bff', // Replace with theme primary color
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };
  const selectStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    marginBottom: '20px',
    border: '1px solid #ccc', // Replace with theme border color
    borderRadius: '4px',
    backgroundColor: '#fff', // Replace with theme background color
    color: '#333', // Replace with theme text color
    // Additional styles for the Select component
    display: 'block',
    minWidth: '150px',
  };
  return (
    <div style={formContainerStyle}>
       <Header/>

      <div className="form-container">
        <Title level={3} style={formTitleStyle}>Start here to become a Product Influencer and earn rewards for feedback!</Title>

        <Form layout="vertical">
          <Form.Item label="First Name:">
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={inputFieldStyle}
              placeholder="Joe"
            />
          </Form.Item>

          <Form.Item label="Last Name:">
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={inputFieldStyle}
              placeholder="Doe"
            />
          </Form.Item>

 
 
          <Form.Item label="Email:">
            <Input
              value={regEmail}
              onChange={(e) => setRegEmail(e.target.value)}
              style={inputFieldStyle}
              placeholder="mail@address.com"
            />
          </Form.Item>

          <Form.Item label="Choose a Password:">
            <Input
              type="password"
              value={regPassword}
              onChange={(e) => setRegPassword(e.target.value)}
              style={inputFieldStyle}
              placeholder="*********"
            />
          </Form.Item>
 
      


          <Button
            type="primary"
            onClick={handleRegisterClick}
            style={buttonStyle}
          >
            Signup
          </Button>

          <p>
          By clicking "Sign Up" you agree to our
          <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>
          {' '}
          and
          <a href="/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
        </p>
        </Form>
      </div>

      <p>Already have an account? <a href="/login">Sign in</a>.</p>

    </div>
  );
}

export default SignupPage;
