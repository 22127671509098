import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Divider, Row, Col } from 'antd';
import './index.css';

const Footer = () => {
  return (
    <>
      <Row justify="center" align="middle" className="blue_footer">
        <Col xs={24} sm={12} md={6}>
          <Link to={'/Signup'}>Sign Up</Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to={'/Login'}>Sign In</Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to={'/About'}>About Us</Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to={'/Faq'}>FAQs</Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to={'/Privacy'}>Privacy</Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to={'/Terms'}>Terms</Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to={'/Contact'}>Contact</Link>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Divider />
          Copyright © 2022. All rights reserved. <b>IDEALY</b>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
