import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminPollView from './AdminPollView';
import { Card,   Modal, Form, Input, Select, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const AdminView = ({ product_id, idea_id }) => {
  const [pollsData, setPollsData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getPollData();
  }, [product_id, idea_id]);

  const getPollData = async () => {
    const userAccessToken = localStorage.getItem('user_access_token');

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/polls',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );
      setPollsData(response.data);
      console.log('get Polls', response.data);
    } catch (error) {
      console.error('Error fetching poll data:', error.message);
    }
  };

  const handleAddPoll = async (values) => {
    const payload = {
      question: values.question,
      options: JSON.stringify(values.options),
      status: values.status,
    };

    const userAccessToken = localStorage.getItem('user_access_token');

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/poll',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      const updatedPollsResponse = await axios.get(
        process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/polls',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      setPollsData(updatedPollsResponse.data);
      handleCloseModal();
    } catch (error) {
      console.error('Error adding poll:', error.message);
    }
  };

  const handleDeletePoll = async (pollId) => {
    const userAccessToken = localStorage.getItem('user_access_token');

    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + `product/${product_id}/idea/${idea_id}/poll/${pollId}`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      const updatedPolls = pollsData.filter((poll) => poll.pollId !== pollId);
      setPollsData(updatedPolls);

      console.log('Poll deleted successfully');
    } catch (error) {
      console.error('Error deleting poll:', error.message);
    }
  };

  const handleTogglePollStatus = (pollId) => {
    console.log(`Toggling status for poll with ID ${pollId}`);
  };

  return (
    <div>
      <Card title="Poll Panel" style={{ width: 400, margin: 'auto', marginTop: 20 }}>
        <p>
          <button type="primary" style={{ marginTop: 10 }} onClick={handleOpenModal}>
            Create New Poll <PlusOutlined />
          </button>
        </p>

        {/* Poll Input Modal */}
<Modal title="Create New Poll" visible={isModalVisible} onCancel={handleCloseModal} footer={null}>
  <Form onFinish={handleAddPoll}>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          label="Question"
          name="question"
          rules={[{ required: true, message: 'Please enter a question' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          label="Options"
          name="options"
          rules={[{ required: true, message: 'Please enter options' }]}
        >
          <Select mode="tags" style={{ width: '100%' }} placeholder="Enter options" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select a status' }]}
        >
          <Select style={{ width: '100%' }}>
            <Option value="1">Active</Option>
            <Option value="0">Inactive</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Form.Item>
          <button type="primary" htmlType="submit">
            Create Poll
          </button>
        </Form.Item>
      </Col>
    </Row>
  </Form>
</Modal>

      </Card>

      {pollsData &&
  pollsData.length > 0 &&
  pollsData.map((poll) => (
    <AdminPollView
      key={poll.pollId}
      poll={poll}
      onDelete={handleDeletePoll}
      onToggleStatus={handleTogglePollStatus}
    />
  ))}

    </div>
  );
};

export default AdminView;
