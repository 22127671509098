import { Card, Col, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';

const { Option } = Select;

function OfferList({ offers, onUpdate,onDelete  }) {
  const [activeOffer, setActiveOffer] = useState(null);

  const handleStatusChange = (value) => {
    if (activeOffer) {
      const offerStatus = { offerId: activeOffer.offerId, status: parseInt(value, 10) };
      onUpdate(offerStatus);
    }
  };
  const handleDeleteOffer = (offerId) => {
    // You can show a confirmation modal before calling onDelete if needed
    onDelete(offerId);
  };

  useEffect(() => {
    // Assuming 'offers' is an array, and you want to show the first offer in the list
    setActiveOffer(offers);
  }, [offers]);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <div>
      {activeOffer && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card title={activeOffer.offerName} bordered={false} style={{ marginBottom: 16 }}>
              <p>{activeOffer.offerDescription}</p>
              <Select value={activeOffer.status !== null ? activeOffer.status.toString() : 'Not Set'} onChange={handleStatusChange}>
              <Option value="0">Inactive</Option>
              <Option value="1">Active</Option>
            </Select>
            
        
              {/* <p>Date Validity: {formatDate(activeOffer.startDate)} - {formatDate(activeOffer.endDate)}</p>
              <p>Quantity Available: {activeOffer.quantity}</p> */}
            </Card>
            <button  onClick={() => handleDeleteOffer(activeOffer.offerId)}>
              Delete Offer
            </button> 
          </Col>
 
            {/* <Card title="Tasks" bordered={false}>
  <Row gutter={[16, 16]}>
    {activeOffer.tasks && Array.isArray(JSON.parse(activeOffer.tasks)) &&
      JSON.parse(activeOffer.tasks).map((task) => (
        <Col xs={24} sm={12} md={8} lg={8}>
          <Card hoverable className="offer-card">
            <p>{task.taskType}</p>
            <p>{task.taskDescription}</p>
            <p>Required: {task.taskCompletionCount}</p>
          </Card>
        </Col>
      ))}
  </Row>
</Card> */}
{/* <Card title="Rewards" bordered={false}>
  <Row gutter={[16, 16]}>
    {activeOffer.reward && Array.isArray(JSON.parse(activeOffer.reward)) &&
      JSON.parse(activeOffer.reward).map((rewards, index) => (
        <Col xs={24} sm={12} md={8} lg={8} key={index}>
          <Card hoverable className="offer-card">
            <p>{rewards.rewardType}</p>
            <p>{rewards.rewardValue}</p>
          </Card>
        </Col>
      ))}
  </Row>
</Card> */}
        </Row>
      )}
    </div>
  );
}

export default OfferList;
