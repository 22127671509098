import React from 'react';
import { Col, Card, Space } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

const ThumbsUpDownChart = ({ filteredVotesData }) => {
  const calculateVotesCount = (filteredVotesData, responseValue) => {
    return filteredVotesData.reduce((total, vote) => {
      return total + (vote.response === responseValue ? 1 : 0);
    }, 0);
  };

  const totalVotes = filteredVotesData.length;
  const yesCount = calculateVotesCount(filteredVotesData, 1);
  const noCount = calculateVotesCount(filteredVotesData, -1);
  const yesPercentage = Math.floor((yesCount / totalVotes) * 100);
  const noPercentage = Math.floor((noCount / totalVotes) * 100);

  return (
   
     
     <Card
     title="Main IDEA Feedback"  
  style={{ minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
>
  <div style={{ position: 'absolute', top: '8px', right: '8px', padding: '6px', border: '1px solid #999999', borderRadius: '4px', textAlign: 'center' }}>
    <span style={{ fontSize: '18px', fontWeight: 'bold', display: 'block', marginBottom: '4px' }}>{totalVotes}</span>
    <span style={{ fontSize: '14px', display: 'block' }}>Responses</span>
  </div>
  <Space direction="horizontal" align="center" style={{ marginTop: '20px' }}>
    <div style={{ textAlign: 'center' }}>
      <LikeOutlined style={{ fontSize: '50px', color: '#52c41a' }} />
      <br/>
      <span style={{ fontSize: '36px', color: '#52c41a', fontWeight: 'bold' }}>{`${yesPercentage}%`}</span>
      <br/>
      <span style={{ fontSize: '12px',color: 'grey' }}>{`${yesCount} Responses`}</span>
    </div>
    <div style={{ marginLeft: '20px', marginRight: '20px', textAlign: 'center' }}>
      <DislikeOutlined style={{ fontSize: '50px', color: '#f5222d' }} />
      <br/>
      <span style={{ fontSize: '36px', color: '#f5222d', fontWeight: 'bold' }}>{`${noPercentage}%`}</span>
      <br/>
      <span style={{ fontSize: '12px',color: 'grey' }}>{`${noCount} Responses`}</span>
    </div>
  </Space>
</Card>
  
  );
};

export default ThumbsUpDownChart;
