import { Button, Card, DatePicker, Form, Input, Space, Spin, Steps, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IdeaCard from './IdeaCard';
const { Title, Text } = Typography;
const PlanForm = ({ onCreatePlan, ideas, onToggleSelect, selectedIdeasStep2 }) => {
  const [form] = Form.useForm();
  const [selectedIdeas, setSelectedIdeas] = useState(selectedIdeasStep2 || []);
  const [step, setStep] = useState(1);
  const [highlightedIdeas, setHighlightedIdeas] = useState([]);
  const { Step } = Steps;
  const [loading, setLoading] = useState(false);
  const [narrative, setNarrative] = useState(false);
  

  useEffect(() => {
    if (step === 1) {
      setHighlightedIdeas([]);
    } else if (step === 2) {
      const planCapacity = form.getFieldValue('capacity');
      const sortedIdeas = ideas.slice().sort((a, b) => b.capacity - a.capacity);

      let cumulativeCapacity = 0;
      const newHighlightedIdeas = [];
      for (const idea of sortedIdeas) {
        if (cumulativeCapacity + idea.capacity <= planCapacity) {
          cumulativeCapacity += idea.capacity;
          newHighlightedIdeas.push(idea);
        }
      }
      setHighlightedIdeas(newHighlightedIdeas);
    }
  }, [step, ideas, form]);

  const handleSelect = (idea) => {
    const newSelectedIdeas = [...selectedIdeas, idea];
    setSelectedIdeas(newSelectedIdeas);
    onToggleSelect(newSelectedIdeas);
  };

  const handleDeselect = (idea) => {
    const newSelectedIdeas = selectedIdeas.filter((selectedIdea) => selectedIdea.id !== idea.id);
    setSelectedIdeas(newSelectedIdeas);
    onToggleSelect(newSelectedIdeas);
  };

  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    }
  };

  const generatePlanNarrative  = async(product_id) => {

    const combinedIdeaTexts = generateCombinedIdeaTexts(selectedIdeas);

    try {
        setLoading(true);
        const user_access_token = localStorage.getItem("user_access_token");
        
        const payload = {
            narrative: combinedIdeaTexts
        };
    
        const response = await axios.post(`${process.env.REACT_APP_API_URL}product/${product_id}/plan/narrative`, payload, {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        });
    
        if (response.status === 200) {
          setNarrative(response.data)
      
        }
      } catch (error) {
        console.error('Error adding comment:', error);
        // Handle error as needed
      }
      finally {
        setLoading(false); // Set loading back to false after the request is completed
      }
  
  

  }

  const handleAddPlan = () => {
    const planData = form.getFieldsValue();
    const selectedIdeasToAdd = selectedIdeas || []; // Set selectedIdeasToAdd to an empty array if selectedIdeas is undefined or empty
    onCreatePlan(planData, selectedIdeasToAdd);
    form.resetFields();
    setSelectedIdeas([]); // Reset selectedIdeas state to an empty array
    setStep(1); // Reset step state to 1
  };
  const handleCancel = () => {
    form.resetFields();
    setSelectedIdeas([]);
    setStep(1);
  };

  const generateCombinedIdeaTexts = (ideas) => {
    return ideas.reduce((result, idea) => {
      return `${result}${idea.title}: ${idea.text}\n`;
    }, '');
  };

  return (
    
<Card title={`Step ${step} - ${step === 1 ? 'Create Plan' : step === 2 ? 'Select Ideas' : 'Generate Narrative'}`}>

  <Steps current={step - 1}>
    <Step title="Create Plan" />
    <Step title="Select Ideas" />
    <Step title="Generate Narrative" />
  </Steps>
  {step === 1 && (
   
   <div>
   <Form form={form} >
    <Space direction='vertical'>
    <Space wrap>
      <Form.Item label="Plan Title" name="title" rules={[{ required: true, message: 'Please enter a plan title' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Date Range" name="dateRange" rules={[{ required: true, message: 'Please select a date range' }]}>
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item label="Capacity" name="capacity" rules={[{ required: true, message: 'Please enter plan capacity', type: 'number' }]}>
        <Input type="number" />
      </Form.Item>
      </Space>
      <Space wrap align='center'>
     
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleNextStep}>Next</Button>
        </Space></Space>
    </Form></div>
  )}

  {step === 2 && (
    <div>
      <p>All Ideas:</p>
      <ul>
        {ideas.map((idea) => (
          <li key={idea.id}>
            <IdeaCard
              idea={idea}
              isSelected={selectedIdeas.includes(idea)}
              fitsPlanCapacity={highlightedIdeas.includes(idea)}
              onToggleSelect={() => selectedIdeas.includes(idea) ? handleDeselect(idea) : handleSelect(idea)}
            />
          </li>
        ))}
      </ul>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button onClick={() => setStep(1)}>Previous</Button>
        <Button type="primary" onClick={handleNextStep}>Next</Button>
      </div>
    </div>
  )}

  {step === 3 && (
    <div>
      <p>Selected Ideas from Step 2:</p>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
        {selectedIdeas.map((idea) => (
          <div key={idea.id}>
            <IdeaCard
              idea={idea}
              isSelected
              fitsPlanCapacity={highlightedIdeas.includes(idea)}
              onToggleSelect={() => handleDeselect(idea)}
            />
          </div>
        ))}
      </div>
      <p>Step 3: Generate Narrative</p>
      <div>
      <Button onClick={() => generatePlanNarrative(1)}>Generate Plan Narrative</Button>

      {loading ? (
                    <Spin size="large" />
                  ) : (
                    <div>  <br/>
                      {narrative && (
                        <Text
                          strong // Make the text bold
                          style={{
                            fontSize: '1rem',
                          
                          }}
                        >
                              <div dangerouslySetInnerHTML={{ __html: narrative }} />
                  
                        </Text>
                      )}
                    </div>
                  )}
 <br/>
        <Button onClick={() => setStep(2)}>Previous</Button>
        <Button type="primary" onClick={handleAddPlan}>Submit</Button>
      </div>
    </div>
  )}
</Card>

  
  );
};

export default PlanForm;
