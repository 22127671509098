import { BulbOutlined } from '@ant-design/icons';
import {  Form, Input, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ideasubmission.css';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const SubmitIdea = () => {
  const location = useLocation();
  const [accessToken, setAccessToken] = useState('');
  const [open, setOpen] = useState(true);
  const [form] = Form.useForm();
  const [error, setError] = useState('');
  const [productId, setProductId] = useState('');

  const pageStyle = {
    width: '500px',
    height: '500px',
    margin: 'auto', // Center the content horizontally
    overflow: 'auto', // Enable scrolling if the content overflows
  };

//   http://localhost:3000/IdeaSubmission?clientId=f3ffe8e1f804f2074aa5cc55233673b8&clientSecret=9b3344bb2ec9fb643eecac8389d2521b&username=nizami@idealy.ca&productId=1

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clientId = searchParams.get('clientId');
    const clientSecret = searchParams.get('clientSecret');
    const username = searchParams.get('username');
    const productId = searchParams.get('productId');
    setProductId(productId);

    const getApiToken = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + 'user/web_token', {
          clientId,
          clientSecret,
          username,
        });

        const userAccessToken = response.data.accessToken;
        setAccessToken(userAccessToken);
      } catch (error) {
        console.error(error);
        toast.error('Error occurred while getting the API token.');
      }
    };

    getApiToken();
  }, [location.search]);

  const onFinish = async () => {
    try {
      const payload = {
        description: form.getFieldValue('desc'),
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + `product/${productId}/idea`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Create Idea successfully');
        form.resetFields();
      } else {
        setError('Something went wrong. Please try again!');
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 403) {
          setError('Oops! Looks like you do not have permissions to submit ideas.');
        } else if (error.response.status === 401) {
          setError('Ops! Please log in to submit ideas.');
        }
      } else {
        setError('Something went wrong. Please try again!');
      }
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div style={pageStyle}>
    <Modal
      title={<h3><BulbOutlined />Got an Idea?</h3>}
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      destroyOnClose
      maskClosable={false}
      closable={false}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>

        <div>
        <Form.Item name="desc" style={{ textAlign: 'center' }}>
          <Input.TextArea
             className="custom-textarea"
            showCount
            maxLength={100}
            rows={10}
            placeholder="Enter your idea description..."
          />
        </Form.Item>
  
        {error && <p className="error-message">{error}</p>}
  
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
            Submit
          </button>
          <button onClick={onCancel}>Cancel</button>
        </div></div>
 
      </Form>
    </Modal></div>
  );
  
  
  
  
  
};

export default SubmitIdea;
