import { Col, Divider, Form, Input, Layout, Select, Space, Tag, Typography, message, theme } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LogoutFooter from '../Layout/LogoutFooter';
import UserFooter from '../Layout/UserFooter';
import WelcomeHeader from '../Layout/WelcomeHeader';
import LoginAgain from '../User/LoginAgain';

const { Title, Text } = Typography;
const { Option } = Select;
const { Content,Header, Footer,Sider } = Layout;


 
const Profile = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [currentUser, setCurrentUser]=useState();
  const [loading, setLoading] = useState(true);
  const [myId, getmyId]=useState('');
  const genderOptions = ['Male', 'Female', 'Other'];
  const ageOptions = ['18-24', '25-44', '35-44', '45-64', '65-74', '74-84', '85+'];
  const householdIncomeOptions = ['Low (<$100,000)', 'Middle ($100,000-$250,000)', 'High (>$500,000)'];
  const educationOptions = ['No High School', 'High School', 'Trade School', 'College', 'University'];
  const occupationOptions = ['Student', 'White Collar', 'Service Sector', 'Blue Collar', 'Business Owner'];
  const homeLanguageOptions = ['English', 'French', 'Non-Official'];
 
  const marritalStatusOptions =['Single','Married','Divorced/Sep/Widowed'];
  const backgroundOptions =['Asian', 'European', 'African', 'Latin American', 'Other'];
  const  dietaryRestrictionsOptions= ['Halal','Kosher','Vegetarian', 'Vegan', 'Gluten-free', 'None'];
  const  familySizeOptions=['Single', 'Small (1-3 members)', 'Medium (4-6 members)', 'Large (7+ members)'];
  const  familyStatusOptions= ['Single','Couples with Kids','Couples, no Kids','Lone-Parent Family'];
  const  preferredShoppingChannelsOptions= ['Physical Stores', 'Online', 'Mobile Apps', 'All'];
  const  onlineShoppingBehaviorOptions= ['Frequent', 'Occasional', 'Rarely'];
  const  techAdoptionLevelOptions= ['Highly Tech-savvy', 'Moderately Tech-savvy', 'Not Tech-savvy'];
  const  productPreferencesOptions= ['Eco-friendly', 'Organic', 'Budget-friendly', 'Premium'];

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [householdIncome, setHouseholdIncome] = useState('');
  const [education, setEducation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [location, setLocation] = useState('');
  const [marritalStatus, setMarritalStatus] = useState('');
  const [homeLanguage, setHomeLanguage] = useState('');
  const [background, setBackground] = useState('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState('');
  const [familySize, setFamilySize] = useState('');
  const [familyStatus, setFamilyStatus] = useState('');
  const [preferredShoppingChannels, setPreferredShoppingChannels] = useState('');
  const [onlineShoppingBehavior, setOnlineShoppingBehavior] = useState('');
  const [techAdoptionLevel, setTechAdoptionLevel] = useState('');
  const [productPreferences, setProductPreferences] = useState('');
  const [province, setProvince] = useState('');
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [form] = Form.useForm();
  const [data, setUserData] = useState({});
  const { success, warning } = message;

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
 
  
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();

    window.location.reload(); // Redirect to login page
  };

  const handleChange = (fieldName, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  

  const refreshIdea = () => {
    // Implement the logic to refresh Idea.js
    window.location.reload(); // Redirect to login page

  };

  const onFinish = () => {
    // Destructure other values as needed
    // ...
    const formValues = form.getFieldsValue();
  
    const payload = {
      email: formValues.email,
      password: formValues.password,
      firstName: formValues.name,
      nickname: formValues.nickname,
      lastName: formValues.lastname,
      province: formValues.province,
      gender: formValues.gender,
      age: formValues.age,
      householdIncome: formValues.income,
      education: formValues.education,
      occupation: formValues.occupation,
      location: formValues.location,
      marritalStatus: formValues.marital,
      homeLanguage: formValues.homeLanguage,
      background: formValues.background,
      dietaryRestrictions: formValues.dietary,
      familySize: formValues.familySize,
      familyStatus: formValues.familyStatus,
      preferredShoppingChannels: formValues.preferredShoppingChannels,
      onlineShoppingBehavior: formValues.onlineShoppingBehavior,
      techAdoptionLevel: formValues.techAdoptionLevel,
      productPreferences: formValues.productPreferences,
      introduction: formValues.introduction,
      // Include other fields from the form values
      // For example: company, etc.
    };
 
    const user_access_token = localStorage.getItem("user_access_token");
  
    axios
      .put(process.env.REACT_APP_API_URL + "user/details", payload, {
        headers: {
          Authorization: "Bearer " + user_access_token,
        },
      })
      .then(response => {
        // Handle the response as needed
        if (response.status === 200) {
          message.success('Profile Updated Sucessfully');
          
        }

      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  
  // ... other options


 
   
  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
     

      const accessKey = '216f01a2a79f40dce58b0a92ac452a03'; // Sign up for a free account to get an access key

      const apiUrl = `http://api.ipstack.com/${data.ip}?access_key=${accessKey}`;
      
      axios.get(apiUrl)
        .then(response => {
          const locationData = response.data;
    
          setProvince(locationData.region_name);

        })
        .catch(error => {
          console.error('Error fetching location data:', error);
        });


      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  const getAllData = async () => {
    try {
      const userAccessToken = localStorage.getItem("user_access_token");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "user/details",
        {
          headers: {
            Authorization: "Bearer " + userAccessToken,
          },
        }
      );
  
      const allData = response.data;
      setUserData(allData);
  
      // Set loading to false once data is loaded
      setLoading(false);
    } catch (error) {
      console.error(error);
      // Set loading to false in case of an error
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getIPAddress();
        setCurrentUser(JSON.parse(localStorage.getItem("user")));
       
        await getAllData();
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  
  
 
   

  return (
  
  
    // {showOnboarding ? (
    //     <UserOnboarding onFinish={() => setShowOnboarding(false)} />
    //   ) : (
        
    
<Layout>
      <Layout  >
        
      <Header
           style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            
          }}

        > 
      {localStorage.getItem("user_access_token") && (
        <WelcomeHeader activity={1} />
      )}
</Header>

<Content
        style={{
          flex: '1',
          padding: '25px 15px',
          background: 'white',
        }}
      >

    {localStorage.getItem("user_access_token") ? (
            
            <div   >
<div   style={{
          padding: '10px',
        }}>
       <Space>        <button
          onClick={handleLogout}
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: '#1890ff', // Change to your preferred color
            textDecoration: 'underline',
            fontSize: '16px', // Adjust font size as needed
            marginLeft: '8px', // Add some margin to the left
          }}
        >
          Logout
        </button>
        </Space>  
      </div>

 <Space>

  <Text strong>Joined:</Text> {data.joinedOn}
  <Space> 
       Badges:  <Tag color="#A8423B"></Tag>
        </Space> 

</Space>
         <Divider/>
   
{loading ? (
      <p>Loading...</p>
    ) : (

<Form

   name="nest-messages"
   onFinish={onFinish}
   validateMessages={validateMessages}
   initialValues={data}
   form={form}

  >
    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
  name='name'
      label="First Name"
      rules={[
        {
          required: true,
        },
      ]}
      defaultValue={data.name || ''}
    >
      <Input onChange={(value) => setAge(value)}/>
    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='lastname'
      label="Last Name"
      defaultValue={data.lastname || ''}
    >
      <Input onChange={handleChange}/>
    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='nickname'
      label="Display Name"
      defaultValue={data.nickname || ''}
    >
      <Input onChange={handleChange}/>
    </Form.Item></Col>


    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item 
      name='email'
      label="Email"
      rules={[
        {
          type: 'email',
        },
      ]}
      defaultValue={data.email || ''}
    >
      <Input readOnly onChange={handleChange}/>
    </Form.Item></Col>

    {/* <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item 
      name='password'
      label="password"
      rules={[
        {
          type: 'password',
        },
      ]}
      defaultValue={data.password || ''}
    >
    

      <Input
              name="password"
              className='form-control'
              placeholder='*********'
              type="password"
             
              onChange={handleChange}
    
            />

    </Form.Item></Col> */}


    {/* <Col xs={24} sm={24} md={24} lg={24}>

    <Form.Item
     name='company'
      label="Company Name"
      defaultValue={data.company || ''}
    >
      <Input onChange={handleChange}/>
    </Form.Item></Col> */}

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
 name='age'
      label="Age"
      defaultValue={data.age || ''}
    >
      <Select value={ageOptions} onChange={(value) => setAge(value)}>
                {ageOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
 name='gender'
      label="Gender"
      defaultValue={data.gender || ''}
    >
      <Select value={genderOptions} onChange={(value) => setGender(value)}>
                {genderOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

    </Form.Item></Col>


    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='province'
      label="State/Province"
      defaultValue={data.province || ''}
    >
    
    
    <Select 
    showSearch
    value={province || undefined}
    style={{
      width: 200,
    }}
    placeholder="Location"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
      label: 'Canada',
      options: [
      {	label	:	'ALBERTA'	,	value	:	'ALBERTA'	,}	,
      {	label	:	'BRITISH COLUMBIA'	,	value	:	'BRITISH COLUMBIA'	}	,
      {	label	:	'MANITOBA'	,	value	:	'MANITOBA'	}	,
      {	label	:	'NEW BRUNSWICK'	,	value	:	'NEW BRUNSWICK'	}	,
      {	label	:	'NEWFOUNDLAND and LABRADOR'	,	value	:	'NEWFOUNDLAND and LABRADOR'	}	,
      {	label	:	'NORTHWEST TERRITORIES'	,	value	:	'NORTHWEST TERRITORIES'	}	,
      {	label	:	'NOVA SCOTIA'	,	value	:	'NOVA SCOTIA'	}	,
      {	label	:	'NUNAVUT'		,value	:	'NUNAVUT'	}	,
      {	label	:	'ONTARIO'	,	value	:	'ONTARIO'	}	,
      {	label	:	'PRINCE EDWARD ISLAND',		value	:	'PRINCE EDWARD ISLAND'	}	,
      {	label	:	'QUEBEC'	,	value	:	'QUEBEC'	}	,
      {	label	:	'SASKATCHEWAN'	,	value	:	'SASKATCHEWAN'	}	,
      {	label	:	'YUKON'	,	value	:	'YUKON'	}	,
      ], },
   
      {
        label: 'USA',
        options: [

      {	label	:	'Alabama'		,value	:	'Alabama'	}	,
      {	label	:	'Alaska'		,value	:	'Alaska'	}	,
      {	label	:	'Arizona'		,value	:	'Arizona'	}	,
      {	label	:	'Arkansas'	,	value	:	'Arkansas'	}	,
      {	label	:	'California',		value	:	'California'	}	,
      {	label	:	'Colorado'	,	value	:	'Colorado'	}	,
      {	label	:	'Connecticut',		value	:	'Connecticut'	}	,
      {	label	:	'Delaware'	,	value	:	'Delaware'	}	,
      {	label	:	'Florida'		,value	:	'Florida'	}	,
      {	label	:	'Georgia'		,value	:	'Georgia'	}	,
      {	label	:	'Hawaii'	,	value	:	'Hawaii'	}	,
      {	label	:	'Idaho'	,	value	:	'Idaho'	}	,
      {	label	:	'Illinois'	,	value	:	'Illinois'	}	,
      {	label	:	'Indiana'	,	value	:	'Indiana'	}	,
      {	label	:	'Iowa'	,	value	:	'Iowa'	}	,
      {	label	:	'Kansas',		value	:	'Kansas'	}	,
      {	label	:	'Kentucky',		value	:	'Kentucky'	}	,
      {	label	:	'Louisiana',		value	:	'Louisiana'	}	,
      {	label	:	'Maine'	,	value	:	'Maine'	}	,
      {	label	:	'Maryland',		value	:	'Maryland'	}	,
      {	label	:	'Massachusetts',		value	:	'Massachusetts'	}	,
      {	label	:	'Michigan'	,	value	:	'Michigan'	}	,
      {	label	:	'Minnesota'	,	value	:	'Minnesota'	}	,
      {	label	:	'Mississippi',		value	:	'Mississippi'	}	,
      {	label	:	'Missouri'		,value	:	'Missouri'	}	,
      {	label	:	'Montana'		,value	:	'Montana'	}	,
      {	label	:	'Nebraska'	,	value	:	'Nebraska'	}	,
      {	label	:	'Nevada'		,value	:	'Nevada'	}	,
      {	label	:	'New Hampshire',	value	:	'New Hampshire'	}	,
      {	label	:	'New Jersey'	,	value	:	'New Jersey'	}	,
      {	label	:	'New Mexico'	,	value	:	'New Mexico'	}	,
      {	label	:	'New York'		,value	:	'New York'	}	,
      {	label	:'	North Carolina',		value	:	'North Carolina'	}	,
      {	label	:	'North Dakota'	,	value	:	'North Dakota'	}	,
      {	label	:	'Ohio'		,value	:	'Ohio'	}	,
      {	label	:	'Oklahoma',		value	:	'Oklahoma'	}	,
      {	label	:	'Oregon'	,	value	:	'Oregon'	}	,
      {	label	:	'Pennsylvania',		value	:	'Pennsylvania'	}	,
      {	label	:	'Rhode Island',		value	:	'Rhode Island'	}	,
      {	label	:	'South Carolina'	,	value	:	'South Carolina'	}	,
      {	label	:	'South Dakota'		,value	:	'South Dakota'	}	,
      {	label	:	'Tennessee'	,	value	:	'Tennessee'	}	,
      {	label	:	'Texas'	,	value	:	'Texas'	}	,
      {	label	:	'Utah'	,	value	:	'Utah'	}	,
      {	label	:	'Vermont'	,	value	:	'Vermont'	}	,
      {	label	:	'Virginia'	,	value	:	'Virginia'	}	,
      {	label	:	'Washington',		value	:	'Washington'	}	,
      {	label	:	'West Virginia',	value	:	'West Virginia	'}	,
      {	label	:	'Wisconsin'		,value	:	'Wisconsin'	}	,
      {	label	:	'Wyoming'		,value	:	'Wyoming'	}	,
      ], },

    ]}
  />

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='education'
      label="Education"
      defaultValue={data.education || ''}
    >
      
      <Select value={educationOptions} onChange={(value) => setEducation(value)}>
                {educationOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='occupation'
      label="Occupation"
      defaultValue={data.occupation || ''}
    >
      
      <Select value={occupationOptions} onChange={(value) => setOccupation(value)}>
                {occupationOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='income'
      label="Household Income"
      defaultValue={data.income || ''}
    >
      
      <Select value={householdIncomeOptions} onChange={(value) => setHouseholdIncome(value)}>
                {householdIncomeOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='homeLanguage'
      label="Home Language"
      defaultValue={data.homeLanguage || ''}
    >
      
      <Select value={homeLanguageOptions} onChange={(value) => setHomeLanguage(value)}>
                {homeLanguageOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
 name='marital'
      label="Marrital Status"
      defaultValue={data.marital || ''}
    >
      <Select value={marritalStatusOptions} onChange={(value) => setMarritalStatus(value)}>
                {marritalStatusOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

    </Form.Item></Col>
 
    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='background'
      label="Cultural Background"
      defaultValue={data.background || ''}
    >
      
      <Select value={backgroundOptions} onChange={(value) => setBackground(value)}>
                {backgroundOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>
 
    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='dietary'
      label="Dietary Restrictions"
      defaultValue={data.dietary || ''}
    >
      
      <Select value={dietaryRestrictionsOptions} onChange={(value) => setDietaryRestrictions(value)}>
                {dietaryRestrictionsOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='productPreferences'
      label="Product Preference"
      defaultValue={data.productPreferences || ''}
    >
      
      <Select value={productPreferencesOptions} onChange={(value) => setProductPreferences(value)}>
                {productPreferencesOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>
 
    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='familyStatus'
      label="Family Status"
      defaultValue={data.familyStatus || ''}
    >
      
      <Select value={familyStatusOptions} onChange={(value) => setFamilyStatus(value)}>
                {familyStatusOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='familySize'
      label="Family Size"
      defaultValue={data.familySize || ''}
    >
      
      <Select value={familySizeOptions} onChange={(value) => setFamilySize(value)}>
                {familySizeOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>
 

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='preferredShoppingChannels'
      label="Prefferred Shopping Channels"
           defaultValue={data.preferredShoppingChannels || ''}
    >
      
      <Select value={preferredShoppingChannelsOptions} onChange={(value) => setPreferredShoppingChannels(value)}>
                {preferredShoppingChannelsOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>
 
    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='onlineShoppingBehavior'
      label="Online Shopping Frequency"
      defaultValue={data.onlineShoppingBehavior || ''}
    >
      
      <Select value={onlineShoppingBehaviorOptions} onChange={(value) => setOnlineShoppingBehavior(value)}>
                {onlineShoppingBehaviorOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>
 
    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
     name='techAdoptionLevel'
      label="Tech Adoption"
      defaultValue={data.techAdoptionLevel || ''}
    >
      
      <Select value={techAdoptionLevelOptions} onChange={(value) => setTechAdoptionLevel(value)}>
                {techAdoptionLevelOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>

 

    </Form.Item></Col>


    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item   name='introduction' label="Introduction"
          defaultValue={data.introduction || ''}>
      <Input.TextArea rows={5} onChange={handleChange}/>
    </Form.Item></Col>

    <Col xs={24} sm={24} md={24} lg={24}>
    <Form.Item
      
    >
      <button  htmlType="submit">
        Update
      </button>
    </Form.Item> </Col>
  </Form> )}

</div>
) : (
  // Render the LoginAgain component for users not logged in
  <LoginAgain refreshIdea={refreshIdea} /> )
} 

</Content>
 
  
 

{/* {reward && (<RewardCard reward={reward} userTaskProgress={userTaskProgress}  variationCount={variationCount} pollCount={pollCount} featureCount={featureData && featureData.idfeature !== null ? 1 : 0} />)} */}
{localStorage.getItem("user_access_token") ? (
        // If user is logged in, show UserFooter
        <UserFooter username={data.name} />
      ) : (
        // If user is not logged in, show LogoutFooter
        <LogoutFooter />
      )}

 
  </Layout>
    </Layout>

     
  ); 
      
};
export default Profile;
