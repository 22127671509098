import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {  Modal, Spin, Upload, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

const FileUploadComponent = ({ product_id }) => {
  const [file, setFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
  const [fileName, setFileName] = useState('');

const handleFileChange = (info) => {
 

  if (info.file && info.file.status === 'done') {
    setFileName(info.file.name);
    setIsModalVisible(true);
    setUploadStatus('success');
  } else if (info.file && info.file.status === 'error') {
    message.error(`${info.file.name} upload failed.`);
    setUploadStatus('error');
  }
};

  
  

const handleUpload = async () => {
  try {
    setUploadStatus('loading');
    const formData = new FormData();
    formData.append('csvFile', file);
    const user_access_token = localStorage.getItem('user_access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${user_access_token}`,
        'Content-Type': 'multipart/form-data', // Set the correct content type
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}product/${product_id}/upload`,
      formData,
      config
    );

    // Comment this line because the status is updated in handleFileChange
    // setUploadStatus('success');

    // Invoke handleFileChange to update the modal and reset the file state
    if (file) {
      handleFileChange({ file: { status: 'done' } });
    }

 
  } catch (error) {
    console.error('Error uploading file:', error);
    message.error('File upload failed.');
    setUploadStatus('error');
  }
};


  

  const handleModalOk = () => {
    setIsModalVisible(false);
    setUploadStatus('idle'); // Reset upload status for the next upload
    setFileName(''); // Clear the file name when modal is closed
    setFile(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setUploadStatus('idle'); // Reset upload status for the next upload
    setFileName(''); // Clear the file name when modal is closed
    setFile(null);
  };

  return (
    <div>
      <Upload
        customRequest={() => {}}
        onChange={handleFileChange}
        showUploadList={false}
        beforeUpload={(file) => {
          setFile(file);
          return false; // Prevent default upload behavior
        }}
      >
        <button icon={<UploadOutlined />}>
          {uploadStatus === 'loading' ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            file ? file.name : 'Select File'
          )}
        </button>
      </Upload>

      <button onClick={handleUpload} disabled={!file} style={{ marginLeft: 16 }}>
        Upload
      </button>

      <Modal
        title={uploadStatus === 'success' ? 'File Uploaded' : 'Upload Failed'}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {uploadStatus === 'success' ? (
          <>
            <p>File uploaded successfully!</p>
            <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 24 }} />
          </>
        ) : (
          <>
            <p>{`File upload failed.`}</p>
            <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 24 }} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default FileUploadComponent;
