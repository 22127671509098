import { decodeToken } from "react-jwt";
import { toast } from 'react-toastify';

const GoogleUserLogin = async (credentialResponse, idCompany) => {
  try {

    const googleIdToken = credentialResponse.credential;
    const response = await fetch(process.env.REACT_APP_API_URL + 'user/authenticateWithGoogle', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ googleIdToken, idCompany: idCompany }),
    });

    if (response.status === 200) {
      const responseData = await response.json(); // Parse JSON response

      console.log("ID", responseData.idToken);
      console.log("Access", responseData.accessToken);

      localStorage.removeItem("user");
      localStorage.removeItem("user_access_token");
      localStorage.removeItem("user_role");
      localStorage.clear();

      const decodedToken = decodeToken(responseData.idToken);
      localStorage.setItem("user", JSON.stringify(decodedToken));
      localStorage.setItem("user_access_token", responseData.accessToken);

      const decodedAccessToken = decodeToken(responseData.accessToken);

      // Get user role from access token
      if (decodedAccessToken && decodedAccessToken.ROLE) {
        const getRole = decodedAccessToken.ROLE;
        const roleData = getRole.split(":");
        localStorage.setItem("user_role", roleData[1]);
      }




      return { code: 200, message: "Login successful." };
    }
  } catch (error) {
    console.error(error);

    // Handle specific error scenarios
    if (error.response && error.response.status === 401) {
      // Unauthorized - Invalid credentials
      toast.error("Invalid credentials. Please check your email and password.");
      return { code: 401, message: "Invalid credentials. Please check your email and password." };
    } else {
      // Other errors
      toast.error("Login failed. Please try again.");
      return { code: 500, message: "Login failed. Please try again." };
    }
  }
};

export default GoogleUserLogin;



