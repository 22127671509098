
const ParseProductAlignment = (productCanvasText) => {
  // Parsing values from the product canvas text
  
  const regexInnovative = /Innovation: (\d)/;
  const regexAppeal = /Consumer Appeal: (\d)/;
  const regexMarketFit = /Market Fit: (\d)/;
  const regexProductAlignment = /Vision Alignment: (\d)/;
  const regexFeasibility = /Feasibility: (\d)/;
  const regexProfitability = /Profitability: (\d)/;
  const regexSustainability = /Sustainability: (\d)/;
  const regexCompliance = /Compliance: (\d)/;

  const marketFit = regexMarketFit.exec(productCanvasText)?.[1] || '';
  const productAlignment = regexProductAlignment.exec(productCanvasText)?.[1] || '';
  const feasibilityValue = regexFeasibility.exec(productCanvasText)?.[1] || '';
  const profitabilityValue = regexProfitability.exec(productCanvasText)?.[1] || '';
  const innovativeValue = regexInnovative.exec(productCanvasText)?.[1] || '';
  const consumerAppealValue = regexAppeal.exec(productCanvasText)?.[1] || '';
  const sustainabilityValue = regexSustainability.exec(productCanvasText)?.[1] || '';
  const complianceValue = regexCompliance.exec(productCanvasText)?.[1] || '';


  // Extracting the explanation content using regex
  const regexExplanation = /Explanation:\s*(<ol>[\s\S]*<\/ol>)/;
  const explanationHTML = regexExplanation.exec(productCanvasText)[1];

  

  return {
    marketFit,
    productAlignment,
    feasibilityValue,
    profitabilityValue,
    innovativeValue,
    consumerAppealValue,
    sustainabilityValue,
    complianceValue,
    explanationHTML,
  };
};

export default ParseProductAlignment;
