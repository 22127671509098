import React, { useState, useEffect } from 'react';
 
import { useParams, Link, useNavigate,useLocation } from 'react-router-dom';
import { Button, Typography, Input, Form, message } from 'antd';
 
import axios from 'axios';
import { decodeToken } from "react-jwt";
import Login from './User/Login';
import GoogleUserLogin from './User/GoogleUserLogin'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import Header from './Layout/Header';
import WelcomeLoginModule from './User/WelcomeLoginModule';
import BrandLogin from './User/BrandLogin';

const { Title } = Typography;
const { success, error, warning } = message;

const LoginPage = () => {
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
 
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
 

  const formContainerStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white', // Replace with theme background color
  };

  const formTitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333', // Replace with theme text color
  };

  const inputFieldStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    marginBottom: '20px',
    border: '1px solid #ccc', // Replace with theme border color
    borderRadius: '4px',
    backgroundColor: '#fff', // Replace with theme background color
    color: '#333', // Replace with theme text color
  };

  const buttonStyle = {
    width: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff', // Replace with theme text color
    background: '#007bff', // Replace with theme primary color
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = async () => {
    try {
      // const result = await Login(formData.email, formData.password);

      // if (result && result.code === 200) {
      //   const accessToken = localStorage.getItem('user_access_token');
      //   const decodeTokens = decodeToken(accessToken);

        if (type === 'Research') {
          // Redirect to "/product-list" when coming from "Research"
          window.location.href = '/Research/Product';
        } else if (type === 'Reward') {
          // Redirect to "/idea-lounge" when coming from "Reward"
          window.location.href = '/Reward/Lounge';
        }
       
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const company = searchParams.get('company');
      const result=  await GoogleUserLogin(credentialResponse,company);
        
        if (result && result.code === 200) {
  
        
  
            const accessToken = localStorage.getItem("user_access_token");
            // Decode the token
            const decodeTokens = decodeToken(accessToken);
            
            // Access token properties
            const { sub, company, scope, ROLE, exp } = decodeTokens;
            
            // Now you can use these properties as needed
            // console.log(sub); // "testmoe@idealy.com"
            // console.log(company); // 1
            // console.log(scope); // "product:read product:update ..."
            // console.log(ROLE); // "role:admin"
            // console.log(exp); // Token expiration timestamp
   
            if (ROLE === 'role:admin' || ROLE=== 'role:user') {
              // Redirect to "/product-list" for Admin or User roles
              window.location.href = "/Research/Product";
            } else if (ROLE === 'role:guest') {
              // Redirect to "/idea-lounge" for other roles
              window.location.href = "/Reward/Lounge";
            }
  
        }
        // if (error.code === 401) {
        //   // Unauthorized - Invalid credentials
        //   // alert("Invalid credentials. Please check your email and password.");
        // } else {
        //   // Other errors
        //   alert("Login failed. Please try again.");
        // }
  
        // Assuming Login is successful, proceed to /product-list
      
      } 
      
  
      catch (error) {
        console.error(error);
   
   
      }
  
  };

  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};

  return (
   
    <div style={formContainerStyle}>
    {type === 'Research' && <BrandLogin  refreshIdea ={handleSubmitClick}/>}
    {type === 'Reward' && <WelcomeLoginModule refreshIdea={handleSubmitClick}/>}
    {/* ... (Add other conditions as needed) */}
  </div>
  );
}; 

export default LoginPage;
