import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import {Breadcrumb, Layout, Menu, theme, Space, Typography, Button, Divider } from 'antd';
import { Row, Col } from 'antd';


import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Employee  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout       style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>

           <Layout   id="featureTextBox"
             style={{
               padding: '24px 0',
               background: colorBgContainer,
             }}
           >
    
             <Content
               style={{
                 padding: '0 0px',
                 minHeight: 500,
               }}
             >
   

 

 

  <div  >
<Space  direction='vertical'>

 <h1 className='h1-IdeaHub'>Internal IDEAS.</h1>
 <h4>Are your employees feeling bogged down by tedious processes and system gaps that cause unnecessary churn?</h4>
 <br/>

      <h3 className='feature'>Empowerment</h3>
      <Text type="secondary" className="feature-text">
        Empowering your team to speak up about a longstanding process, system gaps, or help you blue sky new ideas is key to unlocking higher productivity and boosting morale. Introducing IDEALY, the platform that gives your employees a voice and helps you make meaningful change.
      </Text>
   
      <h3 className='feature'>Centralized Platform</h3>
      <Text type="secondary" className="feature-text">
        IDEALY provides your employees with a centralized platform to share and discover ideas. Shift your organizational culture to be more inclusive, creative, and productive.
      </Text>
    
      <h3 className='feature'>Validation and Communication</h3>
      <Text type="secondary" className="feature-text">
        With voting, conversations, and ratings, your team can easily validate existing ideas while notifications and announcement feature keeps everyone up-to-date on the progress of their favorite ideas.
      </Text>
    
      <h3 className='feature'>Enhanced Employee Experience</h3>
      <Text type="secondary" className="feature-text">
        And it's not just about process improvement - IDEALY helps you improve employee experience and gauge their sentiment before investing effort in rolling out a program. Incorporating employee feedback through IDEALY can make change management more effective and boost overall morale.
      </Text>
    


 
</Space>
</div>


             </Content>
           </Layout>
         </Content>

         <div id="rcorners1"  className="h2white">

<h2>Try IDEALY today and start unlocking the full potential of your Employees. </h2>  <Divider type="vertical" />
 
 <Button type='primary' href="/signup"  >TRY FOR FREE!</Button> 
</div>

         <Footer
           style={{
             textAlign: 'center',
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Employee;
