
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Button, Col, Input, Layout, Row, Tabs, message, Card, Divider, Space, Form, Select , Spin} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { decodeToken } from "react-jwt";
import { Link,useLocation } from 'react-router-dom';
import GoogleUserLogin from './GoogleUserLogin';
import Login from './Login';
import Signup from './Signup';
import logo from '../../assets/images/Logo.png';
 

const { Header, Content } = Layout;
const { success, error, warning } = message;
const {TabPane}=Tabs;
const { Option } = Select;


const BrandLogin = (props) => {
  const {
    setLoggedInUser,
    user,
    refreshIdea
  } = props;

  const [clientId, setClientId]=useState('');
  const [clientSecret, setClientSecret]=useState('');
 const [accessToken, setAccessToken]=useState("");
  const [role, setRole] = useState("guest");
  const [points, setPoints] = useState("0");
  const [level, setLevel] = useState("Rookie");
  const [company, setCompany] = useState("guest");
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [usernameError, setUsernameError] = useState('');

  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [currentStep, setCurrentStep] = useState(0);

  
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const industryOptions = ['Enterpreneur', 'Product Manager', 'Market Researcher', 'Brand Manager', 'Customer Insights Manager','Other'];


  useEffect(() => {
    // Check token expiration on component mount
    const  User = JSON.parse(localStorage.getItem('user'));
    console.log("user",User);
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    // Set company to 0 if it's not present in the URL
    const companyValue = company || 0;
    setCompany(companyValue);
    setIndustry("Select Your Role");

    if (User) {
    setCurrentUser(User);
    checkTokenExpiration();
    decodeAccess();
    // Set up a timer to periodically check token expiration
    const tokenCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute
    
    // Cleanup the interval on component unmount
    return () => clearInterval(tokenCheckInterval);}
  }, []);
  
 


  const checkTokenExpiration = () => {
    // Retrieve the stored token from localStorage
    const storedToken = JSON.parse(localStorage.getItem('user'));
  
    // Check if the token is present and not expired
    if (storedToken && !isTokenExpired(storedToken)) {
      // Token is valid, user is authenticated
      console.log("Token is valid. User is authenticated.");
      return;
    }
  
    // Token is either not present or expired, log the user out and redirect to login page
    handleLogout();
  };
  
  // Function to logout the user
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();
    
    window.location.reload(); // Redirect to login page
  };
  
  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      console.log('User IP Address:', data.ip);
      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
  
  
  const getApiToken = async (clientId,clientSecret,username) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL +`user/web_token`, {
        clientId,
        clientSecret,
        username,
      });

      console.log("token",response.data.accessToken )
     
    return  response.data;



     
    } catch (error) {
      console.error(error);
      message.error('Error occurred while getting the API token.');
    }
  };
  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    if (token && token.exp) {
      return token.exp * 1000 <= Date.now();
    }
    return true; // Token is considered expired if no expiration information is available
  };
  
  const decodeAccess=() => {
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    setCompany(company);

  }

 
  const handleGoogleLogin = async (credentialResponse) => {
    try {
 
   
      const result=  await GoogleUserLogin(credentialResponse,company);
        
        if (result && result.code === 200) {
  
        
  
            const accessToken = localStorage.getItem("user_access_token");
            // Decode the token
            setAccessToken(accessToken);
            refreshIdea();
            const decodeTokens = decodeToken(accessToken);
            
            // Access token properties
            const { sub, company, scope, ROLE, exp } = decodeTokens;
             
  
        }
     
      
      } 
      
  
      catch (error) {
        console.error(error);
   
   
      }
  
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true); // Set loading to true during login
      const result = await Login(regEmail, password);

      if (result && result.code === 200) {
        const accessToken = localStorage.getItem('user_access_token');
  
        setAccessToken(accessToken);
        refreshIdea();
      } else if (result && result.code === 401) {
      
        message.error( 'Invalid credentials. Please check your email and password.');
        setLoading(false); // Set loading back to false after login completes
      } else {
       
    
        setLoading(false); // Set loading back to false after login completes
      }
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading back to false after login completes
      message.error( 'Login failed. Please try again.');
    } finally {
      setLoading(false); // Set loading back to false after login completes
 
    }
  };

 
const showModal=() => {

  setModalVisible(true);

}

 

const isFormValid = () => {
  // Add any additional validation logic if needed
  return !usernameError;
};


const handleRegisterClick = (e) => {
  e.preventDefault();

  if (firstName.length < 3 || !/^[a-zA-Z0-9]+$/.test(firstName)) {
    setFirstNameError('First name must be at least 3 characters.');
    return;
  } else {
    setFirstNameError('');
  }

  // Validate last name
  if (lastName.length < 3 || !/^[a-zA-Z0-9]+$/.test(lastName)) {
    setLastNameError('Last name must be at least 3 characters.');
    return;
  } else {
    setLastNameError('');
  }
  
  if (username.toLowerCase().includes(firstName.toLowerCase()) || username.toLowerCase().includes(lastName.toLowerCase())) {
    setUsernameError('Username cannot be part of the first or last name.');
    return;
  }

  if (username.length < 5 || !/^[a-zA-Z0-9]+$/.test(username)) {
    setUsernameError('Username must be at least 5 characters and contain only alphanumeric characters.');
    return;
  } else {
    setUsernameError('');
  }

  if (companyName.length < 3 || !/^[a-zA-Z0-9]+$/.test(companyName)) {
    setCompanyError('Company name must be at least 3 characters.');
    return;
  } else {
    setCompanyError('');
  }
 

  const payload = {
    name: firstName,
    lastname: lastName,
    nickname:username,
    idcompany: companyName,
    industry: industry,
    email: regEmail,
    password: regPassword,
    new:1,
    userType:"Admin"
  }

  setLoading(true); // Set loading to true during registration

  axios.post(process.env.REACT_APP_API_URL + 'user', payload)
    .then(function (res) {
    
      if (res.status === 200) {
      
        const payload = {
          "email" : regEmail,
          "password" : regPassword,
        }
  
        axios.post(process.env.REACT_APP_API_URL + 'user/authenticate', payload)
          .then(function (res) {
            if(res.status === 200){
              // matain our session
              message.success("Account registered successfully");
              const decodedToken = decodeToken(res.data.idToken);
              localStorage.setItem("user", JSON.stringify(decodedToken));
              localStorage.setItem("user_access_token", res.data.accessToken);
              const decodedAccesstoken = decodeToken(res.data.accessToken);
            //  Get user role from access token 
              if(decodedAccesstoken && decodedAccesstoken.ROLE){
                const getRole = decodedAccesstoken.ROLE;
                const roleData = getRole.split(":");
                localStorage.setItem("user_role",roleData[1]);
              }
              setLoading(false); 
              window.location.href = "/Research/Product";
              }else {
                message.error( res.data.message);
                setLoading(false); 
                // window.location.href = "./"
              }
          })
          .catch(function (error) {
            console.log(error);
            message.error('Signup failed. Please try again.');
            setLoading(false); 
          });

      } else {
        // toast.error("Something went wrong");
        // window.location.href = "/"
        setLoading(false); 
      }
    })
    .catch(function (error) {
      console.log(error);
      // toast.error("Something went wrong. Please try again!!");
      // window.location.href = "/"
      message.error('Signup failed. Please try again.');
      setLoading(false); 
    });
}


const inputFieldStyle = {
  width: '100%',
  padding: '12px',
  fontSize: '16px',
  marginBottom: '20px',
  border: '1px solid #ccc', // Replace with theme border color
  borderRadius: '4px',
  backgroundColor: '#fff', // Replace with theme background color
  color: '#333', // Replace with theme text color
};

const buttonStyle = {
  width: '100%',

  fontSize: '16px',
  fontWeight: 'bold',
  color: '#fff', // Replace with theme text color
  background: '#007bff', // Replace with theme primary color
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};
const selectStyle = {
  width: '100%',
  borderRadius: '4px',
  color: '#5c7c97', // Replace with theme text color
  // Additional styles for the Select component
  display: 'block',
  
};

  return (
    <>
    <Space style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Link to={'/'}>
            <img src={logo} alt="Logo" />
          </Link>
          <h3>DIY Product Reseach</h3>
        </Space>
   
        <div style={{ padding: '16px' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h5>Are you looking to gather valuable insights from customers? Idealy provides the perfect platform for you to connect with customers and elevate your product development process.</h5>

          <ol>
             
              <h5>Direct Access to Customers</h5>
              <p>Connect with a curated community of top customers aka product influencers, and gain insights from diverse perspectives to enhance your product concepts.</p>
             
              <h5>Accelerate Product Development</h5>
              <p>Get real-time feedback on your ideas and concepts. Idealy's platform empowers you to iterate quickly and make data-driven decisions, accelerating your product development lifecycle.</p>
            
              <h5>High Product Market Fit</h5>
              <p>Categorize and score ideas based on our unique innovation frameworks streamlining the evaluation process, and quickly identify promising ideas and consumer-centric features, eliminating guesswork in decision-making.</p>
            
          </ol>
        </Col>
      </Row>

      <h3>Get Started Today!</h3>
      <p>Join Idealy and take your product research to new heights. Setting up your idea page is quick and easy. Start receiving valuable feedback from influencers and transform your concepts into exceptional consumer products.</p>

       
    </div>
 <Tabs defaultActiveKey="signin" type="line" size="small">

                {/* Signup Tab */}
 
                {/* Sign In Tab */}
                <TabPane tab="Sign In" key="signin">
                <h3>Hi there! Have we met before?</h3>
                    <form onSubmit={handleLogin} className="auth-form">
                        <Space direction='vertical'>

                            <Input
                                placeholder="Email"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                value={regEmail}
                                onChange={(e) => setRegEmail(e.target.value)} />


                            <Input.Password
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                                   {loading && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        
        </div>
      )}

                            <button type="primary" htmlType="submit">
                                Login
                            </button>

                         
                            {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                <GoogleLogin
                                    onSuccess={(credentialResponse) => {
                                        handleGoogleLogin(credentialResponse);
                                    } }
                                    onError={() => {
                                        message.error('Login Failed');
                                    } } />
                            </GoogleOAuthProvider> */}
                        </Space>
                    </form>


                </TabPane>
                
              

                
                <TabPane tab="Sign up" key="signup" size="small">
                <form onSubmit={handleRegisterClick} className="auth-form">
                        <Space direction='vertical'>
        <h3>Let's get you started!</h3>
        <p>No Credit Card Required.</p>
  
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            
              placeholder="First Name"
            />
         {firstNameError && <p className="error-message">{firstNameError}</p>}
 
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
          
              placeholder="Last Name"
            />
          {lastNameError && <p className="error-message">{lastNameError}</p>}

       
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          
            placeholder="User Name"
          />
          {usernameError && <p className="error-message">{usernameError}</p>}
      

       
            <Input
              value={regEmail}
              onChange={(e) => setRegEmail(e.target.value)}
             
              placeholder="mail@address.com"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
         

          
            <Input.Password
              type="password"
              value={regPassword}
              onChange={(e) => setRegPassword(e.target.value)}
            
              placeholder="*********"
            />

 
          
            <Input
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
           
              placeholder="Brand or Business Name"
            />
                    {companyError && <p  className="error-message">{companyError}</p>}
          
           
            <Select
              value={industry}
              onChange={(value) => setIndustry(value)}
              placeholder="I am a "
              style={selectStyle}
               
            >
              {industryOptions.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
           
            {loading && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        
        </div>
      )}   
      
          <button type="primary" htmlType="submit">
          Signup
                            </button>
 
          

    
          
        
        </Space>
      </form>
          
     
 
       
  
                </TabPane>
                <p>  By clicking "Sign Up", I expressly agree to the IDEALY's
            <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>
            
            and understand that my account information will be used according to IDEALY's
            <a href="/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
            </p>
                
                            </Tabs>
      
    
  </>

    );
};

export default BrandLogin;
