import axios from 'axios';
import { decodeToken } from "react-jwt";
import { message } from 'antd'; // Assuming you are using Ant Design for message component

const Login = async (regEmail, regPassword) => {
  try {
    const authPayload = {
      email: regEmail,
      password: regPassword,
    };

    const authResponse = await axios.post(process.env.REACT_APP_API_URL + 'user/authenticate', authPayload);

    if (authResponse.status === 200) {
      localStorage.clear();
      const decodedToken = decodeToken(authResponse.data.idToken);
      localStorage.setItem("user", JSON.stringify(decodedToken));
      localStorage.setItem("user_access_token", authResponse.data.accessToken);

      const decodedAccessToken = decodeToken(authResponse.data.accessToken);

      // Get user role from access token
      if (decodedAccessToken && decodedAccessToken.ROLE) {
        const getRole = decodedAccessToken.ROLE;
        const roleData = getRole.split(":");
        localStorage.setItem("user_role", roleData[1]);
      }

      return { code: 200, message: "Login successful." };
    }
  } catch (error) {
    console.error(error);

    let errorMessage = "Login failed. Please try again.";

    // // Handle specific error scenarios
    // if (error.response) {
    //   if (error.response.status === 401) {
    //     // Unauthorized - Invalid credentials
    //     errorMessage = "Invalid credentials. Please check your email and password.";
    //   } else {
    //     // Other errors
    //     errorMessage = "An error occurred during login.";
    //   }
    // }

    message.error(errorMessage);

    return { code: error.response ? error.response.status : 500, message: errorMessage };
  }
};

export default Login;
