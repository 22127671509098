import { Breadcrumb, Layout, Menu, theme, Dropdown, Space, Typography, Tag  } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React from 'react';
import UserHeader2 from './UserHeader2'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import withRouter from '../../withRouter';
import  Footer  from '../Layout/Footer'

 

const { Header, Content, Sider } = Layout;


const items = [
  {
    key: 'Rice_Score',
    label: 'R.I.C.E Framework',
  },
  {
    key: 'Idealy_Value',
    label: 'Idealy Value Framework',
  },
  {
    key: 'Stack',
    label: 'Rank Overrides',
  },
];
 
 
 

const MainFramework = ({children}) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { product_id } = useParams();
  const navigate = useNavigate();
  const onClick = (e)=>{navigate(`/${e.key}/`+product_id)}
   

  return (
    <Layout>
   
     < UserHeader2/>
   
      <Content
        style={{
          padding: '0 50px',
        }}
      >
        <Breadcrumb
            style={{
              margin: '10px 20px',
            }}
          >
         
        <Breadcrumb.Item href={"../product-list/"}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={"../product-list/product"}>Name of Product</Breadcrumb.Item>
        <Breadcrumb.Item active>Prioritization Frameworks</Breadcrumb.Item>
      </Breadcrumb>

      
        <Layout
          style={{
            padding: '24px 0',
            background: colorBgContainer,
          }}
        >
 

<Content
            style={{
              padding: '0 24px',
              minHeight: 100,
            }}
          >
 

<h3>Prioritization Frameworks</h3>

<br/>

 <Dropdown
    menu={{
      items,
      onClick,
    }}
    onClick={(e) => e.preventDefault()}
  >
    
      <Space>
       Select Framework
        <DownOutlined />
      </Space>
  
  </Dropdown>

          </Content>
          <Content
            style={{
              padding: '0 24px',
              minHeight: 1080,
            }}
          > 
    {children}

          </Content>
        </Layout>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
 
      </Footer>
    </Layout>
  ); 
      
};
export default withRouter(MainFramework);
