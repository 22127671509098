import axios from 'axios';
import getCommentsArray from './getCommentsArray';
 
const performSentimentAnalysis = async (product_id, idea_id, description) => {
  try {
    const commentData = await getCommentsArray(product_id, idea_id);
 

    const combinedMessagesArray = ['IDEA: ' + description, 'Conversations:'].concat(
      commentData
        .filter((message) => message && message.text && !message.text.includes('Status Changed'))
        .map((message) => (message && message.text ? message.text : ''))
    );

    const combinedText = combinedMessagesArray.join('\n');
  
    const payload = { sentimentText: combinedText };
    const user_access_token = localStorage.getItem('user_access_token');
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}product/${product_id}/idea/sentimentAnalysis`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      }
    );

    if (response.status === 200) {

      const positiveScore = parseFloat(response.data.positive);
      const negativeScore = parseFloat(response.data.negative);
      const neutralScore = parseFloat(response.data.neutral);
      
      // Check if response.data is not null before accessing properties
      if (response.data !== null) {
        // Calculate compound score
        let compoundScore = positiveScore - negativeScore;
      
        const user_access_token = localStorage.getItem("user_access_token");
      
        const payload = {
          pphrase: response.data.positiveWords.join(", "),
          nphrase: response.data.negativeWords.join(", "),
          neutral: neutralScore,
          positive: positiveScore,
          negative: negativeScore,
          sentiment: response.data.sentiment,
          sentimentScore: compoundScore,
        };
      
        // Rest of your code...
   
    
     await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/sentiment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );
    } else {
      // Handle the case where response.data is null
      console.error("Response data is null");
    }
      return response.data;
    }
  } catch (error) {
    console.error('Error in sentiment analysis:', error);
    return null;
  }
};

export default performSentimentAnalysis;
