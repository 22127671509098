import { Card, Radio, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

// ... (imports)

const PollUserView = ({ poll, product_id, idea_id,onPoll }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [points, setPoints] = useState(0);
  const handleSelectOption = (e) => {
    setSelectedOption(e.target.value);
    console.log(e);
    handleSubmit(e.target.value);
  };

  const handleSubmit = async (value) => {
    // Simulate sending data to the backend
    const payload = {
      response: value,
    };

    const userAccessToken = localStorage.getItem('user_access_token');

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          'product/' +
          product_id +
          '/idea/' +
          idea_id +
          '/poll/' +
          poll.pollId,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      console.log('Selected Option:', poll.pollId, payload);
      setPoints(points + 10);
      onPoll();
      message.success('Great choice! Thanks for sharing your opinion');
    } catch (error) {
      message.error('Ops something went wrong!. Please try again');
    }
  };

  return (
   
<>
    <Card  style={{ marginBottom: 16, textAlign: 'center' }} >
     <h5>{poll.question}</h5>
      <Radio.Group onChange={handleSelectOption} value={selectedOption}>
        {/* Parse the options JSON string back into an array */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '5px'}}>
        {JSON.parse(poll.options).map((option, optionIndex) => (
          <Radio key={optionIndex} value={option}  style={{
            marginBottom: 10,
            
            color: selectedOption === option ? '#3A7F7F' : 'inherit',
          }}>
            {option}
          </Radio>
        ))}
        </div>
      </Radio.Group>

 
     
    </Card>
    </>
   
  );
};

export default PollUserView;
