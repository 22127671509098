import { Layout, Modal, Button, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import UserHeader2 from '../Layout/UserHeader2';
import SurveyForm from '../Survey/SurveyForm';
import SurveyList from '../Survey/SurveyList';

const { Header, Content, Footer } = Layout;

const SurveyDashboard = ({product_id, idea_id }) => {
  const [surveys, setsurveys] = useState(null);
  const [isCreateSurveyModalVisible, setCreateSurveyModalVisible] = useState(false);
 

 
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get('title');

  useEffect(() => {
 

    getSurveyData();
  }, [product_id, idea_id]);

  const getSurveyData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/survey`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );
      setsurveys(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching surveys:', error.message);
    }
  };

  const handleCreateSurveyClick = () => {
    setCreateSurveyModalVisible(true);
  };

  const handleCreateSurveyModalCancel = () => {
    setCreateSurveyModalVisible(false);
  };

  const handleCreateSurvey = async (surveyData) => { 
   
  
      try {
        
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/survey`,
          surveyData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        
    getSurveyData();
        setCreateSurveyModalVisible(false);
        
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    
  };
  
  
  
  const onUpdate = (survey) => {
console.log(survey);


    if (survey) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/survey/${survey.surveyId}`,
           survey,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('Survey status updated successfully:', response.data);
    
        })
        .catch((error) => {
          console.error('Error updating offer status:', error);
        });
    }
  };

  const handleBackButtonClick = () => {
    navigate(`/product-detail/${product_id}`);
  };


  const handleDeleteSurvey = async (surveyId) => {
    try {
      const userAccessToken = localStorage.getItem('user_access_token');
      await axios.delete(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/survey/${surveyId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );
  
      // Assuming setsurveys(null) sets the surveys state to null
      setsurveys(null);
    } catch (error) {
      console.error('Error deleting offer:', error.message);
    }
  };
  
  return (
    <div style={{ background: 'white',   padding: '0 10px' }}>
   
      <Row justify="left">
            <Col>
              <Button onClick={handleCreateSurveyClick} type="primary">
                Create Survey
              </Button>
            </Col>
          </Row>
          <Modal
            title="Create Survey"
            visible={isCreateSurveyModalVisible}
            onCancel={handleCreateSurveyModalCancel}
            footer={null}
            destroyOnClose
          >
            <SurveyForm onCreateSurvey={handleCreateSurvey} />
          </Modal>
      {/* {surveys && ( */}
        <>

          <div className="dashboard-container">
         
            <SurveyList surveys={surveys} onUpdate={onUpdate} onDelete={handleDeleteSurvey} />
          </div>

          {/* Modal for Create Survey */}
  
        </>
      {/* )} */}
    </div>
  );
};

export default SurveyDashboard;
