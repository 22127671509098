import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import {Breadcrumb, Layout,  Button, Menu, theme, Space, Typography, Divider  } from 'antd';
import co from '../assets/images/co.png';
import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Insight  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout       style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>
 
           <Layout  id="featureTextBox"
             style={{
               padding: '24px 0',
               background: colorBgContainer,
             }}
           >
    
             <Content
               style={{
                 padding: '0 24px',
                 minHeight: 500,
               }}
             >
   
   <div className='hero-container'>
      <img src={co} className='hero-background' alt='Background' />

 
    </div>
    <br/>
<Space direction='vertical' >
   <h3 className='feature'>Drive Customer Obsession with IDEALY's Detailed Analytics.</h3>
<Text  type="secondary">Gain valuable insights into product gaps, urgent problems, and customer retention opportunities. With our robust analytics, you can make data-driven decisions and prioritize initiatives that truly meet your customers' needs, driving customer obsession and satisfaction.</Text>

 


<h3 className='feature'>Optimize Your Processes with IDEALY.</h3>
<Text  type="secondary">
With IDEALY, empower employees and customers to improve processes, enhancing the customer experience and bottom line. Streamline workflows, identify areas for improvement, and drive operational efficiency, resulting in a better experience for your team and customers alike.
</Text>
 
<h3 className='feature'>Unlock Customer Insights with Accurate Sentiment Analysis.</h3>
<Text type="secondary" >
Gain deep insights into customer sentiment and perception with IDEALY's powerful sentiment analysis. Address weaknesses, predict preferences, and enhance customer satisfaction by leveraging timely insights. Make data-driven decisions and craft a compelling brand story with IDEALY.

</Text>

 

<h3 className='feature'>Spot Trends and Identify Product Opportunities with IDEALY's Heat Maps</h3>
<Text type="secondary" >
Spot trends, optimize user experiences, and prioritize enhancements with IDEALY's Heat Maps. Gain valuable insights from user interactions to make data-driven decisions and improve your product's success. Uncover hidden opportunities and enhance the user experience with IDEALY.</Text>

 


<h3 className='feature'>Unlock Growth Opportunities with User Segmentation.</h3>
<Text  type="secondary">
Uncover valuable insights and drive growth by segmenting users with IDEALY. Create user personas and tailor your product, marketing, and experiences to address unique customer needs. Maximize satisfaction, personalize your approach, and unlock sustainable growth opportunities with IDEALY's user segmentation.
</Text>
 



</Space>



             </Content>
           </Layout>
         </Content>

         <div id="rcorners1"  className="h2white">

<h2>Don't let uncertainty and guesswork hold you back any longer. Try IDEALY for FREE.  </h2>  <Divider type="vertical" />
 
 <Button type='primary' href="/signup"  >TRY FOR FREE!</Button> 
</div>

         <Footer
           style={{
             textAlign: 'center',
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Insight;
