import React, { useState } from 'react';
import {   Col, DatePicker, Form, Input, InputNumber, Row, Select, Divider } from 'antd';
import {
  PlusOutlined
 
} from '@ant-design/icons';
 
 

const { RangePicker } = DatePicker;
const { Option } = Select;

const offerTypes = [
  { id: '1', value: 'Product Discount Coupon' },
  { id: '2', value: 'Free Product' },
  { id: '3', value: 'Gift Card' },
  { id: '4', value: 'Invitation to Product Trial' },
  { id: '5', value: 'Loyalty Points' },
];

const taskTypes = ['Idea Feedback', 'Poll','Variation' ,'Comment', 'Other']; // Add your task types


const statusOptions = [

  { id: '0', value: 'Inactive' },
  { id: '1', value: 'Active' },
];

function OfferForm({ onCreateOffer }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {

    const rewardsJSON = JSON.stringify(values.rewards);

    // Create a new object with rewards as JSON string and the rest as they are
    const transformedValues = {
      ...values,
      rewards: rewardsJSON,
    };


    onCreateOffer(values);
    console.log(values);
    form.resetFields();
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical" className="offer-form">
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item name="offerName" label="Reward Title" rules={[{ required: true, message: 'Please enter Reward title' }]}>
              <Input />
            </Form.Item>

           

            <Form.Item name="offerDescription" label="Reward Description" rules={[{ required: true, message: 'Please enter Reward description' }]}>
              <Input.TextArea rows={5} />
            </Form.Item>

            {/* <Form.Item name="dateRange" label="Date Range" rules={[{ required: true, message: 'Please select date range' }]}>
              <RangePicker format="YYYY-MM-DD" />
            </Form.Item>

            <Form.Item name="quantityAvailable" label="Quantity Available" >
              <Input />
            </Form.Item>


  {/* Status field */}
 <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Reward Status' }]}>
              <Select>
                {statusOptions.map((status) => (
                  <Option key={status.id} value={status.value}>
                    {status.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>

  {/*
            {/* Reward Fields */}
            {/* <Form.List name="rewards">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row key={key} gutter={[16, 8]}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'rewardType']}
                          label="Reward Type"
                          fieldKey={[fieldKey, 'rewardType']}
                          rules={[{ required: true, message: 'Please select a reward type' }]}
                        >
                          <Select>
                            {offerTypes.map((type) => (
                              <Option key={type.id} value={type.value}>
                                {type.value}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'rewardValue']}
                          label="Reward Detail"
                          fieldKey={[fieldKey, 'rewardValue']}
                          >
                          <Input.TextArea rows={5} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <button type="danger" onClick={() => remove(name)}>
                          Remove
                        </button>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      Add Reward Types
                    </button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider/> */}
 



          {/* Task Fields */}
          {/* <Form.List name="tasks">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row key={key} gutter={[16, 8]}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'taskType']}
                          label="Task Type"
                          fieldKey={[fieldKey, 'taskType']}
                          rules={[{ required: true, message: 'Please select a task type' }]}
                        >
                          <Select>
                            {taskTypes.map((type) => (
                              <Option key={type} value={type}>
                                {type}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'taskDescription']}
                          label="Task Description"
                          fieldKey={[fieldKey, 'taskDescription']}
                          rules={[{ required: true, message: 'Please enter task description' }]}
                        >
                         <Input.TextArea rows={5} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'taskCompletionCount']}
                          label="Task Completion Count"
                          fieldKey={[fieldKey, 'taskCompletionCount']}
                          
                        >
                          <InputNumber min={0} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <button type="danger" onClick={() => remove(name)}>
                          Remove Task
                        </button>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item> 
                    <button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      Add Task
                    </button>   
                  </Form.Item>
                </>
              )}
            </Form.List> */}





      
            <Form.Item>
              <button type="primary" htmlType="submit">
                Create Reward offer
              </button>
            </Form.Item>
          </Col>
        </Row>
      
  
      </Form>


    </div>
  );
}

export default OfferForm;
