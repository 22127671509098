import { useState, useEffect } from 'react';
import {
  HeartFilled,
  HeartOutlined,
  HomeFilled,
  HomeOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  TrophyFilled,
  TrophyOutlined,
  GiftFilled,
  GiftOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Tooltip } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

const UserFooter = ({ username }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location.pathname]);

  const handleHomeClick = () => {
    navigate('/Reward/Lounge');
  };

  const handleFavoriteClick = () => {
    navigate('/Reward/Favorite');
  };

  const handleHistoryClick = () => {
    navigate('/Reward/Activity');
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const getIconStyle = (iconName) => {
    return {
      fontSize: '24px',
      color: currentRoute.includes(iconName) ? '#1890ff' : '#3d5a9f', // Change the active and inactive colors as needed
    };
  };

  return (
    <Layout.Footer
      style={{
        position: 'fixed',
        bottom: '0',
        width: '100%',
        padding: '15px',
        height: '50px',
      }}
      className={`custom-footer ${isMobile ? 'mobile-footer' : ''}`}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ textAlign: 'center' }}>
          <Tooltip title="Home">
            {currentRoute.includes('/Reward/Lounge') ? (
              <HomeFilled onClick={handleHomeClick} style={getIconStyle('Home')} />
            ) : (
              <HomeOutlined onClick={handleHomeClick} style={getIconStyle('Home')} />
            )}
          </Tooltip>
        </div>

      

        <div style={{ textAlign: 'center' }}>
          <Tooltip title="My Favorites">
            {currentRoute.includes('/Reward/Favorite') ? (
              <HeartFilled onClick={handleFavoriteClick} style={getIconStyle('My Favorites')} />
            ) : (
              <HeartOutlined onClick={handleFavoriteClick} style={getIconStyle('My Favorites')} />
            )}
          </Tooltip>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Tooltip title="My Rewards">
            {currentRoute.includes('/Reward/Activity') ? (
              <GiftFilled onClick={handleHistoryClick} style={getIconStyle('My Rewards')} />
            ) : (
              <GiftOutlined onClick={handleHistoryClick} style={getIconStyle('My Rewards')} />
            )}
          </Tooltip>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Tooltip title="My Profile">
            <Avatar
              onClick={handleProfileClick}
              size={24}
              src={`https://ui-avatars.com/api/name=${username}&background=random`}
            />
          </Tooltip>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default UserFooter;
