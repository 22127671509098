import {
  CompassOutlined,
  DollarOutlined,
  FrownOutlined,
  LikeOutlined,
  MehOutlined,
  PieChartOutlined,
  RocketOutlined,
  SmileOutlined,
  ThunderboltOutlined,
  TrophyOutlined
} from "@ant-design/icons";
import {

  Card,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Rate,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message
} from "antd";
import axios from "axios";
import { formatRelative, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import IdeaSentimentDonut from "../Insights/IdeaSentimentDonut";
import '../../index.css';
import ParseProductAlignment from "./ParseProductAlignment";
import ParseRICE from "./ParseRICE";
import SentimentAnalysis from "./SentimentAnalysis";

const { Text } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

const GenericAddModal = ({ visible, onCancel, onOk, action, placeholder }) => {
  const [inputValue, setInputValue] = useState('');

  const handleOk = () => {
    // Call the specific action function passed as a prop
    onOk(inputValue);

    // Reset the input value and close the modal
    setInputValue('');
    onCancel();
  };

  const handleCancel = () => {
    // Reset the input value and close the modal
    setInputValue('');
    onCancel();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Modal title={`Add ${action}`} visible={visible} onOk={handleOk} onCancel={handleCancel}>
      <Input
        placeholder={`Enter ${placeholder}`}
        value={inputValue}
        onChange={handleInputChange}
      />
    </Modal>
  );
};

const IdeaDashboardv3 = ({ product_id, idea_id, canvas, productName, producType, productDescription }) => {
  // State Variables
  const [loading, setLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState(false);
  const [loadingRice, setLoadingRice] = useState(false);
  const [loadingSentiment, setLoadingSentiment] = useState(false);
  const [loadingFinancial, setLoadingFinancial] = useState(false);
  const [desirability, setDesirability] = useState(0);
  const [feasibility, setFeasibility] = useState(0);
  const [profitability, setProfitability] = useState(0);
  const [strategic, setStrategic] = useState(0);
  const [innovative, setInnovative] = useState(0);
  const [appeal, setAppeal] = useState(0);
  const [compliance, setCompliance] = useState(0);
  const [sustainability, setSustainability] = useState(0);
  const [distribution, setDistribution] = useState(0);
  const [valueScore, setValueScore] = useState(0);
  const [reach, setReach] = useState(0);
  const [impact, setImpact] = useState(0);
  const [confidence, setConfidence] = useState(0);
  const [effort, setEffort] = useState(0);
  const [riceScore, setRiceScore] = useState(0);
  const [kanoCategory, setKanoCategory] = useState("");
  const [initiative, setInitiative] = useState("");
  const [entitlement, setEntitlement] = useState(0);
  const [northStarMetric, setNorthStarMetric] = useState("");
  const [productOpportunity, setProductOpportunity] = useState("");
  const [ideaInitiativeList, setIdeaInitiativeList] = useState([]);
  const [ideaMetricList, setIdeaMetricList] = useState([]);
  const [ideaOpportunityList, setIdeaOpportunityList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [riceHTML, setRiceHTML] = useState("");
  const [financialHTML, setFinancialHTML] = useState("");
  const [alignmentHTML, setAlignmentHTML] = useState("");
  const [fundamentalHTML, setFundamentalHTML] = useState("");

  const [form] = Form.useForm();
  const [idea, setIdea] = useState({});
  const [ideaStatus, setIdeaStatus] = useState(null);
  const [allIdeaStatus, setAllIdeaStatus] = useState([]);
  const [description, setDescription] = useState("");
  const [textColor, setTextColor] = useState("grey");
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModaContent] = useState("");
  const [sentimentData, setSentimentData] = useState([]);
  const [positive, setPositive] = useState(null);
  const [negative, setNegative] = useState(null);
  const [neutral, setNeutral] = useState(null);

  const [customerVintage, setCustomerVintage] = useState(false);
  const compoundScore = 0;
  const ideaTags = ["tag1", "tag2"]; // Replace with your recommended tags
  const ProductGaps = "";
  const BusinessInitiatives = "";
  const ProductMetrics = "";
  const kanoCategories = [];
  const kanoCategoryString = "";
  const [CAC, setCAC] = useState(0);
  const [CLV, setCLV] = useState(0);
  const [MarketShare, setMarketShare] = useState(0);
  const [BreakEvenPoint, setBreakEvenPoint] = useState(0);
  const [financialScore, setFinancialScore] = useState(0);
  const [ideaScore, setIdeaScore] = useState(0);

  const showModal = (title, modalContent) => {
    setModalTitle(title);
    setModaContent(modalContent);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  // Constants
  const descInnovative = useMemo(
    () => [
      "Not Innovative: Indicates a lack of innovation, with the idea or initiative lacking originality or creative elements.",
      "Slightly Innovative: Suggests a minor degree of innovation, with some creative aspects but limited groundbreaking features.",
      "Moderately Innovative: Reflects a moderate level of innovation, incorporating creative elements and demonstrating a degree of originality.",
      "Very Innovative: Indicates a high degree of innovation, showcasing significant creativity and originality in the idea or initiative.",
      "Extremely Innovative: Represents an exceptional level of innovation, with groundbreaking creativity and originality, setting new standards in the industry.",
    ],
    []
  );

  const descAppeal = useMemo(
    () => [
      "Not Appealing: Indicates a lack of appeal to customers, with the idea or product failing to capture interest or generate positive responses.",
      "Slightly Appealing: Suggests a minor degree of appeal, with some positive aspects but limited overall attractiveness to customers.",
      "Moderately Appealing: Reflects a moderate level of appeal, with elements that are likely to capture the interest of a significant portion of the target audience.",
      "Very Appealing: Indicates a high degree of appeal, with strong features that are likely to resonate positively with a broad audience, generating interest and positive responses.",
      "Extremely Appealing: Represents an exceptional level of appeal, with standout features that make the idea or product highly attractive and appealing to a wide range of customers.",
    ],
    []
  );

  const descMarketFit = useMemo(
    () => [
      "Poor Market Fit: Indicates a lack of alignment between the product or idea and the market needs, resulting in low acceptance or relevance.",
      "Limited Market Fit: Suggests a partial alignment with market needs, with some aspects resonating with a specific segment but falling short of broader acceptance.",
      "Moderate Market Fit: Reflects a moderate level of alignment with market needs, capturing the interest and meeting the requirements of a significant portion of the target market.",
      "Solid Market Fit: Indicates a strong alignment with market needs, with the product or idea effectively addressing the demands and preferences of the target audience.",
      "Strong Market Fit: Represents an exceptional level of alignment, where the product or idea perfectly matches the market needs, resulting in widespread acceptance and success.",
    ],
    []
  );

  const descVisionAlignment = useMemo(
    () => [
      "Weak Vision Alignment: Indicates a weak connection between the idea or product and the overall product vision, with limited adherence to the envisioned goals.",
      "Limited Vision Alignment: Suggests a partial alignment with the product vision, with some aspects aligning but falling short of a comprehensive match.",
      "Moderate Vision Alignment: Reflects a moderate level of alignment with the overall product vision, demonstrating a reasonable adherence to the envisioned goals and objectives.",
      "Strong Vision Alignment: Indicates a strong alignment with the product vision, with the idea or product closely aligning with the envisioned goals and contributing to the overall vision.",
      "Exceptional Vision Alignment: Represents an exceptional level of alignment, where the idea or product perfectly aligns with and enhances the overall product vision, contributing significantly to the envisioned goals.",
    ],
    []
  );


  const descFeasibility = useMemo(
    () => [
      "Highly Challenging Production Capability: Indicates significant challenges in the feasibility of operational or production capabilities, with potential hurdles that may be difficult to overcome.",
      "Challenging Production Capability: Suggests notable challenges in the feasibility of operational or production capabilities, requiring careful planning and mitigation strategies.",
      "Moderately Feasible Production Capability: Reflects a moderate level of feasibility in operational or production capabilities, with some challenges but overall feasibility.",
      "Quite Feasible Production Capability: Indicates a high level of feasibility in operational or production capabilities, with minimal challenges that can be easily addressed.",
      "Highly Feasible Production Capability: Represents an exceptional level of feasibility, where operational or production capabilities align seamlessly with the requirements, posing minimal challenges.",
    ],
    []
  );


  const descProfitability = useMemo(
    () => [
      "Very Low Profit Potential: Indicates a minimal potential for generating profits, with significant challenges in achieving financial success.",
      "Low Profit Potential: Suggests a limited potential for generating profits, requiring careful financial management and strategic adjustments.",
      "Moderate Profit Potential: Reflects a moderate level of potential for generating profits, with opportunities for financial success under appropriate strategies.",
      "High Profit Potential: Indicates a substantial potential for generating profits, with favorable conditions for financial success.",
      "Very High Profit Potential: Represents an exceptional level of potential for generating profits, suggesting a strong likelihood of significant financial success.",
    ],
    []
  );

  const descSustainability = useMemo(
    () => [
      "Not Sustainable: Indicates a lack of sustainability, with the idea or product showing little regard for environmental or long-term considerations.",
      "Slightly Sustainable: Suggests a minor degree of sustainability, with some efforts toward environmental consciousness but room for improvement.",
      "Moderately Sustainable: Reflects a moderate level of sustainability, indicating a reasonable commitment to environmental considerations and long-term viability.",
      "Fairly Sustainable: Indicates a fair degree of sustainability, with strong efforts toward environmental consciousness and long-term viability.",
      "Highly Sustainable: Represents an exceptional level of sustainability, showcasing a strong commitment to environmental responsibility and long-term viability.",
    ],
    []
  );

  const descCompliance = useMemo(
    () => [
      "Minimal Compliance: Indicates minimal adherence to industry or government regulations, posing potential risks and requiring closer attention to compliance standards.",
      "Limited Compliance: Suggests limited adherence to industry or government regulations, with potential areas for improvement in compliance efforts.",
      "Moderate Compliance: Reflects a moderate level of adherence to industry or government regulations, demonstrating a reasonable commitment to compliance standards.",
      "Substantial Compliance: Indicates substantial adherence to industry or government regulations, with strong efforts to meet and exceed compliance standards.",
      "High Compliance: Represents a high level of adherence to industry or government regulations, showcasing exemplary commitment and compliance with standards.",
    ],
    []
  );

  const descDistribution = useMemo(
    () => [
      "Local Presence Only: The product will be limited to a specific locality or region.",
      "Regional Reach: The product has the potential to expand beyond local boundaries but is primarily focused on a specific region.",
      "National Distribution: The product is designed to reach a national market within the country.",
      "International Expansion: The product has potential for international expansion, targeting markets beyond the initial country.",
      "Global Presence: The product is built for a global audience, demonstrating a clear strategy for international markets and a presence in multiple countries."
    ],
    []
  );

  const descreach = useMemo(
    () => [
      "No Reach: Signifies that the initiative or idea has no reach or impact on users or the market.",
      "Minimal Reach: Indicates very limited reach, targeting a small or specific subset of users.",
      "Low Reach: Reflects a limited reach, targeting a specific or niche audience with restricted market coverage.",
      "Moderate Reach: Suggests a moderate expansion of audience or market coverage, targeting a sizable but not exhaustive user base.",
      "High Reach: Indicates the potential audience size or market coverage is extensive, maximizing the number of users impacted.",



    ],
    []
  );

  const descimpact = useMemo(
    () => [

      "Minimal Impact: Indicates very limited positive effect, with minimal or negligible improvements.",
      "Low Impact: Reflects a limited positive effect, with minor improvements in user experience or market conditions.",
      "Moderate Impact: Suggests a moderate positive effect, contributing positively to user experience or market performance.",
      "High Impact: Indicates a substantial positive effect, making a notable difference in user experience or market dynamics.",
      "Exceptionally High Impact: Reflects a significant and transformative effect, potentially influencing a large portion of users or market.",


    ],
    []
  );

  const descconf = useMemo(
    () => [

      "No Confidence: Signifies a complete lack of confidence in the success of the initiative, with expectations of failure.",
      "Minimal Confidence: Reflects minimal confidence, with a high degree of uncertainty and doubts about the success of the initiative.",
      "Low Confidence: Suggests a low level of certainty, with significant uncertainties regarding the success and outcomes.",
      "Moderate Confidence: Indicates a moderate level of certainty, with confidence in the potential success but some uncertainties.",
      "High Confidence: Reflects a high level of certainty in the success and positive outcomes of the initiative or idea.",
    ],
    []
  );

  const desceffort = useMemo(
    () => [

      "Low Effort: Indicates minimal resources, time, and work required for the implementation of the initiative.",
      "Moderate Effort: Suggests a moderate level of resources, time, and work needed for successful implementation.",
      "High Effort: Reflects a significant investment of resources, time, and work required for the implementation.",
      "Very High Effort: Indicates an extensive investment of resources, time, and work, making the initiative resource-intensive.",
      "Extreme Effort: Signifies an exceptionally high level of resources, time, and work required, making the initiative highly resource-demanding.",
    ],
    []
  );


  const descCAC = useMemo(
    () => [
      "Efficiently Minimized Acquisition Costs: Reflects an effective strategy in managing and minimizing the expenses associated with acquiring new customers, showcasing resource efficiency.",
      "Cost-Effective Acquisition Strategy: Indicates a well-planned and budget-conscious approach to acquiring customers, ensuring optimal utilization of resources.",
      "Optimally Managed Acquisition Expenses: Highlights effective management of acquisition costs, suggesting a balance between investment and results.",
      "Elevated Acquisition Costs: Suggests an increase in the costs associated with customer acquisition, signaling potential challenges that may require a closer review.",
      "Excessively High Acquisition Spending: Indicates a significant and potentially unsustainable investment in acquiring new customers, requiring careful assessment for optimization.",
    ],
    []
  );

  const descCLV = useMemo(
    () => [
      "Challenged Customer Retention and Value: Highlights potential challenges in retaining customers over time, necessitating a closer examination of strategies.",
      "Moderate and Consistent Customer Retention: Indicates a balanced approach to customer retention, with moderate but steady long-term value from customers.",
      "Healthy and Prolonged Customer Relationships: Suggests a positive and lasting connection with customers, leading to continued value generation.",
      "Strong and Sustainable Customer Loyalty: Reflects a positive trend in maintaining long-term relationships with customers, contributing to sustained business success.",
      "Exceptionally High Customer Retention Value: Indicates a strong ability to retain and derive value from customers over an extended period, showcasing robust customer loyalty.",
    ],
    []
  );

  const descMarketShare = useMemo(
    () => [
      "Emerging Market Presence: Indicates a gradual entry into the market with a growing presence, requiring attention to potential expansion opportunities.",
      "Growing Market Share: Reflects positive progress in capturing a larger share of the market, showcasing successful market penetration.",
      "Established Market Standing: Suggests a solid and recognized position in the market, signifying stability and credibility.",
      "Leading Market Position: Indicates a prominent and leading role in the market, showcasing strong competitiveness.",
      "Dominant Market Leadership: Reflects unparalleled influence and dominance in the market, signifying a leading position with significant market share.",
    ],
    []
  );

  const descBreakEven = useMemo(
    () => [
      "Rapid Break-even Achievement: Indicates a swift realization of break-even, showcasing efficient financial management and potentially faster returns on investment.",
      "Steady Progress Toward Break-even: Reflects a consistent and measured approach to reaching the break-even point, suggesting stability in financial performance.",
      "Balanced Break-even Performance: Highlights a balanced and well-managed approach to achieving break-even, ensuring financial sustainability.",
      "Prolonged Time to Break-even: Indicates an extended timeline to achieve break-even, prompting a closer examination of financial strategies and resource allocation.",
      "Extended Time and Resources to Achieve Break-even: Suggests potential challenges in reaching break-even, requiring a thorough evaluation of financial and operational aspects.",
    ],
    []
  );




  const MetricTooltipContent = (
    <div style={{ color: "white" }}>
      <p style={{ color: "white" }}>
        <strong>Metric:</strong> This is a measurable data point that helps
        assess the performance or success of a specific aspect of your product
        or business.
      </p>
      <p style={{ color: "white" }}>
        Examples: Conversion Rate, User Engagement, Revenue Growth, Customer
        Satisfaction.
      </p>
    </div>
  );

  const BusinessInitiativeTooltipContent = (
    <div style={{ color: "white" }}>
      <p style={{ color: "white" }}>
        <strong>Business Initiative:</strong> This represents a strategic action
        or project that the company plans to undertake to achieve its goals or
        address specific challenges.
      </p>
      <p style={{ color: "white" }}>
        Examples: Launching a New Product Line, Expanding into New Markets,
        Improving Customer Support.
      </p>
    </div>
  );

  const ProductGapTooltipContent = (
    <div style={{ color: "white" }}>
      <p style={{ color: "white" }}>
        <strong>Product Gap:</strong> This refers to the difference between the
        current state of your product or service and the desired or expected
        state based on customer needs or industry standards.
      </p>
      <p style={{ color: "white" }}>
        It helps identify areas for improvement and innovation.
      </p>
    </div>
  );

  const SatisfactionToolTipContent = (
    <div>
      <p style={{ color: "white" }}>
        <strong>Satisfaction Tier:</strong> This is a categorization of product
        or feature attributes based on their impact on consumer satisfaction.
      </p>
      <p style={{ color: "white" }}>
        <strong>Essential:</strong> This category represents features that users
        expect as a basic requirement. These features are essential for the
        product or service to be considered functional.
      </p>
      <p style={{ color: "white" }}>
        <strong>Satisfaction Boosters:</strong> These are features that enhance
        the product's performance and usability. They make the product better
        and more efficient, even though users might not have explicitly
        requested them.
      </p>
      <p style={{ color: "white" }}>
        <strong>Delighter:</strong> This category includes features that users
        might not expect but would find pleasant and enjoyable if included.
        These features can be the source of delight and satisfaction.
      </p>
      <p style={{ color: "white" }}>
        <strong>Don't Care Zone:</strong> Features that don't really swing the satisfaction needle either way.
        They're like the color of the car seats—you might have a preference, but it won't make or break the deal.
      </p>

      <p style={{ color: "white" }}>
        <strong>Unpleasant Surprises:</strong>  These are aspects of the product that, if present, catch customers off guard and lead to dissatisfaction.
        However, if these unpleasant surprises are absent, customers are quietly pleased, even if they might not have explicitly expected their absence.
      </p>
    </div>
  );



  const getRandomColor = () => {
    let color = "#1a73e8";
    return color;
  };

  const formatDate = (date) => {
    try {
      const parsedDate = parseISO(date);
      return formatRelative(parsedDate, new Date(), { addSuffix: true });
    } catch (error) {
      // Handle invalid date format here
      return "Invalid date";
    }
  };

  const saveSentiment = async () => {
    try {
      setLoadingSentiment(true);
      const positiveScore = parseFloat(sentimentData.positive);
      const negativeScore = parseFloat(sentimentData.negative);
      const neutralScore = parseFloat(sentimentData.neutral);

      // Calculate compound score
      let compoundScore = positiveScore - negativeScore;

      const user_access_token = localStorage.getItem("user_access_token");

      const payload = {
        pphrase: sentimentData.positiveWords.join(", "),
        nphrase: sentimentData.negativeWords.join(", "),
        neutral: neutralScore,
        positive: positiveScore,
        negative: negativeScore,
        sentiment: sentimentData.sentiment,
        sentimentScore: compoundScore,
      };



      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/sentiment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );

      if (response.status === 200) {
        console.error("save", payload);
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      // Handle error as needed
    } finally {
      setLoadingSentiment(false);
    }
  };

  const handleGetSentiment = async () => {
    // Call the SentimentAnalysis component and get the result
    setLoadingSentiment(true);
    const sentimentResult = await SentimentAnalysis(
      product_id,
      idea_id,
      description
    );
    setSentimentData(sentimentResult);
    setPositive(sentimentResult.positive);
    setNegative(sentimentResult.negative);
    setNeutral(sentimentResult.neutral);
    setLoadingSentiment(false);
  };

  const alignmentCopilot = async () => {
    try {
      setLoadingValue(true);
      const payload = {
        idea: idea.description, canvas: canvas,
        productName: productName,
        producType: producType,
        productDescription: productDescription

      };

      const openAIResponse1 = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/airatings`,
        payload
      );


      const {
        marketFit,
        productAlignment,
        feasibilityValue,
        profitabilityValue,
        innovativeValue,
        consumerAppealValue,
        sustainabilityValue,
        complianceValue,
        explanationHTML,
      } = ParseProductAlignment(openAIResponse1.data);


      const promises = [
        //   // await handleUpdate("innovative", innovativeValue),
        //   // await handleUpdate("appeal", consumerAppealValue),
        //   // await handleUpdate("desirability", marketFit),
        //   // await handleUpdate("strategic", productAlignment),
        //   // await handleUpdate("feasibility", feasibilityValue),
        //   // await handleUpdate("profitability", profitabilityValue),
        //   // await handleUpdate("sustainability", sustainabilityValue),
        //   // await handleUpdate("compliance", complianceValue),
        //  await handleUpdate("value", explanationHTML),
      ];

      // Wait for all promises to resolve
      const responses = await Promise.all(promises);
      // setInnovative(innovativeValue);
      // setAppeal(consumerAppealValue);
      // setDesirability(marketFit);
      // setStrategic(productAlignment);
      // setFeasibility(feasibilityValue);
      // setProfitability(profitabilityValue);
      // setSustainability(sustainabilityValue);
      // setCompliance(complianceValue);
      setAlignmentHTML(explanationHTML);
      showModal("Explanation", explanationHTML);
      const promise = await handleUpdate("Explanation", explanationHTML);

    } catch (error) {
      console.error("Error in alignmentCopilot:", error);
      // Handle error as needed
    } finally {
      setLoadingValue(false); // Set loading back to false after the request is completed
    }
  };

  const riceCopilot = async () => {
    try {
      setLoadingRice(true);

      const payload = {
        idea: idea.description, canvas: canvas,
        productName: productName,
        producType: producType,
        productDescription: productDescription
      };

      const rice = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/rice`,
        payload
      );



      const {
        reachValue,
        impactValue,
        confidenceValue,
        effortValue,
        explanationHTML,
      } = ParseRICE(rice.data);



      // const promises = [
      //   // await handleUpdate("reach", reachValue),
      //   // await handleUpdate("impact", impactValue),
      //   // await handleUpdate("confidence", confidenceValue),
      //   // await handleUpdate("effort", effortValue),
      //   await handleUpdate("rice", explanationHTML),
      // ];
      // // Wait for all promises to resolve
      // const responses = await Promise.all(promises);

      // setReach(reachValue);
      // setImpact(impactValue);
      // setConfidence(confidenceValue);
      // setEffort(effortValue);
      setRiceHTML(explanationHTML);
      showModal("Explanation", explanationHTML);
      const promise = await handleUpdate("rice", explanationHTML);


    } catch (error) {
      console.error("Error in riceCopilot:", error);
      // Handle error as needed
    } finally {
      setLoadingRice(false); // Set loading back to false after the request is completed
    }
  };

  const fundamentalCopilot = async () => {
    try {
      setLoading(true);



      let kanoCategories = ["Satisfaction Booster", "Delighter", "Don't Care Zone", "Unpleasant Surprises"];
      let kanoCategoryString = kanoCategories.join(', ');




      const payload = {
        idea: idea.description, canvas: canvas,
        productName: productName,
        producType: producType,
        productDescription: productDescription,
        ProductGaps: ideaOpportunityList.map(item => item.productOpportunity).join(', '),
        BusinessInitiatives: ideaInitiativeList.map(item => item.productInitiative).join(', '),
        ProductMetrics: ideaMetricList.map(item => item.productMetric).join(', '),
        kanoCategoryString: kanoCategoryString
      };



      const openAIResponseFundamentals = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/Fundamental`,
        payload
      );

      // const { initiativeValue, metricsValue, opportunityValue, kanoValue } =
      //   ParseFundamentals(openAIResponseFundamentals);

      // onClickInitiative(initiativeValue);
      // onClickMetrics(metricsValue);
      // onClickOpportunity(opportunityValue);
      // onClickKanoCategory(kanoValue);
      setFundamentalHTML(openAIResponseFundamentals.data);
      showModal("Explanation", openAIResponseFundamentals.data);


    } catch (error) {
      console.error("Error in fundamentalCopilot:", error);
      // Handle error as needed
    } finally {
      setLoading(false); // Set loading back to false after the request is completed
    }
  };

  const financialCopilot = async () => {
    try {
      setLoadingFinancial(true);

      const payload = {
        idea: idea.description, canvas: canvas,
        productName: productName,
        producType: producType,
        productDescription: productDescription
      };

      const financial = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/financial`,
        payload
      );



      //   const {
      //     // cacValue,
      //     // clvValue,
      //     // marketShareValue,
      //     // breakEvenValue,
      //     explanationHTML
      //   } = ParseFinancialModel(financial.data);

      //   console.log(
      // //     cacValue,
      // // clvValue,
      // // marketShareValue,
      // // breakEvenValue,
      // explanationHTML
      //   );


      setFinancialHTML(financial.data);
      showModal("Explanation", financial.data);
      const promise = await handleUpdate("financial", financial.data);

    } catch (error) {
      console.error("Error in financialCopilot:", error);
      // Handle error as needed
    } finally {
      setLoadingFinancial(false); // Set loading back to false after the request is completed
    }
  };



  const onClickStatus = async (value) => {
    try {
      const payload = {
        ideaStatus: value,
      };
      const user_access_token = localStorage.getItem("user_access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );

      if (response.status === 200) {
        setIdeaStatus(value);

        form.setFieldsValue({
          Comment: "",
        });

        //  open the modal

        Modal.confirm({
          title: "Change Status",
          content: (
            <div>
              <p>New Status: {value}</p>
              <Form form={form} name="control-hooks">
                <Form.Item name="Comment" label="">
                  <TextArea
                    placeholder="Enter your comment (optional)"
                    autoSize={{ minRows: 3 }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Form>
            </div>
          ),
          onOk: async () => {
            // Handle OK button click
            await AddComment(value, form.getFieldValue("Comment"));
          },
          onCancel: () => {
            // Handle modal cancellation if needed

          },
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);

      // Check for permission-related error (403) and provide feedback to the user
      if (error.response && error.response.status === 403) {
        message.error(
          "Permission Denied. You do not have the necessary permissions."
        );
      } else {
        // Handle other errors as needed
        message.error(
          "An error occurred while updating the status. Please try again."
        );
      }
    }
  };

  const updateIdeaScore = async () => {
    
      const userAccessToken = localStorage.getItem("user_access_token");
  
      // Set loading state while waiting for the response
      // You should have a state variable like const [loading, setLoading] = useState(false);
      
  
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/ideaScore`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );
  
      // Handle the response data as needed
      if (response.data.rank) {
        
        setIdeaScore(response.data.rank.toFixed(0));
    }
    
    } 
 
  



  const getListData = async () => {
    try {
      const user_access_token = localStorage.getItem("user_access_token");

      // Fetch Initiative dropdown data
      const initiativeResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}product/initiative`,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );
      setIdeaInitiativeList(initiativeResponse.data);

      // Fetch North Star Metric dropdown data
      const metricResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}product/metric`,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );
      setIdeaMetricList(metricResponse.data);

      // Fetch Product Opportunity dropdown data
      const opportunityResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}product/opportunity`,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );
      setIdeaOpportunityList(opportunityResponse.data);

      // Fetch Idea Status dropdown data
      const ideaStatusResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}product/idea/status`,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );
      setAllIdeaStatus(ideaStatusResponse.data);
    } catch (error) {
      console.error("Error fetching list data:", error);
      // Handle error as needed
    }
  };

  // Example usage of handleUpdate
  const handleChangeValue = async (name, value) => {
    try {
      switch (name) {
        case "desirability":
          setDesirability(value);
          break;
        case "feasibility":
          setFeasibility(value);
          break;
        case "profitability":
          setProfitability(value);
          break;
        case "strategic":
          setStrategic(value);
          break;
        case "innovative":
          setInnovative(value);
          break;
        case "distribution":
          setDistribution(value);
          break;
        case "appeal":
          setAppeal(value);
          break;
        case "compliance":
          setCompliance(value);
          break;
        case "sustainability":
          setSustainability(value);
          break;
        case "reach":
          setReach(value);
          break;
        case "impact":
          setImpact(value);
          break;
        case "confidence":
          setConfidence(value);
          break;
        case "effort":
          setEffort(value);
          break;
        case "CAC":
          setCAC(value);
          break;
        case "LTV":
          setCLV(value);
          break;
        case "MarketShare":
          setMarketShare(value);
          break;
        case "BreakEvenPoint":
          setBreakEvenPoint(value);
          break;
        default:
          console.warn(`Unsupported name: ${name}`);
          return;
      }

      try {
        await handleUpdate(name, value);
      } catch (error) {
        console.error("Error handling update:", error);
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error handling change value:", error);
      // Handle error as needed
    }
  };

  const handleUpdate = async (property, value) => {
    try {
      let responseData = "";

      // Construct the postUrl and payload based on the property
      let postUrl, payload;
      switch (property) {
        case "desirability":
        case "feasibility":
        case "profitability":
        case "strategic":
        case "innovative":
        case "appeal":
        case "compliance":
        case "sustainability":
        case "distributoin":
          postUrl = `product/${product_id}/idea/${idea_id}/${property}`;
          payload = { [property]: value };
          // postRequest('VALUE', postUrl, payload);
          responseData = await postRequest("VALUE", postUrl, payload);

          break;
        case "value":
          postUrl = `product/${product_id}/idea/${idea_id}/value/explanation`;
          payload = { explanation: value };
          // postRequest('VALUE', postUrl, payload);
          responseData = await postRequest("none", postUrl, payload);
          if (responseData.data.valueScore) {
            setValueScore(responseData.valueScore.toFixed(0));
        }
        
        
          break;

        case "reach":
        case "impact":
        case "confidence":
        case "effort":
          postUrl = `product/${product_id}/idea/${idea_id}/${property}`;
          payload = { [property]: value };
          // postRequest('RICE', postUrl, payload);
          responseData = await postRequest("RICE", postUrl, payload);
          setRiceScore(responseData.riceScore);
          break;
        case "rice":
          postUrl = `product/${product_id}/idea/${idea_id}/rice/explanation`;
          payload = { explanation: value };
          // postRequest('RICE', postUrl, payload);
          responseData = await postRequest("none", postUrl, payload);

          break;
        case "initiative":
          postUrl = `product/${product_id}/idea/${idea_id}/productInitiative`;
          payload = { productInitiative: value };
          // postRequest(property, postUrl, payload);
          responseData = await postRequest(property, postUrl, payload);

          break;
        case "metric":
          postUrl = `product/${product_id}/idea/${idea_id}/metric`;
          payload = { productMetric: value };
          // postRequest(property, postUrl, payload);
          responseData = await postRequest(property, postUrl, payload);

          break;
        case "opportunity":
          postUrl = `product/${product_id}/idea/${idea_id}/opportunity`;
          payload = { productOpportunity: value };

          // postRequest(property, postUrl, payload);
          responseData = await postRequest(property, postUrl, payload);

          break;
        case "kanoCategory":
          postUrl = `product/${product_id}/idea/${idea_id}/kanoCategory`;
          payload = { kanoCategory: value };
          // postRequest(property, postUrl, payload);
          responseData = await postRequest(property, postUrl, payload);

          break;
        case "entitlement":
          postUrl = `product/${product_id}/idea/${idea_id}/entitlement`;
          payload = { entitlement: value };
          // postRequest(property, postUrl, payload);
          responseData = await postRequest(property, postUrl, payload);

          break;

        case "LTV":
        case "CAC":
        case "MarketShare":
        case "BreakEvenPoint":
          postUrl = `product/${product_id}/idea/${idea_id}/${property}`;
          payload = { [property]: value };
          // postRequest('RICE', postUrl, payload);
          responseData = await postRequest("Financial", postUrl, payload);
          if (responseData.data.score) {
            setFinancialScore(responseData.score.toFixed(0));
        }

        
          
          break;

        case "financial":
          postUrl = `product/${product_id}/idea/${idea_id}/financialexplanation`;
          payload = { explanation: value };
          // postRequest('RICE', postUrl, payload);
          responseData = await postRequest("none", postUrl, payload);
          break;

        default:
          console.warn(`Unsupported property: ${property}`);
          return;
      }

      console.log(responseData);
      return responseData;
    } catch (error) {
      console.error("Error handling update:", error);
      throw error;
    }
  };

  const postRequest = async (name, postUrl, payload) => {
    const user_access_token = localStorage.getItem("user_access_token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${postUrl}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );

      // Handle success response here if needed
      if (response.status === 200) {
        // Do something with the response data if needed
        // Example: If name==='RICE'
        if (name === "RICE") setRiceScore(response.data.riceScore.toFixed(0));
        else if (name === "VALUE") setValueScore(response.data.valueScore.toFixed(0));

        return response.data; // Return the response data if needed
      }
    } catch (error) {
      // Handle error response here if needed
      console.error("Error:", error);
      throw error; // Throw the error so that the calling code can catch it if needed
    }
  };

  const onClickInitiative = async (value) => {
    try {
      setInitiative(value);
      await handleUpdate("initiative", value);

      // toast.success("Product initiative updated successfully");
    } catch (error) {
      console.error("Error handling click initiative:", error);
      // Handle error as needed
    }
  };

  const onClickMetrics = async (value) => {
    try {
      setNorthStarMetric(value);
      await handleUpdate("metric", value);
      // toast.success("Product initiative updated successfully");
    } catch (error) {
      console.error("Error handling click initiative:", error);
      // Handle error as needed
    }
  };

  const onClickOpportunity = async (value) => {
    try {
      setProductOpportunity(value);
      await handleUpdate("opportunity", value);
      // toast.success("Product initiative updated successfully");
    } catch (error) {
      console.error("Error handling click initiative:", error);
      // Handle error as needed
    }
  };

  const onClickKanoCategory = async (value) => {
    try {
      setKanoCategory(value);
      await handleUpdate("kanoCategory", value);
      // toast.success("Product initiative updated successfully");
    } catch (error) {
      console.error("Error handling click initiative:", error);
      // Handle error as needed
    }
  };

  const onClickIdeaEntitlement = async (value) => {
    try {
      setEntitlement(value);
      await handleUpdate("initiative", value);
      // toast.success("Product initiative updated successfully");
    } catch (error) {
      console.error("Error handling click initiative:", error);
      // Handle error as needed
    }
  };

  const AddComment = async (ideaStatus, comment) => {
    try {
      const user_access_token = localStorage.getItem("user_access_token");
      const commentString =
        "Status Changed to: " +
        ideaStatus +
        "\n\nProduct Manager Comments: " +
        comment;
      const payload = {
        text: commentString,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/comment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
          },
        }
      );

      if (response.status === 200) {
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    
    const fetchData = async () => {
      const user_access_token = localStorage.getItem("user_access_token");
      try {
        // For Idea detail
        axios
          .get(
            process.env.REACT_APP_API_URL +
            "product/" +
            product_id +
            "/idea/" +
            idea_id +
            "/details",
            {
              headers: {
                Authorization: "Bearer " + user_access_token,
              },
            }
          )
          .then((response) => {
            setIdea(response.data);
            setReach(response.data.reach);
            setImpact(response.data.impact);
            setConfidence(response.data.confidence);
            setEffort(response.data.effort);
            setRiceScore(response.data.riceScore);
            setDesirability(response.data.desirability);
            setFeasibility(response.data.feasibility);
            setProfitability(response.data.profitability);
            setStrategic(response.data.strategic);
            setAppeal(response.data.appeal);
            setInnovative(response.data.innovative);
            setCompliance(response.data.compliance);
            setSustainability(response.data.sustainability);
          
            setIdeaStatus(response.data.status);
            setDescription(response.data.description);
            setCustomerVintage(response.data.userstory);
            setTextColor(response.data.bigIdea ? "grey" : "red");
            setInitiative(response.data.initiative);
            setKanoCategory(response.data.kanoCategory);
            setEntitlement(response.data.entitlement);
            setNorthStarMetric(response.data.northStarMetric);
            setProductOpportunity(response.data.productOpportunity);
            setRiceHTML(response.data.riceExplanation);
            setAlignmentHTML(response.data.valueExplanation);
            setCAC(response.data.cac);
            setCLV(response.data.ltv);
            setBreakEvenPoint(response.data.breakeven);
            setMarketShare(response.data.marketshare);
            setFinancialHTML(response.data.financialexplanation);
            setDistribution(response.data.distribution);
             


            if (typeof response.data.financialScore === 'number') {
              setFinancialScore(response.data.financialScore.toFixed(0));
          } else {
              setFinancialScore('0');
          }
          
          if (typeof response.data.rank === 'number') {
              setIdeaScore(response.data.rank.toFixed(0));
          } else {
              setIdeaScore('0');
          }
          
          if (typeof response.data.valueScore === 'number') {
              setValueScore(response.data.valueScore.toFixed(0));
          } else {
              setValueScore('0');
          }
      


          });
      } catch (error) {
        console.error("Error fetching idea data:", error);
      }
    };

    fetchData();
  }, [product_id, idea_id, valueScore, riceScore]);

  useEffect(() => {
   
    getListData();
    



  }, [idea_id]);

  const [currentAction, setCurrentAction] = useState('');
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const showAddModal = (action) => {
    setCurrentAction(action);
    setIsAddModalVisible(true);
  };

  const handleOk = (value) => {
    // Perform the specific action based on the currentAction
    if (currentAction === 'Initiative') {
      AddInitiative(value);
    } else if (currentAction === 'Metrics') {
      AddMetrics(value);
    } else if (currentAction === 'ProductGap') {
      AddProductGap(value);
    }

    // Close the modal
    setIsAddModalVisible(false);
  };

  const handleCancel = () => {
    // Close the modal
    setIsAddModalVisible(false);
  };

  const AddInitiative = (value) => {
    console.log('Adding initiative:', value);
    const user_access_token = localStorage.getItem('user_access_token');
    const apiUrl = process.env.REACT_APP_API_URL;

    const payload = { productInitiative: value };

    axios
      .post(`${apiUrl}product/initiative`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        getListData();
      })
      .catch((error) => {
        console.error(error);
      });
    // Call your AddInitiative function or perform the desired action here
  };

  const AddMetrics = (value) => {
    console.log('Adding metrics:', value);
    const payload = { ProductMetric: value };

    const user_access_token = localStorage.getItem('user_access_token');
    const apiUrl = process.env.REACT_APP_API_URL;


    axios
      .post(`${apiUrl}product/metric`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
    
        getListData();
      })
      .catch((error) => {
        console.error(error);
      });
    // Call your AddMetrics function or perform the desired action here
  };

  const AddProductGap = (value) => {
    console.log('Adding product gap:', value);
    const user_access_token = localStorage.getItem('user_access_token');
    const apiUrl = process.env.REACT_APP_API_URL;
    const payload = { productOpportunity: value };

    axios
      .post(`${apiUrl}product/opportunity`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        getListData();
      })
      .catch((error) => {
        console.error(error);
      });
    // Call your AddProductGap function or perform the desired action here
  };

  return (

    <>


<div>
     

      <GenericAddModal
        visible={isAddModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        action={currentAction}
        placeholder={`${currentAction.toLowerCase()} name`}
      />
    </div>

      <Card
        able
        style={{
          margin: "20px auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2>
          {idea.title}{" "}
        </h2>

        <div style={{ marginBottom: 16 }}>
          <p>{description}</p>
        </div>

        {/* <div>
          {ideaTags.map((tag, index) => (
            <Tag
              key={index}
              style={{ backgroundColor: getRandomColor(), color: "#fff" }}
            >
              {tag}
            </Tag>
          ))}
        </div> */}

        <div

          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}

        >
          <div style={{ marginBottom: 8 }}>
            <p type="secondary">Author: {idea.submitter}</p>
          </div>
          <div style={{ marginBottom: 8 }}>
            <p type="secondary">
              Submitted on: {formatDate(idea.createdOn)}
            </p>
          </div>
          <div style={{ marginBottom: 8 }}>
            <p type="secondary">
              Status:   
            {ideaStatus}
            </p>
          </div>
        </div>

       
        
          
      </Card>




      <Card
      
        style={{
          margin: "20px auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        title={<>  <h3>
          <PieChartOutlined /> Score Card.
        </h3></>}
      >
      
        <div className="tile-row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>

          <div className="tile">
            <Tooltip title="Rank">
              <Space className="tileicon">
                <TrophyOutlined /> <p className="tileInnertext">IDEA Score </p>
              </Space> 
              <div className="center-content">
              <p className="titletext" > {ideaScore}</p></div>
              <button  onClick={updateIdeaScore}>update</button>
            </Tooltip>
          </div>

          <div className="tile">
            <Tooltip title="Innovation">
              <Space className="tileicon">
                <RocketOutlined />{" "}
                <p className="tileInnertext">Innovation</p>
              </Space> 
              <div className="center-content"> 
              <p className="titletext"> {valueScore}%</p></div>
            </Tooltip>
          </div>
          <div className="tile">
            <Tooltip title="Financial">
              <Space className="tileicon">
                <DollarOutlined />{" "}
                <p className="tileInnertext">Financial</p>
              </Space> 
              <div className="center-content">
              <p className="titletext"> {financialScore}%</p></div>
            </Tooltip>
          </div>
          {/* <div className="tile">
            <Tooltip title="R.I.C.E Score">
              <Space className="tileicon">
                <ThunderboltOutlined />{" "}
                <p className="tileInnertext">R.I.C.E</p>
            </Space>
              <div className="center-content"> 
              <p className="titletext"> {riceScore}</p></div>
            </Tooltip>
          </div> */}



          <div className="tile">
            <Tooltip title="Sentiment">
              <Space className="tileicon">
                <SmileOutlined />{" "}
                <p className="tileInnertext">Sentiment</p>
              </Space> 
              <div className="center-content"> 
              {idea.score>0.3 ? (
                <SmileOutlined
                  style={{ color: "green" }}
                  className="titletext"
                />
              ) : idea.score<0  ? (
                <FrownOutlined
                  style={{ color: "red" }}
                  className="titletext"
                />
              ) : (
                <MehOutlined
                  style={{ color: "#2db7f5" }}
                  className="titletext"
                />
              )}</div>
            </Tooltip>
          </div>

          <div className="tile">
            <Tooltip title="Interest">
              <Space className="tileicon">
                <LikeOutlined /> <p>Interest</p>
              </Space> 
              <div className="center-content">
              <p className="titletext"> {idea.votes}</p></div>
            </Tooltip>
          </div>

        </div>
      </Card>



      <Card
        able
        style={{
          margin: "20px auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}

        title={<> <h3>
          <CompassOutlined style={{ marginRight: "8px" }} />
          <a
            href="#"
            onClick={() => showModal("Explanation", fundamentalHTML)}
          >

            Fundamentals.

          </a>
          {" "}
          <a href="#" onClick={fundamentalCopilot}>
            <Tag>Ask Max!</Tag>
          </a>
          {loading ? (
            <Spin size="large" /> // Show spinner when loading is true
          ) : null}
        </h3></>}
      >
         <div className="tile-row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          
            

              <Space align="start" direction="vertical">
   
              <Tag onClick={() => showAddModal('ProductGap')}>Add</Tag>
                <Tooltip title={ProductGapTooltipContent}>
                  <h4 style={{ cursor: "help" }}>
                    Product Gap
                  </h4>
                </Tooltip>
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    style={{ border: "1px gray solid", width: '200px' }} // Set a fixed width

                  >
                    {productOpportunity}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {ideaOpportunityList &&
                      ideaOpportunityList.map((opportunity) => (
                        <Dropdown.Item
                          key={opportunity.productOpportunity}
                          onClick={() =>
                            onClickOpportunity(
                              opportunity.productOpportunity
                            )
                          }
                        >
                          {opportunity.productOpportunity}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Space>
              
              <Space align="start" direction="vertical">
              <Tag onClick={() => showAddModal('Initiative')}>Add</Tag>
    
                <Tooltip title={BusinessInitiativeTooltipContent}>
                  <h4 style={{ cursor: "help" }}>
                   Business Initiative
                  </h4>
                </Tooltip>

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    style={{ border: "1px gray solid", width: '200px' }} // Set a fixed width

                  >
                    {initiative}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {ideaInitiativeList &&
                      ideaInitiativeList.map((initiative) => (
                        <Dropdown.Item
                          key={initiative.productInitiative}
                          onClick={() =>
                            onClickInitiative(
                              initiative.productInitiative
                            )
                          }
                        >
                          {initiative.productInitiative}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Space>
             
              <Space align="start" direction="vertical">
              <Tag onClick={() => showAddModal('Metrics')}>Add</Tag>
                <Tooltip title={MetricTooltipContent}>
                  <h4 style={{ cursor: "help" }}>North Star Metric</h4>
                </Tooltip>
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    style={{ border: "1px gray solid", width: '200px' }} // Set a fixed width

                  >
                    {northStarMetric}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {ideaMetricList &&
                      ideaMetricList.map((metric) => (
                        <Dropdown.Item
                          key={metric.productMetric}
                          onClick={() =>
                            onClickMetrics(metric.productMetric)
                          }
                        >
                          {metric.productMetric}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Space>
           
              <Space direction="vertical">
              <Tag ></Tag>
                <Tooltip title={SatisfactionToolTipContent}>
                  <h4 style={{ cursor: "help" }}>Satisfaction Tier</h4>
                </Tooltip>

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    style={{ border: "1px gray solid", width: '200px' }} // Set a fixed width

                  >
                    {kanoCategory}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key={1}
                      onClick={() => onClickKanoCategory("Essential")}
                    >
                      Essential
                    </Dropdown.Item>
                    <Dropdown.Item
                      key={2}
                      onClick={() =>
                        onClickKanoCategory("Satisfaction Booster")
                      }
                    >
                      Satisfaction Booster
                    </Dropdown.Item>
                    <Dropdown.Item
                      key={3}
                      onClick={() => onClickKanoCategory("Delighter")}
                    >
                      Delighter
                    </Dropdown.Item>

                    <Dropdown.Item
                      key={3}
                      onClick={() => onClickKanoCategory("Don't Care Zone")}
                    >
                      Don't Care Zone
                    </Dropdown.Item>
                    <Dropdown.Item
                      key={3}
                      onClick={() => onClickKanoCategory("Unpleasant Surprises")}
                    >
                      Unpleasant Surprises
                    </Dropdown.Item>



                  </Dropdown.Menu>
                </Dropdown>
              </Space>


        
          
            </div>
      </Card>

      <Card
        able
        style={{
          margin: "20px auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}

        title={<>      <h3>
          {" "}
          <RocketOutlined style={{ marginRight: "8px" }} />
          Innovation Evaluation Framework{" "}
          <Space align="center" direction="vertical">
            <a
              href="#"
              onClick={() => showModal("Explanation", alignmentHTML)}
            >
              {" "}
              <Tag
                color="#1c3048ff"
                style={{ fontSize: "18px", padding: "px" }}
              >
                {valueScore}
              </Tag>{" "}
            </a>
          </Space>{" "}
          <a href="#" onClick={alignmentCopilot}>
            <Tag> Ask Max!</Tag>
          </a>
          {loadingValue ? (
            <Spin size="large" /> // Show spinner when loading is true
          ) : null}
        </h3></>}
      >
        <Space align="start" direction="vertical">
    
          <p>
            The Innovation Evaluation Framework is a tool tailored
            for teams to assess and prioritize innovative ideas
            effectively. For instance, consider a novel product idea that
            not only aligns with the brand's values but also captures
            consumer interest. The framework also evaluates its
            feasibility within existing resources, potential for
            profitability, and adherence to sustainability principles.
            Moreover, it ensures compliance with industry standards while
            addressing contemporary market needs. This focused approach
            ensures that only the most promising and industry-relevant
            ideas take precedence, fostering strategic decision-making and
            successful execution.
          </p>

          <h4>Strategic Alignment</h4>
          <Space align="start" direction="horizontal">
            <Space align="start" direction="vertical">
              <Tooltip title="Is the idea creatively distinctive and fresh?">
                {" "}
                Innovative (+ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="Innovative"
                  value={innovative}
                  tooltips={descInnovative}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("innovative", value)
                  }
                />
              </div>
              {descInnovative[innovative - 1]}
            </Space>

            <Space align="start" direction="vertical">
              <Tooltip title="Will consumers find this idea appealing and interesting? Is it trendy and unique?">
                Consumer Appeal (+ve factor){" "}
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="Appeal"
                  value={appeal}
                  tooltips={descAppeal}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) => handleChangeValue("appeal", value)}
                />
              </div>
              {descAppeal[appeal - 1]}
            </Space>

            <Space align="start" direction="vertical">
              <Tooltip title="Does this idea address current market needs and demands?">
                {" "}
                Market Fit (+ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="desirability"
                  value={desirability}
                  tooltips={descMarketFit}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("desirability", value)
                  }
                />
              </div>
              {descMarketFit[desirability - 1]}
            </Space>

            <Space align="start" direction="vertical">
              <Tooltip title="Does this idea align with your product or brand's identity and values?">
                {" "}
                Vision Alignment (+ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="strategic"
                  value={strategic}
                  tooltips={descVisionAlignment}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("strategic", value)
                  }
                />
              </div>
              {descVisionAlignment[strategic - 1]}
            </Space>
          </Space>

          <h4>Operational Viability</h4>
          <Space align="start" direction="horizontal">
            <Space align="start" direction="vertical">
              <Tooltip title="Can this idea be realistically executed with your resources?">
                {" "}
                Production Feasibility (+ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="feasibility"
                  value={feasibility}
                  tooltips={descFeasibility}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("feasibility", value)
                  }
                />
              </div>
              {descFeasibility[feasibility - 1]}
            </Space>

            <Space align="start" direction="vertical">
              <Tooltip title=" Is the idea worth its production costs?">
                {" "}
                Distribution (+ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="profitability"
                  value={profitability}
                  tooltips={descDistribution}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("profitability", value)
                  }
                />
              </div>
              {descDistribution[profitability - 1]}
            </Space>

            <Space align="start" direction="vertical">
              <Tooltip title="Does this idea consider environmental and corporate social responsibility concerns?">
                {" "}
                Sustainability & CSR (+ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="Sustainability"
                  value={sustainability}
                  tooltips={descSustainability}
                  character={"◉"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("sustainability", value)
                  }
                />
              </div>
              {descSustainability[sustainability - 1]}
            </Space>

            <Space align="start" direction="vertical">
              <Tooltip title="Does this idea involve industry compliance or goverment regulations?">
                {" "}
                Compliance (-ve factor)
              </Tooltip>
              <div className="in_row">
                <Rate
                  name="Compliance"
                  value={compliance}
                  tooltips={descCompliance}
                  character={"◉"}
                  style={{ color: "red" }}
                  allowClear={false}
                  onChange={(value) =>
                    handleChangeValue("compliance", value)
                  }
                />
              </div>
              {descCompliance[compliance - 1]}
            </Space>
          </Space>
        </Space>
      </Card>

      <Card
        able
        style={{
          margin: "20px auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}

        title={<>   <h3>
          <DollarOutlined style={{ marginRight: "8px" }} />Financial Evaluation{" "}
          <Space align="center" direction="vertical" style={{ marginLeft: "10px" }}>
            <a
              href="#"
              onClick={() =>
                showModal("Financial Score Explanation", financialHTML)
              }
            >
              {" "}
              <Tag
                color="#1c3048ff"
                style={{ fontSize: "18px", padding: "px" }}
              >
                {financialScore}
              </Tag>{" "}
            </a>
          </Space>{" "}
          <a href="#" onClick={financialCopilot}>
            <Tag>Ask Max!</Tag>
          </a>{" "}
          {loadingFinancial ? (
            <Spin size="large" /> // Show spinner when loading is true
          ) : null}
        </h3></>}

      >
        <Space align="start" direction="vertical">
       

          <p>
            The Financial Scoring Model provides a comprehensive assessment of the idea's financial viability, considering factors such as acquisition costs, customer lifetime value, market share projections, and the critical milestone of reaching the break-even point. The model guides decision-making by highlighting strengths, challenges, and areas that require strategic focus.
          </p>

          <Space align="start" direction="horizontal">
            <Space align="start" direction="vertical">
              Customer Acquisition Costs (CAC) (-ve factor)
              <div className="in_row">
                <Rate
                  name="CustomerAcquisitionCost"
                  value={CAC}
                  tooltips={descCAC}
                  character={"◆"}
                  style={{ color: "red" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setReach(value);
                    handleChangeValue("CAC", value);
                  }}
                />
              </div>
              {descCAC[CAC - 1]}
            </Space>
            <br />
            <br />
            <Space align="start" direction="vertical">
              Customer Lifetime Value (CLV): (+ve factor)
              <div className="in_row">
                <Rate
                  name="CustomerLifetimeValue"
                  value={CLV}
                  tooltips={descCLV}
                  character={"◆"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setImpact(value);
                    handleChangeValue("LTV", value);
                  }}
                />
              </div>
              {descCLV[CLV - 1]}
            </Space>
            <br />
            <br />
            <Space align="start" direction="vertical">
              Market Share Projections (+ve factor)
              <div className="in_row">
                <Rate
                  name="MarketShareProjections"
                  value={MarketShare}
                  tooltips={descMarketShare}
                  character={"◆"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setConfidence(value);
                    handleChangeValue("MarketShare", value);
                  }}
                />
              </div>
              {descMarketShare[MarketShare - 1]}
            </Space>
            <br />
            <br />
            <Space align="start" direction="vertical">
              Break-even Point (-ve factor)
              <div className="in_row">
                <Rate
                  name="BreakEvenPoint"
                  value={BreakEvenPoint}
                  tooltips={descBreakEven}
                  character={"◈"}
                  style={{ color: "red" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setEffort(value);
                    handleChangeValue("BreakEvenPoint", value);
                  }}
                />
              </div>
              {descBreakEven[BreakEvenPoint - 1]}
            </Space>
          </Space>
        </Space>
      </Card>

      {/* <Card
        able
        style={{
          margin: "20px auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        title={<>    <h3>
          <ThunderboltOutlined style={{ marginRight: "8px" }} /> R.I.C.E Framework.{" "}
          <Space align="center" direction="vertical">
            <a
              href="#"
              onClick={() =>
                showModal("R.I.C.E Score Explanation", riceHTML)
              }
            >
              {" "}
              <Tag
                color="#1c3048ff"
                style={{ fontSize: "18px", padding: "px" }}
              >
                {riceScore}
              </Tag>{" "}
            </a>
          </Space>{" "}
          <a href="#" onClick={riceCopilot}>
            <Tag>Ask Max!</Tag>
          </a>{" "}
          {loadingRice ? (
            <Spin size="large" /> // Show spinner when loading is true
          ) : null}
        </h3></>}
      >
        <Space align="start" direction="vertical">
      

          <p>
            The RICE scoring model is a prioritization framework designed
            to help you determine which ideas. features, and other
            initiatives to put on your roadmaps by scoring these items
            according to reach, impact, confidence, and effort. This will
            enable you to make better-informed decisions, minimize
            personal biases in decision making, and help defend your
            priorities to your executive leadership.
          </p>

          <Space align="start" direction="horizontal">
            <Space align="start" direction="vertical">
              Reach (+ve factor)
              <div className="in_row">
                <Rate
                  name="reach"
                  value={reach}
                  tooltips={descreach}
                  character={"◆"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setReach(value);
                    handleChangeValue("reach", value);
                  }}
                />
              </div>
              {descreach[reach - 1]}
            </Space>
            <br />
            <br />
            <Space align="start" direction="vertical">
              Impact (+ve factor)
              <div className="in_row">
                <Rate
                  name="impact"
                  value={impact}
                  tooltips={descimpact}
                  character={"◆"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setImpact(value);
                    handleChangeValue("impact", value);
                  }}
                />
              </div>
              {descimpact[impact - 1]}
            </Space>
            <br />
            <br />
            <Space align="start" direction="vertical">
              Confidence (+ve factor)
              <div className="in_row">
                <Rate
                  name="conf"
                  value={confidence}
                  tooltips={descconf}
                  character={"◆"}
                  style={{ color: "green" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setConfidence(value);
                    handleChangeValue("confidence", value);
                  }}
                />
              </div>
              {descconf[confidence - 1]}
            </Space>
            <br />
            <br />
            <Space align="start" direction="vertical">
              Effort (-ve factor)
              <div className="in_row">
                <Rate
                  name="effort"
                  value={effort}
                  tooltips={desceffort}
                  character={"◈"}
                  style={{ color: "red" }}
                  allowClear={false}
                  onChange={(value) => {
                    // setEffort(value);
                    handleChangeValue("effort", value);
                  }}
                />
              </div>
              {desceffort[effort - 1]}
            </Space>
          </Space>
        </Space>
      </Card> */}



 

      <Modal
        title={modalTitle}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <button key="ok" onClick={closeModal} type="primary">
            OK
          </button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
      </Modal>
    </>

  );
};

export default IdeaDashboardv3;
