// FeatureCarousel.jsx
import React, { useState } from 'react';
import { Card, Badge, Result, Button, Space } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import FeatureUserView from './FeatureUserView';

const FeatureCarousel = ({ featuresData, product_id, idea_id, onSort }) => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

  const handleFeature = (updatedItems) => {
    // No automatic advancement, wait for user action
    onSort(updatedItems);
  };

  const handleNextFeature = () => {
    setCurrentFeatureIndex((prevIndex) => prevIndex + 1);
  };

  const handleStartOver = () => {
    setCurrentFeatureIndex(0);
  };

  const featuresArray = JSON.parse(featuresData.features); // Parse the features string

  const isAllDone = currentFeatureIndex >= 1;

  const featuresCompleted = isAllDone ? 1 : currentFeatureIndex;

  return (
    <Card>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

 
         <h4 className="h5-md" style={{   marginBottom: 3,  }}>  ▲ Rank it.</h4>
        <div>
          <Badge count={featuresCompleted} style={{ backgroundColor: '#52c41a', marginRight: '8px' }} />
          <span>{`Features: ${featuresCompleted} / ${featuresData.hasOwnProperty('features') && featuresData.features.length > 0 ? 1 : 0}`}</span>
        </div>
  
 </div>

      {isAllDone ? (
        <>
          <Result
            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            title="High-five! All rankings are in!"
            subTitle="Your choice matters, and we've got it recorded!"
          />
          <button type="primary" onClick={handleStartOver}>
            Start Over
          </button>
        </>
      ) : (
        <>
          <p>Tap into your inner visionary! Rank from most important to least important!</p>
          <div style={{  gap: '16px', marginTop: '5px' }}>
            {featuresArray.length > 0 && currentFeatureIndex < featuresArray.length && (
              <>
                
                  <FeatureUserView
                    key={featuresArray[currentFeatureIndex].id}
                    title={featuresData.title}
                    featureList={featuresArray}
                    product_id={product_id}
                    idea_id={idea_id}
                    onFeature={handleFeature}
                  />
                  <button type="primary" onClick={handleNextFeature}>
                    I'm done!
                  </button>
                
              </>
            )}
          </div>
          
        </>

      )}    
    </Card>
  );
};

export default FeatureCarousel;
