import { Column } from '@ant-design/plots';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const MetricBarChart = ({product_id}) => {


  const [data,getData]=useState('');
  const getAllData = ()=> {
    var user_access_token = localStorage.getItem("user_access_token");
    // For message data
   axios.get(  process.env.REACT_APP_API_URL +"reports/product/"+product_id+"/northstarMetric",{ 
    headers: {
      Authorization: "Bearer "+ user_access_token,
    }})
  .then(response => {
  const allData =response.data; 
 getData(allData);

  
  }) .catch(error => console.error(error));
  
  }
  useEffect(()=> {getAllData();},[product_id]);


  // const data = [
  //   {
  //     type: 'Margin %',
  //     value: 16,
  //   },
  //   {
  //     type: 'Cost',
  //     value: 8,
  //   },
  //   {
  //     type: 'Customer',
  //     value: 5,
  //   },
  //   {
  //     type: 'NPS',
  //     value: 1,
  //   },
   
  // ];
  const paletteSemanticRed = '#F4664A';
  const brandColor = '#5B8FF9';
  const config = {
    title: {
      open: true,
      text: 'Metric Mover',
    },
    data,
    xField: 'type',
    yField: 'count',
    seriesField: 'type',
    label: {
      
      content: (originData) => {
        const val = parseFloat(originData.count);

        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
      
    },
  };
  return <Column {...config} />;
};

 

 

export default MetricBarChart;