import { Avatar , Card, Col, Descriptions, Divider, Drawer, List, Row, Space, Statistic, Tabs, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
 
import { BulbOutlined, LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import badge from '../../../assets/images/rank7.png';
import UserIdeas from './UserIdeas';
import UserMessages from './UserMessages';

const UserDrawer = ({product_id}) => {

    const [user_list,getData]=useState('');
    const [selectedUser, setSelectedUser] = useState(null);

    //get a list of users and their information. UserID is the key
    //Data looks like this
    // [  {title: 'Ahmed xxx', userId:1, email: 'ahmed@xx.com' , location: 'Canada'  }, ]

    const getAllData = ()=> {
    var user_access_token = localStorage.getItem("user_access_token");
   axios.get(  process.env.REACT_APP_API_URL +"reports/product/"+product_id+"/users",{ 
    headers: {
      Authorization: "Bearer "+ user_access_token,
    }})
  .then(response => {
  const allData =response.data; 
  getData(allData);
  }) .catch(error => console.error(error));
  


}
  
  
useEffect(()=> {getAllData();},[]);
  

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
    
  
  const showLargeDrawer = (userId) => {  
    
    const chosenUser = user_list?.find(user=>user.userId===userId) ?? null;
    setSelectedUser(chosenUser);
    setOpen(true);

  }



    const onClose = () => {
    
     setOpen(false);
    
    };

    //Menu of the Tabs. Need to pass the UserId for each customer to the component to display the data associated with the user
    const  items = [
        {
          key: '1',
          label: ( <span><BulbOutlined style={{ fontSize: '24px'}} /> Ideas</span>),
          children: <UserIdeas
          user_id={ selectedUser?.userId}   
          product_id={ product_id}
          />,
       
        },
        {
          key: '2',
          label: ( <span><MessageOutlined  style={{ fontSize: '24px'}}/> Comments</span>),
          children: <UserMessages
          user_id={ selectedUser?.userId}
          product_id={ product_id}
          />,
      
        },
      
      ];

    const IconText = ({ icon, text }) => (
        <Space>
          {React.createElement(icon)}
          {text}
        </Space>
      );
  

    return (
 
<>
 



<List 
     itemLayout="vertical"
     dataSource={user_list}
     renderItem={(item) => (
       <List.Item 
       actions={[  
        <Tooltip title="Ideas Submitted">   <IconText icon={BulbOutlined} text={item.ideas} key="ideas"   /></Tooltip>,
       <Tooltip title="Voted on">  <IconText icon={LikeOutlined} text={item.votes} key="votes" /></Tooltip>,
       <Tooltip title="Comments Made">  <IconText icon={MessageOutlined} text={item.comments} key="comments" /></Tooltip>,
       <Tooltip title="Points Earned">  <IconText icon={StarOutlined} text={item.points} key="points" /></Tooltip>,
        <Tooltip title={item.badge}> <Avatar src={<img src={badge} alt="Badge Level" />} /></Tooltip>,
        "Joined on " ,  
                  ]}
       >
{/*text={item.userId}*/}
         <List.Item.Meta
         avatar={ <Tooltip title={item.title}><Avatar src={'https://ui-avatars.com/api/name=' +item.title+'&background=random'} /></Tooltip>}
         title= {<a onClick={()=>showLargeDrawer(item.userId)} key={`a-${item.userId}`} >{item.title}</a>}
        
       
        
        />

         
       </List.Item>   
     )} 
   />

    
    <Drawer width={1000}
         title={selectedUser?.title +"'s Activity"}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        // dataSource={UserData}
        extra={
          <Space>
            
            <button  onClick={onClose}>
              close
            </button>
          </Space>
        }
      >
        
        <div>
    <Descriptions 
    // title={user_list.title}
    >
    <Descriptions.Item label="E-Mail Address">{selectedUser?.email}</Descriptions.Item>
    <Descriptions.Item label="Company">{selectedUser?.company}</Descriptions.Item>
    <Descriptions.Item label="Location">{selectedUser?.location}</Descriptions.Item>
  </Descriptions>
<Space><br/></Space>
    <Row gutter={16}>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="Ideas"
          value={selectedUser?.ideas}
          valueStyle={{
        
          }}
          prefix={<BulbOutlined style={{ fontSize: '28px'}}/>}
          
        />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="Comments"
          value={selectedUser?.comments}
          valueStyle={{
 
          }}
          prefix={<MessageOutlined style={{ fontSize: '28px'}} />}
     
        />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="Votes"
          value={selectedUser?.votes}
          valueStyle={{
    
          }}
          prefix={<LikeOutlined style={{ fontSize: '28px'}} />}
     
        />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="User Level"
          value={selectedUser?.badge}
          valueStyle={{
      
          }}
        
        />
        
      </Card>

      
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="Points"
          value={selectedUser?.points}
          valueStyle={{
          
          }}
          prefix={<StarOutlined style={{ fontSize: '28px'}} />}
        />
        
      </Card>

      
    </Col>

    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="Joined"
          value={selectedUser?.created}
          valueStyle={{
   
          }}
          
        />
        
      </Card>

      
    </Col>


  </Row>
<Divider/>
      </div>

   <Tabs   tabPosition={'left'} defaultActiveKey="1" items={items}   closable />
   
      </Drawer>
 
   
      </>

    ); 
 
}

export default UserDrawer;
 



