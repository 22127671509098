import React, { useState , useEffect} from 'react';
import { Modal, Divider, List, Progress, Avatar, Card, Button, Space, Row, Col, Layout } from 'antd';
import {
  TrophyOutlined,
  ThunderboltOutlined,
  LikeOutlined,
  BarChartOutlined,
  MessageOutlined,
  FundProjectionScreenOutlined,
  RocketOutlined,GiftOutlined
} from '@ant-design/icons';

const RewardCard = ({ reward, userTaskProgress, variationCount, pollCount, featureCount,TaskComplete }) => {
  const rewardTasks = [
    { taskType: 'Vote', count: 1 },
    { taskType: 'Comments', count: 1 },
    { taskType: 'Variations', count: variationCount },
    { taskType: 'Poll', count: pollCount },
    { taskType: 'Features', count: featureCount },
  ];

  const [showDetails, setShowDetails] = useState(false);
  const [complete, setComplete] = useState(0);

  // Placeholder data for task progress
  const taskProgressData = JSON.parse(reward.tasks);

  // Calculate top-level progress
  const totalTasks = 2 + variationCount + pollCount + featureCount;

  // Count total completed tasks
  // const completedTasksCount =
  //   (userTaskProgress.vote ?? 0) +
  //   (userTaskProgress.poll ?? 0) +
  //   (userTaskProgress.variations ?? 0) +
  //   (userTaskProgress.comments ?? 0) +
  //   (userTaskProgress.features ?? 0);


    // Define weights for each task type
    const taskWeights = {
      vote: 1,
      poll: pollCount ?? 0,
      variations: variationCount ?? 0,
      comments: 1,
      features: featureCount ?? 0,
    };

 
// Calculate total needed tasks with weights
const totalNeededTasks =
   taskWeights.vote +
   taskWeights.poll +
   taskWeights.variations +
 taskWeights.comments +
  taskWeights.features;

// Calculate completed tasks with weights
// Handle null values by replacing them with 0
 

// Calculate completed tasks count with weights
// Handle null values by replacing them with 0
const completedCommentsCount = (userTaskProgress.comments ?? 0) / (taskWeights.comments !== 0 ? taskWeights.comments : 1);

// Ensure the final value does not go over 1
const normalizedCommentsCount = Math.min(completedCommentsCount, 1);


// Calculate completed tasks count with weights
const completedTasksCount =(userTaskProgress.vote ?? 0)+ (userTaskProgress.poll ?? 0)+(userTaskProgress.variations ?? 0) 

+ normalizedCommentsCount + (userTaskProgress.features ?? 0);

 

  // (
  //   (userTaskProgress.vote ?? 0) / (taskWeights.vote !== 0 ? taskWeights.vote : 1)
  // ) +
  // (
  //   (taskWeights.poll !== 0 ? (userTaskProgress.poll ?? 0) / taskWeights.poll : 0)
  // ) +
  // (
  //   (taskWeights.variations !== 0 ? (userTaskProgress.variations ?? 0) / taskWeights.variations : 0)
  // ) +
  // (
  //   normalizedCommentsCount
  // ) +
  // (
  //   (userTaskProgress.features ?? 0) / (taskWeights.features !== 0 ? taskWeights.features : 1)
  // );




// Calculate weighted percentage
const weightedPercentage = Math.ceil((completedTasksCount/totalTasks)*100);
const topLevelProgressPercent =weightedPercentage;
 

useEffect(() => {
  // Assuming topLevelProgressPercent is updated somewhere in your component
  // Example: setTopLevelProgressPercent(updatedValue);

  // Check if topLevelProgressPercent is 100 and update complete state
  if (topLevelProgressPercent === 100) {
    TaskComplete();  // Add parentheses to invoke the function
  }
}, [topLevelProgressPercent]);

 

  // const topLevelProgressPercent = Math.ceil((completedTasksCount / totalTasks) * 100);

  const handleDetailsClick = () => {
    setShowDetails(true);
  };

  const handleModalClose = () => {
    setShowDetails(false);
  };

  const DetailsCard = () => {
    // Placeholder content for reward details

    const getTaskIcon = (taskType) => {
      switch (taskType) {
        case 'Vote':
          return <LikeOutlined />;
        case 'Variations':
          return <FundProjectionScreenOutlined />;
        case 'Poll':
          return <BarChartOutlined />;
        case 'Comments':
          return <MessageOutlined />;
        case 'Features':
          return <RocketOutlined />;
        default:
          return null;
      }
    };

    return (
      <>
     <Progress
      percent={topLevelProgressPercent}
      status="active"
      strokeWidth={12}
      showInfo={true}
 
       
    />

        <p>{reward.offerDescription}</p>

        <Divider />

        <div style={{ textAlign: 'left', marginBottom: '16px' }}>
          <TrophyOutlined style={{ fontSize: '24px', color: '#ffcc00', marginRight: '8px' }}/>
          <span style={{ fontWeight: 'bold' }}>Activities required to unlock the reward:</span>
        </div>

        <List
          dataSource={rewardTasks}
          renderItem={(task) => (
            <List.Item key={task.taskType}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon={getTaskIcon(task.taskType)}
                    style={{
                      backgroundColor:
                        userTaskProgress[task.taskType.toLowerCase()] >= task.count ? '#87d068' : 'rgba(0, 0, 0, 0.25)',
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                    }}
                  />
                }
                title={task.taskType}
                description={
                  <>
                    <Progress
                      type="circle"
                      percent={
                        Math.min((userTaskProgress[task.taskType.toLowerCase()] / task.count) * 100, 100) // Consider 100% if progress exceeds 100%
                      }
                      width={40}
                      format={() => `${userTaskProgress[task.taskType.toLowerCase()] || 0}/${task.count}`}
                      strokeColor="#87d068"
                      strokeLinecap="round"
                    />
                    <span style={{ marginLeft: '8px' }}>
                      Completed: {userTaskProgress[task.taskType.toLowerCase()] || 0} / {task.count}{' '}
                    </span>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </>
    );
  };

  return (
    <>
    
  
   
    <Layout.Header style={{ 
      
      // position: 'fixed', top: '0', width: '100%',
    
      //   padding: '10px', // Adjust padding as needed
      //   height: '100px', // Set the desired height
     

    }}
    
    className="custom-header"
    
    >
   <div
    >
   
      <Space style={{alignItems: 'left' }} onClick={handleDetailsClick} >
        <GiftOutlined style={{ fontSize: '24px', color: '#ffcc00' }} />
        <span >Unlock your reward: {reward.offerName} </span>
      </Space>
 
 
  

      <Modal title={<Space><GiftOutlined style={{ fontSize: '24px', color: '#ffcc00', marginRight: '1px' }} /><h5>{reward.offerName}</h5></Space>}
             visible={showDetails}
             onCancel={handleModalClose}
             footer={null}>
        <DetailsCard />
      </Modal>
        
    

    </div>
  </Layout.Header>
 
    
    </>
  );
};

export default RewardCard;
