import React from 'react';
import { Bar } from '@ant-design/plots';

const FeaturePriorityChart = ({ featureData }) => {

 
  const barChartData = featureData.flatMap((item) => {
    const features = JSON.parse(item.response);
    return features.map((feature, index) => ({
      featureName: feature.name,
      personaId: item.personaId, // Change to personaId
      rank: index + 1,
    }));
  });

  const chartConfig = {
    data: barChartData,
    xField: 'rank',
    yField: 'featureName',
    seriesField: 'personaId', // Change to personaId
    isGroup: true,
    isStack: true,
    legend: false,
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    xAxis: { title: { text: 'Rank' } },
    yAxis: { title: { text: 'Features' } },
    title: { text: 'Feature Priority Order by Persona', position: 'top' },
    tooltip: {
      customContent: (title, items) => {
        // Customize tooltip content
        return `<div>${title}</div>`;
      },
    },
  };


 
  
 

  // Render the chart component
  return <Bar {...chartConfig} />;
};

export default FeaturePriorityChart;
