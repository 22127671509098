import {
  BulbOutlined,UserOutlined
} from '@ant-design/icons';
import emailjs from '@emailjs/browser';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/Logo.png';
import GoogleUserLogin from '../User/GoogleUserLogin';
// import FacebookUserLogin from '../User/FacebookUserLogin';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Form, Avatar,   Col, Divider, Input, Layout, Menu, Modal, Row, Space, Tabs, message } from 'antd';
import { decodeToken } from "react-jwt";
// import { FacebookProvider, Loginbutton  } from 'react-facebook';

import Login from '../User/Login';
import Signup from '../User/Signup';

const { success, error, warning } = message;
const {TabPane}=Tabs;


const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};

const menu = [
  // {
  //   label: 'Product',
  //   key: 'Feature',
  //   children: [
  //     {
  //       type: 'group',
  //       label: 'Features',
  //       key:'1',
  //     },
  //     {
  //       icon: <BulbTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Features/Collect">
  //           Collect
  //         </Link>
  //       ),
  //       key: 'Collect',
  //     },
  //     {
  //       icon: <MessageTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Features/Engage">
  //           Engage
  //         </Link>
  //       ),
  //       key: 'Engage',
  //     },
  //     {
  //       icon: <ThunderboltTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Features/Prioritize">
  //           Prioritize
  //         </Link>
  //       ),
  //       key: 'Prioritize',
  //     },
  //     {
  //       icon: <PieChartTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Features/Key-Insights">
  //           Analyse
  //         </Link>
  //       ),
  //       key: 'Insight',
  //     },
  //   ],
  // },
  // {
  //   label: 'Use Case',
  //   key: 'usecase',
  //   children: [
  //     {
  //       icon: <ShoppingTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Usecases/Product-Development">
  //           Consumer Products
  //         </Link>
  //       ),
  //       key: 'Consumer Products',
  //     },
  //     {
  //       icon: <ThunderboltTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Usecases/Customer-Feature-Intake">
  //           Startups
  //         </Link>
  //       ),
  //       key: 'Feature Intake',
  //     },
  //     {
  //       icon: <RocketTwoTone style={{ fontSize: '25px', color: '#08c' }} />,
  //       label: (
  //         <Link to="/Usecases/Internal-Employee-Engagement">
  //           Internal IDEAS
  //         </Link>
  //       ),
  //       key: 'Internal Employee Ideas',
  //     },
  //   ],
  // },
  {
    label: (
      <Link to="/contact">
        Contact
      </Link>
    ),
    key: 'Contact',
   },
  // {
  //   label: (
  //     <Link to="/login">
  //       Login
  //     </Link>
  //   ),
  //   key: 'login',
  // },
];

const Header = () => {
  const [current, setCurrent] = useState('1');
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [company, setCompany] = useState("guest");
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setCurrent('1');
      if (window.innerWidth > 768) {
        setOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const showModal = () => {
    setOpen(true);
    setModalVisible(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Thank you for your feedback!',
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Your request could not be submitted, please try again',
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const payload = {
      email: form.getFieldValue('title'),
      company: form.getFieldValue('feedback'),
    };

    emailjs
      .send('service_vv81mh9', 'template_hc24sbk', payload, 'YOUR_USER_ID')
      .then(
        (result) => {
          success();
          form.resetFields();
          hideModal();
        },
        (error) => {
          error();
        }
      );
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
     
      const searchParams = new URLSearchParams(location.search);
      let company = searchParams.get('company');
      
      // Check if company parameter is not present or empty
      if (!company) {
        company = 0;
      }
      
      const result=  await GoogleUserLogin(credentialResponse,company);
        
        if (result && result.code === 200) {
  
        
  
            const accessToken = localStorage.getItem("user_access_token");
            // Decode the token
            const decodeTokens = decodeToken(accessToken);
            
            // Access token properties
            const { sub, company, scope, ROLE, exp } = decodeTokens;
    
            window.location.href = "/product-list";
  
        }
     
      
      } 
      
  
      catch (error) {
        console.error(error);
   
   
      }
  
  };

  // const handleFacebookLogin = async (credentialResponse) => {
  //   try {
     
  //     const searchParams = new URLSearchParams(location.search);
  //     let company = searchParams.get('company');
      
  //     // Check if company parameter is not present or empty
  //     if (!company) {
  //       company = 0;
  //     }
      
  //     const result=  await FacebookUserLogin(credentialResponse,company);
        
  //       if (result && result.code === 200) {
  
        
  
  //           const accessToken = localStorage.getItem("user_access_token");
  //           // Decode the token
  //           const decodeTokens = decodeToken(accessToken);
            
  //           // Access token properties
  //           const { sub, company, scope, ROLE, exp } = decodeTokens;
    
  //           window.location.href = "/product-list";
  
  //       }
     
      
  //     } 
      
  
  //     catch (error) {
  //       console.error(error);
   
   
  //     }
  
  // };



const handleLogin = async () => {
  try {
    const result = await Login(username, password);

    if (result && result.code === 200) {
      const accessToken = localStorage.getItem("user_access_token");
      // Decode the token
      const decodeTokens = decodeToken(accessToken);
      // Access token properties
      const { sub, company, scope, ROLE, exp } = decodeTokens;
      window.location.href = "/product-list";
    } else if (result && result.code === 401) {
      // Unauthorized - Invalid credentials
      alert("Invalid credentials. Please check your email and password.");
    } else {
      // Other errors
      alert("Login failed. Please try again.");
    }
  } catch (error) {
    console.error(error);
  }
};

 
const handleSignupFormSubmit = async (e) => {
  e.preventDefault();

const formData = new FormData(e.target);
const email = formData.get('email');
const password = formData.get('password');
const name = formData.get('name');
const searchParams = new URLSearchParams(location.search);
let company = searchParams.get('company');

// Check if company parameter is not present or empty
if (!company) {
  company = 0;
}


const names = name.split(' ');


const result = await Signup(names[0] || '', names.slice(1).join(' ') || '', company, "", email, password,"Guest");

    if (result && result.code === 200) {

      const result=  await Login(email, password);
    
      if (result && result.code === 200)  
  {
    const accessToken = localStorage.getItem("user_access_token");
    console.log("continue as New User", accessToken)

    setModalVisible(false);
    window.location.href = "/product-list";
     
  }

    }

};

  return (
    <>    <div className='hero-container'>
<Space style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
  <Link to={'/'}>
    <img src={logo} alt="Logo" />
  </Link>
  
</Space>


</div>

        {/* <Row gutter={16} align="middle">
        <Col span={8}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
      
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Col>
        <Col span={8}>
          <Input.Password
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Col>
        <Col span={6} style={{ display: 'flex',  padding: '10px' }}>
 <Space> <button type="primary" onClick={handleLogin} style={{ backgroundColor: '#7701cb' }}>
    Login
  </button>  
  <button type="primary" onClick={showModal} style={{ backgroundColor: '#7701cb' }}>
    Free Signup
  </button>


  {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <GoogleLogin
      onSuccess={credentialResponse => {
        handleGoogleLogin(credentialResponse);  
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  </GoogleOAuthProvider>
  <FacebookProvider appId="925577259282497">
  <Loginbutton 
            scope="email"
            onError={(error) => console.log(error)}
            onSuccess={handleFacebookLogin}
            style={{ backgroundColor: '#7701cb' }}
          >
             
              
   Login with FACEBOOK
  
      </Loginbutton>


</FacebookProvider> */}
  
{/*   
  </Space>
</Col>

      </Row> */}
      {/* </Space></div> */}  
        {/* <Menu
          className="menuitem"
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={menu}
     
        /> */}
        {/* <button className="demo" onClick={showModal}>
          FEEDBACK?
        </button> */}
    

      {/* <Modal
        open={open}
        onOk={sendEmail}
        onCancel={hideModal}
        okText="Submit"
        cancelText="Cancel"
        width={500}
        bodyStyle={{ height: 250, borderRadius: 5 }}
      >
        <BulbOutlined style={{ fontSize: '45px', color: 'gold' }} />{' '}
        <Space>
          {' '}
          <h3 className="h3-IdeaHub"> Got Feedback for us?</h3>
        </Space>
        <Form {...layout} style={{ maxWidth: 500 }} form={form} name="control-hooks">
          <Form.Item name="title" label="Title">
            <Input placeholder="Give it a title" />
          </Form.Item>

          <Form.Item name="feedback" label="Feedback">
            <TextArea
              showCount
              maxLength={250}
              style={{ height: 120, resize: 'none' }}
              placeholder="Tell us more"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            {contextHolder}
          </Form.Item>
        </Form>
      </Modal> */}

      {/* <Modal
  title="Join the IDEALY Community"
  open={isModalVisible}
  onCancel={hideModal}
  footer={null}
>
  <Tabs defaultActiveKey="signup" type="card" size="small">

    {/* Signup Tab */}
    {/* <TabPane tab="Sign Up" key="signup">
      <form onSubmit={handleSignupFormSubmit} className="auth-form">
      <p>
        Already part of our community? click on Login. 
      </p>
        <label>Name:</label>
        <Input type="text" name="name" required   />
      
        <label>Email:</label>
        <Input type="email" name="email" required  prefix={<UserOutlined className="site-form-item-icon" />}/>

        <label>Password:</label>
        <Input.Password type="password" name="password" required />
        <br/>
        <button type="submit">Sign Up</button>

        <p>
          Join IDEALY and be part of something amazing! By clicking "Sign Up," you agree to our
          <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>
          {' '}
          and
          <a href="/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
        </p>
      </form>
    </TabPane>
  </Tabs>
</Modal>     */}


    </>
  );
};

export default Header;
