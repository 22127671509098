import { Card, List, Space, Tag } from 'antd';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import eng_one from '../../assets/images/eng_one.png';
import { default as eng_three, default as eng_two } from '../../assets/images/eng_two.png';
import '../Layout/index.css';
import OnboardingExperience from './OnboardingExperience';

const ProductTable = () => {
  const [productList, setProductList] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAccessToken = localStorage.getItem('user_access_token');
        const response = await axios.get(process.env.REACT_APP_API_URL + 'product/', {
          headers: {
            Authorization: 'Bearer ' + userAccessToken,
          },
        });

        const newProductList = response.data.map((value) => value);
        setProductList(newProductList); // Clear existing list and set new data

        if (newProductList.length === 0) {
          setShowOnboarding(true);
        }
      } catch (error) {
        // Handle the error here
        console.error('Error fetching product data:', error);

        // Check for forbidden error (403) and show onboarding
        if (error.response && error.response.status === 403) {
          setShowOnboarding(true);
        }
      }
    };

    fetchData();
  }, []);

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <>
      {showOnboarding ? (
        <OnboardingExperience onFinish={() => setShowOnboarding(false)} />
      ) : (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={productList}
          renderItem={(record) => (
            <List.Item key={record.id}>
              <Link to={`/Research/Product/${record.id}`}>
                <Card hoverable
                  title={record.name}
                  extra={<span>{record.status ? 'Active' : 'Inactive'}</span>}
                  style={{ width: '100%', marginBottom: 16,  }}
                >
                  {/* <p>
                    <Tag color="blue">
                      <strong>Category:</strong> {record.type}
                    </Tag>
                  </p> */}
                  <p>
                    <Tag color="blue">
                      <strong>Identifier:</strong> {record.productIdentifier}
                    </Tag>
                  </p>
                  <p>
                    <Tag color="black">
                      <strong>Ideas:</strong> {record.ideaCount}
                    </Tag>
                  </p>
                  <p>
                    <Tag color="#3d5a9f">
                      <strong>Owner:</strong> {record.owner}
                    </Tag>
                  </p>
                </Card>
              </Link>
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default ProductTable;
