import {   Card, Col, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
 
import { CrownOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Title } = Typography;

const BusinessCanvas = ({ product }) => {
  const [vision, setVision] = useState();
  const [why, setWhy] = useState();
  const [productTenets, setProductTenets] = useState();
  const [competitorAdvantage, setCompetitorAdvantage] = useState();
  const [keyDifferentiator, setKeyDifferentiator] = useState();
  const [keyMetrics, setKeyMetrics] = useState();
  const [targetCustomers, setTargetCustomers] = useState();
  const [flyWheelDrivers, setFlyWheelDrivers] = useState();
  const [channels, setChannels] = useState();

  const [editing, setEditing] = useState({
    vision: false,
    why: false,
    productTenets: false,
    competitorAdvantage: false,
    keyDifferentiator: false,
    keyMetrics: false,
    targetCustomers: false,
    flyWheelDrivers: false,
    channels: false,
  });

  useEffect(() => {
    // Fetch data from your endpoint and set the values
     
    // setVision('');
    // setWhy('');
    // setProductTenets(' ' );
    // setCompetitorAdvantage('Highlight the unique strengths and advantages your product possesses over competitors. Identify the key differentiators that set you apart, such as superior features, technology, cost-effectiveness, or market positioning.' );
    // setKeyDifferentiator( '');
    // setKeyMetrics( 'Identify the primary metrics that will be used to measure the success and performance of your product. These could include user engagement, conversion rates, revenue, customer satisfaction, or any other relevant indicat.');
    // setTargetCustomers('' );
    // setFlyWheelDrivers('' );
    // setChannels(' Specify the distribution channels through which you will reach and engage with your target customers. This can include online platforms, social media, partnerships, direct sales, or any other channels that effectively connect you with your audience.' );

 
         setVision(product.vision);
         setWhy(product.why);
         setProductTenets(product.tenets);
        // setCompetitorAdvantage(data.competitorAdvantage);
         setKeyDifferentiator(product.differentiator);
        // setKeyMetrics(data.keyMetrics);
         setTargetCustomers(product.customers);
         setFlyWheelDrivers(product.flywheel);
        // setChannels(data.channels);
       
  }, [])

  const handleSave = (field) => {
    const user_access_token = localStorage.getItem("user_access_token");
    const updatedValue = getUpdatedValue(field);

    axios
      .post(`${process.env.REACT_APP_API_URL}product/${product.product_id}`, {
        [field]: updatedValue,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + user_access_token,
        },
      })
      .then((response) => {
     
        setEditing({ ...editing, [field]: false });
      })
      .catch((error) => {
        console.error(error);
        setEditing({ ...editing, [field]: false });
      });
  };

  const handleCancel = (field) => {
    setEditing({ ...editing, [field]: false });
  };

  const handleEdit = (field) => {
    setEditing({ ...editing, [field]: true });
  };

  const getUpdatedValue = (field) => {
    switch (field) {
      case 'vision':
        return vision;
      case 'why':
        return why;
      case 'productTenets':
        return productTenets;
      case 'competitorAdvantage':
        return competitorAdvantage;
      case 'keyDifferentiator':
        return keyDifferentiator;
      case 'keyMetrics':
        return keyMetrics;
      case 'targetCustomers':
        return targetCustomers;
      case 'flywheelDrivers':
        return flyWheelDrivers;
      case 'channels':
        return channels;
      default:
        return '';
    }
  };

  const renderBox = (title, value, onChange, onSave, onCancel, isEditing, field) => {
    return (
      <div className='boxCanvas' >
        
        <Title level={5} className="boxCanvas-title">
          {title}
        </Title>
        {isEditing ? (
          <ReactQuill
            value={value}
            onChange={onChange}
            className="box-editor"
          />
        ) : (
          <div
            className="box-value"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        )}
        {isEditing ? (
          <div className="box-buttons">
            <button type="primary" onClick={() => onSave(field)}>
              Save
            </button>
            <Space />
            <button onClick={() => onCancel(field)}>Cancel</button>
          </div>
        ) : (
          <div className="box-buttons">
            <button onClick={() => handleEdit(field)}>Edit</button>
        
          </div>
        )}
      </div>
    );
  };
   return (
    <div>
      <h3 >
        <CrownOutlined style={{ fontSize: '40px' }} />Product Strategy Canvas
      </h3>
<p>Unlock IDEALY's AI driven recommendations when you fil in essential details such as your product mission, target customers, and key differentiators, empowering our AI to delve deep into understanding your brand.  </p>

      <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={12}>
          <Card hoverable className="canvas-card">
            {/* Vision Card */}
            {renderBox(
              'Product Vision',
              vision,
              setVision,
              handleSave,
              handleCancel,
              editing.vision,
              'vision'
            )}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card hoverable className="canvas-card">
            {/* Why Card */}
            {renderBox(
              'Why does your Product exist?',
              why,
              setWhy,
              handleSave,
              handleCancel,
              editing.why,
              'why'
            )}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card hoverable className="canvas-card">
            {/* Target Customers Card */}
            {renderBox(
              'Who are your Primary Customers?',
              targetCustomers,
              setTargetCustomers,
              handleSave,
              handleCancel,
              editing.targetCustomers,
              'targetCustomers'
            )}
          </Card>
        </Col>
 
        <Col xs={24} sm={12} md={12} lg={12}>
        <Card hoverable className="canvas-card">
   {/* Key Differentiator Card */}
   {renderBox(
     'What is your Key Differentiator?',
     keyDifferentiator,
     setKeyDifferentiator,
     handleSave,
     handleCancel,
     editing.keyDifferentiator,
     'keyDifferentiator'
   )}
 </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BusinessCanvas;

 