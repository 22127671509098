import axios from 'axios';
import { toast } from 'react-toastify';
 

const Signup = async (firstName, lastName, nick, companyName, industry, regEmail, regPassword,userType) => {
  
  const userPayload = {
    name: firstName,
    lastname: lastName,
    nickname: nick,
    idcompany: companyName,
    industry: industry,
    email: regEmail,
    password: regPassword,
    userType: userType !== null ? userType : "Guest",
  };

  console.log(userType, userPayload);

  try {

 

  
      const userResponse = await axios.post(process.env.REACT_APP_API_URL + 'user', userPayload);
  
      if (userResponse.status === 200) {
       
        // Proceed with login if registration is successful
        localStorage.removeItem("user");
        localStorage.removeItem("user_access_token");
        localStorage.removeItem("user_role");
        localStorage.clear();
        return { code: 200, message: "Account registered and logged in successfully." };
      } else if (userResponse.status === 208) {
      
        return { code: 208, message: "User already exists. Please use the login form." };
      }
    } catch (error) {
      console.error(error);
  
      // Handle other errors here
     
  
      // Check if the error is due to an existing user (status code 208)
      if (error.response && error.response.status === 208) {
        
        return { code: 208, message: "User already exists. Please use the login form." };
      } else {
        // Other errors
        return { code: 500, message: "Registration failed. Please try again." };
      }
    }
  };
  
  export default Signup;
  
