// import React, { useState } from "react";
// import { Card, Divider, List, Space } from "antd";
// import { MenuOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

// const FeatureUserView = ({ featureList, title, onFeature }) => {
//   const [dataSource, setDataSource] = useState(featureList);

//   const moveItem = (index, direction) => {
//     const newIndex = direction === "up" ? index - 1 : index + 1;
    
//     if (newIndex >= 0 && newIndex < dataSource.length) {
//       const updatedItems = [...dataSource];
//       [updatedItems[index], updatedItems[newIndex]] = [updatedItems[newIndex], updatedItems[index]];

//       setDataSource(updatedItems);
//       onFeature(updatedItems);
//     }
//   };

//   return (
//     <Card style={{ marginBottom: 16, textAlign: "center" }}>
//       <h5>{title}</h5>

//       <div style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "5px" }}>
//         <List
//           dataSource={dataSource}
//           renderItem={(item, index) => (
//             <DraggableItem
//               key={item.id}
//               index={index}
//               name={item.name}
//               moveItem={moveItem}
//             />
//           )}
//         />
//       </div>
//     </Card>
//   );
// };

// function DraggableItem({ index, name, moveItem }) {
//   return (
//     <div style={{ border: "none", padding: "10px", marginBottom: "5px", cursor: "grab", backgroundColor: "white" }}>
//       <Space> 
//         <MenuOutlined style={{ marginRight: "8px", cursor: "grab" }} /><Divider/>
//       <UpOutlined style={{ cursor: "pointer" }} onClick={() => moveItem(index, "up")} /><Divider/>
//         <DownOutlined style={{ cursor: "pointer" }} onClick={() => moveItem(index, "down")} /><Divider/>
//       </Space>
//       {name} 
//     </div>
//   );
// }

// export default FeatureUserView;
import React, { useState } from "react";

import { Card, List , Space,Divider} from "antd";
import { MenuOutlined,UpOutlined ,DownOutlined} from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// ... (imports remain the same)

const FeatureUserView = ({ featureList, title, onFeature }) => {
  const [dataSource, setDataSource] = useState(featureList);

  const moveItem = (index, direction) => {
    const newIndex = direction === "up" ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < dataSource.length) {
      const updatedItems = [...dataSource];
      [updatedItems[index], updatedItems[newIndex]] = [
        updatedItems[newIndex],
        updatedItems[index],
      ];

      setDataSource(updatedItems);
      onFeature(updatedItems);
    }
  };

  return (
    <Card style={{ marginBottom: 16, textAlign: "center" }}>
      <h5>{title}</h5>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginTop: "5px",
        }}
      >
        <List
          dataSource={dataSource}
          renderItem={(item, index) => (
            <DraggableItem
              key={item.id}
              index={index}
              name={item.name}
              moveItem={moveItem}
            />
          )}
        />
      </div>
    </Card>
  );
};

function DraggableItem({ index, name, moveItem }) {
  return (
    <div
      style={{
        border: "none",
        padding: "10px",
        marginBottom: "5px",
        cursor: "grab",
        backgroundColor: "white",
      }}
    >
      <Space>
        <UpOutlined
          style={{ cursor: "pointer" }}
          onClick={() => moveItem(index, "up")}
        />
        <DownOutlined
          style={{ cursor: "pointer" }}
          onClick={() => moveItem(index, "down")}
        />
     
      {name} </Space>
    </div>
  );
}

export default FeatureUserView;
