import React from 'react';
import { Card, Col, Space , Row} from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

const calculateVotesCount = (variationData, pollValue) => {
  return variationData.reduce((total, item) => {
    return total + (item.pollValue.toLowerCase() === pollValue.toLowerCase() ? 1 : 0);
  }, 0);
};

const VariationResultsChart = ({ variationCountData }) => {
  const uniqueVariationIds = Array.from(new Set(variationCountData.map((item) => item.variationId)));

  return (
    <>   <Row gutter={[16, 16]} justify="center">
      {uniqueVariationIds.map((variationId) => {
        const variationData = variationCountData.filter((item) => item.variationId === variationId);
        const variationTitle = variationData[0]?.title || 'Unknown Variation';

        const yesCount = calculateVotesCount(variationData, 'yes');
        const noCount = calculateVotesCount(variationData, 'no');
        const totalVotes = yesCount + noCount;

        const yesPercentage = Math.floor((yesCount / totalVotes) * 100);
        const noPercentage = Math.floor((noCount / totalVotes) * 100);

        return (
       
          <Col key={variationId} xs={24} sm={12} md={12} lg={12} xl={12}>

            
<Card
  title={`Variation: ${variationTitle}`}
  style={{ minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
>
  <div style={{ position: 'absolute', top: '8px', right: '8px', padding: '6px', border: '1px solid #999999', borderRadius: '4px', textAlign: 'center' }}>
    <span style={{ fontSize: '18px', fontWeight: 'bold', display: 'block', marginBottom: '4px' }}>{totalVotes}</span>
    <span style={{ fontSize: '14px', display: 'block' }}>Responses</span>
  </div>
  <Space direction="horizontal" align="center" style={{ marginTop: '20px' }}>
    <div style={{ textAlign: 'center' }}>
      <LikeOutlined style={{ fontSize: '40px', color: '#52c41a' }} />
      <br/>
      <span style={{ fontSize: '24px', color: '#52c41a', fontWeight: 'bold' }}>{`${yesPercentage}%`}</span>
      <br/>
      <span style={{ fontSize: '12px',color: 'grey' }}>{`${yesCount} Responses`}</span>
    </div>
    <div style={{ marginLeft: '20px', marginRight: '20px', textAlign: 'center' }}>
      <DislikeOutlined style={{ fontSize: '40px', color: '#f5222d' }} />
      <br/>
      <span style={{ fontSize: '24px', color: '#f5222d', fontWeight: 'bold' }}>{`${noPercentage}%`}</span>
      <br/>
      <span style={{ fontSize: '12px',color: 'grey' }}>{`${noCount} Responses`}</span>
    </div>
  </Space>
</Card>


        </Col>

        );
      })}</Row>
    </>
  );
};

export default VariationResultsChart;
