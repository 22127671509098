import axios from 'axios';
import { toast } from 'react-toastify';

const handleSubmitProduct = (productName, productID, description, vision, why, customers, flywheel, differentiator, tenets,newProductType, brand, hyperlink) => {
  return new Promise((resolve, reject) => {
    const payload = {
      name: productName,
      productIdentifier: productID,
      type: newProductType,
      description: description,
      vision: vision,
      why: why,
      tenets: tenets,
      customers: customers,
      flywheel: flywheel,
      differentiator: differentiator,
      brand: brand,
      hyperlink:hyperlink
    };

    console.log(payload);

    const userAccessToken = localStorage.getItem('user_access_token');
    axios
      .post(`${process.env.REACT_APP_API_URL}product`, payload, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success('Product added successfully');
          resolve(res.data); // Resolve the promise with the response data
        } else {
          toast.error('Something went wrong. Please try again!');
          reject(new Error('Product addition failed')); // Reject the promise with an error
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error); // Reject the promise with the caught error
      });
  });
};

export default handleSubmitProduct;
