import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, theme } from 'antd';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import UserHeader2 from '../Layout/UserHeader2';
import handleSubmitProduct from './handleSubmitProduct';

const { TextArea } = Input;
const { Option } = Select;
const { Content, Header } = Layout;

const CreateProduct2 = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const spaceOptions = [
    'Electronics and Gadgets',
    'Clothing, Shoes & Jewelry',
     'Health and Wellness Products',
    'Home and Kitchen Essentials',
   'Toys & Games',
   'Beauty & Personal Care',
    'Pet Supplies',
    'Snack Foods',
    'Frozen Foods',
    'Dairy Products',
    'Bakery Goods',
    'Confectionery',
    'Breakfast Cereals',
    'Beverages',
  'Gourmet Food',
    'Baby Care Products',
    'Arts, Crafts & Sewing',
    'Collectibles & Fine Art',
    'Office and School Supplies',
    'Handmade',
    'Luggage & Travel Gear',
    'Patio, Lawn & Garden',
    'Sports & Outdoors',
    'Tools & Home Improvement',
    'Personal Care Products',
    'Household Cleaning Products',
    'Other'
  ];
  
  const [type, setType] = useState('');
  const [form] = Form.useForm();
  const [productTypes, setProductTypes] = useState([]);
  const [vision, setVision] = useState('');
  const [why, setWhy] = useState('');
  const [tenets, setTenets] = useState('');
  const [customers, setCustomers] = useState('');
  const [flywheel, setFlywheel] = useState('');
  const [differentiator, setDifferentiator] = useState('');

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const userAccessToken = localStorage.getItem('user_access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}product/types`, {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        });
        setProductTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProductTypes();
  }, []);

  const handleProductType = (value) => {
    form.setFieldsValue({ product_type: value });
  };



  const submit = (values) => {
 
 

    handleSubmitProduct(values.product_title, values.product_id, values.product_description, vision, why, customers, flywheel, differentiator, tenets,type, values.brand, values.hyperlink)
    .then((data) => {
      // Handle success response
 
      window.location.href = '/Research/Product';
      
    })
    .catch((error) => {
      // Handle error response
      console.error('Error adding product:', error);
      window.location.href = '/Research/Product';
    });
  };

  const handleSelectChange = (value) => {
    setType(value);
  };

  return (
    <Layout>
    <Layout>
      <Header
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  width: '100%',
                  display: 'flex',
                  
                }}

              > 
          
                <UserHeader2 />
    
       </Header>
   <Content
      style={{
        flex: '1',
        padding: '25px 15px',
        background: 'white',
      }}
    >

  
   
  
      <div className="main-body-container">
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <div style={{ height: '100%' }}>
              <h3 className="h3-IdeaHub">Add a New Product</h3>
              <Form form={form} onFinish={submit} layout="vertical">
                {/* First Three Fields */}
                <Col xs={24} sm={24} md={24} lg={24}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      name="product_title"
                      label="Product title"
                      rules={[
                        { required: true, message: 'Please enter a title.' },
                      ]}
                    >
                      <Input
                        style={{ height: '40px' }}
                        placeholder="Product title"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="product_description"
                      label="Product Description"
                      rules={[
                        {
                          required: true,
                          message: 'Please describe your product.',
                        },
                      ]}
                    >
                      <TextArea
                        rows={5}
                        placeholder="Space Description"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="product_type" label="Product Category">
                      <Select
                        onChange={handleSelectChange}
                        dropdownRender={(menu) => <div>{menu}</div>}
                      >
                        {spaceOptions.map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="brand"
                      label="Product Brand"
                  
                    >
                      <Input
                        style={{ height: '40px' }}
                        placeholder="Brand Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="hyperlink"
                      label="URL"
                      
                    >
                      <Input
                        style={{ height: '40px' }}
                        placeholder="Link to Website"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                
                </Col>
                {/* Next Six Fields */}
                <Col xs={24} sm={24} md={24} lg={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Product Mission">
                      <ReactQuill
                        theme="snow"
                        value={vision}
                        onChange={setVision}
                        className="react-quill-container"
                        placeholder="Clearly state the purpose and motivation behind your product. "
                   
                      />
                    </Form.Item>
                  </Col>
       
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="What are your Product Core Values?">
                      <ReactQuill
                        theme="snow"
                        value={tenets}
                        onChange={setTenets}
                        className="react-quill-container"
                        placeholder="Define the guiding principles and core values that shape your product's development."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Who are your Primary Customers?">
                      <ReactQuill
                        theme="snow"
                        value={customers}
                        onChange={setCustomers}
                        className="react-quill-container"
                        placeholder="Define your ideal customer profile and the specific audience segments you aim to serve..."
                      />
                    </Form.Item>
                  </Col>
  
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="What is your Key Differentiator?">
                      <ReactQuill
                        theme="snow"
                        value={differentiator}
                        onChange={setDifferentiator}
                        className="react-quill-container"
                        placeholder="Clearly articulate the distinct qualities or features that differentiate your product from others in the market..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                </Col>
           
                {/* Submit button */}
                <Row gutter={[16, 16]} justify="center">
                  <Col>
                    <Form.Item>
                      <button
                        type="primary"
                        htmlType="submit"
                        style={{ backgroundColor: '#23A6F0' }}
                      >
                        Add
                      </button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      </Content>
  </Layout>
    </Layout>
   
  );
  
  
  
  
};

export default CreateProduct2;
