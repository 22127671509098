import { Form, Layout, Select, Typography, message, theme } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LogoutFooter from '../Layout/LogoutFooter';
import UserFooter from '../Layout/UserFooter';
import WelcomeHeader from '../Layout/WelcomeHeader';
import LoginAgain from '../User/LoginAgain';

const { Title, Text } = Typography;
const { Option } = Select;
const { Content,Header, Footer,Sider } = Layout;


 
const Favorite = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [form] = Form.useForm();
  const [data, setUserData] = useState({});
  const { success, warning } = message;




  const refreshIdea = () => {
    // Implement the logic to refresh Idea.js
    window.location.reload(); // Redirect to login page

  };

   
  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
     

      const accessKey = '216f01a2a79f40dce58b0a92ac452a03'; // Sign up for a free account to get an access key

      const apiUrl = `http://api.ipstack.com/${data.ip}?access_key=${accessKey}`;
      
      axios.get(apiUrl)
        .then(response => {
          const locationData = response.data;
    
        

        })
        .catch(error => {
          console.error('Error fetching location data:', error);
        });


      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };


  
  useEffect(() => {
 
  }, []);
  
  
 
   

  return (
  
  
    // {showOnboarding ? (
    //     <UserOnboarding onFinish={() => setShowOnboarding(false)} />
    //   ) : (
        
    
<Layout>
      <Layout >
        
      <Header
           style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            
          }}

        > 
      {localStorage.getItem("user_access_token") && (
        <WelcomeHeader activity={1} />
      )}
</Header>
<Content
        style={{
          flex: '1',
          padding: '25px 15px',
          background: 'white',
        }}
      >

    {localStorage.getItem("user_access_token") ? (
            
            <div   >
 

</div>
) : (
  // Render the LoginAgain component for users not logged in
  <LoginAgain refreshIdea={refreshIdea} /> )
} 

</Content>
 
  
 

{/* {reward && (<RewardCard reward={reward} userTaskProgress={userTaskProgress}  variationCount={variationCount} pollCount={pollCount} featureCount={featureData && featureData.idfeature !== null ? 1 : 0} />)} */}
{localStorage.getItem("user_access_token") ? (
        // If user is logged in, show UserFooter
        <UserFooter username={data.name} />
      ) : (
        // If user is not logged in, show LogoutFooter
        <LogoutFooter />
      )}

 
  </Layout>
    </Layout>

     
  ); 
      
};
export default Favorite;

 
