import React, { useState, useEffect } from 'react';
import { Slider, InputNumber, Button, Modal } from 'antd';
import { WeightOutlined,SettingOutlined } from '@ant-design/icons';
import axios from 'axios';




const WeightSlider = ({product_id}) => {
  const [open, setOpen] = useState(false);
const [totalWeightError, setTotalWeightError] = useState(false);

  const [weights, setWeights] = useState({
    innovation: 0,
    financial: 0,
    rice: 0,
    Sentiment: 0,
    Votes: 0,
  });

  useEffect(() => {
    // Fetch initial weights from the endpoint
    const fetchInitialWeights = async () => {
      try {
        // const response = await axios.get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/weights');
        // setWeights(response.data); // Assuming the response is an object with weights
      } catch (error) {
        console.error('Error fetching initial weights:', error);
        // Handle error if needed
      }
    };

    fetchInitialWeights();
  }, [product_id]);


  const handleSliderChange = (category, value) => {
    const roundedValue = Math.round(value / 5) * 5;
    setWeights((prevWeights) => ({
      ...prevWeights,
      [category]: roundedValue,
    }));
  };

  const handleInputNumberChange = (category, value) => {
    if (isNaN(value) || value < 0 || value > 100) {
      return;
    }

    const roundedValue = Math.round(value / 5) * 5;
    setWeights((prevWeights) => ({
      ...prevWeights,
      [category]: roundedValue,
    }));
  };

  const handleSliderAfterChange = (category, value) => {
    setWeights((prevWeights) => ({
      ...prevWeights,
      [category]: value,
    }));
  };

  const calculateTotalWeight = () => {
    return Object.values(weights).reduce((sum, value) => sum + value, 0);
  };

  const handleSave = () => {
    const totalWeight = calculateTotalWeight();

    if (totalWeight !== 100) {
      setTotalWeightError(true);
      return;
    }

    setTotalWeightError(false);

    const userAccessToken = localStorage.getItem('user_access_token');
console.log("prod id weight",product_id);
    axios
      .post(process.env.REACT_APP_API_URL + "product/" + product_id+ "/weights", weights, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // Successfully saved
          setOpen(false); // Close the modal or handle navigation
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const OpenWeightModal = () => {
    setOpen(true);
  };

  const HideWeightModal = () => {
    setOpen(false);
    setTotalWeightError(false); // Reset error state when closing the modal
  };

  return (
    <>
      <SettingOutlined onClick={OpenWeightModal} style={{ fontSize: '24px', color: '#08c' }} />

      <Modal
        open={open}
        onOk={handleSave}
        onCancel={HideWeightModal}
        okText="Save"
        cancelText="Cancel"
        width={400}
        bodyStyle={{ marginBottom: 0 }}
        okButtonProps={{
          className: 'custom-ok-button',
        }}
        className="custom-modal"
      >
        <h2>Set Scoring Weights</h2>
        <p>Total Weight: {calculateTotalWeight()}%</p>
        {totalWeightError && <p style={{ color: 'red' }}>Total weight must be 100. Please adjust the weights.</p>}


   
      <div className="weight-slider">
        <label>Innovation Score:</label>
        <Slider
          min={0}
          max={100}
          step={5}
          value={weights.innovation}
          onChange={(value) => handleSliderChange('innovation', value)}
          onAfterChange={(value) => handleSliderAfterChange('innovation', value)}
        />
        <InputNumber
          min={0}
          max={100}
          step={5}
          value={weights.innovation}
          onChange={(value) => handleInputNumberChange('innovation', value)}
        />
      </div>
      <div className="weight-slider">
        <label>Financial Score:</label>
        <Slider
          min={0}
          max={100}
          step={5}
          value={weights.financial}
          onChange={(value) => handleSliderChange('financial', value)}
          onAfterChange={(value) => handleSliderAfterChange('financial', value)}
        />
        <InputNumber
          min={0}
          max={100}
          step={5}
          value={weights.financial}
          onChange={(value) => handleInputNumberChange('financial', value)}
        />
      </div>
      <div className="weight-slider">
        <label>R.I.C.E Score</label>
        <Slider
          min={0}
          max={100}
          step={5}
          value={weights.rice}
          onChange={(value) => handleSliderChange('rice', value)}
          onAfterChange={(value) => handleSliderAfterChange('rice', value)}
        />
        <InputNumber
          min={0}
          max={100}
          step={5}
          value={weights.rice}
          onChange={(value) => handleInputNumberChange('rice', value)}
        />
      </div>
      <div className="weight-slider">
        <label>Customer Sentiment:</label>
        <Slider
          min={0}
          max={100}
          step={5}
          value={weights.Sentiment}
          onChange={(value) => handleSliderChange('Sentiment', value)}
          onAfterChange={(value) => handleSliderAfterChange('Sentiment', value)}
        />
        <InputNumber
          min={0}
          max={100}
          step={5}
          value={weights.Sentiment}
          onChange={(value) => handleInputNumberChange('Sentiment', value)}
        />
      </div>
      <div className="weight-slider">
        <label>Votes:</label>
        <Slider
          min={0}
          max={100}
          step={5}
          value={weights.Votes}
          onChange={(value) => handleSliderChange('Votes', value)}
          onAfterChange={(value) => handleSliderAfterChange('Votes', value)}
        />
        <InputNumber
          min={0}
          max={100}
          step={5}
          value={weights.Votes}
          onChange={(value) => handleInputNumberChange('Votes', value)}
        />
      </div>
 
    </Modal></>
  );
};

export default WeightSlider;


