import React, { useState } from 'react';
import PlanCardList from './PlanCardList';
import PlanDetails from './PlanDetails';
import PlanForm from './PlanForm';

const allPlansData = [
    { 
      id: 1, 
      title: 'Plan 1', 
      dateRange: [new Date('2023-08-05'), new Date('2023-08-10')], 
      capacity: 100,
      selectedIdeas: [1, 2] // Add selectedIdeas property and its values here
    },
    { 
      id: 2, 
      title: 'Plan 2', 
      dateRange: [new Date('2023-08-15'), new Date('2023-08-20')], 
      capacity: 150,
      selectedIdeas: [3] // Add selectedIdeas property and its values here
    },
    { 
      id: 3, 
      title: 'Plan 3', 
      dateRange: [new Date('2023-08-05'), new Date('2023-08-10')], 
      capacity: 100,
      selectedIdeas: [1,2,3, 4,6,7] // Add selectedIdeas property and its values here
    },
    // Add more plans here
  ];
  
  const allIdeasData = [
    { id: 1, title: 'Please create a variety pack of different flavored crackers, each made with a unique keto-friendly ingredient. For example, the variety pack could include crackers made with almond flour, coconut flour, and flaxseed, each flavored with different herbs and spices. This would allow customers to try a range of flavors and textures, while still sticking to their keto diet. Additionally, offering a variety pack would provide a convenient and cost-effective way for customers to try multiple products from the company', capacity: 50, cost: 100 },
    { id: 2, title: 'First time customer of this brand and I love it. However this is quite a large size. My kids love it for snacking at school. If there were mini packs available, I can easily give them for school', capacity: 30, cost: 150 },
    { id: 3, title: 'Hey! Looking for coconut clusters with a tropical twist. What do you think about tossing in some pineapple or mango vibes?  good?', capacity: 20, cost: 80 },
    { id: 4, title: 'Idea 4', capacity: 30, cost: 150 },
    { id: 5, title: 'Idea 5', capacity: 20, cost: 80 },
    { id: 6, title: 'Idea 6', capacity: 20, cost: 80 },
    { id: 7, title: 'Idea 7', capacity: 20, cost: 80 },
    // Add more ideas here
  ];
  

const PlansPage = () => {
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [isCreatingNewPlan, setIsCreatingNewPlan] = useState(false);
  const [allPlans, setAllPlans] = useState(allPlansData);
  const [allIdeas, setAllIdeas] = useState(allIdeasData);

  const handleViewDetails = (planId) => {
    setSelectedPlanId(planId);
  };

  const handleGoBack = () => {
    setSelectedPlanId(null);
  };

  const handleToggleSelect = (ideaId) => {
    setAllIdeas((prevIdeas) =>
      prevIdeas.map((idea) =>
        idea.id === ideaId ? { ...idea, selected: !idea.selected } : idea
      )
    );
  };

  const handleAddPlan = (newPlanData, selectedIdeasToAdd) => {
    const newPlan = {
      id: allPlans.length + 1,
      ...newPlanData,
      selectedIdeas: selectedIdeasToAdd,
    };
    setAllPlans((prevPlans) => [...prevPlans, newPlan]);
    setIsCreatingNewPlan(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      {isCreatingNewPlan ? (
        <PlanForm onCreatePlan={handleAddPlan} ideas={allIdeas} onToggleSelect={handleToggleSelect} />
      ) : selectedPlanId ? (
        <PlanDetails
          plan={allPlans.find((plan) => plan.id === selectedPlanId)}
          allIdeasData={allIdeas}
          onGoBack={handleGoBack}
        />
      ) : (
        <div>
          <button onClick={() => setIsCreatingNewPlan(true)}>Create New Plan</button>
          <PlanCardList plans={allPlans} onViewDetails={handleViewDetails} />
        </div>
      )}
    </div>
  );
};

export default PlansPage;
