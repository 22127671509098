import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {  Input, List, Space,Col } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AddProductGapCategory = () => {
  const [ProductGapCategorys, setProductGapCategorys] = useState([]);
  const [newProductGapCategory, setNewProductGapCategory] = useState('');

  const user_access_token = localStorage.getItem('user_access_token');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchProductGapCategory();
  }, []);

  const fetchProductGapCategory = () => {
    axios
      .get(`${apiUrl}product/opportunity`, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        setProductGapCategorys(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addProductGapCategory = () => {
    const payload = { productOpportunity: newProductGapCategory };

    axios
      .post(`${apiUrl}product/opportunity`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchProductGapCategory();
        setNewProductGapCategory('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeProductGapCategory = (ProductGapCategoryId) => {
    axios
      .delete(`${apiUrl}product/opportunity/${ProductGapCategoryId}`, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchProductGapCategory();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editProductGapCategory = (ProductGapCategoryId, newName) => {
    const payload = { name: newName };

    axios
      .put(`${apiUrl}product/opportunity/${ProductGapCategoryId}`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchProductGapCategory();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>  
      <h4>Product Gaps</h4>
      <p>Drive excellence by identifying and addressing Product Gap Areas. Assign ideas to bridge these gaps, transforming challenges into opportunities and strengthening your product ecosystem. Whether it's refining Product Features, Packaging, or expanding your Product Range, this section provides a strategic roadmap for continuous product improvement and innovation.</p>
      <List
        dataSource={ProductGapCategorys}
        renderItem={(ProductGapCategory) => (
          <List.Item
            actions={[
              <button
                type="primary"
                danger
                onClick={() => removeProductGapCategory(ProductGapCategory.id)}
                icon={<DeleteOutlined />}
              />,
              <button
                type="primary"
                onClick={() => editProductGapCategory(ProductGapCategory.id, 'New Name')}
                icon={<EditOutlined />}
              />,
            ]}
          >
            {ProductGapCategory.productOpportunity}
          </List.Item>
        )}
      />
      <Space>
        <Input
          type="text"
          value={newProductGapCategory}
          onChange={(e) => setNewProductGapCategory(e.target.value)}
        />
        <button type="primary" onClick={addProductGapCategory} icon={<PlusOutlined />}>
          
        </button>
      </Space> 
    </div>
  );
};

export default AddProductGapCategory;
