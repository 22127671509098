import {   Card, Image, Popconfirm } from 'antd';
import React, { useState } from 'react';

const AdminVariation = ({ title, description, variationId, onVote, image,onDelete }) => {
  const [voted, setVoted] = useState(false);

  const onClickVote = (vote) => {
    setVoted(true);
    // Call the onVote function with the selected vote option and variationId
    onVote({ variationId, vote });
  };

 
  const onClickDelete= (variationId) => {
   console.log("variation to be deleted", variationId );
    // Call the onVote function with the selected vote option and variationId
    onDelete({ variationId });
  };

  const renderedDescription = description || ""; // If description is undefined, use an empty string
  const renderedImage = image || ""; // If image is undefined, use an empty string


  return (
    <Card title={title} style={{ width: 300, marginBottom: 16 ,justifyContent: 'center', alignItems: 'center'}}
    
    extra={
        <>
          <Popconfirm
            title="Are you sure to delete this variation?"
            onConfirm={() => onClickDelete(variationId)}
            okText="Yes"
            cancelText="No"
          >
            <button style={{ marginRight: 8 }}>Delete</button>
          </Popconfirm>
 
        </>
      }
    
    >
        <div   >
      {renderedDescription && <p>{renderedDescription}</p>}
      {renderedImage && <Image src={renderedImage} alt="Variation Image" style={{ width: '100%', maxHeight: 200, objectFit: 'cover' }} />}
      <br />
         <br />
       
      {/* <button
        type="primary"
        icon={voted ? <CheckOutlined /> : <CheckOutlined />}
        disabled={voted}
        onClick={() => onClickVote("yes")}
        style={{ marginRight: '8px' }}
      >
        Yes
      </button>
      <button
        icon={voted ? <CloseOutlined /> : <CloseOutlined />}
        disabled={voted}
        onClick={() => onClickVote("no")}
      >
        No
      </button> */}
      
      
      </div>
    </Card>
  );
};

export default AdminVariation;
