import { Card, Col, Divider, Row, Space,message, Checkbox, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PersonaAdmin from './PersonaAdmin';

const Persona = ({ product_id,
  productName, productDescription }) => {
  // State for form inputs

  const [productID, setProductID] = useState('');
  const [personaID, setPersonaID] = useState('');
  const [personaName, setPersonaName] = useState('');
  const [personaDescription, setPersonaDescription] = useState('');
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);
  const [selectedAttitudesString, setSelectedAttitudesString] = useState('');
  // State for attributes
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [attributeValues, setAttributeValues] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility

  // State for existing personas
  const [existingPersonas, setExistingPersonas] = useState([]);

    // Function to show the modal
    const showModal = () => {
      setIsModalVisible(true);
    };
  
    // Function to handle modal submission
    const handleModalSubmit = (e) => {
      e.preventDefault();  // Pass the event to handleFormSubmit
      handleFormSubmit(e);
      setIsModalVisible(false);
      // Your existing form submission logic
    };
  
    // Function to handle modal cancelation
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };

  const Attitudes = [
    {
      'Brand Conscious': [
        "I often buy things just because they are beautiful, whether or not they are practical.",
        "It is important that people admire the things I own.",
        "I have my favourite brand and I normally stick to it.",
        "I am willing to pay more for brand-name products.",
        "When I buy a product, the brand is very important to me.",

      ]
    },
    {
      'Environmentally Conscious': [

        "I am willing to pay more for eco-friendly products.",
        "I make an effort to buy local produce/products.",
        "When I buy a brand, product, or service, it is very important to me that I get a sense of the place where it was created."
      ]
    },
    {
      'Social Media Influence': [
        "I feel that I have a great deal of influence on the consumption choices of the people around me.",
        "Staying connected via social media is very important to me.",
        "I am likely to share shopping deals/product information through social media channels."
      ]
    },
    {
      'Fashion and Style': [
        "It is important to try new products, new places for vacation or new foods, just for the pleasure of the novelty.",
        "I am prepared to pay more for products that are a bit different from those one sees all over.",
        "I consider myself to be sophisticated.",
        "I try to keep abreast of changes in style and fashion.",
        "It is important to keep young looking."
      ]
    },
    {
      'Adventurous/Outdoorsy': [
        "I am adventurous/outdoorsy."
      ]
    },
    {
      'Indifferent to Brands': [
        "Brands are not important to me at all.",
        "No-name products are as good as nationally advertised brands."
      ]
    },
    {
      'Shopping Preferences': [
        "I prefer to shop online for convenience.",
        "I prefer to shop at a retail store location for the customer experience.",
        "I am willing to pay a little extra to save time shopping."
      ]
    },
    {
      'Ethical Consumer': [
        "I would never buy products from a company if I knew that they tested their products on animals.",
        "I believe I have a personal responsibility to help those worse off than me."
      ]
    },
    {
      'Entertainment and Socializing': [
        "I love to buy consumer goods beyond the basics that my household needs.",
        "I entertain at home more than ever.",
        "It is important for me to have a more intense and more spiritual life."
      ]
    }
  ];


  // Sample attributes with predefined values
  const predefinedValues = {
    Age: ['18-24', '25-44', '35-44', '45-64', '65-74', '74-84', '85+'],
    'Household Income': ['Low (<$100,000)', 'Middle ($100,000-$250,000)', 'High (>$500,000)'],
    Education: ['No High School', 'High School', 'Trade School', 'College', 'University'],
    'Occuplation': ['Student', 'White Collar', 'Service Sector', 'Blue Collar', 'Business Owner'],
    'Home Language': ['English', 'French', 'Non-Officual'],
    Location: ['Urban', 'Suburban', 'Rural'],
    'Maritial Status': ['Single', 'Married', 'Divorced/Sep/Widowed'],
    'Cultural Background': ['Asian', 'European', 'African', 'Latin American', 'Other'],
    'Dietary Restrictions': ['Halal', 'Kosher', 'Vegetarian', 'Vegan', 'Gluten-free', 'None'],
    'Family Size': ['Single', 'Small (1-3 members)', 'Medium (4-6 members)', 'Large (7+ members)'],
    'Family Status': ['Single', 'Couples with Kids', 'Couples, no Kids', 'Lone-Parent Family'],
    'Preferred Shopping Channels': ['Physical Stores', 'Online', 'Mobile Apps', 'All'],
    'Online Shopping Behavior': ['Frequent', 'Occasional', 'Rarely'],
    'Tech Adoption Level': ['Highly Tech-savvy', 'Moderately Tech-savvy', 'Not Tech-savvy'],
    'Buying Frequency': ['Daily', 'Weekly', 'Monthly', 'Occasionally'],
    'Usage Patterns': ['Daily', 'Weekly', 'Monthly', 'Occasionally'],
    'Product Preferences': ['Eco-friendly', 'Organic', 'Budget-friendly', 'Premium'],
  };


  const handleUpdate = async (updatedPersona) => {
    const userAccessToken = localStorage.getItem('user_access_token');

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}product/${product_id}/personas/${updatedPersona.personaId}`,
        updatedPersona,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      console.log('Persona updated successfully:', response.data);
      message.success('Persona updated successfully!');
      
      // Fetch existing personas again after update
      fetchExistingPersonas();
    } catch (error) {
      console.error('Error updating persona:', error.message);
    }
  };

  // Function to handle deletion of persona
  const handleDelete = async (personaId) => {
    const userAccessToken = localStorage.getItem('user_access_token');

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}product/${product_id}/personas/${personaId}`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      console.log('Persona deleted successfully:', response.data);
      message.success('Persona deleted successfully!');
      
      // Fetch existing personas again after delete
      fetchExistingPersonas();
    } catch (error) {
      console.error('Error deleting persona:', error.message);
    }
  };

  // Function to handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Using Axios


    const userAccessToken = localStorage.getItem('user_access_token');

    const payload = {
      name: personaName,
      description: personaDescription,
      attitude: selectedAttitudesString
    };

 
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'product/' + product_id + '/personas', payload, {

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userAccessToken}`,
        },
      });

      fetchExistingPersonas();
      message.success("Persona added successfully!");


      // Reset form fields after successful submission
      setProductID('');
      setPersonaID('');
      setPersonaName('');
      setPersonaDescription('');
      setSelectedAttributes([]);
      setAttributeValues({});
    } catch (error) {
      console.error('Error sending persona data:', error.message);
    }



  };



  // Function to fetch existing personas from the backend
  const fetchExistingPersonas = () => {


    try {
      const userAccessToken = localStorage.getItem('user_access_token');


      axios.get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/personas', {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      })
        .then((response) => {
          // Assuming the response.data contains an array of existing personas
          console.log(response.data);
          setExistingPersonas(response.data);
         
        })
        .catch((error) => {
          console.error('Error fetching existing personas:', error);
        });
    } catch (error) {
      console.error('Error fetching existing personas:', error);
    }
  };

  // Call the fetchExistingPersonas function wherever you need to retrieve existing personas
  // For example, you can call it inside a useEffect to fetch personas when the component mounts:

  useEffect(() => {
    fetchExistingPersonas();
  }, []);  // The empty dependency array ensures the effect runs only once when the component mounts



  // Function to handle attribute checkbox change
  const handleAttributeChange = (attribute) => {
    if (selectedAttributes.includes(attribute)) {
      // Deselect attribute
      const { [attribute]: removed, ...newAttributeValues } = attributeValues;
      setSelectedAttributes((prevSelected) =>
        prevSelected.filter((selected) => selected !== attribute)
      );
      setAttributeValues(newAttributeValues);
    } else {
      // Select attribute
      setSelectedAttributes((prevSelected) => [...prevSelected, attribute]);
    }
  };

  // Function to render attribute value dropdowns based on selected attributes
  const renderAttributeValueDropdowns = () => {
    return selectedAttributes.map((attribute) => (
      <div key={attribute}>
        <label>
          {attribute}:
          <select
            value={attributeValues[attribute] || ''}
            onChange={(e) =>
              setAttributeValues({
                ...attributeValues,
                [attribute]: e.target.value,
              })
            }
          >
            <option value="" disabled>
              Select {attribute}
            </option>
            {predefinedValues[attribute].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </label> <Divider />
        <br />
      </div>
    ));
  };


  const handleAttitudeChange = (selectedOptions) => {
    const selectedValues = Array.from(selectedOptions).map((option) => option.value);

    // Assuming each attitude is a separate string in the array
    const attitudesArray = selectedValues.map((value) => ({ attitude: value }));

    // Convert the array of attitude objects to JSON
    const attitudesJsonString = JSON.stringify(attitudesArray);

    // Set the state and log the JSON string
    setSelectedAttitudes(selectedValues);
    setSelectedAttitudesString(attitudesJsonString);

  };


  const renderExistingPersonas = () => {
    return existingPersonas.map((persona) => {
      console.log('Persona:', persona);

      const attitudes = persona.attitude ? JSON.parse(persona.attitude) : [];

      return (
        <PersonaAdmin
        key={persona.personaId}
        persona={persona}
        attitudes={attitudes}    
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />

       
      );
    });


  }


  const renderAttitudes = (attitudesJsonString) => {
    try {
      const attitudesArray = JSON.parse(attitudesJsonString);
      return attitudesArray.map((attitudeObj, index) => (
        <p key={index}>{attitudeObj.attitude}</p>
      ));
    } catch (error) {
      console.error('Error parsing JSON string:', error);
      return <p>Error parsing JSON string</p>;
    }
  }
  return (
    <div style={{ background: 'white', minHeight: '100vh', padding: '0 10px' }}>
      <div className="main-body-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
         <Space><h3>Define your Audience </h3> <button onClick={showModal}>Create Audience</button></Space>
             <p >
             Capture the essence of your target audience through compelling personas that delve into their attitudes,
              lifestyle choices, and motivations. Go beyond demographics and paint a vivid picture of who your customers truly are. 
              Understand their aspirations, challenges, and desires, enabling your brand to resonate on a deeper level.  <br/><br/>
             <b>How will these Audience personas be used?</b><br/>
             The feedback providers are asked to select from one of the audience persona's so that we can segment insights based on these personas.
             </p>
             
            {existingPersonas.length > 0 && (
              <>
                
                <h3>Existing Audience</h3>
                <div  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                  {renderExistingPersonas()}
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
      
      <Modal
        title="Create a new Audience"
        visible={isModalVisible}
        onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        okText="Submit"  // Rename the OK button to "Submit"
      >

<form onSubmit={handleFormSubmit}>
              <Space direction="vertical">
                <div className="tile-row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                  <Space direction="vertical">
                    <label>
                      Audience Name:
                      <input
                        type="text"
                        value={personaName}
                        onChange={(e) => setPersonaName(e.target.value)}
                      />
                    </label>
                    <br />
                    <label>
                    Audience Description:
                      <textarea
                        value={personaDescription}
                        onChange={(e) => setPersonaDescription(e.target.value)}
                      />
                    </label>
                    <br />
                    <label>
                    Audience Attitude: (Hold down CTRL key to select multiple options) <br />
                     
                      <select
                        multiple
                        value={selectedAttitudes}
                        onChange={(e) => handleAttitudeChange(e.target.selectedOptions)}
                        style={{ background: 'white', minHeight: '20vh', padding: '0 10px' }}
                      >
                        {Attitudes.map((attitudeObject) => {
                          const attitudeKey = Object.keys(attitudeObject)[0];
                          return (
                            <optgroup key={attitudeKey} label={attitudeKey}>
                              {attitudeObject[attitudeKey].map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </optgroup>
                          );
                        })}
                      </select>

                    </label>


                  </Space>
                </div>
                <br />
                <div className="tile-row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
              <Col>
                {/* <h4>Select Attributes:</h4>
 <Row gutter={[16, 16]}>
  {Object.keys(predefinedValues).map((attribute) => (
    <Col key={attribute} span={6}>
      <div>
        <label>
          <input
            type="checkbox"
            checked={selectedAttributes.includes(attribute)}
            onChange={() => handleAttributeChange(attribute)}
          />
          {attribute}<Divider type="vertical" />
        </label>
      </div>
    </Col>
  ))}
</Row>  
{selectedAttributes.length > 0 && (
   
                  <>
                    <h4>Attribute Values:</h4>
                    {renderAttributeValueDropdowns()}
                  </> 
                )}  */}
                
              </Col>
            </div>
          
              
              </Space>
            </form>

</Modal>

    </div>

  );
};

export default Persona;
