import React, { useEffect, useState } from 'react';
import fetchIdea from '../Idea/fetchIdea';
import IdeaTable3 from './IdeaTable3';
import CreateIdea from '../Idea/CreateIdea';
import IdeaCardView from '../Idea/IdeaCardView';
import IdeaList from '../Idea/IdeaList';

const IdeaTable = ({ product_id, canvas, productName, producType, productDescription }) => {
  const [ideas, setIdeas] = useState([]); // Store ideas in a state
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    fetchIdea(product_id)
      .then((data) => {
        setIdeas(data);
        setLoading(false); // Data fetched, set loading to false
        console.log(ideas);
      })
      .catch((error) => {
        console.error('Error fetching ideas:', error);
        setLoading(false); // Error occurred, set loading to false
      });
  }, [product_id]);

  const refresh = ()=>{
    fetchIdea(product_id)
    .then((data) => {
      setIdeas(data);
      setLoading(false); // Data fetched, set loading to false
    })
    .catch((error) => {
      console.error('Error fetching ideas:', error);
      setLoading(false); // Error occurred, set loading to false
    });

  }

  return (
    <>
      {/* Your other components or UI elements here */}


    
      {loading ? (
        <p>Loading...</p> // Display loading message while fetching data
      ) : (
        <>
            {ideas.length === 0 ? (  
 <div>       

              <h5>Welcome to the Idea Hub for {productName}!</h5>
              <p class="p-sm"> Ready to make your mark?</p>
              <p class="p-sm"> Add Your First Idea  <CreateIdea product_id={product_id} refresh={refresh}/></p>
          
            

            </div>
           ) : (  
            // <IdeaTable3
            //   product_id={product_id}
            //   ideas={ideas}
            //   canvas={canvas}
            //   productName={productName}
            //   producType={producType}
            //   productDescription={productDescription}
            //   ideaRefresh={refresh}
            // />

            <IdeaCardView           
            product_id={product_id}
            ideas={ideas}
            canvas={canvas}
            productName={productName}
            producType={producType}
            productDescription={productDescription}
            ideaRefresh={refresh}
          />

           )}  
           {/* <>
          <IdeaList          
          product_id={product_id}
          ideas={ideas}
          canvas={canvas}
          productName={productName}
          producType={producType}
          productDescription={productDescription}
          ideaRefresh={refresh}
        />
</> */}
        </>
        
        
      )}

    </>
  );
};

export default IdeaTable;
