import { CommentOutlined } from '@ant-design/icons';
import {  Col, Form, Input, message } from 'antd';
import axios from 'axios';
import React from 'react';
const { TextArea } = Input;

const AddComment = ({ product_id, idea_id }) => {
  const [form] = Form.useForm();
  const { success, warning } = message;

  const onFill = async () => {
    try {
      const payload = { "text": form.getFieldValue('Comment') };
      const user_access_token = localStorage.getItem("user_access_token");
      const res = await axios.post(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/comment', payload, {
        headers: {
          Authorization: "Bearer " + user_access_token,
        }
      });

      if (res.status === 200) {
        message.success('Comment added successfully');
        form.resetFields();
      }

    }

    catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 403) {
          message.error('Your comment could not be submitted, please try again, Comment Rejected due to either Hate, Profanity, or Violence');
          form.resetFields();
        } else if (error.response.status === 401) {
          warning('Ops! Please log in to comment.');
        }
      } else {
        success('Something went wrong. Please try again!');
      }
    }


  };




  return (
    <>
      <Form form={form} name="control-hooks">
        <Col xs={24} sm={24} md={8}>
          <Form.Item name="Comment" label="">
            <TextArea
              rows={4}

            />
          </Form.Item>
        </Col>

        <button type="primary" onClick={onFill} style={{ width: '100%' }}>
          <CommentOutlined /> Comment
        </button>

      </Form>
    </>

  );
};

export default AddComment;
