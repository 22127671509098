import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import {Breadcrumb, Layout, Menu, Button, theme, Space, Typography , Divider } from 'antd';
import collect from '../assets/images/collect.png';
import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Feature  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout       style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>

           <Layout id="featureTextBox"
             style={{
               padding: '10px 0',
               background: colorBgContainer
 
             }}
           >
    
             <Content
               style={{
                 padding: '0 10px',
                 minHeight: 500
               }}
             >
   
   <div  >
   <div className='hero-container'>
      <img src={collect} className='hero-background' alt='Background' />

 
    </div>
    <br/>
<Space  direction='vertical'>

   <h3 className='feature'>Give Your Customers a Voice in Product Development</h3>
   <Text  type="secondary">
With IDEALY, you can tap into the collective wisdom of your users, gain valuable feedback, and leverage their unique perspectives to drive product innovation. Don't miss out on the opportunity to enhance your offerings based on the insights and ideas of your most important stakeholders – your customers. Start empowering your users and transforming your product development process with IDEALY's In-App Idea widget today.

</Text>
 
<h3 className='feature'>Make participation inclusive.</h3>
<Text type="secondary">We understand the importance of gathering valuable insights from your user community, and that's why we've made it easy for them to have a voice in the development of your product. By leveraging IDEALY's In-App Idea widget, you create an inclusive environment where users feel encouraged and empowered to contribute their ideas, fostering a sense of ownership and engagement.</Text>

 
 
<h3 className='feature'>All your customer ideas captured in a single centralized place.</h3>
<Text type="secondary">
Maximize user insights and streamline idea management with IDEALY's centralized IDEA HUB. Effortlessly evaluate, track, and organize ideas in real-time, saving time and effort. Collaborate seamlessly, prioritize features, and unlock the full potential of user feedback for streamlined decision-making and innovation.
</Text>

<br/>
 

 



</Space>
</div>


             </Content>
           </Layout>
         </Content>
         <div id="rcorners1"  className="h2white">

 <h2>Try IDEALY FREE today & start building a Robust Idea Pipeline. </h2>  <Divider type="vertical" />
  
  <Button type='primary' href="/signup"  >TRY FOR FREE!</Button> 
</div>
         <Footer
           style={{
             textAlign: 'center',
             align:'center'
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Feature;
