import React, { useState } from 'react';
import { Layout, Menu,Col } from 'antd';
 import HeaderComponent from '../Layout/HeaderComponent';
import './product.css'; // Import your styles
import UserHeader2 from '../Layout/UserHeader2';
import logo from '../../assets/images/Logo.png';
import { Link, useLocation } from 'react-router-dom';
const { Sider, Content } = Layout;


const ProductHome = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
        <UserHeader2 collapsed={collapsed} toggleSider={toggleSider} />
      <Layout className="site-layout">
        {/* Use HeaderComponent here */}


        <Sider trigger={null} collapsible collapsed={collapsed}>
      
      
     
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">Menu 1</Menu.Item>
        <Menu.Item key="2">Menu 2</Menu.Item>
        <Menu.Item key="3">Menu 3</Menu.Item>
      </Menu>
    </Sider>
        <Content style={{ margin: '16px' }}>
          {/* Your content goes here */}
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            Content
          </div>
        </Content>
      </Layout>



    </Layout>
  );
};

export default ProductHome;
