import { Avatar, Layout, Row, Space, Tabs, message,Badge  } from 'antd';
import { TrophyOutlined } from '@ant-design/icons';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PopAnimation from '../Engagement/PopAnimation';
import './index.css';
import star from '../../assets/images/jewel_4.png';
import Level from '../../assets/images/level.png';

const { Header, Content } = Layout;
const { success, error, warning } = message;
const {TabPane}=Tabs;


const WelcomeHeader = ( {activity}) => {
  const [clientId, setClientId]=useState('');
  const [clientSecret, setClientSecret]=useState('');
 
  const [role, setRole] = useState("guest");
  const [points, setPoints] = useState("0");
  const [level, setLevel] = useState("Rookie");
  const [company, setCompany] = useState("guest");
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();
   


  useEffect(() => {
    // Check token expiration on component mount
    const  User = JSON.parse(localStorage.getItem('user'));
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    const companyValue = company || 0;
    setCompany(companyValue);
    getBadge();
    if (User) {
    setCurrentUser(User);
    checkTokenExpiration();
    decodeAccess();
    // Set up a timer to periodically check token expiration
    const tokenCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute
    
    // Cleanup the interval on component unmount
    return () => clearInterval(tokenCheckInterval);}
  }, [activity]);
  
  useEffect(() => {
    // Check token expiration on component mount
    const  User = JSON.parse(localStorage.getItem('user'));
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    const companyValue = company || 0;
    setCompany(companyValue);
    getBadge();
    if (User) {
    setCurrentUser(User);
    checkTokenExpiration();
    decodeAccess();
    // Set up a timer to periodically check token expiration
    const tokenCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute
    
    // Cleanup the interval on component unmount
    return () => clearInterval(tokenCheckInterval);}
  }, []);


  const checkTokenExpiration = () => {
    // Retrieve the stored token from localStorage
    const storedToken = JSON.parse(localStorage.getItem('user'));
  
    // Check if the token is present and not expired
    if (storedToken && !isTokenExpired(storedToken)) {
      // Token is valid, user is authenticated
      console.log("Token is valid. User is authenticated.");
      return;
    }  
    // Token is either not present or expired, log the user out and redirect to login page
    handleLogout();
  };
  
  // Function to logout the user
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();
    window.location.reload(); // Redirect to login page
  };
  
 
  
  const getBadge = async () => {
    try {


      const userAccessToken = localStorage.getItem('user_access_token');

      const response = await axios.get(process.env.REACT_APP_API_URL +`user/badge`,{
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
       console.log("badhe",response.data.level)
      setPoints(response.data.points);
      setLevel(response.data.level);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
 
  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    if (token && token.exp) {
      return token.exp * 1000 <= Date.now();
    }
    return true; // Token is considered expired if no expiration information is available
  };
  
  const decodeAccess=() => {

    // const accessToken = localStorage.getItem("user_access_token");
    // // Decode the token
    // const decodeTokens = decodeToken(accessToken);
    
    // // Access token properties
    // const { sub, company, scope, ROLE, exp } = decodeTokens;
    
    // // Now you can use these properties as needed
    // // console.log(sub); // "testmoe@idealy.com"
    // // console.log(company); // 1
    // // console.log(scope); // "product:read product:update ..."
    //  console.log(ROLE); // "role:admin"
    // // console.log(exp); // Token expiration timestamp
    // setRole(ROLE);
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    setCompany(company);

  }

  const profile = [
    {
      label: (
        <Avatar src={`https://ui-avatars.com/api/name=${currentUser.name}&background=random`} />
      ),
      key: 'avatar',
      children: [
        {
          type: 'group',
          label: (
            <>
              <Space>
                <Avatar
                  src={`https://ui-avatars.com/api/name=${currentUser.name}&background=random`}
                />
                <b>{currentUser.name}</b>
              </Space>
            </>
          ),
          key: 'menu',
          children: [
            {
              label: <Link to="/Reward/Lounge">Home</Link>,
              key: 'home',
            },

            {
                label: <Link to="/profile">Profile</Link>,
                key: 'profile',
              },
  
            
            {
              label: (
                <a rel="noopener noreferrer" onClick={handleLogout}>
                  Logout
                </a>
              ),
              key: 'Logout',
            },
          ],
        },
      ],
    },
  ];

   
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
  return (
<Layout.Header className={`welcome-header ${isMobile ? 'mobile-header' : ''}`}>
  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
    {currentUser && (
      <Space>
        <div>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Hi! {currentUser.name}
          </span>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Space>
            <span>
              <img src={star} alt="points" style={{ width: '30px', height: '30px' }} />
              {points}
            </span>
            <span>
              <img src={Level} alt="Level" style={{ width: '30px', height: '30px' }} />
              {level}
            </span>
          </Space>
        </div>
      </Space>
    )}
  </Row>
</Layout.Header>



  );
};

export default WelcomeHeader;
