import React from 'react';

function WidgetHTML() {
  return (
    <div>
 <div id="idealy_getallideas"></div>
  <link href="https://idealystorage.blob.core.windows.net/jscript/index.css" rel="stylesheet"/>
  <script id="widget-params" src="https://idealystorage.blob.core.windows.net/jscript/ideas.js" clientId="f3ffe8e1f804f2074aa5cc55233673b8" 
  clientSecret="9b3344bb2ec9fb643eecac8389d2521b" username="nizami@idealy.ca" product_id="1" ideaIds="{1,2}" images = "1" type="module"></script>    

    </div>
  );
}

export default WidgetHTML;