import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import {Breadcrumb, Layout, Menu, theme, Space, Typography, Divider, Button  } from 'antd';
import img1 from '../assets/images/guessing.png';
import img2 from '../assets/images/topideas.png';
import img3 from '../assets/images/notification.png';
import img4 from '../assets/images/conversation.png';



import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Intake  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout     style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>

           <Layout   id="featureTextBox" 
             style={{
               padding: '24px 0',
               background: colorBgContainer,
             }}
           >
    
             <Content
               style={{
                 padding: '0 24px',
                 minHeight: 500,
               }}
             >
   

   <div style={{ textAlign: 'center' }}>
<h3 className='h1-IdeaHub'>Customer Feature Intake.</h3>
<h4  >Are you tired of playing guessing games when it comes to your next big feature?  </h4  >
</div>
  <br/>
  <br/>


<Space style={{ padding: "3%", alignItems: "start" }}>
  <h5 className="feature-title">Feature Intake Widget</h5>
  <Text type="secondary" className="feature-text">
    Give your customers a voice with our feature intake widget, embedded right in your product ecosystem. Then turn these rough requests into solid features with a powerful conversation feature which promotes bi-directional feedback loop with your customers.
  </Text>
</Space>
<br />

<Space style={{ padding: "3%", alignItems: "start" }}>
  <h5 className="feature-title">Powerful Conversation</h5>
  <Text type="secondary" className="feature-text">
    The conversation feature promotes a bi-directional feedback loop, allowing you to engage in meaningful conversations with your customers. Gain deeper insights into their needs, understand customer feedback from every level, and assess which ideas align with your business strategy.
  </Text>
</Space>
<br />

<Space style={{ padding: "3%", alignItems: "start" }}>
  <h5 className="feature-title">Notifications and Announcements</h5>
  <Text type="secondary" className="feature-text">
    But that's not all! Our notification and announcements keep your customers in the loop on the latest updates, including the most requested features. Engage your customers with interactive features like voting and rating, allowing them to validate your features and create a buzz around your product.
  </Text>
</Space>
<br />

<Space style={{ padding: "3%", alignItems: "start" }}>
  <h5 className="feature-title">Measure Customer Sentiment</h5>
  <Text type="secondary" className="feature-text">
    Stop guessing and start measuring customer sentiment. With IDEALY, you can evaluate product-market fit at a fraction of the cost and time, ensuring you create a product that your customers truly love. Don't miss out on the opportunity to turn ideas into powerful features that drive customer satisfaction and business success.
  </Text>
</Space>
<br />


 



             </Content>
           </Layout>
         </Content>
         <div id="rcorners1"  className="h2white">

 <h2>IDEALY is completely FREE to get started with. </h2>  <Divider type="vertical" />
  
  <Button type='primary' href="/signup"  >TRY FOR FREE!</Button> 
</div>

         <Footer
           style={{
             textAlign: 'center',
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Intake;
