import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {   Input, List, Space, Col } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AddInitiative = () => {
  const [initiatives, setInitiatives] = useState([]);
  const [newInitiative, setNewInitiative] = useState('');

  const user_access_token = localStorage.getItem('user_access_token');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchInitiatives();
  }, []);

  const fetchInitiatives = () => {
    axios
      .get(`${apiUrl}product/initiative`, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        setInitiatives(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addInitiative = () => {
    const payload = { productInitiative: newInitiative };

    axios
      .post(`${apiUrl}product/initiative`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchInitiatives();
        setNewInitiative('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeInitiative = (initiativeId) => {
    axios
      .delete(`${apiUrl}product/initiative/${initiativeId}`, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchInitiatives();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editInitiative = (initiativeId, newName) => {
    const payload = { name: newName };

    axios
      .put(`${apiUrl}product/initiative/${initiativeId}`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchInitiatives();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
     
      <h4>Business Initiatives</h4>
      <p>
      Align your ideas with broader business initiatives. 
      Categorize each idea under specific initiatives to form a strategic movement within your organizational journey. Whether it's improving Sustainability, Cost Efficiency, introducing New Features, or expanding into new Markets, this section facilitates purposeful innovation and strategic alignment with your business.
      </p>
      <List
        dataSource={initiatives}
        renderItem={(initiative) => (
          <List.Item
            actions={[
              <button
                type="primary"
                danger
                onClick={() => removeInitiative(initiative.id)}
                icon={<DeleteOutlined />}
              />,
              <button
                type="primary"
                onClick={() => editInitiative(initiative.id, 'New Name')}
                icon={<EditOutlined />}
              />,
            ]}
          >
            {initiative.productInitiative}
          </List.Item>
        )}
      />
      <Space>
        <Input
          type="text"
          value={newInitiative}
          onChange={(e) => setNewInitiative(e.target.value)}
        />
        <button type="primary" onClick={addInitiative} icon={<PlusOutlined />}>
          
        </button>
      </Space> 
    </div>
  );
};

export default AddInitiative;
