import React, { useState } from 'react';
import { Result } from 'antd';
import { CheckCircleTwoTone, ArrowUpOutlined } from '@ant-design/icons';


const ThankYouScreen = () => {
  const [showThankYou, setShowThankYou] = useState(true);

  return showThankYou ? (
    <div className="thank-you-container">
      <Result
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        title="Thank You!"
        subTitle="You completed all required tasks, and we couldn't be more grateful."
        extra={
          <button
            type="primary"
            key="returnHome"
            onClick={() => {
              setShowThankYou(false);
              // Add any other logic you need for returning to the home screen
            }}
          >
            Return to Home
          </button>
        }
      />
   
    </div>
  ) : null;
};

export default ThankYouScreen;
