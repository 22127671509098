import React from 'react';
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { Header } = Layout;

const HeaderComponent = ({ collapsed, toggleSider }) => {
  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <UserOutlined />
        Profile
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggleSider,
      })}
      <div className="header-right">
        <Dropdown overlay={menu}>
          <Avatar size="small" icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Header>
  );
};

export default HeaderComponent;
