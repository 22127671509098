import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import {Breadcrumb, Button, Layout, Menu, theme, Space, Typography, Divider  } from 'antd';
import engage2 from '../assets/images/engage2.png';
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Engage  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout       style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>

           <Layout  id="featureTextBox"
             style={{
               padding: '24px 0',
               background: colorBgContainer,
             }}
           >
    
             <Content
               style={{
                 padding: '0 24px',
                 minHeight: 500,
               }}
             >
   
   <div  >

   <div className='hero-container'>
      <img src={engage2} className='hero-background' alt='Background' />

 
    </div>
<br/>
<Space direction='vertical' >

<h3 className='feature'>Connect Emotionally: Harness the Power of Qualitative Feedback</h3>
<Text  type="secondary">
Emotionally connect with your customers and uncover valuable insights with IDEALY's mobile-optimized platform. By tapping into the emotions and needs of your customers, you can detect market shifts and adapt your strategies accordingly. Bridge the gap between data-driven decisions and emotional connections, leading to better outcomes and a stronger organization. Embrace qualitative feedback to anticipate and adapt to market dynamics effectively.
</Text>
 


<h3 className='feature'>Enhance Customer Engagement: Foster Deeper Connections and Efficient Idea Management</h3>
<Text  type="secondary">Unleash customer engagement with our ideation platform. Refine ideas through Conversations, gather detailed feedback, and align with market demand. Stay transparent with Notifications, boosting engagement and fostering collaborative innovation. Streamline idea management and forge stronger connections with your users.
</Text>
 

<h3 className='feature'>Gamification: Ignite User Excitement and Loyalty.</h3>
<Text  type="secondary">
Elevate user engagement with our gamified ideation platform. Incentivize participation, reward valuable contributions, and foster loyalty among your user community. Earn points, unlock badges, and even receive real rewards for launching ideas and active involvement. Create an interactive and enjoyable ideation process that boosts engagement, loyalty, and rewards valuable input.
</Text>
<br/>


 

 
</Space>
</div>


             </Content>
           </Layout>
         </Content>

         <div id="rcorners1"  className="h2white">

<h2>Try IDEALY FREE today & turn your users into your biggest champions. </h2>  <Divider type="vertical" />
 
 <Button type='primary' href="/signup"  >TRY FOR FREE!</Button> 
</div>

         <Footer
           style={{
             textAlign: 'center',
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Engage;
