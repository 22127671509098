import React, { useState, useEffect } from 'react';
import {
  CaretUpFilled,
  DashboardTwoTone,
  UpOutlined,
  MessageOutlined,
  LikeOutlined,
  EllipsisOutlined,
  DislikeOutlined,
  StarFilled,
  SettingOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Button, Drawer, Space, Card, Dropdown, Menu, Tag, Select,  Avatar, Tooltip } from 'antd';
import axios from 'axios';
import { formatRelative, isWithinInterval, parseISO, subDays } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';

import CreateIdea from '../Idea/CreateIdea';
import IdeaDashboardv3 from '../Idea/IdeaDashboardv3';
import FileUploadComponent from '../Product/FileUploadComponent';
import IdeaInsights from '../Insights/IdeaInsights';
const { Meta } = Card;

const IdeaCardView = ({
  product_id,
  ideas,
  canvas,
  productName,
  producType,
  productDescription,
  ideaRefresh,
}) => {


    const [openDash, setOpenDash] = useState(false);
    const [record, setRecord] = useState(null);
    const [openEngagement, setOpenEngagement] = useState(false);
  
    const [dataSource, setDataSource] = useState([]);
    const [votedIdeaIds, setVotedIdeaIds] = useState([]);
    const [initiativeFilter, setInitiativeFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [submitterFilter, setSubmitterFilter] = useState(null);
    const [dateRangeFilter, setDateRangeFilter] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]);
	const [submitterOptions, setSubmitterOptions] = useState([]);
    const [initiativeOptions, setInitiativeOptions] = useState([]);
	const [filteredDataSource, setFilteredDataSource] = useState([]);
  
	const clearInitiativeFilter = () => {
		setInitiativeFilter(null);
	};

	const clearStatusFilter = () => {
		setStatusFilter(null);
	};

	const clearSubmitterFilter = () => {
		setSubmitterFilter(null);
	};
	const clearDateFilter = () => {
		setDateRangeFilter(null);
	};

  const navigate = useNavigate();

  useEffect(() => {
    const storedVotedIdeaIds =
      JSON.parse(localStorage.getItem('votedIdeaIds')) || [];
    setVotedIdeaIds(storedVotedIdeaIds);
  }, []);

  useEffect(() => {
   // Split the idea.urls string into an array of URLs
 
 
    setDataSource(ideas);
    setFilteredDataSource(ideas);

    // Generate filter options based on data
    if (ideas) {
      const uniqueInitiatives = [
        ...new Set(ideas.map((item) => item.initiative)),
      ];
      const uniqueStatuses = [...new Set(ideas.map((item) => item.status))];
      const uniqueSubmitters = [...new Set(ideas.map((item) => item.submitter))];
  
      setInitiativeOptions(uniqueInitiatives);
      setStatusOptions(uniqueStatuses);
      setSubmitterOptions(uniqueSubmitters);
    }
}, [ideas, product_id]);

useEffect(() => {
    // Apply filters on data source
    let filteredData = dataSource;



    if (initiativeFilter) {
        filteredData = filteredData.filter(
            (item) => item.initiative === initiativeFilter
        );
    }

    if (statusFilter) {
        filteredData = filteredData.filter(
            (item) => item.status === statusFilter
        );
    }

    if (submitterFilter) {
        filteredData = filteredData.filter(
            (item) => item.submitter === submitterFilter
        );
    }

    if (dateRangeFilter) {
        const today = new Date();
        let startDate;
        switch (dateRangeFilter) {
            case "last7Days":
                startDate = subDays(today, 7);
                break;
            case "last30Days":
                startDate = subDays(today, 30);
                break;
            case "last90Days":
                startDate = subDays(today, 90);
                break;
            case "last180Days":
                startDate = subDays(today, 180);
                break;
            default:
                break;
        }
        filteredData = filteredData.filter((item) =>
            isWithinInterval(parseISO(item.createdOn), {
                start: startDate,
                end: today,
            })
        );
    }

    setFilteredDataSource(filteredData);
}, [
    
    initiativeFilter,
    statusFilter,
    submitterFilter,
    dateRangeFilter,
    dataSource,
]);
 
  const saveVotedIdeaIdsToLocalStorage = (votedIdeaIds) => {
    localStorage.setItem('votedIdeaIds', JSON.stringify(votedIdeaIds));
  };

  const refreshIdea = () => {
    ideaRefresh();
  };

  const DashboardDrawer = (ideaId) => {
    setRecord(ideaId);
    setOpenDash(true);
  };

  const EngagementDrawer = (ideaId) => {
    setRecord(ideaId);
    setOpenEngagement(true);
  };

  const onClose = () => {
    setOpenDash(false);
    setRecord(null);
    setOpenEngagement(false);
  };

  const handleMenuClick = ({ key }, ideaId) => {
    switch (key) {
      case 'manage':
        navigate(`/Research/Product/${product_id}/Idea/${ideaId}`);
        break;
      case 'evaluate':
        DashboardDrawer(ideaId);
        break;
      case 'insights':
        EngagementDrawer(ideaId);
        break;
      case 'createOffer':
        navigate(
          `/Research/Product/${product_id}/Idea/${ideaId}/RewardDashboard?title=${encodeURIComponent(
            ''
          )}`
        );
        break;
      // Add more cases as needed
      default:
        break;
    }
  };

  const menu = (ideaId) => (
    <Menu onClick={(e) => handleMenuClick(e, ideaId)}>
      <Menu.Item key="manage">Create Feedback Page</Menu.Item>
      <Menu.Item key="evaluate">Evaluate IDEA</Menu.Item>
      <Menu.Item key="insights">Feedback Insights</Menu.Item>
      <Menu.Item key="createOffer">Reward Management</Menu.Item>
      {/* Add more menu items as needed */}
    </Menu>
  );

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>


      
      <Space style={{ width: '100%' }}>
        <CreateIdea product_id={product_id} refresh={refreshIdea} />
        
        <div className="filters">
					<span>Initiative:</span>
					<Select
						style={{ width: 120 }}
						value={initiativeFilter}
						onChange={setInitiativeFilter}
					>
						{initiativeOptions.map((option) => (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						))}
					</Select>{" "}
					{initiativeFilter && (
						<button onClick={clearInitiativeFilter}>Clear</button>
					)}
					<span>Status:</span>
					<Select
						style={{ width: 120 }}
						value={statusFilter}
						onChange={setStatusFilter}
					>
						{statusOptions.map((option) => (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						))}
					</Select>
					{statusFilter && <button onClick={clearStatusFilter}>Clear</button>}
					<span>Submitter:</span>
					<Select
						style={{ width: 120 }}
						value={submitterFilter}
						onChange={setSubmitterFilter}
					>
						{submitterOptions.map((option) => (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						))}
					</Select>
					{submitterFilter && (
						<button onClick={clearSubmitterFilter}>Clear</button>
					)}
					<span>Date Range:</span>
					<Select
						style={{ width: 120 }}
						value={dateRangeFilter}
						onChange={setDateRangeFilter}
					>
						<Select.Option value="last7Days">Last 7 Days</Select.Option>
						<Select.Option value="last30Days">Last 30 Days</Select.Option>
						<Select.Option value="last90Days">Last 90 Days</Select.Option>
						<Select.Option value="last180Days">Last 180 Days</Select.Option>
					</Select>
					{dateRangeFilter && <button onClick={clearDateFilter}>Clear</button>}
		</div>
          <FileUploadComponent product_id={product_id} />
      
      </Space>



      {filteredDataSource.map((idea) => (
        <Card
          key={idea.ideaId}
          style={{
            width: '400px',
           
            marginBottom: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Align items with space in between
          }}

          cover={ 
            idea.url && typeof idea.url === 'string' && idea.url.length > 0 && (
              <img alt="example" src={idea.url.split(',')[0].trim()} />
            )
          }
          actions={[
            <Space>
            <LikeOutlined style={{ fontSize: '20px' }} />
            <span> {idea.vote}</span>
          </Space>,
            <Space>
            <MessageOutlined style={{ fontSize: '20px' }} />
            <span> {idea.commentCount}</span>
          </Space>,
          <Tag
          color={
            idea.status === 'Research' ? 'green' : 'grey'
          }
 // Split the idea.urls string into an array of URLs
 


>
          {idea.status}
        </Tag>,
            <Dropdown
            overlay={() => menu(idea.ideaId)}
            placement="bottomRight"
            trigger={['click']}
          >
             <EllipsisOutlined />
          </Dropdown>,
          ]}


        >
     

          <Meta
avatar={
    <Tooltip title={`Idea Rank: ${idea.rank}`}>
      <Avatar
        style={{
          backgroundColor: '#ffeb3b', // Star color
          verticalAlign: 'middle',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        size="large"
        // icon={<StarFilled />}
      >
        <span style={{ fontSize: '14px', color: '#000', fontWeight: 'bold' }}>
        {idea.rank ? idea.rank.toFixed(0) : null}
        </span>
      </Avatar>
    </Tooltip>
  }
  
  
      title= { <Link to={`/Reward/idea/${product_id}/${idea.ideaId}`}>
      {idea.title}
    </Link>}
      description=  {idea.description}
    />


   

          <Space>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              
            </span>
          </Space>
 
        </Card>
      ))}




      <Drawer
        width={'60%'}
        title={<h1>IDEA Scorecard</h1>}
        placement="right"
        onClose={onClose}
        open={openDash}
        footer={
          <Space>
            <button onClick={onClose}>Close</button>
          </Space>
        }
      >
        {record && (
          <IdeaDashboardv3
            product_id={product_id}
            idea_id={
              dataSource.find((item) => item.ideaId === record)?.ideaId
            }
            canvas={canvas}
            productName={productName}
            producType={producType}
            productDescription={productDescription}
          />
        )}
      </Drawer>

      <Drawer
        width={'80%'}
        title={<h1>User Engagement Insights</h1>}
        placement="right"
        onClose={onClose}
        open={openEngagement}
        footer={
          <Space>
            <button onClick={onClose}>Close</button>
          </Space>
        }
      >
        {record && (
          <IdeaInsights
            product_id={product_id}
            idea_id={
              dataSource.find((item) => item.ideaId === record)?.ideaId
            }
            productName={productName}
            productDescription={productDescription}
          />
        )}
      </Drawer>
    
    
    </div>
  );
};

export default IdeaCardView;
