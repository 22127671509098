// Import dependencies
import { Select, Tabs , Space} from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import SentimentAnalysis from '../Idea/SentimentAnalysis';
import CommentInsight from './CommentInsights.js';
import PersonaChart from './PersonaChart.js';
import PollResultsCarousel from './PollResultsCarousel.js';
import SentimentCard from './SentimentCard.js';
import ThumbsUpDownChart from './ThumbsUpDownChart.js';
import VariationResultsChart from './VariationResultsChart.js';
import FeaturePriorityChart from './FeaturePriorityChart.js';
const { TabPane } = Tabs;

const IdeaInsights = ({ product_id, idea_id, productName, productDescription, description }) => {
  const [selectedPersona, setSelectedPersona] = useState('');
  const [personaOptions, setPersonaOptions] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [variationData, setVariationData] = useState([]);
  const [votesData, setVotesData] = useState([]);
  const [personaData, setPersonaData] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [loadingSentiment, setLoadingSentiment] = useState(false);
  const [sentimentData, setSentimentData] = useState({});
  const [positive, setPositive] = useState(null);
  const [negative, setNegative] = useState(null);
  const [neutral, setNeutral] = useState(null);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchReportData('pollResults'),
        fetchReportData('variationResults'),
        fetchReportData('voteResults'),
        fetchReportData('featureResults'),
        fetchReportData('commentResults'),
        fetchUserPersonas(),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUserPersonas = async () => {
    try {
      const userAccessToken = localStorage.getItem('user_access_token');
      const response = await axios.get(process.env.REACT_APP_API_URL + 'user/persona/' + product_id, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
      console.log("UserpersonaData",response.data);
      setPersonaData(response.data);
    } catch (error) {
      console.error('Error fetching user personas:', error);
    }
  };

  const fetchExistingPersonas = async () => {
    try {
      const userAccessToken = localStorage.getItem('user_access_token');
      const response = await axios.get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/personas', {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
      console.log("UserpersonaData",response.data);
      setPersonaOptions(response.data);
    } catch (error) {
      console.error('Error fetching existing personas:', error);
    }
  };

  const fetchReportData = async (reportName) => {
    try {
      const userAccessToken = localStorage.getItem('user_access_token');
      const response = await axios.get(process.env.REACT_APP_API_URL + `api/product/${product_id}/idea/${idea_id}/${reportName}`, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });

      console.log(reportName, response.data)

      switch (reportName) {
        case 'pollResults':
         
         

        setPollData(response.data);
          break;
        case 'variationResults':
          setVariationData(response.data);
          break;
        case 'voteResults':
          setVotesData(response.data);
          break;
        case 'featureResults':
          setFeatureData(response.data);
          break;
        case 'commentResults':
          setCommentsData(response.data);
          break;
        default:
          console.error('Invalid reportName:', reportName);
      }
    } catch (error) {
      console.error(`Error fetching ${reportName} data:`, error);
    }
  };

  const handlePersonaChange = (value) => {
    setSelectedPersona(value);
  };

  const filteredData = (data, filterKey) => {
    return selectedPersona ? data.filter((item) => item[filterKey] === selectedPersona) : data;
  };

  const calculateVotesCount = (filteredVotesData, responseValue) => {
    return filteredVotesData.reduce((total, vote) => {
      return total + (vote.response === responseValue ? 1 : 0);
    }, 0);
  };

  const handleGetSentiment = async () => {
    setLoadingSentiment(true);
    const sentimentResult = await SentimentAnalysis(product_id, idea_id, description);
    setSentimentData(sentimentResult);
    setPositive(sentimentResult.positive);
    setNegative(sentimentResult.negative);
    setNeutral(sentimentResult.neutral);
    setLoadingSentiment(false);
  };

  useEffect(() => {
    fetchExistingPersonas();
    fetchData();
    fetchUserPersonas();

   
  }, []);


  
  const filteredVotesData = selectedPersona !== '' // Updated condition to check for empty string
? votesData.filter((dataPoint) => dataPoint.personaId === selectedPersona)
: votesData;

const filteredVariationData = selectedPersona
? variationData.filter((dataPoint) => dataPoint.personaId === selectedPersona)
: variationData;

const filteredPollData = selectedPersona
  ? pollData.filter((dataPoint) => dataPoint.personaId === selectedPersona)
  : pollData;

  const featureResultsData = selectedPersona
  ? featureData.filter((dataPoint) => dataPoint.personaId === selectedPersona)
  : featureData;

 
const filteredPersonaData = selectedPersona
  ? personaData.filter((dataPoint) => dataPoint.personaId === selectedPersona)
  : personaData;

const filteredCommentsData =
  selectedPersona !== '' && selectedPersona !== undefined
    ? commentsData.filter((comment) => comment.personaId === selectedPersona)
    : commentsData;

  
  
  
 

  return (
    <div>
    <Select
      style={{ width: 200, marginBottom: 5 }}
      placeholder="Select Persona"
      onChange={handlePersonaChange}
      value={selectedPersona || undefined}
    >
      <Select.Option value="">Clear Filter</Select.Option>
      {personaOptions.map((persona) => (
        <Select.Option key={persona.personaId} value={persona.personaId}>
          {persona.name}
        </Select.Option>
      ))}
    </Select>

    <p>
      A deeper look into customer feedback can reveal strengths and weaknesses to capitalize on or address in the innovation.
    </p>

    <Tabs defaultActiveKey="1" type="card" tabPosition="top" size="small">
      <TabPane tab="Popularity Vote" key="1">
        <Space direction='vertical'>
        <ThumbsUpDownChart filteredVotesData={filteredVotesData} />
        <VariationResultsChart variationCountData={filteredVariationData} /></Space>
      </TabPane>
      <TabPane tab="Poll Response Summary" key="3">
        <PollResultsCarousel filteredPollData={filteredPollData} />
      </TabPane>

      <TabPane tab="Feature Sorting Results" key="feature">
        <FeaturePriorityChart featureData={featureResultsData} />
      </TabPane>    
      
      <TabPane tab="Audience Sentiment Analysis" key="6">
        <SentimentCard
          handleGetSentiment={handleGetSentiment}
          sentimentData={sentimentData}
          positive={positive}
          negative={negative}
          neutral={neutral}
        />
      </TabPane>

      <TabPane tab="Persona Representation Stats" key="4">
        <PersonaChart filteredPersonaData={filteredPersonaData} />
      </TabPane>
      <TabPane tab="User Comments" key="5">
        <CommentInsight filteredCommentsData={filteredCommentsData} />
      </TabPane>
  
    </Tabs>
  </div>
  );
};

export default IdeaInsights;
