import React from 'react';
import {   message, Tooltip } from 'antd';
import { CopyOutlined, ShareAltOutlined, } from '@ant-design/icons';
import { decodeToken } from "react-jwt";

const ShareLinkbutton = ({ productId, ideaId}) => {


          const accessToken = localStorage.getItem("user_access_token");
         // const decodeTokens = decodeToken(accessToken);
          
          // const { company } =  decodeTokens;
          // Now you can use these properties as needed
          // console.log(sub); // "testmoe@idealy.com"
          // console.log(company); // 1

  const shareLink = `https://app.idealy.ca/Reward/Idea/${productId}/${ideaId}?company=${0}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink)
      .then(() => message.success('Link copied'))
      .catch(() => message.error('Failed to copy link to clipboard'));
  };

  return (
    <>
      <Tooltip title="Share Link">
       
          
           <ShareAltOutlined onClick={handleCopy}/>
          
        
      </Tooltip>
    </>
  );
};

export default ShareLinkbutton;
