import axios from 'axios';

const getCommentsArray = async (product_id, idea_id) => {
  var user_access_token = localStorage.getItem('user_access_token');
  // For Idea detail
  const commentResponse = await axios.get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/comment', {
    headers: {
      Authorization: 'Bearer ' + user_access_token,
    },
  });

  const commentData = commentResponse.data || [];

  return commentData;
};
 

export default getCommentsArray;
