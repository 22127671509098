import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import {Breadcrumb, Button, Layout, Menu, theme, Space, Typography  } from 'antd';

import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Communicate  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout       style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>

           <Layout  id="featureTextBox"
             style={{
               padding: '24px 0',
               background: colorBgContainer,
             }}
           >
    
             <Content
               style={{
                 padding: '0 24px',
                 minHeight: 500,
               }}
             >
   

<Space direction='vertical' align='center'>
   <h3>Launch Announcements.</h3>
<Text className="Body1" type="secondary">Communicate with your users using Launch Announcements. Keep your users informed and build their trust by showing them that you're listening to their feedback.
</Text>
<br/>
<h3>Status Updates.</h3>
<Text type="secondary" className="Body1">Don’t leave users and internal teams wondering what’s come of their ideas. Easily notify users when ideas they’ve asked move from one stage to the next, or have been launched. Build user trust by giving them full visibility and showing them that you are listening. </Text>
<br/> 
<Button  id='buttonSignup'><a href="/signup">GET STARTED</a></Button> 
<br/>
 

<Text type="secondary" className="Body1"> With Idealy, you can turn your users into your biggest champions by creating a 
great customer experience that incorporates their ideas and feedback.</Text>
<br/>
<h5>Try Idealy today and see the difference it can make in your product development process.</h5>

</Space>



             </Content>
           </Layout>
         </Content>
         <Footer
           style={{
             textAlign: 'center',
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Communicate;
