import React, { useState } from 'react';
import { Col, Card } from 'antd';
import { Column } from '@ant-design/plots';

const PollResultsCarousel = ({ filteredPollData }) => {

  // Group filtered poll data by pollId
  const groupedPollData = {};
  filteredPollData.forEach((poll) => {
    const { pollId } = poll;
    if (!groupedPollData[pollId]) {
      groupedPollData[pollId] = [];
    }
    groupedPollData[pollId].push(poll);
  });

  // Convert the object values to an array
  const groupedPollArray = Object.values(groupedPollData);

  const getResponseCounts = (pollData) => {
    const responseCounts = {};
  
    pollData.forEach((poll) => {
      const { response } = poll;
      responseCounts[response] = (responseCounts[response] || 0) + 1;
    });
  
    const result = Object.keys(responseCounts).map((response) => ({
      response,
      count: responseCounts[response],
    }));
  
    return result;
  };

  const getTotalResponses = (pollData) => {
    return pollData.reduce((total, poll) => total + 1, 0);
  };

  const PollResultsChart = ({ pollData }) => {
    const responseCounts = getResponseCounts(pollData);
    const totalResponses = getTotalResponses(pollData);

    return (
      <div>
        <div style={{ position: 'absolute', top: '8px', right: '8px', padding: '6px', border: '1px solid #999999', borderRadius: '4px', textAlign: 'center' }}>
          <span style={{ fontSize: '18px', fontWeight: 'bold', display: 'block', marginBottom: '4px' }}>{totalResponses}</span>
          <span style={{ fontSize: '14px', display: 'block' }}>Responses</span>
        </div>
        <Column
          data={responseCounts}
          xField="response"
          yField="count"
          columnStyle={{
            fill: '#1890ff',
          }}
          label={{
            position: 'middle',
            style: {
              fill: '#FFFFFF',
              opacity: 0.6,
            },
          }}
          xAxis={{
            label: {
              autoHide: true,
              autoRotate: false,
            },
          }}
          yAxis={{
            label: {
              formatter: (v) => `${v}`,
            },
          }}
          meta={{
            response: { alias: 'Response' },
            count: { alias: 'Count' },
          }}
          title={{ visible: true, text: 'Poll Results', style: { fontSize: 14 } }}
          forceFit
        />
      </div>
    );
  };

  return (
    <Col span={24}>
      {groupedPollArray.map((pollGroup, index) => (
        <Card key={index} title={`Poll Question: ${pollGroup[0].question}`} bordered={false}>
          <PollResultsChart pollData={pollGroup} />
        </Card>
      ))}
    </Col>
  );
};

export default PollResultsCarousel;
