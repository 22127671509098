import React, { useState, useEffect } from 'react';
import logo from '../assets/images/Logo.png';
import { useParams, Link, useNavigate,useLocation } from 'react-router-dom';
import { Button, Typography, Input, Form, message, Col, Space, Row, Card, Divider } from 'antd';
import { AppstoreOutlined, DollarCircleOutlined } from '@ant-design/icons';

 
import axios from 'axios';
import { decodeToken } from "react-jwt";
import Login from './User/Login';
import GoogleUserLogin from './User/GoogleUserLogin'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import Header from './Layout/Header';
import HeroHome from './HeroHome';
import BenefitHome from './BenefitHome ';
import FeatureHome from './FeatureHome';

const { Title } = Typography;
const { success, error, warning } = message;

const HomePage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.success('Request submitted successfully. Someone from our team will get back to you shortly.');
  };

  const error = () => {
    messageApi.error('Your request could not be submitted. Please try again.');
  };

  

  // const onFinish = (values) => {
  //   const payload = {
  //     email: values.email,
  //   };

  //   emailjs.send('service_vv81mh9', 'template_hc24sbk',payload, '7pLLTRzU9sBM6pSD5')
  //     .then(
  //       (result) => {
  //         success();
  //       },
  //       (error) => {
  //         error();
  //       }
  //     );
  // };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const location = useLocation();

  const formContainerStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white', // Replace with theme background color
  };

  const formTitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333', // Replace with theme text color
  };

  const inputFieldStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    marginBottom: '20px',
    border: '1px solid #ccc', // Replace with theme border color
    borderRadius: '4px',
    backgroundColor: '#fff', // Replace with theme background color
    color: '#333', // Replace with theme text color
  };

  const buttonStyle = {
    width: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff', // Replace with theme text color
    background: '#007bff', // Replace with theme primary color
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = async () => {
    try {
    const result=  await Login(formData.email, formData.password);
      
      if (result && result.code === 200) {

      

          const accessToken = localStorage.getItem("user_access_token");
          // Decode the token
          const decodeTokens = decodeToken(accessToken);
          
          // Access token properties
          const { sub, company, scope, ROLE, exp } = decodeTokens;
          
          // Now you can use these properties as needed
          // console.log(sub); // "testmoe@idealy.com"
          // console.log(company); // 1
          // console.log(scope); // "product:read product:update ..."
          // console.log(ROLE); // "role:admin"
          // console.log(exp); // Token expiration timestamp
 
          // if (ROLE === 'role:admin' || ROLE=== 'role:user') {
          //   // Redirect to "/product-list" for Admin or User roles
          //   window.location.href = "/product-list";
          // } else if (ROLE === 'role:guest') {
          //   // Redirect to "/idea-lounge" for other roles
          //   window.location.href = "/idea-lounge";
          // }
          window.location.href = "/product-list";
      }
      // if (error.code === 401) {
      //   // Unauthorized - Invalid credentials
      //   // alert("Invalid credentials. Please check your email and password.");
      // } else {
      //   // Other errors
      //   alert("Login failed. Please try again.");
      // }

      // Assuming Login is successful, proceed to /product-list
    
    } 
    

    catch (error) {
      console.error(error);
 
 
    }
  };
  
  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const company = searchParams.get('company');
      const result=  await GoogleUserLogin(credentialResponse,company);
        
        if (result && result.code === 200) {
  
        
  
            const accessToken = localStorage.getItem("user_access_token");
            // Decode the token
            const decodeTokens = decodeToken(accessToken);
            
            // Access token properties
            const { sub, company, scope, ROLE, exp } = decodeTokens;
            
            // Now you can use these properties as needed
            // console.log(sub); // "testmoe@idealy.com"
            // console.log(company); // 1
            // console.log(scope); // "product:read product:update ..."
            // console.log(ROLE); // "role:admin"
            // console.log(exp); // Token expiration timestamp
   
            if (ROLE === 'role:admin' || ROLE=== 'role:user') {
              // Redirect to "/product-list" for Admin or User roles
              window.location.href = "/product-list";
            } else if (ROLE === 'role:guest') {
              // Redirect to "/idea-lounge" for other roles
              window.location.href = "/idea-lounge";
            }
  
        }
        // if (error.code === 401) {
        //   // Unauthorized - Invalid credentials
        //   // alert("Invalid credentials. Please check your email and password.");
        // } else {
        //   // Other errors
        //   alert("Login failed. Please try again.");
        // }
  
        // Assuming Login is successful, proceed to /product-list
      
      } 
      
  
      catch (error) {
        console.error(error);
   
   
      }
  
  };

  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};

const handleCardClick = (path) => {
  switch (path) {
    case 'research':
      window.location.href = '/login?type=Research';
      break;
    case 'reward':
      window.location.href = '/Reward/Login';
      break;
    default:
      // Handle other cases if needed
      break;
  }
};

return (
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Link to={'/'}>
    <img src={logo} alt="Logo" style={{ paddingTop: '10px', paddingBottom: '5px' }} />
  </Link>

  <div style={{ padding: '20px', width: '100%' }}>
    <Row gutter={[16, 16]} justify="center" align="center">
      <Col xs={24} sm={12} md={8} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
        <h6>Welcome to IDEALY, a Product Research community that offers rewards in exchange for feedback!</h6>
      </Col>
    </Row>
<Divider/>
    <Row gutter={[16, 16]} justify="center" align="center">
      <Col xs={24} sm={12} md={8}>
        <h6 className="h6-sm">
          CHOOSE YOUR PATH
        </h6>
        <h3 className="h3-sm">
          What are you looking to do?
        </h3>
      </Col>
    </Row>
    {/* Card 1: Evaluate UserTesting for my company */}
    <Row gutter={[16, 16]} justify="center" align="center">
      <Col xs={24} sm={12} md={8} className="p-xl">
        <Card
          hoverable
          cover={<AppstoreOutlined style={{ fontSize: '48px', color: '#627dc9' }} />}
          onClick={() => handleCardClick('research')}
          style={{
            padding: '20px',
            textAlign: 'center',
            border: '1px solid #7196c4',  // Border color for a button-like appearance
            borderRadius: '10px',         // Border radius for rounded corners
            cursor: 'pointer',           // Change cursor on hover
             
          }}
        >
        <h6 className="h6-sm"  >  I want Feedback on my Ideas & Products.</h6>
        </Card>
      </Col>

      {/* Card 2: Make money online */}
      <Col xs={24} sm={12} md={8} className="p-xl">
        <Card
          hoverable
          cover={<DollarCircleOutlined style={{ fontSize: '48px', color: '#FFD700' }} />}
          onClick={() => handleCardClick('reward')}
          style={{
            padding: '20px',
            textAlign: 'center',
            border: '1px solid #7196c4',  // Border color for a button-like appearance
            borderRadius: '10px',         // Border radius for rounded corners
            cursor: 'pointer',           // Change cursor on hover
             
          }}
        >
           <h6 className="h6-sm"  > I want to earn Rewards online.</h6>
          
        </Card>
      </Col>
    </Row>
  </div>
</div>
);
};
;

export default HomePage;
