import React from 'react';
import { Col, Card, Avatar, Space } from 'antd';
import { Bar } from '@ant-design/plots';

const PersonaChart = ({ filteredPersonaData }) => {
  // Calculate counts for each persona
  const personaCounts = {};
  filteredPersonaData.forEach((dataPoint) => {
    const { personaId, userId, name } = dataPoint;
    if (!personaCounts[personaId]) {
      personaCounts[personaId] = { name, count: 0 };
    }
    personaCounts[personaId].count += 1;
  });

  // Convert counts to an array for the chart
  const chartData = Object.values(personaCounts);

  // Ant Design Chart configuration
  const chartConfig = {
    data: chartData,
    xField: 'name',
    yField: 'count',
    seriesField: 'name',
    legend: { position: 'right-top' },
    label: { position: 'middle', layout: [{ type: 'interval-hide-overlap' }] },
    xAxis: { title: { text: 'Personas' } },
    yAxis: { title: { text: 'User Count' } },
    title: { text: 'Persona User Count', position: 'top' },
  };

  return (
    <Col span={8}>
      <Card title="Personas" bordered={false}>
        
        <Space direction="vertical" style={{ marginTop: '16px' }}>
          {chartData.map((persona) => (
            <div key={persona.name}>
         

              <Avatar src={`https://api.dicebear.com/7.x/personas/svg?seed=${persona.name.charAt(0)}`} />

              <span style={{ marginLeft: '8px' }}>{persona.name}</span>
              <span style={{ marginLeft: '8px' }}>{persona.count}</span>
            </div>
          ))}
        </Space>
      </Card>
    </Col>
  );
};

export default PersonaChart;
