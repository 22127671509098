import { List, Space, Tag } from 'antd';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const UserIdeas = ({user_id,product_id}) => {

   
  const [data,getData]=useState('');

  const getAllData = ()=> {
    var user_access_token = localStorage.getItem("user_access_token");
    // For message data
   axios.get(  process.env.REACT_APP_API_URL +"user/"+user_id+"/ideas",{ 
    headers: {
      Authorization: "Bearer "+ user_access_token,
    }})
  .then(response => {
  const allData =response.data; 
 getData(allData);

  
  }) .catch(error => console.error(error));
  
  }

  useEffect(()=> {getAllData();  
 

  
  },[user_id]);

 

    const IconText = ({ icon, text }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );

  return (
     <>
    <div><Tag color="blue">Ideas ({data.length})</Tag> </div>
     <List
     itemLayout="vertical"
     dataSource={data}
     renderItem={(item) => (
       <List.Item
      
     
       >
 
       
         <List.Item.Meta
           title= {<Link to={`/idea-details/${product_id}/${item.ideaId}`} >{item.title}</Link>}
         />
  Submitted: {item.dateCreated}  
       </List.Item>   
     )} 
   />  
 </>
   );

     

 };
 export default UserIdeas;