import React, { useState } from 'react';
import { Form, Input, Select, Button, Card, Space, List } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const SurveyForm = ({ onCreateSurvey }) => {
  const [form] = Form.useForm();
  const [questions, setQuestions] = useState([]);

  const templates = {
    NPS: [
      { question: 'On a scale of 0 to 10, how likely are you to recommend our business to a friend or colleague?', type: 'Rank' },
      { question: 'What is the primary reason for your score?', type: 'Open Question' },
      { question: 'What\'s the one thing we could do to make [PRODUCT] better?', type: 'Open Question' },
      { question: 'Which features do you use the most?', type: 'MultiChoice' },
      { question: 'Is anything missing from your experience?', type: 'Yes/No' },
      { question: 'Any further thoughts or feedback you\'d like to note?', type: 'Open Question' },
    ],
    'Feature-Specific': [
      { question: 'How was your [Feature] experience?', type: 'Opinion Scale' },
      { question: 'How satisfied are you with [Feature]?', type: 'Opinion Scale' },
      { question: 'How was your overall experience with [Feature]?', type: 'Opinion Scale' },
      { question: 'Any problems accessing/setting up [Feature]?', type: 'Yes/No' },
      { question: 'Did the [Feature] work as expected?', type: 'Yes/No' },
      { question: 'What didn\'t work as expected?', type: 'Open Question' },
      { question: 'Will you continue using [Feature]?', type: 'Yes/No' },
      { question: 'Why?', type: 'Open Question' },
      { question: 'Do you have any other thoughts/feedback on [Feature]?', type: 'Open Question' },
    ],
    'Packaging Survey': [
      { question: 'Based on what you saw, what do you think this product offers?', type: 'Open Question' },
      { question: 'What was your impression of the design?', type: 'Opinion Scale' },
      { question: 'What did you like best/least about the design?', type: 'Open Question' },
      { question: 'What is the main thing you can recall?', type: 'Open Question' },
    ],
    'Customer Messaging': [
      { question: 'Which of the images do you prefer?', type: 'Multiple Choice' },
      { question: 'Why?', type: 'Open Question' },
      { question: 'How well do you understand what is required of you on this page?', type: 'Opinion Scale' },
      { question: 'How clear was the information on this page to you?', type: 'Opinion Scale' },
      { question: 'What do you like about how this page looks?', type: 'Open Question' },
    ],
    Concepts: [
      { question: 'What was your first impression of Concept A?', type: 'Opinion Scale' },
      { question: 'What are your favorite aspects of Concept A?', type: 'Open Question' },
      { question: 'What are your least favorite aspects of Concept A?', type: 'Open Question' },
      { question: 'How often would you use Concept A?', type: 'Multiple Choice' },
      { question: 'How much would you pay for Concept A?', type: 'Simple Input' },
    ],
    Discovery: [
      { question: 'Please explain the problem you face when [scenario or problem space for product]?', type: 'Open Question' },
      { question: 'What are the implications if you fail to solve the problem?', type: 'Open Question' },
      { question: 'What have you tried so far?', type: 'Open Question' },
      { question: 'What’s missing from what you’ve tried already?', type: 'Open Question' },
      { question: 'How much are you currently spending to solve the problem?', type: 'Simple Input' },
      { question: 'In your experience of this challenge, what would be the key area you\'d look to alleviate first?', type: 'Open Question' },
      { question: 'Is there anything else you\'d consider important to know?', type: 'Open Question' },
    ],
    'Customer Job to be Done': [
      { question: 'What are your three most important activities?', type: 'Multiple Choice' },
      { question: 'What are the activities you spent the most amount of time on [last week]?', type: 'Open Question' },
      { question: 'What type of work-arounds have you created to help you with this?', type: 'Open Question' },
      { question: 'Which of the activities do you find add the most value, despite how much time they may take you to complete, and why?', type: 'Open Question' },
      { question: 'What could be better about how you do this?', type: 'Open Question' },
    ],
    'Marketing Messaging': [
      { question: '[Enter your first messaging claim here] (Context Screen)', type: 'Multiple Choice' },
      { question: 'Thinking about the claim overall, which of the following best describes your feelings about it?', type: 'Multiple Choice' },
      { question: 'How unique is the claim?', type: 'Opinion Scale' },
      { question: 'How relevant is the claim to your wants and needs?', type: 'Opinion Scale' },
      { question: 'In your own words, what is the message of the claim?', type: 'Open Question' },
      { question: '[Enter your second/third messaging claim here] (Context Screen)', type: 'Multiple Choice' },
      { question: 'How relevant is the claim to your wants and needs?', type: 'Opinion Scale' },
      { question: 'Thinking about the claim overall, which of the following best describes your feelings about it?', type: 'Multiple Choice' },
      { question: 'Does the claim provide too much information, too little information, or the right amount of information?', type: 'Multiple Choice' },
    ],
    'Product Naming': [
      { question: 'Take a look at the image (Context Screen)', type: 'Context Screen' },
      { question: 'Try and give this feature a descriptive name, based on what it does.', type: 'Simple Input' },
      { question: 'Do you have another idea for a name? (Yes/No)', type: 'Yes/No' },
      { question: 'Give this feature another name (Simple Input)', type: 'Simple Input' },
      { question: 'Do you have another idea for a name? (Yes/No)', type: 'Yes/No' },
      { question: 'Give this feature another name (Simple Input)', type: 'Simple Input' },
    ],
    'Validate Product Naming': [
      { question: 'Here\'s an image from an app that lets users [send and request money]. (Context Screen)', type: 'Context Screen' },
      { question: 'What do you think [feature] lets you do?', type: 'Open Question' },
      { question: 'Take a look at the updated image with the [feature] now open. (Context Screen)', type: 'Context Screen' },
      { question: 'What do you think the feature lets you do, given this new information?', type: 'Open Question' },
    ],
    'Pricing Sensitivity Survey': [
      { question: 'What\'s your role?', type: 'Multiple Choice' },
      { question: 'Are you currently a paying customer of [Product]?', type: 'Yes/No' },
      { question: 'Which plan are you on?', type: 'Multiple Choice' },
      { question: 'What\'s [Product] worth? (Context Screen)', type: 'Context Screen' },
      { question: 'At what point is [Product] so cheap you wouldn’t consider it a quality product?', type: 'Simple Input' },
      { question: 'At what point would you consider [Product] a bargain?', type: 'Simple Input' },
      { question: 'At what price point is [Product] too expensive?', type: 'Simple Input' },
      { question: 'At what price point is [Product] so expensive that you wouldn\'t purchase it?', type: 'Simple Input' },
      { question: 'When thinking about [Product]\'s price point, what other product and service\'s comes to mind as a comparison?', type: 'Simple Input' },
      { question: 'What do you think of our current pricing?', type: 'Opinion Scale' },
      { question: 'Can you please explain your rating?', type: 'Open Question' },
      { question: 'Anything else you\'d like to add regarding our pricing?', type: 'Open Question' },
    ],
  };

  const handleTemplateChange = (template) => {
    setQuestions(templates[template] || []);
  };

  const handleAddQuestion = () => {
    if (questions.length < 5) {
      setQuestions([...questions, 'New Question']);
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = (values) => {
    const surveyData = {
      title: values.title,
      status: values.status,
      questions: JSON.stringify(questions),
    };

    // Send surveyData to the backend
    console.log('Survey Data:', surveyData);
    onCreateSurvey(surveyData);
  };

  return (
    <Card title="Create Survey" style={{ margin: 'auto', marginTop: 20, padding: '10px' }}>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter a title' }]} style={{ margin: 16 }}>
          <Input />
        </Form.Item>

        <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Please select a status' }]} style={{ margin: 16 }}>
          <Select placeholder="Select Status">
            <Option value={1}>Active</Option>
            <Option value={0}>Inactive</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Select Template" name="template" style={{ margin: 16 }}>
          <Select placeholder="Select Template" onChange={handleTemplateChange}>
            {Object.keys(templates).map((template) => (
              <Option key={template} value={template}>
                {template}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Survey Questions" shouldUpdate style={{ margin: 16 }}>
          {() => (
            <>
              <List
                dataSource={questions}
                renderItem={(item, index) => (
                  <List.Item>
                    <Space>
                      <Form.Item name={['questions', index, 'question']} initialValue={item.question} noStyle>
                        <Input placeholder="Enter Question" />
                      </Form.Item>
                      <Form.Item name={['questions', index, 'type']} initialValue={item.type} noStyle>
                        <Select style={{ width: 120 }} placeholder="Type">
                          <Option value="Open">Open Question</Option>
                          <Option value="Yes/No">Yes/No</Option>
                          <Option value="Scale">Opinion Scale</Option>
                          <Option value="Multiple">Multi Choice</Option>
                        </Select>
                      </Form.Item>
                    </Space>
                    <MinusCircleOutlined onClick={() => handleRemoveQuestion(index)} />
                  </List.Item>
                )}
              />
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={handleAddQuestion}
                  icon={<PlusOutlined />}
                  disabled={questions.length >= 5}
                >
                  Add Question
                </Button>
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit Survey
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SurveyForm;