import React, { useState } from 'react';
import { Button, Input, Form, Select, Typography, Divider } from 'antd';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import '../index.css';
import { isExpired, decodeToken } from "react-jwt";

const { Title } = Typography;
const { Option } = Select;

const NewSignup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [regPassword, setRegPassword] = useState('');

  const industryOptions = ['Automobile', 'Consumer', 'E-commerce', 'Education', 'Electronics', 'Entertainment', 'Fashion', 'Finance', 'Food & Beverage', 'Gaming', 'Healthcare', 'Human Resources', 'Marketing', 'Real Estate', 'Software', 'Sports', 'Telecommunications', 'Travel', 'Other'];

  const handleRegisterClick = () => {

 

    const payload = {
      name: firstName,
      lastname: lastName,
      nickname:firstName,
      idcompany: companyName,
      industry: industry,
      email: regEmail,
      password: regPassword,
      new:1,
      userType:"Admin"
    }

 

    axios.post(process.env.REACT_APP_API_URL + 'user', payload)
      .then(function (res) {
      
        if (res.status === 200) {
          toast.success("Account registered successfully");
          const payload = {
            "email" : regEmail,
            "password" : regPassword,
          }
    
          axios.post(process.env.REACT_APP_API_URL + 'user/authenticate', payload)
            .then(function (res) {
              if(res.status === 200){
                // matain our session
                const decodedToken = decodeToken(res.data.idToken);
                localStorage.setItem("user", JSON.stringify(decodedToken));
                localStorage.setItem("user_access_token", res.data.accessToken);
                const decodedAccesstoken = decodeToken(res.data.accessToken);
              //  Get user role from access token 
                if(decodedAccesstoken && decodedAccesstoken.ROLE){
                  const getRole = decodedAccesstoken.ROLE;
                  const roleData = getRole.split(":");
                  localStorage.setItem("user_role",roleData[1]);
                }
               
                window.location.href = "/Research/Product";
                }else {
                  // toast.error("Something went worng");
                  window.location.href = "/"
                }
            })
            .catch(function (error) {
              console.log(error);
    
            });

        } else {
          // toast.error("Something went wrong");
          // window.location.href = "/"
        }
      })
      .catch(function (error) {
        console.log(error);
        // toast.error("Something went wrong. Please try again!!");
        // window.location.href = "/"
      });
  }

  const formContainerStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white', // Replace with theme background color
  };

  const formTitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333', // Replace with theme text color
  };

  const inputFieldStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    marginBottom: '20px',
    border: '1px solid #ccc', // Replace with theme border color
    borderRadius: '4px',
    backgroundColor: '#fff', // Replace with theme background color
    color: '#333', // Replace with theme text color
  };

  const buttonStyle = {
    width: '100%',
 
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff', // Replace with theme text color
    background: '#007bff', // Replace with theme primary color
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };
  const selectStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    marginBottom: '20px',
    border: '1px solid #ccc', // Replace with theme border color
    borderRadius: '4px',
    backgroundColor: '#fff', // Replace with theme background color
    color: '#333', // Replace with theme text color
    // Additional styles for the Select component
    display: 'block',
    minWidth: '150px',
  };
  return (
    <div style={formContainerStyle}>
       <Header/>

      <div className="form-container">
        <h3>Sign up for a Product Research account</h3>
        <p>No Credit Card Required</p>
        <Form layout="vertical">
          <Form.Item label="First Name:">
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={inputFieldStyle}
              placeholder="Joe"
            />
          </Form.Item>

          <Form.Item label="Last Name:">
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={inputFieldStyle}
              placeholder="Doe"
            />
          </Form.Item>

       
          <Form.Item label="Email:">
            <Input
              value={regEmail}
              onChange={(e) => setRegEmail(e.target.value)}
              style={inputFieldStyle}
              placeholder="mail@address.com"
            />
          </Form.Item>

          <Form.Item label="Choose a Password:">
            <Input
              type="password"
              value={regPassword}
              onChange={(e) => setRegPassword(e.target.value)}
              style={inputFieldStyle}
              placeholder="*********"
            />

          </Form.Item>
          <Form.Item label="Company Name:">
            <Input
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              style={inputFieldStyle}
              placeholder="ABC Company"
            />
          </Form.Item>
 
           <Divider/>
          <Form.Item label="Industry:">
            <Select
              value={industry}
              onChange={(value) => setIndustry(value)}
              style={selectStyle}
              placeholder="Select your industry"
              dropdownMatchSelectWidth={false} // Prevent dropdown from shrinking
            >
              {industryOptions.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
           


          <Button
            type="primary"
            onClick={handleRegisterClick}
            style={buttonStyle}
          >
            Signup
          </Button>
          <p>
            By clicking "Sign Up" button, I expressly agree to the IDEALY's
            <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>
            {' '}
            and understand that my account information will be used according to IDEALY's
            <a href="/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
          </p>
        </Form>
        <p>Already have an account? <a href="/login">Sign in</a>.</p>
      </div>
    </div>
  );
}

export default NewSignup;
