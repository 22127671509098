import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {   Input, List, Modal, Space,Col } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AddMetrics = () => {
  const [metrics, setMetrics] = useState([]);
  const [newMetric, setNewMetric] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editedMetric, setEditedMetric] = useState('');
  const [editedMetricId, setEditedMetricId] = useState('');

  const user_access_token = localStorage.getItem('user_access_token');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchMetrics();
  }, []);

  const fetchMetrics = () => {
    axios
      .get(`${apiUrl}product/metric`, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        setMetrics(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addMetric = () => {
    const payload = { ProductMetric: newMetric };

  

    axios
      .post(`${apiUrl}product/metric`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchMetrics();
        setNewMetric('');
      
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeMetric = (metricId) => {
    axios
      .delete(`${apiUrl}product/metric/${metricId}`, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchMetrics();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editMetric = (metricId, metricName) => {
    setEditedMetric(metricName);
    setEditedMetricId(metricId);
    setEditModalVisible(true);
  };

  const handleEditOk = () => {
    const payload = { name: editedMetric };

    axios
      .put(`${apiUrl}product/metric/${editedMetricId}`, payload, {
        headers: {
          Authorization: `Bearer ${user_access_token}`,
        },
      })
      .then((response) => {
        fetchMetrics();
        setEditModalVisible(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setEditedMetric('');
    setEditedMetricId('');
  };

  return (
    <div>  
      <h4>North Star Metrics</h4>
      <p>Enhance your innovation strategy by defining Key Metrics crucial to your product's success. With defined metrics, IDEALY aligns each IDEA to your configured metric, providing valuable insights on which IDEAs are relevant to your important metrics, empowering data-driven decision-making for overall product success."
        </p>
      <List
        dataSource={metrics}
        renderItem={(metric) => (
          <List.Item
            actions={[
              <button
                type="primary"
                danger
                onClick={() => removeMetric(metric.id)}
                icon={<DeleteOutlined />}
              />,
              <button
                type="primary"
                onClick={() => editMetric(metric.id, metric.productMetric)}
                icon={<EditOutlined />}
              />,
            ]}
          >
            {metric.productMetric}
          </List.Item>
        )}
      />
      <Space>
        <Input
          type="text"
          value={newMetric}
          onChange={(e) => setNewMetric(e.target.value)}
        />
        <button type="primary" onClick={addMetric} icon={<PlusOutlined />}>
 
        </button>
      </Space>

      <Modal
        title="Edit Metric"
        visible={editModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      >
        <Input
          type="text"
          value={editedMetric}
          onChange={(e) => setEditedMetric(e.target.value)}
        />
      </Modal> 
    </div>
  );
};

export default AddMetrics;
