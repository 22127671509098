import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LogoutOutlined,AppstoreAddOutlined  } from '@ant-design/icons';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Avatar,   Col, Divider, Input, Layout, Menu, Modal, Row, Space, Tabs, Tooltip, message, Dropdown } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { decodeToken } from "react-jwt";
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/Logo.png';
import pointsImage from '../../assets/images/points.png';
import membership from '../../assets/images/rank1.png';
import rank12 from '../../assets/images/rank12.png';
import rank2 from '../../assets/images/rank2.png';
import CircularProgressBar from '../IdeaLounge/ProfileProgressBar';
import GoogleUserLogin from '../User/GoogleUserLogin';
import Login from '../User/Login';
import Signup from '../User/Signup';
import './index.css';
import PopAnimation from '../Engagement/PopAnimation';
import NewFeature from '../Idea/NewFeature';

const { Header, Content } = Layout;
const { success, error, warning } = message;
const {TabPane}=Tabs;

 
const UserHeader2 = ( {activity, collapsed, toggleSider }) => {
  const [clientId, setClientId]=useState('');
  const [clientSecret, setClientSecret]=useState('');
 
  const [role, setRole] = useState("guest");
  const [points, setPoints] = useState("0");
  const [level, setLevel] = useState("Rookie");
  const [company, setCompany] = useState("guest");
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const [current, setCurrent] = useState('mail');

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
 

  useEffect(() => {
    // Check token expiration on component mount
    const  User = JSON.parse(localStorage.getItem('user'));
    console.log("user",User);
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    // Set company to 0 if it's not present in the URL
    const companyValue = company || 0;
    setCompany(companyValue);
    getBadge();
    if (User) {
    setCurrentUser(User);
    checkTokenExpiration();
    decodeAccess();
    // Set up a timer to periodically check token expiration
    const tokenCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute
    
    // Cleanup the interval on component unmount
    return () => clearInterval(tokenCheckInterval);}
  }, []);
  

  useEffect(() => {
console.log("points changing",activity );
    getBadge(); 

  }, [activity]);


  const checkTokenExpiration = () => {
    // Retrieve the stored token from localStorage
    const storedToken = JSON.parse(localStorage.getItem('user'));
  
    // Check if the token is present and not expired
    if (storedToken && !isTokenExpired(storedToken)) {
      // Token is valid, user is authenticated
      console.log("Token is valid. User is authenticated.");
      return;
    }
  
    // Token is either not present or expired, log the user out and redirect to login page
    handleLogout();
  };
  
  // Function to logout the user
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();
    window.location.reload(); // Redirect to login page
  };
  
  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      console.log('User IP Address:', data.ip);
      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
  
  const getBadge = async () => {
    try {


      const userAccessToken = localStorage.getItem('user_access_token');

      const response = await axios.get(process.env.REACT_APP_API_URL +`user/badge`,{
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
       console.log("badhe",response.data.level)
      setPoints(response.data.points);
      setLevel(response.data.level);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
  
  const getApiToken = async (clientId,clientSecret,username) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL +`user/web_token`, {
        clientId,
        clientSecret,
        username,
      });

      console.log("token",response.data.accessToken )
     
    return  response.data;



     
    } catch (error) {
      console.error(error);
      message.error('Error occurred while getting the API token.');
    }
  };
  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    if (token && token.exp) {
      return token.exp * 1000 <= Date.now();
    }
    return true; // Token is considered expired if no expiration information is available
  };
  
  const decodeAccess=() => {

 
    const searchParams = new URLSearchParams(location.search);
    const company = searchParams.get('company');
    setCompany(company);

  }

 
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


 

 
  const handleGoogleLogin = async (credentialResponse) => {
    try {
     
   
      const result=  await GoogleUserLogin(credentialResponse,company);
        
        if (result && result.code === 200) {
  
        
  
            const accessToken = localStorage.getItem("user_access_token");
            // Decode the token
   
    
            window.location.reload();
  
        }
     
      
      } 
      
  
      catch (error) {
        console.error(error);
   
   
      }
  
  };


const handleLogin = async () => {
  try {
    const result = await Login(username, password);

    if (result && result.code === 200) {
      const accessToken = localStorage.getItem("user_access_token");
     
      window.location.reload();
    } else if (result && result.code === 401) {
      // Unauthorized - Invalid credentials
      alert("Invalid credentials. Please check your email and password.");
    } else {
      // Other errors
      alert("Login failed. Please try again.");
    }
  } catch (error) {
    console.error(error);
  }
};

 
const showModal=() => {

  setModalVisible(true);

}

  const handleContinueAsGuest = async (e) => {
    //http://localhost:3000/idea/5193/5295?ClientId=f3ffe8e1f804f2074aa5cc55233673b9&ClientSecret=9b3344bb2ec9fb643eecac8389d3001b&Email=headstart.essentials@gmail.com
    e.preventDefault();
 
    setModalVisible(false);

    const searchParams = new URLSearchParams(location.search);
    const clientId = searchParams.get('ClientId');
    setClientId(clientId);
    const clientSecret = searchParams.get('ClientSecret');
    setClientSecret(clientSecret);
    const Email = searchParams.get('Email');
    const token= await getApiToken(clientId,clientSecret,Email);
    localStorage.setItem("user_access_token", token.accessToken);
    window.location.reload();
  //   // http://localhost:3000/idea/1/1?ClientId=f3ffe8e1f804f2074aa5cc55233673b8&ClientSecret=9b3344bb2ec9fb643eecac8389d2521b

   
  // console.log("continue as new  token user", "user access", guestAccessToken)
  // triggerUpdate();

  };

  const handleSignupFormSubmit = async (e) => {
    e.preventDefault();
 
  const formData = new FormData(e.target);
  const email = formData.get('email');
  const password = formData.get('password');
  const name = formData.get('name');
  const lastName = formData.get('lastName');
  const nick = formData.get('nick');
  const searchParams = new URLSearchParams(location.search);
  let company = searchParams.get('company');

  // Check if company parameter is not present or empty
  if (!company) {
    company = 0;
  }
 

 
 

  const result = await Signup( name,lastName,nick, company, "", email, password,"Guest");
  
      if (result && result.code === 200) {

        const result=  await Login(email, password);
      
        if (result && result.code === 200)  
    {
      const accessToken = localStorage.getItem("user_access_token");
      console.log("continue as New User", accessToken)
 
      setModalVisible(false);
      window.location.reload();
       
    }

      }
 
  };

  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <UserOutlined />
        <Link to="/profile">Profile</Link>   
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined  />
        Logout
      </Menu.Item>
    </Menu>
  );

  
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };


 
 
return (
  <Layout.Header
    className={`welcome-header ${isMobile ? 'mobile-header' : ''}`}
    style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '100%',
    }}
  >
 

    {!currentUser ? (
      <Row align="middle" gutter={16}>
        <Col>
          <Row gutter={8} align="center">
            <Col span={8}>
              <Input
                prefix={<UserOutlined  />}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Col>
            <Col span={8}>
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
            <Col span={8}>
              <Space>
                <button type="submit" onClick={handleLogin} className="login-button">
                  Login
                </button>
                {/* <button type="submit" onClick={showModal} className="signup-button">
                  New Signup
                </button> */}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    ) : (
<Row   span={isMobile ? 24 : 20} justify="space-between" >
 
<Row gutter={15} justify="space-between">
  <Link to={'/'} style={{ marginRight: '20px' }}>
    {/* <img src={logo} alt="Logo" /> */}
    IDEALY HOME
  </Link>



  <Link to="/Research/Product">
    <AppstoreAddOutlined /> My Products
  </Link>

  {/* Icon linked to "/Research/Product" */}
  <Link to="https://idealystorage.blob.core.windows.net/docs/User Guide.pdf">
    <AppstoreAddOutlined /> User Guide
  </Link>

  <Divider type='vertical'/>
 <NewFeature  />
</Row>



  <Dropdown overlay={menu} placement="bottomRight">
    <Avatar src={`https://ui-avatars.com/api/name=${currentUser.name}&background=random`} />
  </Dropdown>
</Row>

    )}

  
  </Layout.Header>
);

};

export default UserHeader2;
