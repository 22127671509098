import React from 'react';

 const categories  = [
    {
      name: "Apparel",
      attributes: [
        "Organic Cotton",
        "Fair Trade",
        "Vegan",
        "Sustainable",
        "Ethical Manufacturing",
        "Recyclable Packaging"
      ]
    },
    {
      name: "Toys",
      attributes: [
        "BPA-Free",
        "Eco-Friendly Materials",
        "Fair Trade (for handmade or artisanal toys)",
        "Non-Toxic",
        "Child Labor-Free"
      ]
    },
    {
      name: "Food and Beverage",
      attributes: [
        "Organic",
        "Gluten-Free",
        "Dairy-Free",
        "Sugar-Free",
        "Keto",
        "Vegan",
        "Kosher",
        "Halal",
        "Non-GMO",
        "Plant-Based",
        "Fair Trade",
        "Locally Sourced",
        "Paleo",
        "Allergen-Free",
        "No Artificial Preservatives"
      ]
    },
    {
      name: "Electronics",
      attributes: [
        "Eco-Friendly Materials",
        "Energy-Efficient",
        "Recyclable Components",
        "RoHS (Restriction of Hazardous Substances) Compliance",
        "Carbon-Neutral",
        "Ethical Supply Chain"
      ]
    },
    {
      name: "Beauty and Personal Care",
      attributes: [
        "Cruelty-Free",
        "Vegan",
        "Organic",
        "No Animal Testing",
        "Non-Toxic",
        "Sustainable Packaging",
        "Fair Trade (for ingredients sourcing)",
        "Paraben-Free"
      ]
    },
    {
      name: "Home and Furniture",
      attributes: [
        "Sustainable Materials",
        "Eco-Friendly Manufacturing",
        "FSC Certified Wood (Forest Stewardship Council)",
        "Recycled Content",
        "Energy-Efficient Appliances"
      ]
    },
    {
      name: "Sporting Goods",
      attributes: [
        "Eco-Friendly Materials",
        "Fair Trade (for equipment production)",
        "Recyclable Packaging",
        "Carbon-Neutral Manufacturing"
      ]
    },
    {
      name: "Pet Products",
      attributes: [
        "Grain-Free",
        "Organic",
        "Sustainable Packaging",
        "Cruelty-Free (for pet care products)",
        "Ethical Sourcing (for pet food ingredients)"
      ]
    },
    {
      name: "Automotive",
      attributes: [
        "Electric or Hybrid Vehicles",
        "Recyclable Materials in Manufacturing",
        "Carbon-Neutral Practices",
        "Energy-Efficient Models"
      ]
    },
    {
      name: "Office Supplies",
      attributes: [
        "Recycled Paper",
        "Sustainable Packaging",
        "Eco-Friendly Inks",
        "Forest Stewardship Council (FSC) Certification for Paper"
      ]
    },
    {
      name: "Travel and Luggage",
      attributes: [
        "Eco-Friendly Materials",
        "Recyclable Suitcases",
        "Carbon-Neutral Travel Options",
        "Fair Trade (for handmade or artisanal travel accessories)"
      ]
    },
    {
      name: "Home Appliances",
      attributes: [
        "Energy Star Certified",
        "Water-Efficient",
        "Recyclable Components",
        "Eco-Friendly Manufacturing Practices"
      ]
    },
    {
      name: "Health and Wellness",
      attributes: [
        "Organic Supplements",
        "Non-GMO Vitamins",
        "Cruelty-Free Health and Wellness Products",
        "Sustainable Packaging for Supplements"
      ]
    }
  ];
  
 
  

const getCategoryAttributes = (categoryName) => {
    // Find the category by name
    const foundCategory = categories.find(category => category.name === categoryName);
  
    // Return the attributes if the category is found, otherwise return an empty array
    return foundCategory ? foundCategory.attributes : [];
  };
 
  export default getCategoryAttributes