import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import {Breadcrumb, Button, Layout, Menu, theme, Space, Typography , Divider } from 'antd';
import problem from '../assets/images/problem.png';
import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const {  Content,  Sider } = Layout;

const Prioritize  = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    


    return (
       
        <Layout       style={{
            padding: '3px 0',
            background: colorBgContainer,
          }}>
   
<div>        < Header/></div>
      
         <Content>

           <Layout  id="featureTextBox"
             style={{
               padding: '24px 0',
               background: colorBgContainer,
             }}
           >
    
             <Content
               style={{
                 padding: '0 24px',
                 minHeight: 500,
               }}
             >
   

   <div className='hero-container'>
      <img src={problem} className='hero-background' alt='Background' />

 
    </div>
<br/>
<Space direction='vertical'  >

   <h3 className='feature'>AI Powered Decisions: Unleash the Power of Art combined with Science.</h3>
<Text  type="secondary">
Unleash the potential of your ideas with IDEALY's automated AI driven ranking, idea refinement and user stories. Our platform uses advanced data analytics and machine learning to identify and prioritize the most promising opportunities, empowering you to make informed decisions, maximize ROI, and drive innovation confidently. Say goodbye to subjective evaluations and harness the power of automated ranking for a more effective ideation process.
</Text>
 
<h3 className='feature'>Industry Standards Frameworks: Boost Confidence in Decision-Making.</h3>
<Text type="secondary" >
IDEALY elevates your decision-making process with industry-standard frameworks like R.I.C.E and the Kano Model. Assess ideas based on reach, impact, confidence, and effort with R.I.C.E, and identify customer delighters with the Kano Model. Make informed decisions, prioritize effectively, and drive innovation with confidence using IDEALY's industry-standard frameworks. Align your decisions with your business strategy and achieve successful outcomes.
</Text>


 
<h3 className='feature'>Strategic Alignment: Categorize and Score Ideas for Success. </h3>
<Text type="secondary" >
IDEALY provides the tools to categorize and score ideas based on strategic alignment, feasibility, and profitability. Assess how well ideas align with your business goals, evaluate practicality and potential financial gains, and make data-driven decisions. Streamline your evaluation process, prioritize strategically, and increase your chances of success with IDEALY.</Text>

 <br/>

</Space>



             </Content>
           </Layout>
         </Content>
         <div id="rcorners1"  className="h2white">

<h2>Don't let uncertainty and guesswork hold you back any longer. Try IDEALY for FREE.  </h2>  <Divider type="vertical" />
 
 <Button type='primary' href="/signup"  >TRY FOR FREE!</Button> 
</div>
         <Footer
           style={{
             textAlign: 'center',
           }}
         >
    
         </Footer>
       </Layout>

      
        
            );
    


                      }

export default Prioritize;
