import React from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import { Breadcrumb, Layout, Menu, theme, Space, Typography, Divider, Button, Row, Col } from 'antd';
import airank from '../assets/images/airank.png';
import voice1 from '../assets/images/voice1.png';
import productdevelopment from '../assets/images/productdevelopment.png';

import "react-toastify/dist/ReactToastify.css";
const { Title, Text } = Typography;
const { Content, Sider } = Layout;

const Feature = () => {
const {
token: { colorBgContainer },
} = theme.useToken();

return (
<Layout style={{ padding: '3px 0', background: colorBgContainer }}>
<Header />
<Content>
<Layout
id="featureTextBox"
style={{ padding: '24px 0', background: colorBgContainer }}
>
<Content style={{ padding: '0 24px', minHeight: 500 }}>
<div style={{ textAlign: 'center' }}>
<h3 className='h1-IdeaHub'>Consumer Product Development</h3>
<br />
</div>
<Row  >
  <Col span={24}>
     
      <img src={productdevelopment} alt="Easy Idea Intake Widget" />
      <h4 className="feature-title" style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>Revolutionize Consumer Product Development</h4>
      <Text type="secondary" className="feature-text" style={{ fontSize: "1.2rem" }}>
        IDEALY is here to revolutionize your consumer product development process. Whether you're a national brand or a small ecommerce retailer, our platform empowers you to easily identify and address your customers' needs, driving product assortment growth and customer delight.
      </Text>
     
  </Col>
</Row>
<Row  >
  <Col span={24}>
  <img src={voice1} alt="Easy Idea Intake Widget" />
      <h4 className="feature-title" style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>Give Your Customers a Voice</h4>
      <Text type="secondary" className="feature-text" style={{ fontSize: "1.2rem" }}>
        With IDEALY's simple widget, seamlessly embedded on your website's product page, checkout page, or even sent via email, you can give your customers a voice in shaping your product offerings. Crowdsource new ideas, validate customer demand, and tap into valuable insights to fuel your innovation pipeline.
      </Text>
   
  
  </Col>
</Row>
<Row  >
  <Col span={24}>
     
      <img src={airank} alt="Easy Idea Intake Widget"  />
      <h4 className="feature-title" style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>AI Powered Ranking</h4>
      <Text type="secondary" className="feature-text" style={{ fontSize: "1.2rem" }}>
        When it comes to prioritizing your customers' feedback, IDEALY's AI powered ranking system helps you focus on the most impactful ideas first.
      </Text>
    
  </Col>
</Row>
<Row  >
  <Col span={24}>
    <Space style={{ padding: "3%", alignItems: "start" }}>
      <h4 className="feature-title" style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>Engage and Ensure Data Privacy</h4>
      <Text type="secondary" className="feature-text" style={{ fontSize: "1.2rem" }}>
        But our commitment doesn't end there. Through gamification, we engage your customers and keep them informed about the status of their feedback with our notification and announcement features. Rest assured, we prioritize data privacy, requiring only non-personally identifiable information while offering super-friendly APIs for customization.
      </Text>
    </Space>
  </Col>
</Row>

      </Content>
    </Layout>
  </Content>
  <div id="rcorners1" className="h2white">
    <h2>Try IDEALY today and embark on a journey of continuous growth and customer delight.</h2>
    <Divider type="vertical" />
    <Button type='primary' href="/signup">TRY FOR FREE!</Button>
  </div>
  <Footer style={{ textAlign: 'center' }}>
  </Footer>
</Layout>
);
}

export default Feature;