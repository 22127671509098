import axios from 'axios';

const fetchIdea = (product_id) => {
  return new Promise((resolve, reject) => {


   
    const user_access_token = localStorage.getItem('user_access_token');

   axios.get(
      process.env.REACT_APP_API_URL + 'product/' + product_id + '/ideas',
      {
        headers: {
          Authorization: 'Bearer ' + user_access_token,
        },
      }
    )

    .then((response) => {
      const data = response.data;
      resolve(data); // Resolve the promise with fetched data
    })
    .catch((error) => {
      reject(error); // Reject the promise on error
    });
});
};



export default fetchIdea;
