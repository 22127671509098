import { BulbOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Checkbox, Form, Input, Modal, Space, Tour, Col, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef,useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const { success, error, warning } = message;

 
const NewFeature = () => {
  const [accessToken, setAccessToken] = useState('');
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [idea, setIdea] = useState('');
  const ref1 = useRef(null);
  
 
  useEffect(() => {


    }, []);


 

  const handleSubmit = async () => {
    try {
      const payload = {
        description: form.getFieldValue('desc')
      };

 

const clientId= process.env.REACT_APP_API_CLIENTID;
const clientSecret=process.env.REACT_APP_API_SECRET;
const username=process.env.REACT_APP_API_USER;
const product_id=process.env.REACT_APP_API_PRODUCT;
 
const Token = await axios.post(`https://idealynodeapi.azurewebsites.net/user/web_token`, {
  clientId,
  clientSecret,
  username,
});
 
const userAccessToken = Token.data.accessToken;

const response = await axios.post(`https://idealynodeapi.azurewebsites.net/product/${product_id}/idea`, payload, {
  headers: {
    Authorization: `Bearer ${userAccessToken}`,
  },
});

      if (response.status === 200) {
       
        message.success('Hurray! Your Feature Request is in!');
        hideModal();
      } else {
        setError('Something went wrong. Please try again!');
         
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 403) {
          setError('Oops! Looks like you do not have permissions to submit ideas.');
        } else if (error.response.status === 401) {
          setError('Oops! Please log in to submit ideas.');
        }
      } else {
        setError('Something went wrong. Please try again!');
      }
    }
  };

  const onChange = (e) => {
 
    setChecked(e.target.checked);
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setError('');
  };

  return (
    <>
    	{/* <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} /> */}
      {/* <PlusCircleFilled  title='New IDEA' style={{ fontSize: '30px' }}/> */}

      <Link onClick={showModal}>
      New Request
    </Link>

      {/* <Icon type="message" style={{ fontSize: '16px', color: '#08c' }} theme="outlined" /> */}


      <div>
      <Modal
        open={open}
        onOk={handleSubmit}
        onCancel={hideModal}
        okText="Submit"
        cancelText="Cancel"
        className="custom-modal"
      >


 
          <BulbOutlined className="bulb-icon" />
          <h3 className="idea-text"></h3>
         
    <Form form={form}  >
   
 
     
         
          <Form.Item name="desc" className="custom-textarea">
            <Input.TextArea
              
              showCount
             
              rows={4}
              placeholder="New Feature Request Details"
              value={idea}
              onChange={(e) => setIdea(e.target.value)}
            />
          </Form.Item>
        
          {error && <p className="error-message">{error}</p>}
        </Form>
      </Modal>
        </div>
    </>
  );
};
 

export default NewFeature;
