import { BulbOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Checkbox, Form, Input, Modal, Space, Tour,   Col, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef,useState } from 'react';
import { toast } from 'react-toastify';
 
const { success, error, warning } = message;
 
const LocalizedModal = ({ product_id ,refresh}) => {
  const [accessToken, setAccessToken] = useState('');
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [idea, setIdea] = useState('');
  const ref1 = useRef(null);
  
	const [openTour, setOpenTour] = useState(true);
  
	const steps = [
	 
	  {
		title: 'Add a new IDEA',
		description: 'Click to see other actions.',
		target: () => ref1.current,
	  },
  ];

  useEffect(() => {
    var accessToken = localStorage.getItem("user_access_token");
    setAccessToken(accessToken);
  }, []);

  const handleSubmit = async () => {
    try {
      const payload = {
        anonymous: checked,
        title: form.getFieldValue('title'),
        description: form.getFieldValue('desc'),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
       
        message.success('Hurray! Your Idea is in!');
        hideModal();
        refresh();
      } else {
        setError('Something went wrong. Please try again!');
         
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 403) {
          setError('Oops! Looks like you do not have permissions to submit ideas.');
        } else if (error.response.status === 401) {
          setError('Oops! Please log in to submit ideas.');
        }
      } else {
        setError('Something went wrong. Please try again!');
      }
    }
  };

  const onChange = (e) => {
 
    setChecked(e.target.checked);
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setError('');
  };

  return (
    <>
    	{/* <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} /> */}
      {/* <PlusCircleFilled  title='New IDEA' style={{ fontSize: '30px' }}/> */}

      <button htmlType="button" type='primary' onClick={showModal} >
				New IDEA
					</button>

      {/* <Icon type="message" style={{ fontSize: '16px', color: '#08c' }} theme="outlined" /> */}


      <div>
      <Modal
        open={open}
        onOk={handleSubmit}
        onCancel={hideModal}
        okText="Submit"
        cancelText="Cancel"
        className="custom-modal"
      >


 
          <BulbOutlined className="bulb-icon" />
          <h3 className="idea-text"></h3>
         
    <Form form={form}  >
   
 
     
         
          <Form.Item name="desc" className="custom-textarea">
            <Input.TextArea
              
              showCount
             
              rows={4}
              placeholder="Tell us more"
              value={idea}
              onChange={(e) => setIdea(e.target.value)}
            />
          </Form.Item>
        
          {error && <p className="error-message">{error}</p>}
        </Form>

 
   
 

        

       
      </Modal>
        </div>
    </>
  );
};

const CreateIdea = ({ product_id,refresh }) => {
  return (
    <>
      <Space>
        <LocalizedModal product_id={product_id} refresh={refresh}/>
      </Space>
    </>
  );
};

export default CreateIdea;
