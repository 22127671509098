import { HeartTwoTone } from '@ant-design/icons';
import { Layout, Space } from 'antd';
import React, { Fragment } from 'react';
import withRouter from '../../../withRouter';
import UserBadgeDonut from './UserBadgeDonut';
 
import UserDrawer from './UserDrawer';
import './user.css';

const ProductUser = ({product_id}) => {

    const { Content } = Layout;
 

    return (
 

       <Fragment>    
    <h3 className='h3-IdeaHub'> <HeartTwoTone  style={{ fontSize: '40px'}}/> USER INSIGHTS</h3>
<Space direction='horizontal'>


<Space direction='vertical' align='center'>

        <h4>Users Levels</h4>
        <UserBadgeDonut  product_id={product_id}/>


</Space>
<Space direction='vertical' align='center'>
            <h4>Product Users</h4>
               
<UserDrawer product_id={product_id}/>     
</Space>     
</Space>


 </Fragment>         
        
    ); 
 
}

export default withRouter(ProductUser);
// export default ProductDetail;



