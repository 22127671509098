import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminFeatureView from './AdminFeatureView';
import { Card, Modal, Form, Input, Row, Col, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const AddFeature = ({ product_id, idea_id }) => {
  const [featuresData, setFeaturesData] = useState([]);
  const [isFeatureModalVisible, setIsFeatureModalVisible] = useState(false);

  const handleOpenFeatureModal = () => {
    setIsFeatureModalVisible(true);
  };

  const handleCloseFeatureModal = () => {
    setIsFeatureModalVisible(false);
  };

  useEffect(() => {
    getFeaturesData();
  }, [product_id, idea_id]);

  const getFeaturesData = async () => {
    const userAccessToken = localStorage.getItem('user_access_token');
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/features`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );
  
      // Parse the features string to convert it into a JavaScript array
      const featuresArray = JSON.parse(response.data.features);
  
      // Check if featuresArray is an array before updating state
      if (Array.isArray(featuresArray)) {
        // Update the response object with the parsed features array
        const updatedResponse = {
          ...response.data,
          features: featuresArray,
        };
  
        setFeaturesData([updatedResponse]);
      } else {
        setFeaturesData([]);
      }
    } catch (error) {
      console.error('Error fetching features data:', error.message);
    }
  };
  
  

  const handleAddFeature = async (values) => {
    const payload = {
      title: values.featureTitle,
      status: values.status,
      features: JSON.stringify(values.features.map((name, id) => ({ id: id + 1, name }))),
    };

    console.log(payload);
   
    const userAccessToken = localStorage.getItem('user_access_token');
  

 

    try {
      const response =      await axios.post(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/features`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

      
  getFeaturesData();
      
      handleCloseFeatureModal();
    } catch (error) {
      console.error('Error adding feature:', error.message);
    }
  };

  return (
    <div>
      <Card title="Sorting Feature Card Panel" style={{ width: 400, margin: 'auto', marginTop: 20 }}>
        <p>
          <button type="primary" style={{ marginTop: 10 }} onClick={handleOpenFeatureModal}>
          Add a New Feature Card <PlusOutlined />
          </button>
        </p>

           {/* Display Feature Views */}
      {featuresData &&
        featuresData.map((feature) => (
          <AdminFeatureView key={feature.title} feature={feature}     product_id={product_id}
          idea_id={idea_id} />
        ))}

        {/* Feature Input Modal */}
        <Modal
          title="Create New Feature"
          visible={isFeatureModalVisible}
          onCancel={handleCloseFeatureModal}
          footer={null}
        >
          <Form onFinish={handleAddFeature}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Title"
                  name="featureTitle"
                  rules={[{ required: true, message: 'Please enter a feature title' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Status"
                  name="Status"
                  rules={[{ required: true, message: 'Please select a status' }]}
                >
                  <Select placeholder="Select Status">
                    <Option value="1">Active</Option>
                    <Option value="0">Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Features"
                  name="features"
                  rules={[{ required: true, message: 'Please enter features' }]}
                >
                  <Select mode="tags" style={{ width: '100%' }} placeholder="Enter features" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Form.Item>
                  <button type="primary" htmlType="submit">
                    Create Feature List
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Card>

   
    </div>
  );
};

export default AddFeature;
