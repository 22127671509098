import React from 'react';
import {  Card, Popconfirm } from 'antd';
import axios from 'axios';

const AdminFeatureView = ({ feature, product_id,idea_id }) => {
  // Function to handle feature deletion
  const handleDeleteFeature = async (featureId) => {
    // Implement feature deletion logic as needed
    console.log(`Deleting feature with ID ${featureId}`);
    
        const userAccessToken = localStorage.getItem('user_access_token');
    
        try {
          await axios.delete(
            process.env.REACT_APP_API_URL + `product/${product_id}/idea/${idea_id}/features/${featureId}`,
            {
              headers: {
                Authorization: `Bearer ${userAccessToken}`,
              },
            }
          );
    
        //   const updatedPolls = pollsData.filter((poll) => poll.pollId !== pollId);
        //   setPollsData(updatedPolls);
    
          console.log('Poll deleted successfully');
        } catch (error) {
          console.error('Error deleting poll:', error.message);
        }
  };

  // Function to handle toggling feature status (active/inactive)
  const handleToggleFeatureStatus = async (featureId) => {
    // Implement feature status toggling logic as needed
    console.log(`Toggling status for feature with ID ${featureId}`);
    const userAccessToken = localStorage.getItem('user_access_token');
    const payload = {status: feature.status}

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `product/${product_id}/idea/${idea_id}/features/${featureId}`, payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

    //   const updatedPolls = pollsData.filter((poll) => poll.pollId !== pollId);
    //   setPollsData(updatedPolls);

      console.log('Poll deleted successfully');
    } catch (error) {
      console.error('Error deleting poll:', error.message);
    }
  };

  return (
    <Card
      title={feature.title}
      extra={
        <>
         <Popconfirm
  title="Are you sure to delete this feature?"
  onConfirm={() => handleDeleteFeature(feature.idfeature)}
  okText="Yes"
  cancelText="No"
>
  <button style={{ marginRight: 8 }}>Delete</button>
</Popconfirm>
        
<button onClick={() => handleToggleFeatureStatus(feature.idfeature)}>
  {feature.status === 'active' ? 'Deactivate' : 'Activate'}
</button>

        </>
      }
      style={{ margin: 'auto', marginTop: 20 }}
      className="responsive-card"
    >
        {feature.features &&
        feature.features.map((subFeature) => (
          <div key={subFeature.id}>
            <p>{subFeature.name}</p>
            {/* Add other content for sub-features as needed */}
          </div>
        ))}
    </Card>
  );
};

export default AdminFeatureView;
