import React from 'react';
import { Card, Tag, Spin, Divider } from 'antd';
import { SmileOutlined, FrownOutlined, MehOutlined } from '@ant-design/icons';
import IdeaSentimentDonut from './IdeaSentimentDonut';

const SentimentCard = ({
  handleGetSentiment,
  
  sentimentData,
  positive,
  negative,
  neutral,
}) => {
  return (
    <Card
      title={
        <>
          Consumer Sentiment.{' '}
          <a href="#" onClick={handleGetSentiment} style={{ textDecoration: 'none' }}>
            <Tag>Ask Max!</Tag>
          </a>
           
        </>
      }
    >
      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        {sentimentData.sentiment === 'positive' ? (
          <SmileOutlined style={{ color: 'green', fontSize: 60 }} />
        ) : sentimentData.sentiment === 'negative' ? (
          <FrownOutlined style={{ color: 'red', fontSize: 60 }} />
        ) : (
          <MehOutlined style={{ color: 'gray', fontSize: 60 }} />
        )}
        <div style={{ fontSize: 18, marginTop: 10 }}>Sentiment</div>
      </div>

      <div>
        <h3>Positive Words:</h3>
        {sentimentData?.positiveWords?.map((word, index) => (
          <span key={index} className="tag-positive">
            <Tag color="green"> {word}</Tag>
          </span>
        ))}
      </div>

      <div>
        <h3>Negative Words:</h3>
        {sentimentData?.negativeWords?.map((word, index) => (
          <span key={index} className="tag-negative">
            <Tag color="pink"> {word}</Tag>
          </span>
        ))}
      </div>
      <Divider />
      <IdeaSentimentDonut positive={positive} negative={negative} neutral={neutral} />
    </Card>
  );
};

export default SentimentCard;
