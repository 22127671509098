import { BulbOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Layout, Row, Space, Tabs, Tour , Tag} from 'antd';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useRef, useEffect, useState } from 'react';
import withRouter from '../../withRouter';
import IdeaInitiativeDonut from '../Insights/IdeaInitiativeDonut';
import IdeaStatusDonut from '../Insights/IdeaStatusDonut';
import Insights from '../Insights/Insights';
import ProductGapDonut from '../Insights/ProductGapDonut';
import UserHeader2 from '../Layout/UserHeader2';
import Dashboard from '../Offer/Dashboard';
import PlansPage from '../Plan/PlansPage';
import Persona from '../User/Persona';
import IdeaTable from './IdeaTable';
import BusinessCanvas from './Settings/BusinessCanvas';
import WeightSlider from './WeightSlider';
import Widgets from './widgets/Widget';
import IdeaInsights from '../Insights/IdeaInsights';

const { Content, Header } = Layout;

const { TabPane } = Tabs;

const ProductDetail = (props) => {
  const [product, setProduct] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [canvas, setCanvas] = useState('');
 
 
  const product_id = props.params.id;

 
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const user_access_token = localStorage.getItem("user_access_token");
        const response = await axios.get(process.env.REACT_APP_API_URL + "product/" + product_id, {
          headers: {
            Authorization: "Bearer " + user_access_token,
          }
        });
        setProduct(response.data);
        setCanvas(`
        Product Vision: ${response.data.vision}
        Why does your Product exist?: ${response.data.why}
        Top Product Tenets: ${response.data.tenets}
        Primary Customers: ${response.data.customers}
        Fly Wheel Drivers: ${response.data.flywheel}
        Key Differentiator: ${response.data.differentiator}
      `);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProduct();
 
 
   
  }, [product_id]);

 

 
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {


    
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    
    window.addEventListener('resize', handleResize);

  


    return () => {
      window.removeEventListener('resize', handleResize);
    };



  }, []);


  // const ref1 = useRef(null);
  // const ref2 = useRef(null);
  // const ref3 = useRef(null);
  // const ref4 = useRef(null);
  // const ref5 = useRef(null);
  // const ref6 = useRef(null);
  // const ref7 = useRef(null);
  // const ref8 = useRef(null);

  // const [open, setOpen] = useState(true);

  // const steps = [
  //   {
  //     title: 'Product Title',
  //      // description: 'Put your files here.',
  //     // cover: (
        
  //     // ),
  //     target: () => ref1.current,
  //   },
  //   {
  //     title: 'Idea by Status',
  //     description: 'Click to see other actions.',
  //     target: () => ref2.current,
  //   },
  //   {
  //     title: 'Ideas by Gaps',
  //     description: 'Click to see other actions.',
  //     target: () => ref3.current,
  //   },
  //   {
  //     title: 'Ideas by Business Initiatives',
  //     description: 'Click to see other actions.',
  //     target: () => ref4.current,
  //   },
  //   {
  //     title: 'List of IDEAs',
  //     description: 'Save your changes.',
  //     target: () => ref5.current,
  //   },
  //   {
  //     title: 'Manage User Personas here',
  //     description: 'Click to see other actions.',
  //     target: () => ref6.current,
  //   },
  //   {
  //     title: 'Manage Rewards',
  //     description: 'Click to see other actions.',
  //     target: () => ref7.current,
  //   },
  //   {
  //     title: 'Review Product business canvas',
  //     description: 'Click to see other actions.',
  //     target: () => ref8.current,
  //   },
  // ];
 

  return (

    <Layout>
    <Layout>
      <Header
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  width: '100%',
                  display: 'flex',
                  
                }}

              > 
         
                <UserHeader2 />
 
       </Header>
   <Content
      style={{
        flex: '1',
        padding: '25px 15px',
        background: 'white',
      }}
    >

      
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <div style={{ height: '100%' }}>
             

             
            <Space direction='vertical' >
             <br/>
            <h3>  Product Title:  {product.name}</h3> <h5> by 
  <a href={product.hyperlink} target="_blank" rel="noopener noreferrer">
     {" "+product.brand}
  </a>
</h5>         <p>
                    <Tag color="blue">
                      {product.productType} 
                    </Tag>
                    {/* <Tag color="blue">
                    {product.owner}  </Tag> */}
                  </p>
          <b>Product Description: {product.description}</b>
        <br/>
        
            </Space>


            <Row   justify="left">
  <Col xs={24} sm={12} md={8} lg={8} xl={8}  >
    <div style={{ textAlign: 'center' }}>
      <h4>IDEAs by Status</h4>
      <IdeaStatusDonut product_id={product_id}    />
    </div>
  </Col>
  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
    <div style={{ textAlign: 'center' }}>
      <h4>IDEAs by Product Gap</h4>
      <ProductGapDonut product_id={product_id}  />
    </div>
  </Col>
  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
    <div style={{ textAlign: 'center' }}>
      <h4>IDEAs by Business Initiatives</h4>
      <IdeaInitiativeDonut product_id={product_id}   />
    </div>
  </Col>
</Row>

<WeightSlider product_id={product_id}/>
            <br />
            <br />
            <div>
              
 
            </div>
{/*            
            <Tabs defaultActiveKey="1" items={[
              {
                key: '1',
                label: `Ideas`,
                children: <IdeaTable product_id={product_id}  canvas={canvas} 
                productName={product.name}producType={product.productType} productDescription= {product.description}
               
                />,
                ref:{ref5}
              },
              {
                key: 'persona',
                label: `User Personas`,
                children: <Persona product_id={product_id}   
                productName={product.name}  productDescription= {product.description}
              
                />,
                ref:{ref6}
              },
              {
                key: 'reward',
                label: `Rewards`,
                children: <Dashboard product_id={product_id}   
                productName={product.name}  productDescription= {product.description}
                
                />,
                ref:{ref7}
              },
              {
                key: '5',
                label: `Product Canvas`,
                children:   <BusinessCanvas product={product} />,
                ref: {ref8}
              },             
              // {
              //   key: '2',
              //   label: `Announcements`,
              //   children: <AnnouncementWidget product_id={product_id} />,
              // },
          
              //  {
              //   key: '3',
              //   label: `Product Insights`,
              //   children: <Insights product_id={product_id} />,
              // },
              // {
              //   key: '6',
              //   label: `Widgets`,
              //   children: <Widgets product_id={product_id} />  ,
              // },
       
              // {
              //   key: '7',
              //   label: `Plans`,
              //   children: <PlansPage product_id={product_id} />,
              // },
      
              

            ]} /> */}


<Tabs defaultActiveKey="1" >
      <TabPane tab="Ideas" key="1"  >
        <IdeaTable product_id={product_id} canvas={canvas} productName={product.name} productType={product.productType} productDescription={product.description}  />
      </TabPane>
      <TabPane tab="Audience Management" key="persona"  >
        <Persona product_id={product_id} productName={product.name} productDescription={product.description}  />
      </TabPane>
      {/* <TabPane tab="Rewards" key="reward" >
        <Dashboard product_id={product_id} productName={product.name} productDescription={product.description}  />
      </TabPane> */}
      <TabPane tab="Product Canvas" key="5"   >
        <BusinessCanvas product={product} />
      </TabPane>
      {/* Add more TabPanes as needed */}
    </Tabs>

              </div>
   
          </Col>
        </Row>
 
    
      </Content>
  </Layout>
    </Layout>
   

 
  );
};

export default withRouter(ProductDetail);
