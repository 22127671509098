import { PieChartOutlined } from '@ant-design/icons';
import { Divider, Space } from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import '../Layout/index.css';
import CalendarHeatMap from './CalendarHeatMap';
import KanoCategoryBarChart from './KanoCategoryBarChart';
import MetricBarChart from './MetricBarChart';
import ProductUser from './User/ProductUser';

const Insights = ({ product_id }) => {
  return (
    <>
      <h3 className='h3-IdeaHub'>
        {' '}
        <PieChartOutlined style={{ fontSize: '40px' }} /> PRODUCT INSIGHTS
      </h3>

      <Space direction='vertical' align='center'>
        <Divider type='horizontal'></Divider>
        <Space>
          <Space direction='vertical' align='center'>
            <h4>Consumer Satisfaction Distribution</h4>
            <KanoCategoryBarChart product_id={product_id}/>
          </Space>
          <Divider type='vertical'></Divider>
          <Space direction='vertical' align='center'>
            <h4>Idea Distribution by Metrics</h4>
            <MetricBarChart product_id={product_id}/>
          </Space>
          <Divider type='vertical'></Divider>
          <Space direction='vertical' align='center'>
            <h4>Product Gap Heatmap</h4>
            {/* <Engagement/> */}
            <CalendarHeatMap product_id={product_id}/>
          </Space>
        </Space>
        <ProductUser product_id={product_id} />
      </Space>
    </>
  );
};

export default Insights;
