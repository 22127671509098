import React, { useState, useEffect } from 'react';
import { SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';

const KeyFeatures = ({ keyFeatures, saveFeatures }) => {
  const [newFeature, setNewFeature] = useState('');
  const [features, setFeatures] = useState([]);
 

  const addFeature = () => {
    if (newFeature.trim() !== '' && features.length < 3) {
      setFeatures([...features, newFeature.trim()]);
      setNewFeature('');
    }
  };

  const removeFeature = (index) => {
    const updatedFeatures = [...features];
    updatedFeatures.splice(index, 1);
    setFeatures(updatedFeatures);
  };

  const handleSave = () => {
    const finalFeatures = features.join(',');
    saveFeatures({ features: finalFeatures });
  };


  useEffect(() => {
    setFeatures(keyFeatures);
  }, [keyFeatures]);

  return (
    <div>
      <h5>Top 3 Key Features        <SaveOutlined style={{ color: 'green' }} onClick={handleSave}/>
       </h5>
      {features.length > 0 ? (
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              {feature} <DeleteOutlined style={{ color: 'red' }}  onClick={() => removeFeature(index)}/>   
            </li>
          ))}
        </ul>
      ) : (
        <p>No features available.</p>
      )}
      <input
        type="text"
        value={newFeature}
        onChange={(e) => setNewFeature(e.target.value)}
        placeholder="Enter a new feature"
      />
      <button onClick={addFeature}>Add</button>
 
      <br/>         

    </div>
  );
};

export default KeyFeatures;
