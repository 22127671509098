import {   Card, Popconfirm, Radio, Select } from "antd";
import React from "react";
 
const { Option } = Select;
 

const AdminPollView = ({
	 
	poll,
	onDelete,
	onToggleStatus,
  }) => {
	return (
	  <>
		<Card
		  title={poll.question}
		  extra={
			<>
			  <Popconfirm
				title="Are you sure to delete this poll?"
				onConfirm={() => onDelete(poll.pollId)}
				okText="Yes"
				cancelText="No"
			  >
				<button style={{ marginRight: 8 }}>Delete</button>
			  </Popconfirm>
			  <button onClick={() => onToggleStatus(poll.pollId)}>
				{poll.status === "active" ? "Deactivate" : "Activate"}
			  </button>
			</>
		  }
		  style={{ margin: "auto", marginTop: 20 }}
		  className="responsive-card"
		>
			<Radio.Group>
  {poll.options &&
    Array.isArray(JSON.parse(poll.options)) &&
    JSON.parse(poll.options).map((option, optionIndex) => (
      <Radio key={optionIndex} value={option}>
        {option}
      </Radio>
    ))}
</Radio.Group>

		  {/* <Radio.Group>
			{poll.options &&
			  JSON.parse(poll.options).map((option, optionIndex) => (
				<Radio key={optionIndex} value={option}>
				  {option}
				</Radio>
			  ))}
		  </Radio.Group> */}
		</Card>
	  </>
	);
  };
  
  export default AdminPollView;
  
  
 