import React, { useEffect, useState } from 'react';

import {
  CommentOutlined,
  DislikeOutlined,
  HeartOutlined,
  LikeFilled,
  LikeOutlined,
  MessageOutlined
} from '@ant-design/icons';
import {Button,
  Avatar,
  Badge,
  Card,
  Col,
  Divider,
  FloatButton,
  Form,
  Image,
  Input,
  Layout,
  List,
  Modal,
  Result,
  Row,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
  theme
} from 'antd';
import axios from 'axios';
import { formatRelative, parseISO } from 'date-fns';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../index.css';
import FeatureCarousel from '../CardSort/FeatureCarousel';
import TipsBox from '../Engagement/TipBox';
import LogoutFooter from '../Layout/LogoutFooter';
import UserFooter from '../Layout/UserFooter';
import PollCarousel from '../Poll/PollCarousel';
import RewardCard from '../Rewards/RewardCard';
import LoginAgain from '../User/LoginAgain';
import PersonaModal from '../User/PersonaModal';
import ThankYouScreen from '../User/ThankYouScreen';
import VariationCarousel from '../Variations/VariationCarousel';
import UserAvatarGroup from './UserAvatarGroup';
import ShareLinkButton from '../Engagement/ShareLinkButton';


const { Content,Header, Footer,Sider } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { success, error, warning } = message;

const { Meta } = Card;

 

const Idea = () => {
  const { product_id, idea_id } = useParams();
  const [totalVotes, setVote] = useState(0);
  const [idea, setIdea] = useState({});
  const [voteSubmitted, setVoteSubmit] = useState(false);
  const [downVoteSubmitted, setDownVoteSubmit] = useState(false);
  const [description, setDescription] = useState('');
  const [customerVintage, setCustomerVintage] = useState('');
  const navigate = useNavigate(); // Access the history object
  const [fileList, setFileList] = useState([]);
  const [image_urls, setImage_urls] = useState([]);
  const [visible, setVisible] = useState(false);
  const [ideaStatus, setStatus] = useState('');
  const [error, setError] = useState('');
  const [variations, setVariation] = useState(null);

  const [userTag, setUserTag] = useState('');
  const [tags, setIdeaTags] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [guestAccessToken, setGuestAccessToken] = useState('');
  const [userID, setUserID] = useState(''); // Set the user ID from your authentication
  const [user, setUser] = useState(null); // State to track logged-in user
  const [isModalVisible, setModalVisible] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("guest");
  const [company, setCompany] = useState("guest");
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const location = useLocation();
  const [commentList, setCommentList] = useState([]);
  const [form] = Form.useForm();
  const [isGuest, setIsGuest] = useState(0);
  const [isPersonaModalVisible, setPersonaModalVisible] = useState(false);
  const [userPersona, setUserPersona] = useState([]);
  const [pollsData, setPollsData] = useState(null);
  const [featureData, setFeatureData] = useState(null);
  const [points, setPoints] = useState(0);
  const [reward, setReward] = useState(null);
  const [userTaskProgress, setUserProgress]= useState(null);
  const [isFloatModalVisible, setIsFloatModalVisible] = useState(false);
  const [isIdeaFloatModal, setIsIdeaFloatModal] = useState(false);
  const [showThankYouScreen, setShowThankYouScreen] = useState(false);
  const [variationCount, setVariationCount] = useState(0);
  const [pollCount, setPollCount] = useState(0);
  const [featureCount, setFeatureCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [keyFeatures, setKeyFeatures]= useState([]);
  

  const items = [
  
    {
      label: <a href="/Research/Product">My Products</a>,
      key: 'product',
    },
    {
      label: <a href="/Reward/Lounge">My IDEAs</a>,
      key: 'dashboard',
    },
  ];
 
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  const [collapsed, setCollapsed] = useState(false);
 
  const handleFeature = async ( newOrder) => {
  
  
    try {

      const payload = {
       userResult:JSON.stringify(newOrder)
        
      };

 
      const res = await axios.post(`${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/features/${featureData.idfeature}/featureResponse`, payload, {
        headers: {
          Authorization: "Bearer " + accessToken,
        }
      });


 
    
     
      if (res.status === 200) {
        message.success('Great choice! Your Feedback has been received');
        getUserResponses();
        
    getfeatures();
        setPoints(points + 10);
      }
    
    } catch (error) {
      console.error('Error fetching offers:', error.message);
    }

  };

  const refreshIdea = () => {
    // Implement the logic to refresh Idea.js
 
    setAccessToken(localStorage.getItem("user_access_token"));
    triggerUpdate();
  };

  const getListofUsers = async () => {

    const userAccessToken = localStorage.getItem('user_access_token');


    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + 'user/list/' + product_id + '/' + idea_id, {

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
      // Assuming response.data is an array of polls
     
console.log(response.data);
      setUserList(response.data);
     
  
    }

    catch (error) {
      console.error('Error sending persona data:', error.message);
    }

  }

  const getPollData = async () => {

    const userAccessToken = localStorage.getItem('user_access_token');


    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/polls', {

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
      // Assuming response.data is an array of polls
      const activePolls = response.data.filter((poll) => poll.status === 1);

      setPollsData(activePolls);
     
        setPollCount(activePolls.length)
 

    

    }

    catch (error) {
      console.error('Error sending persona data:', error.message);
    }

  }

  const checkUserPersona = async () => {
    try {
     

      // Check if personaId is not present or falsy (e.g., empty, null, undefined)
      if (!userPersona.personaId) {
        // If no persona is assigned, show the modal
        setPersonaModalVisible(true);
      }
    } catch (error) {
      console.error('Error checking user persona:', error);
    }
  };



  const handlePersonaModalClose = (persona) => {
    // Refresh the page or perform any necessary action after persona selection
    // window.location.reload();
 
    setUserPersona(persona);
    setPersonaModalVisible(false);

  };


  const getUserPersona = async () => {
    try {
      let accessToken = localStorage.getItem("user_access_token");


      axios.get(process.env.REACT_APP_API_URL + 'user/product/' + product_id + '/persona', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response && response.data) {
          const parsedData = {
            ...response.data,
            attitude: JSON.parse(response.data.attitude),
          };
      
         
          setUserPersona(parsedData);
          return parsedData;
        } else {
          console.error('Invalid response format:', response);
          alert('Oops! Something went wrong. Please try again later.');
        }
      })
      
    } catch (error) {
      console.error('Error fetching existing personas:', error);
    }

  }

  const triggerUpdate = () => {
    setForceUpdate((prev) => prev + 1);
    
 

  };


  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
   
      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };


  const checkTokenExpiration = () => {
    // Retrieve the stored token from localStorage
    const storedToken = JSON.parse(localStorage.getItem('user'));

    // Check if the token is present and not expired
    if (storedToken && !isTokenExpired(storedToken)) {
      // Token is valid, user is authenticated
 

      return;
    }

    // Token is either not present or expired, log the user out and redirect to login page
    handleLogout();
  };

  // Function to logout the user
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("user_role");
    localStorage.clear();

  };

  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    if (token && token.exp) {
      return token.exp * 1000 <= Date.now();
    }
    return true; // Token is considered expired if no expiration information is available
  };


  useEffect(() => {
    setAccessToken(localStorage.getItem("user_access_token"));
    setPoints(points+1);   

    // getApiToken();  
    // Check if there is a stored user
    if (accessToken) {
      const  User = JSON.parse(localStorage.getItem('user'));
      setUser(User);
      triggerUpdate();
      setIsAuthenticated(true);
      checkTokenExpiration();
      setPoints(points+10)
      // Set up a timer to periodically check token expiration
      const tokenCheckInterval = setInterval(() => {
        checkTokenExpiration();
      }, 60000); // Check every minute

      // Cleanup the interval on component unmount
      return () => clearInterval(tokenCheckInterval);
    } else {
      // If there is no user, show the modal


    }
  }, [accessToken ]);

  // useEffect(() => {

  // }, [accessToken]);



  useEffect(() => {
    const fetchDataAfterAuthentication = async () => {
      try {
        if (accessToken) {
          const [ideaData, variationsData, commentData, persona, reward, userProgress, features] = await Promise.all([

            fetchData(),
            fetchVariations(),
            CommentCard(),
            getUserPersona(),
            getOfferData(),
            getUserResponses(),
            getfeatures(),
            getListofUsers()
          ]);

      

          setIdea(ideaData);
          setVariation(variationsData);
          setCommentList(commentData);
          getPollData();
          setIsAuthenticated(true);
          setReward(reward);
          setUserProgress(userProgress);
          setFeatureData(features);

      setVariationCount(variationsData.length);
    //  setFeatureCount(featureData.hasOwnProperty('features') && featureData.features.length > 0 ? 1 : 0); 
      
     
 
        }
         
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };



    fetchDataAfterAuthentication();
   
    

  }, [accessToken]); // Assuming forceUpdate triggers the effect when needed


  const getfeatures = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/features`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );

     
      if (response.data && response.data.idfeature !== null) {
        
        return   response.data;
      } else {
  
        return   0;
      }

      
     
    
    } catch (error) {
      console.error('Error fetching offers:', error.message);
    }
  };

  const getOfferData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${product_id}/idea/${idea_id}/RewardOffer`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );

     
      return response.data.length === 0 ? null : response.data;
     
    
    } catch (error) {
      console.error('Error fetching offers:', error.message);
    }
  }

  const getUserResponses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}user/userProgress/${idea_id}`,   
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_access_token')}`,
          },
        }
      );

      setUserProgress(response.data);
    return response.data;
     
    
    } catch (error) {
      console.error('Error fetching offers:', error.message);
    }
  };


  const handleContinueAsGuest = async (e) => {
    //http://localhost:3000/idea/5193/5295?ClientId=f3ffe8e1f804f2074aa5cc55233673b9&ClientSecret=9b3344bb2ec9fb643eecac8389d3001b&Email=headstart.essentials@gmail.com
    e.preventDefault();
    setIsGuest(false);
    const searchParams = new URLSearchParams(location.search);
    const clientId = searchParams.get('ClientId');
    setClientId(clientId);
    const clientSecret = searchParams.get('ClientSecret');
    setClientSecret(clientSecret);
    const Email = searchParams.get('Email');

    const username = await getIPAddress()
    // const token= await getApiToken(clientId,clientSecret,Email);
    // localStorage.setItem("user_access_token", token.accessToken);
    const accessToken = localStorage.getItem("user_access_token");


    setAccessToken(accessToken);

    triggerUpdate();

    //   // http://localhost:3000/idea/1/1?ClientId=f3ffe8e1f804f2074aa5cc55233673b8&ClientSecret=9b3344bb2ec9fb643eecac8389d2521b


    // console.log("continue as new  token user", "user access", guestAccessToken)
    // triggerUpdate();

    setModalVisible(false);





  };




  const getApiToken = async () => {

    const searchParams = new URLSearchParams(location.search);
    const clientId = searchParams.get('ClientId');
    const clientSecret = searchParams.get('ClientSecret');
    const username = searchParams.get('Email');



    const response = await axios.post(process.env.REACT_APP_API_URL + `user/web_token`, {
      clientId,
      clientSecret,
      username,
    });

    if (response.status === 200) {

      localStorage.setItem("user_access_token", response.data.accessToken);
      triggerUpdate();
      setIsAuthenticated(true);
    } else {

      
    }
  };

  const formatDate = (date) => {
    try {
      const parsedDate = parseISO(date);
      return formatRelative(parsedDate, new Date(), { addSuffix: true });
    } catch (error) {
      // Handle invalid date format here
      return "Invalid date";
    }
  };




  const onClickVote = async () => {
    try {

      const payload = { "idIdea": idea_id };
       
      let accessToken = localStorage.getItem("user_access_token");

      if (isGuest) {
        accessToken = guestAccessToken;
      }
      const res = await axios.post(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/vote', payload, {
        headers: {
          Authorization: "Bearer " + accessToken,
        }
      });

      if (res.status === 200) {
        message.success('Great choice! Your Feedback has been received');
        setVote(res.data.totalVotes);
        setVoteSubmit(true);
        setDownVoteSubmit(false);
        checkUserPersona();
        setPoints(points + 10);
        getUserResponses();
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
      message.error('Ops something went wrong!. Please try again');
    }
  };

  const onDownVote = async () => {
    try {

      const payload = { "idIdea": idea_id };
      let accessToken = localStorage.getItem("user_access_token");
      
      if (isGuest) {
        accessToken = guestAccessToken;
      }
      const res = await axios.post(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/downVote', payload, {
        headers: {
          Authorization: "Bearer " + accessToken,
        }
      });

      if (res.status === 200) {
        message.success('Unique choice! Your Feedback has been received');
        setVote(res.data.totalVotes);
        setDownVoteSubmit(true);
        setVoteSubmit(false);
        checkUserPersona();
        setPoints(points + 10);
        setVoteSubmit(true);
        getUserResponses();
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
      message.error('Ops something went wrong!. Please try again');
    }
  };


  const CommentCard = async () => {

    let accessToken = localStorage.getItem("user_access_token");

    if (isGuest) {
      accessToken = guestAccessToken;
    }



    try {


      if (idea_id && accessToken) {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/comment', {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        });
        setCommentList(response.data);
        return response.data;

      }
    } catch (error) {
      console.error('Error fetching comment data:', error);
    }

  }

  const fetchData = async () => {
    let accessToken = localStorage.getItem("user_access_token");

    if (isGuest) {
      accessToken = guestAccessToken;
    }


    try {

      if (idea_id && accessToken) {
        // For Idea detail

        const response = await axios.get(process.env.REACT_APP_API_URL + "product/" + product_id + "/idea/" + idea_id + "/short", {
          headers: {
            Authorization: "Bearer " + accessToken,
          }
        });

        setStatus(response.data.status);
        setVote(response.data.votes);
        setVoteSubmit(false);
        setDescription(response.data.description);
        setCustomerVintage(response.data.userstory);
        if (response.data.tags) {
          setIdeaTags(response.data.tags.split(','));
        }
        if (response.data.features) {
          setKeyFeatures(response.data.features.split(','));
        }

       
    
        const imageUrlString = response.data.url;
					

        // Check if the string is not empty before splitting
        if (imageUrlString) {
          setImage_urls(imageUrlString.split(","));
        }



        return response.data;
      }
    } catch (error) {
      console.error('Error fetching idea data:', error);
    }
  };

  const fetchVariations = async () => {

    let accessToken = localStorage.getItem("user_access_token");

    if (isGuest) {
      accessToken = guestAccessToken;
    }


    try {

      if (idea_id && accessToken) {
        // For Idea detail

        const response = await axios.get(process.env.REACT_APP_API_URL + "product/" + product_id + "/idea/" + idea_id + "/variation", {
          headers: {
            Authorization: "Bearer " + accessToken,
          }
        });
 
return response.data.length === 0 ? null : response.data;


      }
    } catch (error) {
      console.error('Error fetching idea data:', error);

    }
  };




  const onVote = async ({ vote }) => {
    try {
      let accessToken = localStorage.getItem("user_access_token");

      if (isGuest) {
        accessToken = guestAccessToken;
      }
      const payload = { pollValue: vote.vote };
    


      const res = await axios.post(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/variation/' + vote.ideaid + '/poll', payload, {
        headers: {
          Authorization: "Bearer " + accessToken,
        }
      });

      if (res.status === 200) {
      
        message.success('🌟 Your variation is in. Thanks for pushing the boundaries!');
        checkUserPersona();
        setPoints(points + 10);
        getUserResponses();
      }
    } catch (error) {
      // Handle errors here
      message.error('Ops something went wrong!. Please try again');
    }
  };

  const onFill = async () => {
    try {
      const title = isIdeaFloatModal ? 'New Idea' :'Comment';

      const payload = { "text": `${title}: ${form.getFieldValue('Comment')}`  };
      let accessToken = localStorage.getItem("user_access_token");
    

      if (isGuest) {
        accessToken = guestAccessToken;
      }
      const res = await axios.post(process.env.REACT_APP_API_URL + 'product/' + product_id + '/idea/' + idea_id + '/comment', payload, {
        headers: {
          Authorization: "Bearer " + accessToken,
        }
      });

      if (res.status === 200) {
        message.success('Bingo! Your feedback has landed. Keep those insights coming!');
        setPoints(points + 10);
        form.resetFields();
        checkUserPersona();
        CommentCard();
        getUserResponses();
       
      }
      setIsFloatModalVisible(false);
      setShowThankYouScreen(true);
    }

    catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 403) {
          message.error('Your comment could not be submitted, please try again, Comment Rejected due to either Hate, Profanity, or Violence');
          form.resetFields();
        } else if (error.response.status === 401) {
          warning('Ops! Please log in to comment.');
        }
      } else {
        message.error('Something went wrong. Please try again!');
      }
    }


  };

  const handleStartOver = () => {
    // Your logic to reset the voting state or navigate to the starting point
    setVoteSubmit(false);
    // Additional logic as needed
  };




  const showFloatModal = (isIdea) => {
    setIsIdeaFloatModal(isIdea);
    setIsFloatModalVisible(true);
  };

 

  const handleCancel = () => {
    setIsFloatModalVisible(false);
  };


  return (



<Layout>
      <Layout  >
        
      <Header
           style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            
          }}

        > 
 
          
          {isAuthenticated && reward && (
            <RewardCard
              reward={reward}
              userTaskProgress={userTaskProgress}
              variationCount={variationCount}
              pollCount={pollCount}
              featureCount={featureData && featureData.idfeature !== null ? 1 : 0}
              TaskComplete={() => setShowThankYouScreen(true)}
            />
          )}
        </Header>
        <Content
        style={{
          flex: '1',
          padding: '25px 15px',
          background: 'white',
        }}
      >


     



{localStorage.getItem("user_access_token") && idea ? (
            
             <>

{showThankYouScreen && <ThankYouScreen />}
<div>
  <Row gutter={[16, 16]}>
    <Col xs={24} sm={24} md={24} lg={24}>
      <Card>
        <Row gutter={[4, 4]}>
          {/* First Column */}
          <Col xs={24} sm={24} md={24} lg={16}>
            <br />
            <p>
              Ready to shape the future? Your authentic voice matters here! Engage with ideas, vote in polls, or dive into discussions, and make a real impact. Remember, your participation is governed by the{' '}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                terms and conditions
              </a>{' '}
              you accepted during signup
            </p>
          </Col>

          {/* Second Column */}
          <Col xs={24} sm={24} md={24} lg={16}>
           
          <h4 className="h5-md" style={{ marginBottom: 3 }}>
  🚀 {idea.title}
</h4>

<h6 style={{ marginTop: 8, marginBottom: 8, marginRight: '5px' }}>
  {idea.brand && (
    <>
      {' by '}
      <a href={idea.hyperlink} target="_blank" rel="noopener noreferrer">
        <Tag color="#3d5a9f">{idea.brand}</Tag>
      </a>
    </>
  )}
  <ShareLinkButton productId={product_id} ideaId={idea_id} />
</h6>

           </Col> 
              {/* Third Column */}
              <Col xs={24} sm={24} md={24} lg={24}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Image preview={{ visible: false }} width="50%" src={image_urls[0]} onClick={() => setVisible(true)} />
              <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                  {image_urls.map((url, index) => (
                    <Image key={index} src={url} />
                  ))}
                </Image.PreviewGroup>
              </div>
            </div>
          </Col>
              
          <Col xs={24} sm={24} md={24} lg={16}>
            
       
            {/* <p>Product Category: {idea.productType}</p> */}
        
            <p>Product Description:</p>
              <p className="ideatext">{description}</p>

              {tags && tags.length > 0 ? (
        <>
    <p>{tags.map((tag, index) => (index === 0 ? tag : ` | ${tag}`))}</p>
        </>
      ) : null}


             
            
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
           
            {keyFeatures && keyFeatures.length > 0 ? (
        <>
          <p>Key Features:</p>
          {keyFeatures.map((feature, index) => (
            <p key={index} >
        <ul>
    <li>    {feature}</li>
        </ul>     
            </p>
          ))}
        </>
      ) : null}
               
            
            </Col>


            <Col xs={24} sm={24} md={24} lg={16}>
            <h4 className="h5-md" style={{   marginBottom: 3,  }}>
              <Tooltip title="Get ready for an amazing story—all about you! Discover how a cool idea makes a big difference in your experience. See how it perfectly meets your needs and creates a future you'll love!">🎬 Story Board</Tooltip>
            </h4>
            <div className="ideabox">
              <p className="ideatext">{customerVintage}</p>
            </div>
          </Col>


        </Row>
        <div></div>
      </Card>
<br/>

<Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h4 className="h5-md" style={{   marginBottom: 3,  }}>     IDEA Feedback.    </h4>
   
        <div>
          
          <Badge count={voteSubmitted ? 1 : 0} style={{ backgroundColor: '#52c41a', marginRight: '8px' }} />
          <span>{`Votes: ${voteSubmitted ? 1 : 0} / 1`}</span>
        </div>
      </div>

      {voteSubmitted ? (
        <>
          <Result
            icon={<LikeFilled style={{ fontSize: '48px', color: '#52c41a' }} />}
            title="Voila! Your vote has been cast."
            subTitle="You're shaping the future—thanks for being part of it!"
          />
          <button type="primary" onClick={handleStartOver}>
            Start Over
          </button>
         
        </>
      ) : (
        <>
        <div style={{   justifyContent: 'center', alignItems: 'center', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
          <p>Share your feedback on this IDEA by giving it a Thumbs up or Thumbs down!</p>
          {userList && (
 <UserAvatarGroup userList={userList}/> 
)}
          <Space size="middle">
            <Tooltip title="I Like it!">
              <Link onClick={onClickVote}>
                <div style={{ justifyContent: 'center', alignItems: 'center', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <LikeOutlined style={{ fontSize: '24px',  }} />
                  
                </div>
              </Link>
            </Tooltip>
            <Tooltip title="I Do Not Like it!">
              <Link onClick={onDownVote}>
                <div style={{ justifyContent: 'center', alignItems: 'center', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <DislikeOutlined style={{ fontSize: '24px',  }} />
                
                </div>
              </Link>
            </Tooltip>
          </Space>
          </div>
        </>
      )}
    </Card>
                <br />

                {variations &&  (

        <VariationCarousel
        variations={variations}
        onVote={onVote}
        />
                  
                  )}

<br/>

                {pollsData && (

< PollCarousel
pollsData={pollsData}
 product_id={product_id}
 idea_id ={idea_id}
 onPoll={getUserResponses}

/> )}
 
<br/>
{featureData && (

< FeatureCarousel
featuresData={featureData}
 product_id={product_id}
 idea_id ={idea_id}
 onSort={handleFeature}

/>  )}
  
 
<br/>
                  
 
                <Card >
                <h3 className="h3-md" style={{   marginBottom: 3,  }}>  💬 Comments.  <Tag color="#FF6F61">({commentList.length})</Tag>
               </h3>     
                       
                  {commentList && (
                    <Col xs={24} sm={24} md={24} lg={24}>


                 

                      <List
                        itemLayout="vertical"
                        dataSource={commentList.sort((a, b) => new Date(b.commentDate) - new Date(a.commentDate))}
                        renderItem={(item) => (
                          <List.Item > 
                            <List.Item.Meta className='comments'
                              avatar={<Tooltip title={item.userId}><Avatar src={`https://ui-avatars.com/api/name=${item.userId}&background=random`} /></Tooltip>}
                              title={item.userId} // Highlight name if it's the owner
                              description={
                                <p  className='comments' style={{ fontSize: '0.75rem', margin: 5 }}>
                                  {item.text} <br />  {formatRelative(parseISO(item.commentdate), new Date(), { addSuffix: true })}
                                </p>
                              }

                            />

                          </List.Item>
                        )}
                        style={{ padding: '0 5px', marginBottom: '5px' }}
                      />






                    </Col>)}






                </Card>

                <FloatButton.Group trigger="hover" type="primary"   icon={<CommentOutlined />}>
        <FloatButton
          icon={<MessageOutlined />}
          tooltip="Share your thoughts!"
          onClick={() => showFloatModal(false)}
        />
        <FloatButton
          icon={<HeartOutlined />}
          tooltip="Got an Idea?"
          onClick={() => showFloatModal(true)}
        />
      </FloatButton.Group>


              
            </Col>
          </Row>
   
        </div>
        
        <PersonaModal
        userID={userID}
        isVisible={isPersonaModalVisible}
        onClose={handlePersonaModalClose}
        product_id={product_id}
      />

 

 

      <Modal
  title={isIdeaFloatModal ? 'New Idea' : 'Comment'}
  visible={isFloatModalVisible}
  maskClosable={true}
  onCancel={handleCancel}
  onOk={onFill} // Handle the "Submit" action when the "OK" button is clicked
  okText="Submit" // Rename the "OK" button to "Submit"
>
  <Col xs={24} sm={24} md={24} lg={24}>
    <p>
      {isIdeaFloatModal
        ? "Share your groundbreaking ideas!"
        : 'Share your additional feedback or ask questions. We would love to hear from you!'}
    </p>
    <Form form={form} name="control-hooks">
      <Col xs={24} sm={24} md={8}>
        <Form.Item name="Comment" label="">
          <TextArea rows={4} />
        </Form.Item>
      </Col>
    </Form>
  </Col>
</Modal>

        
        
 </> 
 ) : (
   // Render the LoginAgain component for users not logged in
   <LoginAgain refreshIdea={refreshIdea} /> )
 }    

 

</Content>
 
  

   {user && isAuthenticated && (     <UserFooter username={user.name}/> )}
   {!user && !isAuthenticated && (     <LogoutFooter/> )}

 
  </Layout>
    </Layout>
   
  );
};

export default Idea;
