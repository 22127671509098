import { Pie, measureTextWidth } from '@ant-design/plots';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const IdeaInitiativeDonut = ({product_id}) => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  const [data,getData]=useState('');
  const getAllData = ()=> {
    var user_access_token = localStorage.getItem("user_access_token");
    // For message data
   axios.get(  process.env.REACT_APP_API_URL +"reports/product/"+product_id+"/productInitiative",{ 
    headers: {
      Authorization: "Bearer "+ user_access_token,
    }})
  .then(response => {
  const allData =response.data; 
 getData(allData);

  
  }) .catch(error => console.error(error));
  
  }
  useEffect(()=> {getAllData();},[product_id]);


  // const data = [
  //   {count: 2, type: 'Packaging'  },
  //   {count: 5, type: 'New Flavors'   },
  //   {count: 3, type: 'Cost Reductions'   },

  // ];

  const config = {
    appendPadding: 0,
    data,
    angleField: 'count',
    colorField: 'type',
    radius: .75,
    innerRadius: 0.65,
    meta: {
      count: {
        formatter: (v) => `${v}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    },
    statistic: {
      title: {
        offsetY: -20,
        style: {
          fontSize: '12px',
        },
        customHtml: (container, view, datum) => {
          const text = datum ? datum.type : null;
          return  text

        },
      },
      content: {
        offsetY: -15,
        style: {
          fontSize: '32px',
        },
        customHtml: (container, view, datum, data) => {
          const text = datum ? `${datum.count}` : `${data.reduce((r, d) => r + d.count, 0)}`;
          return text
        },
      },
    },
 
 
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
    legend: {
      position: 'right', // Adjust the legend position as needed ('top', 'bottom', 'left', 'right')
      
      offsetX: -10, // Adjust the offsetX to move the legend closer to the chart
    },
  };
  return <Pie {...config} />;
};


 

 
 

 
export default IdeaInitiativeDonut;