// AddVariationModal.jsx
import { UploadOutlined } from '@ant-design/icons';
import {  Form, Input, Modal, Space, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

const AddVariationModal = ({ visible, onCancel, ideaId, productId,onUpdate }) => {
 
    const [form] = Form.useForm();
    const [imageFile, setImageFile] = useState(null);
    const [fileName, setFileName] = useState('');

  const handleAddVariation = async () => {
    try {
      const values = await form.validateFields();

      // Create FormData to send as JSON
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description !== undefined && values.description !== null ? values.description : '');


      // Check if an image file is selected
      if (values.image && values.image.file) {
        formData.append('images', values.image.file);
      }

      // Call your endpoint to add the variation using Axios
      const user_access_token = localStorage.getItem("user_access_token");

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}product/${productId}/idea/${ideaId}/variation`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user_access_token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Reset form and imageFile
      form.resetFields();
      setImageFile(null); setFileName(''); // Reset the file name

      // Close the modal
      onUpdate();
      onCancel();
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const onFileChange = (file) => {
    // Extract the file name from the file object
    const fileName = file.name;
    
    // Display the file name when it's selected
    setFileName(fileName);
  
    // Save the file object if needed
    setImageFile(file.originFileObj);
  };
  
  const uploadProps = {
    beforeUpload: (file) => {
      // Trigger onFileChange with the file object
      onFileChange(file);
      return false; // Prevent default upload behavior
    },
    fileList: imageFile ? [imageFile] : [],
  };

 
  return (
    <Modal
      open={visible}
      title="Add Variation"
      onCancel={onCancel}
      footer={[
        <button key="cancel" onClick={onCancel}>
          Cancel
        </button>,
        <button key="add" type="primary" onClick={handleAddVariation}>
          Add Variation
        </button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Space direction="vertical">
          <Form.Item name="title" label="Variation Title" rules={[{ required: true, message: 'Please enter a title' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Variation Description">
            <Input.TextArea rows='5' />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <Upload {...uploadProps}>
              <button icon={<UploadOutlined />}>Click to Upload</button>
            </Upload>
          </Form.Item>
          <p>{fileName}</p> {/* Display the file name */}
        </Space>
      </Form>
    </Modal>
  );
};

export default AddVariationModal;
