// PersonaModal.js
import { Button, Card, Col, Modal, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './persona.css'; // Import a CSS file for styling

const PersonaModal = ({ userID, isVisible, onClose,product_id }) => {
  const [availablePersonas, setAvailablePersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);


  useEffect(() => {
    // Fetch available personas from the backend
    

    
    
    const fetchAvailablePersonas = async () => {
     

  try {
    const userAccessToken = localStorage.getItem('user_access_token');
 

    axios.get(process.env.REACT_APP_API_URL+'product/'+product_id+'/personas', {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      })
      .then((response) => {
        // Assuming the response.data contains an array of existing personas
        console.log(response.data);
        // const activePolls = response.data.filter((persona) => persona.status === 1);
        setAvailablePersonas(response.data);
    
      })
      .catch((error) => {
        console.error('Error fetching existing personas:', error);
      });
  } catch (error) {
    console.error('Error fetching existing personas:', error);
  }
    };
  

    if (isVisible) {
      fetchAvailablePersonas();
    }
  }, [isVisible, userID,product_id]);

  const isPersonaSelected = (persona) => selectedPersona === persona;

  const handlePersonaSelect = (persona) => {
    console.log("selected persona", persona.personaId);
  
    const payload = persona.personaId;
    const userAccessToken = localStorage.getItem('user_access_token');
  
    axios.post(
      `${process.env.REACT_APP_API_URL}user/product/${product_id}/persona/${payload}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    )
      .then(() => {
        // Close the modal after successful assignment
        onClose(persona);
      })
      .catch((error) => {
        console.error('Error assigning persona:', error);
        onClose(persona);
      });
  };
  
  

  return (
    <Modal
      title={<h3>Pick the Avatar that Best Matches You</h3>}
      open={isVisible}
      onCancel={onClose}
      footer={[
      
      ]}
    >
      {/* Use Row and Col to display cards horizontally */}
      <Row gutter={[16, 16]}>
        {availablePersonas.map((persona) => (
          <Col key={persona.personaId} span={24}>
            {/* Apply a selected class based on the selection state */}
            <Card
              hoverable
              className={isPersonaSelected(persona) ? 'selected-card' : ''}
              style={{ cursor: 'pointer', width: '100%', minHeight: '150px' }}
              onClick={() => handlePersonaSelect(persona)}
            >
              <h3>{persona.name}</h3>
              <p>{persona.description}</p>
              <p>

              {JSON.parse(persona.attitude).map((attitude, index) => (
  <p key={index}>{attitude.attitude}</p>
))}
</p>
              
            </Card>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default PersonaModal;
