import React from 'react';
import { Card, List, Avatar } from 'antd';

const CommentInsight = ({ filteredCommentsData }) => {
  return (
    <Card title="User Comments" bordered={false}>
      <List
        itemLayout="horizontal"
        dataSource={filteredCommentsData}
        renderItem={(comment) => (
          <List.Item key={comment.commId}>
            <List.Item.Meta
              avatar={<Avatar src={`https://api.dicebear.com/7.x/personas/svg?seed=${comment.userName}`} />}
              title={comment.userName}
              description={comment.text}
            />
            {/* Add sentiment-related components or information here */}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default CommentInsight;
