import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Heatmap } from '@ant-design/plots';
import axios from 'axios';

const CalendarHeatMap = ({product_id}) => {
  const [  setData] = useState([]);

 

  const [data,getData]=useState('');
  const getAllData = ()=> {
    var user_access_token = localStorage.getItem("user_access_token");
    // For message data
   axios.get(  process.env.REACT_APP_API_URL +"reports/product/product_id/heatMap",{ 
    headers: {
      Authorization: "Bearer "+ user_access_token,
    }})
  .then(response => {
  const allData =response.data; 
 getData(allData);

  
  }) .catch(error => console.error(error));
  
  }
  useEffect(()=> {getAllData();},[product_id]);


  // const data = [
  //   {
  //     date: '2023-03',
  //     Ideas: 350,
  //     type: 'Assortment',
  //   },
  //   {
  //     date: '2023-03',
  //     Ideas: 900,
  //     type: 'Flavour',
  //   },
  //   {
  //     date: '2023-03',
  //     Ideas: 300,
  //     type: 'Price',
  //   },
  //   {
  //     date: '2023-03',
  //       Ideas: 150,
  //       type: 'Packaging',
  //     },
  //     {
  //       date: '2023-03',
  //       Ideas: 250,
  //       type: 'Availability',
  //     },
  //   {
  //     date: '2023-04',
  //     Ideas: 30,
  //     type: 'Assortment',
  //   },
  //   {
  //     date: '2023-04',
  //     Ideas: 700,
  //     type: 'Flavour',
  //   },
  //   {
  //     date: '2023-04',
  //     Ideas: 300,
  //     type: 'Price',
  //   },
  //   {
  //     date: '2023-04',
  //       Ideas: 250,
  //       type: 'Packaging',
  //     },
  //     {
  //       date: '2023-04',
  //       Ideas: 250,
  //       type: 'Availability',
  //     },
  //   {
  //     date: '2023-05',
  //     Ideas: 230,
  //     type: 'Assortment',
  //   },
  //   {
  //     date: '2023-05',
  //     Ideas: 950,
  //     type: 'Flavour',
  //   },
  //   {
  //     date: '2023-05',
  //     Ideas: 200,
  //     type: 'Price',
  //   },
  //     {
  //       date: '2023-05',
  //       Ideas: 150,
  //       type: 'Packaging',
  //     },
  //     {
  //       date: '2023-05',
  //       Ideas: 130,
  //       type: 'Availability',
  //     },
    
  //   {
  //     date: '2023-06',
  //     Ideas: 150,
  //     type: 'Assortment',
  //   },
  //   {
  //     date: '2023-06',
  //     Ideas: 550,
  //     type: 'Flavour',
  //   },
  //   {
  //     date: '2023-06',
  //     Ideas: 100,
  //     type: 'Price',
  //   },
  //   {
  //     date: '2023-06',
  //       Ideas: 150,
  //       type: 'Packaging',
  //     },
  //     {
  //       date: '2023-06',
  //       Ideas: 150,
  //       type: 'Availability',
  //     },
   
  // ];

 
  const config = {
    width: 650,
    height: 500,
    autoFit: false,
    data,
    xField: 'date',
    yField: 'type',
    colorField: 'Ideas',
    color: ['#174c83', '#7eb6d4', '#efefeb', '#efa759', '#9b4d16'],
    meta: {
      'Month of Year': {
        Ideas: 'cat',
      },
    },
    tooltip: {
      showMarkers: true,
    },
    pattern: {
      type: 'square',
      cfg: {
        isStagger: true,
      },
    },
  };

  return <Heatmap {...config} />;
};
 


export default CalendarHeatMap;
